// @copyright (c) 2023-2024 Compular AB
/* eslint-disable react/prop-types */
import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AccountContext } from '../Accounts';
import DefaultButton from '../components/DefaultButtons/DefaultButton';
import NonVisibleIcon from '../icons/NonVisibleIcon';
import VisibleIcon from '../icons/VisibleIcon';
import { logIn } from '../redux/actions/actions';
// DEMOVERSION
import { request } from '../redux/api/axios';

export default function SignIn({
  // setForceChangePassword,
  email,
  password,
  setEmail,
  setPassword,
  setIsHelpModalOpen
  // setSetUpMFA,
  // setForgotPassword,
  // setIsMFA
}) {
  const { authenticate } = useContext(AccountContext);
  const dispatch = useDispatch();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isPasswordActive, setIsPasswordActive] = useState(false);

  const [error, setError] = useState(false);
  const onSubmit = (event) => {
    event.preventDefault();

    authenticate(email, password)
      .then(({ data }) => {
        // REMOVE FOR DEMOVERSION
        // if (data.mfatoken) {
        //   return setIsMFA(true);
        // }
        const { accessToken } = data;
        // if (!data.loggedIn) {
        //   if (data.forceChangePassword) {
        //     setForceChangePassword(data.forceChangePassword);
        //   }
        // }
        // getSession().then(({ accessToken }) => {
        // REMOVE FOR DEMOVERSION
        // if (!datas.mfaEnabled) {
        //   return setSetUpMFA(true);
        // }
        // dispatch(logIn());
        // DEMOVERSION
        if (accessToken) {
          const AUTH_TOKEN = accessToken.jwtToken;
          request.defaults.headers.common.Authorization = AUTH_TOKEN;
          dispatch(logIn());
        }
        // });
        // if (data.loggedIn) {
        //   getSession().then(({ idToken }) => {
        //     // REMOVE FOR DEMOVERSION
        //     // if (!datas.mfaEnabled) {
        //     //   return setSetUpMFA(true);
        //     // }
        //     // dispatch(logIn());
        //     // DEMOVERSION
        //     if (idToken) {
        //       const AUTH_TOKEN = idToken.jwtToken;
        //       request.defaults.headers.common.Authorization = AUTH_TOKEN;
        //       console.log('test22');
        //       dispatch(logIn());
        //     }
        //   });
        // }
      })
      .catch(() => {
        setError(true);
      });
  };

  return (
    <div className="w-full">
      <form onSubmit={onSubmit} className="w-full flex flex-col">
        <div className="flex flex-1 flex-col justify-between  w-full p-0">
          <div className="flex gap-2 pb-2 items-center">
            <p className="font-sans font-normal text-base text-NGrey-9">
              Email
            </p>
          </div>
          <input
            className="h-11 bg-currentColor caret-secondary-10 border-box rounded border border-NGrey-6 font-sans font-normal text-base text-NGrey-9 pl-2 focus:ring-0 ring-0 focus:outline-none focus:border-primary-10"
            value={email}
            autoComplete="off"
            onChange={(event) => {
              setError(false);
              setEmail(event.target.value);
            }}
          />
        </div>
        <div className="flex flex-1 flex-col justify-between  w-full p-0 mt-6">
          <div className="flex gap-2 pb-2 items-center">
            <p className="font-sans font-normal text-base text-NGrey-9">
              Password
            </p>
          </div>
          <div
            className={`border ${
              isPasswordActive ? 'border-primary-9' : 'border-NGrey-6'
            } rounded flex items-center h-11 bg-white pr-2 w-full`}>
            <div className="w-full flex items-center my-[1px]">
              <input
                className="mr-2 caret-secondary-10  my-auto border-0 text-NGrey-9 text-normal border-transparent focus:border-transparent focus:ring-0 h-full w-full focus:outline-none"
                onFocus={() => setIsPasswordActive(true)}
                onBlur={() => {
                  setIsPasswordActive(false);
                }}
                type={passwordVisible ? 'text' : 'password'}
                value={password}
                autoComplete="off"
                onChange={(e) => {
                  setError(false);
                  setPassword(e.target.value);
                }}
              />
            </div>
            {password.length > 0 && (
              <div className="w-full h-full flex items-center flex-1">
                {passwordVisible ? (
                  <VisibleIcon
                    className="w-5 h-5 cursor-pointer text-primary-9 hover:text-primary-7"
                    onMouseDown={() => setPasswordVisible(false)}
                  />
                ) : (
                  <NonVisibleIcon
                    className="w-5 h-5 cursor-pointer text-primary-9 hover:text-primary-7"
                    onMouseDown={() => setPasswordVisible(true)}
                  />
                )}
              </div>
            )}
          </div>
        </div>

        <div className="mt-3">
          {error && (
            <p className="text-Error-8 mb-2">Incorrect email or password</p>
          )}
          {/* REMOVE FOR DEMOVERSION */}
          {/* <div>
            <DefaultButton
              label="Forgot password?"
              type="text-primary"
              onPressed={() => setForgotPassword(true)}
            />
          </div> */}
        </div>
        <div className="flex justify-between w-full mt-6">
          {/* <DefaultButton
            type="outlined-primary"
            label="Contact us"
            onPressed={() => {
              setIsHelpModalOpen(true);
            }}
          /> */}
          {/* DEMO VERSION */}
          <DefaultButton
            type="outlined-primary"
            label="Book demo"
            onPressed={() => {
              setIsHelpModalOpen(true);
            }}
          />
          <button
            className="active:bg-secondary-8 active:border-secondary-8 hover:bg-secondary-7 hover:border-secondary-7 items-center bg-secondary-10 text-white border border-secondary-10 rounded flex gap-2 py-2 px-4 min-w-fit"
            type="submit">
            Login
          </button>
        </div>
      </form>
    </div>
  );
}
