// @copyright (c) 2023-2024 Compular AB
/* eslint-disable no-unused-expressions */
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import RingLoader from 'react-spinners/ClipLoader';
import CloseIcon from '../../icons/CloseIcon';
import InputField from '../InputFields/InputField';
import { convertHeader, openInNewTab } from '../../utils/utils';
import TextArea from '../TextArea/TextArea';
import DefaultButton from '../DefaultButtons/DefaultButton';
// import { UpsertMolecules } from '../../redux/actions/actions';
// import { useDeleteMolecule } from '../../redux/api/molecules/molecules';

/**
 * A modal that can create & update moleculebank.
 *
 * @param isOpen boolean
 * @param close func
 * @returns
 */
const customModalStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    minWidth: '430px',
    overflow: 'hidden',
    height: '100%',
    maxHeight: '477px',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '16px',
    borderRadius: '5px',
    zIndex: 20
  }
};
export default function MoleculeModal({
  isOpen,
  close,
  moleculeKey,
  setJobMolecule
}) {
  const { isLoading } = useSelector((state) => state.reducer);

  const molecule = useSelector((state) =>
    state.reducer.moleculeBank?.find((x) => x[0] === parseInt(moleculeKey))
  );
  const [chemForm, setChemForm] = useState(
    moleculeKey && molecule ? molecule[8] : ''
  );
  const [isMoleculeError, setIsMoleculeError] = useState({
    moleculeNameError: false,

    densityError: false,
    chemFormError: false
  });
  const [info, setInfo] = useState(
    moleculeKey && molecule ? molecule[6] || '' : ''
  );
  const [moleculeDensity, setMoleculeDensity] = useState(
    moleculeKey && molecule ? molecule[4] || '' : ''
  );
  const [moleculeName, setMoleculeName] = useState(
    moleculeKey && molecule ? molecule[1] : ''
  );
  useEffect(() => {
    if (isMoleculeError.densityError) {
      setIsMoleculeError({ ...isMoleculeError, densityError: false });
    }
  }, [moleculeDensity?.length]);
  useEffect(() => {
    if (isMoleculeError.chemFormError) {
      setIsMoleculeError({ ...isMoleculeError, chemFormError: false });
    }
  }, [chemForm.length]);
  useEffect(() => {
    if (isMoleculeError.moleculeNameError) {
      setIsMoleculeError({
        ...isMoleculeError,
        moleculeNameError: false
      });
    }
  }, [moleculeName?.length]);

  // check if true
  function checkMolecule() {
    // DEMOVERSION
    return setJobMolecule(true);
    // let oCheck = { ...isMoleculeError };
    // if (!moleculeName.length > 0) {
    //   oCheck = { ...oCheck, moleculeNameError: true };
    // }
    // if (!chemForm.length > 0) {
    //   oCheck = { ...oCheck, chemFormError: true };
    // }
    // if (moleculeDensity !== '') {
    //   if (moleculeDensity < 0) {
    //     oCheck = { ...oCheck, densityError: true };
    //   }
    // }
    // setIsMoleculeError(oCheck);
    // for (let i = 0, iMax = Object.values(oCheck).length; i < iMax; i++) {
    //   if (Object.values(oCheck)[i] === true) {
    //     return;
    //   }
    // }
    // if (!moleculeKey)
    //   dispatch(
    //     UpsertMolecules(
    //       {
    //         orgId: user.at(0),
    //         name: moleculeName,
    //         chemForm: chemForm,
    //         dens: moleculeDensity === '' ? null : moleculeDensity,
    //         description: info === '' ? null : info
    //       },
    //       function (m) {
    //         setJobMolecule(m);
    //       }
    //     )
    //   );
    // if (moleculeKey)
    //   dispatch(
    //     UpsertMolecules(
    //       {
    //         orgId: user.at(0),
    //         id: moleculeKey,
    //         name: moleculeName,
    //         chemForm: chemForm,
    //         dens: moleculeDensity === '' ? null : moleculeDensity,
    //         description: info === '' ? null : info
    //       },
    //       function (m) {
    //         setJobMolecule(m);
    //       }
    //     )
    //   );
    // while (isLoading) {
    //   setTimeout(() => {}, 100);
    // }
    // if (error) setIsMoleculeError({ ...isMoleculeError, chemFormError: true });
    // if (!isLoading && !error) {
    //   setIsMoleculeError({ ...isMoleculeError, chemFormError: false });
    //   close();
    // }
  }
  useEffect(() => {
    if (moleculeKey && molecule) {
      setChemForm(molecule[8] || '');
      setMoleculeName(molecule[1] || '');
      molecule?.[4]
        ? setMoleculeDensity(molecule?.[4])
        : setMoleculeDensity('');
      molecule?.[5] && molecule?.[6] !== null
        ? setInfo(molecule[6])
        : setInfo('');
    } else {
      setChemForm('');
      setMoleculeName('');
      setMoleculeDensity('');
      setInfo('');
    }
  }, [moleculeKey]);

  return (
    <Modal
      style={customModalStyle}
      isOpen={isOpen}
      ariaHideApp={false}
      onRequestClose={close}
      contentLabel="Create new project">
      <div className="w-full h-full relative">
        <div className="flex justify-end absolute right-0 items-center mt-2 mr-2">
          <CloseIcon
            className="h-5 w-5 cursor-pointer hover:text-NGrey-8"
            onClick={close}
          />
        </div>
        <div
          id="modal-header"
          className="flex flex-col pt-5 gap-2 items-center">
          {moleculeKey ? (
            <p className="text-primary-10 text-xl font-bold">{`Edit ${
              molecule && molecule[8]
            }`}</p>
          ) : (
            <p className="text-primary-10 text-xl font-bold">New molecule</p>
          )}
          <div className="w-full">
            <InputField
              label="Name"
              isNoText={false}
              name="MoleculeName"
              inputValue={moleculeName}
              setInputValue={(e) => setMoleculeName(e.target.value)}
              isRequired={false}
              isError={isMoleculeError?.moleculeNameError || false}
            />
          </div>
          <div className="w-full flex flex-row items-center gap-4">
            <div className="flex-1">
              <InputField
                label="Chemical Formula"
                isNoText={false}
                name="ChemicalFormula"
                inputValue={chemForm}
                setInputValue={(e) => setChemForm(e.target.value)}
                isRequired={false}
                isError={isMoleculeError?.chemFormError || false}
              />
            </div>

            <div className="w-1/3">
              <InputField
                label={convertHeader('Density [g/cm^3]')}
                isNoText={false}
                isRequired={false}
                name="MoleculeDensity"
                isError={isMoleculeError?.densityError}
                inputValue={moleculeDensity}
                setInputValue={(e) => {
                  setMoleculeDensity(e.target.value);
                }}
              />
            </div>
          </div>
          <div id="modal-body" className="items-center w-full h-36 mt-2">
            <TextArea
              label="Additional information"
              isResizable={false}
              inputValue={info}
              setInputValue={(e) => setInfo(e.target.value)}
            />
          </div>
        </div>
        <div className="absolute bottom-0 w-full justify-between items-center flex px-0.5">
          <div>
            <DefaultButton
              label="Help"
              type="text-primary"
              onPressed={() => {
                openInNewTab(
                  'https://compulartech.com/docs/create-job/#composition'
                );
              }}
              icon="help"
              iconPosition="left"
            />
          </div>
          <div className="flex gap-3">
            {moleculeKey ? (
              <>
                <div className="flex-1">
                  <DefaultButton
                    type="outlined-primary"
                    label="Delete"
                    onPressed={() => {
                      close();
                      // dispatch(deletePrivateMolecule(moleculeKey));
                    }}
                  />
                </div>
                <div className="">
                  {isLoading ? (
                    <button
                      type="button"
                      className="active:bg-secondary-8 active:border-secondary-8 hover:bg-secondary-7 hover:border-secondary-7 items-center bg-secondary-10 text-white border border-secondary-10 rounded flex gap-2 py-2 px-4 min-w-fit "
                      disabled>
                      <RingLoader
                        color="#FFF"
                        size={15}
                        speedMultiplier={0.9}
                      />
                      Processing
                    </button>
                  ) : (
                    <DefaultButton
                      type="contained-secondary"
                      label="Update"
                      onPressed={() => {
                        checkMolecule();
                      }}
                    />
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="flex-1">
                  <DefaultButton
                    type="outlined-primary"
                    label="Cancel"
                    onPressed={close}
                  />
                </div>
                <div className="">
                  {isLoading ? (
                    <button
                      type="button"
                      className="active:bg-secondary-8 active:border-secondary-8 hover:bg-secondary-7 hover:border-secondary-7 items-center bg-secondary-10 text-white border border-secondary-10 rounded flex gap-2 py-2 px-4 min-w-fit "
                      disabled>
                      <RingLoader
                        color="#FFF"
                        size={15}
                        speedMultiplier={0.9}
                      />
                      Processing
                    </button>
                  ) : (
                    <DefaultButton
                      type="contained-secondary"
                      label="Add new"
                      onPressed={() => {
                        checkMolecule();
                      }}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}
MoleculeModal.propTypes = {
  isOpen: PropTypes.any.isRequired,
  close: PropTypes.func.isRequired,
  moleculeKey: PropTypes.any,
  setJobMolecule: PropTypes.func.isRequired
};

MoleculeModal.defaultProps = {
  moleculeKey: null
};
