// @copyright (c) 2022-2024 Compular AB
import * as React from 'react';

function CompareIcon(props) {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      {' '}
      <path
        d="M6.007 9.833H1.333v1.333h4.674v2l2.66-2.666-2.66-2.667v2Zm3.986-.667v-2h4.674V5.833H9.993v-2L7.333 6.5l2.66 2.666Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default CompareIcon;
