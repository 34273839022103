// @copyright (c) 2022-2024 Compular AB
import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import MessageIcon from '../../icons/MessageIcon';
import ArrowRightIcon from '../../icons/ArrowRightIcon';
import ArrowLeftIcon from '../../icons/ArrowLeftIcon';
import ArrowDownIcon from '../../icons/ArrowDownIcon';
import ArrowUpIcon from '../../icons/ArrowUpIcon';
import ChevronRight from '../../icons/ChevronRight';
import ChevronLeft from '../../icons/ChevronLeft';
import ChevronDown from '../../icons/ChevronDown';
import ChevronUp from '../../icons/ChevronUp';
import MinusIcon from '../../icons/MinusIcon';
import PlusIcon from '../../icons/PlusIcon';
import LogOutIcon from '../../icons/LogOutIcon';
import TrashIcon from '../../icons/TrashIcon';
import SaveIcon from '../../icons/SaveIcon';
import CompareIcon from '../../icons/CompareIcon';
import InfoIcon from '../../icons/InfoIcon';
import QuestionMarkIcon from '../../icons/QuestionMarkIcon';
import EditIcon from '../../icons/EditIcon';
import PreviewIcon from '../../icons/PreviewIcon';
import ScreeningIcon from '../../icons/ScreeningIcon';
import BarChartIcon from '../../icons/BarChartIcon';
import RadarChartIcon from '../../icons/RadarChartIcon';

/**
 * Outlined button with optioinal label and disabled state
 *
 * @param {string} label Label displayed above button
 * @param {string} tippyContent Content displayed in tooltip
 * @param {boolean} isDisabled Boolean to shift between disabled or not
 * @param {string} type Type of button
 * @param {function} onPressed Function to handle onClick event
 * @param {string} iconPosition If icon is left or right
 * @param {element} icon Icon for button
 * @param {object} refs
 * @returns
 */
const getIconFromName = (icon, size) => {
  let iconSize;
  switch (size) {
    case '2xl':
      iconSize = 'h-6 w-6';
      break;
    default:
      iconSize = 'h-5 w-5';
      break;
  }

  switch (icon) {
    case 'message':
      return <MessageIcon className={iconSize} />;
    case 'BarChart':
      return <BarChartIcon className={iconSize} />;
    case 'RadarChart':
      return <RadarChartIcon className={iconSize} />;
    case 'screening':
      return <ScreeningIcon className={iconSize} />;
    case 'arrowRight':
      return <ArrowRightIcon className={iconSize} />;
    case 'arrowLeft':
      return <ArrowLeftIcon className={iconSize} />;
    case 'arrowDown':
      return <ArrowDownIcon className={iconSize} />;
    case 'arrowUp':
      return <ArrowUpIcon className={iconSize} />;
    case 'chevronRight':
      return <ChevronRight className={iconSize} />;
    case 'chevronLeft':
      return <ChevronLeft className={iconSize} />;
    case 'chevronDown':
      return <ChevronDown className={iconSize} />;
    case 'chevronUp':
      return <ChevronUp className={iconSize} />;
    case 'plus':
      return <PlusIcon className={iconSize} />;
    case 'minus':
      return <MinusIcon className={iconSize} />;
    case 'logout':
      return <LogOutIcon className={iconSize} />;
    case 'help':
      return <QuestionMarkIcon className={iconSize} />;
    case 'edit':
      return <EditIcon className={iconSize} />;
    case 'delete':
      return <TrashIcon className={iconSize} />;
    case 'save':
      return <SaveIcon className={iconSize} />;
    case 'compare':
      return <CompareIcon className={iconSize} />;
    case 'preview':
      return <PreviewIcon className={iconSize} />;
    case 'info':
      return <InfoIcon className={iconSize} />;
    default:
      return null;
  }
};
function DefaultButton({
  label,
  type,
  onPressed,
  iconPosition,
  icon,
  refs,
  tippyContent,
  size,
  underlineOnAction
}) {
  const ref = useRef();
  const [isHovering, setIsHovering] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const handleMouseOver = () => setIsHovering(true);
  const handleMouseOut = () => setIsHovering(false);
  const handleMouseDown = () => setIsActive(true);
  const handleMouseUp = () => setIsActive(false);

  switch (type) {
    case 'outlined-primary':
      if (tippyContent) {
        return (
          <div>
            <Tippy content={tippyContent}>
              <button
                type="button"
                className="active:bg-primary-10 active:text-white hover:py-[7px] hover:px-[15px] items-center hover:border-2 bg-white text-primary-10 border border-primary-10 rounded flex gap-2 py-2 px-4 min-w-fit"
                onClick={onPressed}>
                {iconPosition === 'left' && getIconFromName(icon, size)}
                {label && label.length > 0 && (
                  <p
                    className={`font-bold text-${size} whitespace-nowrap select-none`}>
                    {label}
                  </p>
                )}
                {iconPosition === 'right' && getIconFromName(icon, size)}
              </button>
            </Tippy>
          </div>
        );
      }
      return (
        <div>
          <button
            type="button"
            className="active:bg-primary-10 active:text-white hover:py-[7px] hover:px-[15px] items-center hover:border-2 bg-white text-primary-10 border border-primary-10 rounded flex gap-2 py-2 px-4 min-w-fit"
            onClick={onPressed}>
            {iconPosition === 'left' && getIconFromName(icon, size)}
            {label && label.length > 0 && (
              <p
                className={`font-bold text-${size} whitespace-nowrap select-none`}>
                {label}
              </p>
            )}
            {iconPosition === 'right' && getIconFromName(icon, size)}
          </button>
        </div>
      );
    case 'outlined-secondary':
      if (tippyContent) {
        return (
          <div>
            <Tippy content={tippyContent}>
              <button
                type="button"
                className="active:bg-secondary-10 active:text-white hover:py-[7px] hover:px-[15px] items-center hover:border-2 bg-white text-secondary-10 border border-secondary-10 rounded flex gap-2 py-2 px-4 min-w-fit"
                onClick={onPressed}>
                {iconPosition === 'left' && getIconFromName(icon, size)}
                {label && label.length > 0 && (
                  <p
                    className={`font-bold text-${size} whitespace-nowrap select-none`}>
                    {label}
                  </p>
                )}
                {iconPosition === 'right' && getIconFromName(icon, size)}
              </button>
            </Tippy>
          </div>
        );
      }
      return (
        <div>
          <button
            type="button"
            className="active:bg-secondary-10 active:text-white hover:py-[7px] hover:px-[15px] items-center hover:border-2 bg-white text-secondary-10 border border-secondary-10 rounded flex gap-2 py-2 px-4 min-w-fit"
            onClick={onPressed}>
            {iconPosition === 'left' && getIconFromName(icon, size)}
            {label && label.length > 0 && (
              <p
                className={`font-bold text-${size} whitespace-nowrap select-none`}>
                {label}
              </p>
            )}
            {iconPosition === 'right' && getIconFromName(icon, size)}
          </button>
        </div>
      );
    case 'outlined-error':
      if (tippyContent) {
        return (
          <div>
            <Tippy content={tippyContent}>
              <button
                type="button"
                className="active:bg-Error-6 active:text-white hover:py-[7px] hover:px-[15px] items-center hover:border-2 bg-white text-Error-6 border border-Error-6 rounded flex gap-2 py-2 px-4 min-w-fit"
                onClick={onPressed}>
                {iconPosition === 'left' && getIconFromName(icon, size)}
                {label && label.length > 0 && (
                  <p
                    className={`font-bold text-${size} whitespace-nowrap select-none`}>
                    {label}
                  </p>
                )}
                {iconPosition === 'right' && getIconFromName(icon, size)}
              </button>
            </Tippy>
          </div>
        );
      }
      return (
        <div>
          <button
            type="button"
            className="active:bg-Error-6 active:text-white hover:py-[7px] hover:px-[15px] items-center hover:border-2 bg-white text-Error-6 border border-Error-6 rounded flex gap-2 py-2 px-4 min-w-fit"
            onClick={onPressed}>
            {iconPosition === 'left' && getIconFromName(icon, size)}
            {label && label.length > 0 && (
              <p
                className={`font-bold text-${size} whitespace-nowrap select-none`}>
                {label}
              </p>
            )}
            {iconPosition === 'right' && getIconFromName(icon, size)}
          </button>
        </div>
      );
    case 'contained-primary':
      if (tippyContent) {
        return (
          <div>
            <Tippy content={tippyContent}>
              <button
                type="button"
                className="active:bg-primary-8 active:border-primary-8 hover:bg-primary-7 hover:border-primary-7 items-center bg-primary-10 text-white border border-primary-10 rounded flex gap-2 py-2 px-4 min-w-fit"
                onClick={onPressed}>
                {iconPosition === 'left' && getIconFromName(icon, size)}
                {label && label.length > 0 && (
                  <p
                    className={`font-bold text-${size} whitespace-nowrap select-none`}>
                    {label}
                  </p>
                )}
                {iconPosition === 'right' && getIconFromName(icon, size)}
              </button>
            </Tippy>
          </div>
        );
      }
      return (
        <div>
          <button
            type="button"
            className="active:bg-primary-8 active:border-primary-8 hover:bg-primary-7 hover:border-primary-7 items-center bg-primary-10 text-white border border-primary-10 rounded flex gap-2 py-2 px-4 min-w-fit"
            onClick={onPressed}>
            {iconPosition === 'left' && getIconFromName(icon, size)}
            {label && label.length > 0 && (
              <p
                className={`font-bold text-${size} whitespace-nowrap select-none`}>
                {label}
              </p>
            )}
            {iconPosition === 'right' && getIconFromName(icon, size)}
          </button>
        </div>
      );
    case 'contained-gray': {
      if (tippyContent) {
        return (
          <div>
            <Tippy theme="primaryBlue" content={tippyContent}>
              <button
                type="button"
                ref={refs}
                className="active:bg-secondary-8 active:border-secondary-8 hover:bg-secondary-7 hover:border-secondary-7 items-center bg-secondary-10 text-white border border-secondary-10 rounded flex gap-2 py-2 px-4 min-w-fit"
                onClick={onPressed}>
                {iconPosition === 'left' && getIconFromName(icon, size)}
                {label && label.length > 0 && (
                  <p
                    className={`font-bold text-${size} whitespace-nowrap select-none`}>
                    {label}
                  </p>
                )}
                {iconPosition === 'right' && getIconFromName(icon, size)}
              </button>
            </Tippy>
          </div>
        );
      }
      return (
        <div>
          <button
            type="button"
            ref={refs}
            className="active:bg-NGrey-3 active:border-NGrey-3 cursor-default hover:bg-NGrey-3 hover:border-NGrey-3 items-center bg-NGrey-3 text-NGrey-6 border border-NGrey-3 rounded flex gap-2 py-2 px-4 min-w-fit"
            onClick={onPressed}>
            {iconPosition === 'left' && getIconFromName(icon, size)}
            {label && label.length > 0 && (
              <p
                className={`font-bold text-${size} whitespace-nowrap select-none`}>
                {label}
              </p>
            )}
            {iconPosition === 'right' && getIconFromName(icon, size)}
          </button>
        </div>
      );
    }
    case 'contained-secondary': {
      if (tippyContent) {
        return (
          <div>
            <Tippy theme="primaryBlue" content={tippyContent}>
              <button
                type="button"
                ref={refs}
                className="active:bg-secondary-8 active:border-secondary-8 hover:bg-secondary-7 hover:border-secondary-7 items-center bg-secondary-10 text-white border border-secondary-10 rounded flex gap-2 py-2 px-4 min-w-fit"
                onClick={onPressed}>
                {iconPosition === 'left' && getIconFromName(icon, size)}
                {label && label.length > 0 && (
                  <p
                    className={`font-bold text-${size} whitespace-nowrap select-none`}>
                    {label}
                  </p>
                )}
                {iconPosition === 'right' && getIconFromName(icon, size)}
              </button>
            </Tippy>
          </div>
        );
      }
      return (
        <div>
          <button
            type="button"
            ref={refs}
            className="active:bg-secondary-8 active:border-secondary-8 hover:bg-secondary-7 hover:border-secondary-7 items-center bg-secondary-10 text-white border border-secondary-10 rounded flex gap-2 py-2 px-4 min-w-fit"
            onClick={onPressed}>
            {iconPosition === 'left' && getIconFromName(icon, size)}
            {label && label.length > 0 && (
              <p
                className={`font-bold text-${size} whitespace-nowrap select-none`}>
                {label}
              </p>
            )}
            {iconPosition === 'right' && getIconFromName(icon, size)}
          </button>
        </div>
      );
    }
    case 'text-primary':
      if (tippyContent) {
        return (
          <div>
            <Tippy content={tippyContent}>
              <button
                type="button"
                className=" text-primary-10 hover:text-primary-7 flex items-center gap-2 min-w-fit "
                onClick={onPressed}
                onMouseEnter={handleMouseOver}
                onMouseLeave={handleMouseOut}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}>
                {iconPosition === 'left' && getIconFromName(icon, size)}
                {label && label.length > 0 && (
                  <p
                    ref={ref}
                    className={`whitespace-nowrap font-bold text-${size} select-none ${
                      (isActive || isHovering) && underlineOnAction
                        ? 'underline underline-offset-2'
                        : ''
                    }`}>
                    {label}
                  </p>
                )}
                {iconPosition === 'right' && getIconFromName(icon, size)}
              </button>
            </Tippy>
          </div>
        );
      }
      return (
        <div>
          <button
            type="button"
            className={` text-primary-10 hover:text-primary-8 flex items-center gap-2 min-w-fit `}
            onClick={onPressed}
            onMouseEnter={handleMouseOver}
            onMouseLeave={handleMouseOut}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}>
            {iconPosition === 'left' && getIconFromName(icon, size)}
            {label && label.length > 0 && (
              <p
                ref={ref}
                className={`text-${size} whitespace-nowrap font-bold select-none ${
                  (isActive || isHovering) && underlineOnAction
                    ? 'underline underline-offset-2'
                    : ''
                }`}>
                {label}
              </p>
            )}
            {iconPosition === 'right' && getIconFromName(icon, size)}
          </button>
        </div>
      );
    case 'text-grey':
      if (tippyContent) {
        return (
          <div>
            <Tippy content={tippyContent}>
              <button
                type="button"
                className=" text-NGrey-6 hover:text-NGrey-4 flex items-center gap-2  min-w-fit "
                onClick={onPressed}
                onMouseEnter={handleMouseOver}
                onMouseLeave={handleMouseOut}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}>
                {iconPosition === 'left' && getIconFromName(icon, size)}
                {label && label.length > 0 && (
                  <p
                    ref={ref}
                    className={`whitespace-nowrap font-bold text-${size} select-none ${
                      (isActive || isHovering) && underlineOnAction
                        ? 'underline underline-offset-2'
                        : ''
                    }`}>
                    {label}
                  </p>
                )}
                {iconPosition === 'right' && getIconFromName(icon, size)}
              </button>
            </Tippy>
          </div>
        );
      }
      return (
        <div>
          <button
            type="button"
            className=" text-NGrey-6 hover:text-NGrey-4 flex items-center gap-2  min-w-fit "
            onClick={onPressed}
            onMouseEnter={handleMouseOver}
            onMouseLeave={handleMouseOut}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}>
            {iconPosition === 'left' && getIconFromName(icon, size)}
            {label && label.length > 0 && (
              <p
                ref={ref}
                className={`whitespace-nowrap font-bold text-${size} select-none ${
                  (isActive || isHovering) && underlineOnAction
                    ? 'underline underline-offset-2'
                    : ''
                }`}>
                {label}
              </p>
            )}
            {iconPosition === 'right' && getIconFromName(icon, size)}
          </button>
        </div>
      );
    case 'text-secondary':
      if (tippyContent) {
        return (
          <div>
            <Tippy content={tippyContent}>
              <button
                type="button"
                className=" text-secondary-10 hover:text-secondary-7 flex items-center gap-2  min-w-fit "
                onClick={onPressed}
                onMouseEnter={handleMouseOver}
                onMouseLeave={handleMouseOut}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}>
                {iconPosition === 'left' && getIconFromName(icon, size)}
                {label && label.length > 0 && (
                  <p
                    ref={ref}
                    className={`whitespace-nowrap font-bold text-${size} select-none ${
                      (isActive || isHovering) && underlineOnAction
                        ? 'underline underline-offset-2'
                        : ''
                    }`}>
                    {label}
                  </p>
                )}
                {iconPosition === 'right' && getIconFromName(icon, size)}
              </button>
            </Tippy>
          </div>
        );
      }
      return (
        <div>
          <button
            type="button"
            className=" text-secondary-10 hover:text-secondary-7 flex items-center gap-2  min-w-fit "
            onClick={onPressed}
            onMouseEnter={handleMouseOver}
            onMouseLeave={handleMouseOut}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}>
            {iconPosition === 'left' && getIconFromName(icon, size)}
            {label && label.length > 0 && (
              <p
                ref={ref}
                className={`whitespace-nowrap font-bold text-${size} select-none ${
                  (isActive || isHovering) && underlineOnAction
                    ? 'underline underline-offset-2'
                    : ''
                }`}>
                {label}
              </p>
            )}
            {iconPosition === 'right' && getIconFromName(icon, size)}
          </button>
        </div>
      );
    case 'text-error':
      if (tippyContent) {
        return (
          <div>
            <Tippy content={tippyContent}>
              <button
                type="button"
                className="text-Error-5 hover:text-Error-4 flex items-center gap-2 min-w-fit"
                onClick={onPressed}
                onMouseEnter={handleMouseOver}
                onMouseLeave={handleMouseOut}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}>
                {iconPosition === 'left' && getIconFromName(icon, size)}
                {label && label.length > 0 && (
                  <p
                    ref={ref}
                    className={`font-bold text-${size} whitespace-nowrap select-none ${
                      (isActive || isHovering) && underlineOnAction
                        ? 'underline underline-offset-2'
                        : ''
                    }`}>
                    {label}
                  </p>
                )}
                {iconPosition === 'right' && getIconFromName(icon, size)}
              </button>
            </Tippy>
          </div>
        );
      }
      return (
        <div>
          <button
            type="button"
            className="text-Error-5 hover:text-Error-4 flex items-center gap-2 min-w-fit"
            onClick={onPressed}
            onMouseEnter={handleMouseOver}
            onMouseLeave={handleMouseOut}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}>
            {iconPosition === 'left' && getIconFromName(icon, size)}
            {label && label.length > 0 && (
              <p
                ref={ref}
                className={`font-bold text-${size} whitespace-nowrap select-none ${
                  (isActive || isHovering) && underlineOnAction
                    ? 'underline underline-offset-2'
                    : ''
                }`}>
                {label}
              </p>
            )}
            {iconPosition === 'right' && getIconFromName(icon, size)}
          </button>
        </div>
      );
    default:
      return (
        <div>
          <button
            type="button"
            className="text-NGrey-6 hover:text-NGrey-4 flex items-center gap-2 min-w-fit"
            onClick={onPressed}
            onMouseEnter={handleMouseOver}
            onMouseLeave={handleMouseOut}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}>
            {iconPosition === 'left' && getIconFromName(icon, size)}
            {label && label.length > 0 && (
              <p
                ref={ref}
                className={`font-bold text-${size} whitespace-nowrap select-none ${
                  (isActive || isHovering) && underlineOnAction
                    ? 'underline underline-offset-2'
                    : ''
                }`}>
                {label}
              </p>
            )}
            {iconPosition === 'right' && getIconFromName(icon, size)}
          </button>
        </div>
      );
  }
}
DefaultButton.propTypes = {
  label: PropTypes.string.isRequired,
  tippyContent: PropTypes.string,
  type: PropTypes.string.isRequired,
  onPressed: PropTypes.func.isRequired,
  iconPosition: PropTypes.string,
  icon: PropTypes.string,
  refs: PropTypes.shape({}),
  size: PropTypes.string,
  underlineOnAction: PropTypes.bool
};
DefaultButton.defaultProps = {
  icon: null,
  iconPosition: null,
  refs: null,
  tippyContent: null,
  size: 'base',
  underlineOnAction: true
};

export default DefaultButton;
