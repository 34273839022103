// @copyright (c) 2022-2024 Compular AB
import * as React from 'react';

function ArrowDownIcon(props) {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="m13.5 8-.94-.94-3.727 3.72V2.667H7.5v8.113L3.78 7.053 2.833 8l5.334 5.333L13.5 8Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default ArrowDownIcon;
