// @copyright (c) 2022-2024 Compular AB
import * as React from 'react';

function ArrowUpIcon(props) {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="m2.833 8 .94.94L7.5 5.22v8.113h1.333V5.22l3.72 3.727L13.5 8 8.167 2.667 2.833 8Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default ArrowUpIcon;
