/* @copyright (c) 2021-2022 Compular AB */
import PropTypes from 'prop-types';
import React from 'react';
/**
 * The compular logo, here for easier reuse.
 *
 * @param {React.CSSProperties} style
 * @returns
 */
function CompularLogo({ style }) {
  return (
    <div style={style}>
      <img src="/compular_logo.svg" alt="Compular logo" />
    </div>
  );
}
CompularLogo.propTypes = {
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};
CompularLogo.defaultProps = {
  style: null
};
export default CompularLogo;
