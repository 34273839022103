/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// @copyright (c) 2024 Compular AB
import PropTypes from 'prop-types';
import { useEffect, useState, useMemo } from 'react';
import RingLoader from 'react-spinners/RingLoader';
import LineChartIcon from '../../icons/LineChartIcon';
import ScatterChartIcon from '../../icons/ScatterChartIcon';
import { convertHeader } from '../../utils/utils';
import LineChart from '../Charts/LineChart';
import DropdownMenu from '../DropdownMenu/DropdownMenu';

function getAxisDataMain(key, result) {
  const data = result.mainResult.find((x) => x[0] === key);
  return [`${data[0]} ${data[1]}`, data[2] ?? null];
}

function getData(result, option, dataKeyValue, dataKey) {
  if (
    option.includes('Temperature') ||
    option.includes('Ionic conductivity') ||
    option.includes('Density')
  )
    return getAxisDataMain(dataKey, result);
  return [
    result.molecularSystem[0][dataKeyValue],
    result.molecularSystem
      .slice(1)
      .find((x) => x[0] === option.split(' ')[0])?.[dataKeyValue] ?? null
  ];
}
/**
 * Custom card with chart inside
 * @param {object} jobsData data
 * @returns
 */
export default function CustomCardChart({ jobsData }) {
  const [isComponentLoading, setIsComponentLoading] = useState(true);
  const [axisOptions, setAxisOptions] = useState([]);
  const [isLine, setIsLine] = useState(true);
  const [xAxisOption, setXAxisOption] = useState(null);
  const [yAxisOption, setYAxisOption] = useState(null);

  useEffect(() => {
    if (!isComponentLoading) return;
    if (axisOptions.length > 0) {
      setXAxisOption(axisOptions.find((x) => x.includes('Temperature')));
      setYAxisOption(axisOptions.find((x) => x.includes('Ionic conductivity')));
      setIsComponentLoading(false);
    } else {
      const molRes = jobsData.map(({ result }) => result.molecularSystem);
      const setterHeader = new Set();
      // First add mainres-options to setter
      jobsData[0].result.mainResult
        .filter((x, i) => i > 0)
        .forEach((row) => {
          // row[0] == type, row[1] == unit
          setterHeader.add(`${row[0]} ${row[1]}`);
        });
      // add molRes to setter
      molRes.forEach((element) => {
        const headers = element[0];
        const aHeaders = headers
          .filter(
            (z) =>
              z.includes('Concentration') ||
              z.includes('Diffusivity') ||
              z.includes('Partial conductivity')
          )
          .filter((z) => !z.includes('stderr'));
        aHeaders.forEach((ele) => {
          element
            .filter((val, i) => i > 0)
            .forEach((val) => {
              setterHeader.add(`${val[0]} ${ele}`);
            });
        });
      });
      // loop setter and add to state
      setterHeader.forEach((header) => {
        setAxisOptions((prev) => [...prev, convertHeader(header)]);
      });
    }
  }, [axisOptions]);

  const values = useMemo(() => {
    if (!xAxisOption || !yAxisOption) return [];
    const dataKeyMap = {
      Density: 1,
      Temperature: 2,
      'Ionic conductivity': 3,
      Concentration: 2,
      Diffusivity: 4,
      'Partial conductivity': 7
    };
    const xDataKey = Object.keys(dataKeyMap).find((key) =>
      xAxisOption.includes(key)
    );
    const yDataKey = Object.keys(dataKeyMap).find((key) =>
      yAxisOption.includes(key)
    );
    if (xDataKey === undefined || yDataKey === undefined) return [];
    const xDataKeyValue = dataKeyMap[xDataKey];
    const yDataKeyValue = dataKeyMap[yDataKey];
    return jobsData.map(({ name, result }) => {
      const yData = getData(result, yAxisOption, yDataKeyValue, yDataKey);
      const xData = getData(result, xAxisOption, xDataKeyValue, xDataKey);
      return {
        name,
        data: [xData, yData]
      };
    });
  }, [xAxisOption, yAxisOption]);
  return (
    <div className=" w-full mx-auto flex relative flex-col items-center justify-center card min-w-fit  min-h-[88vh] max-h-[88vh]">
      <p className="w-full text-center absolute top-8 text-primary-10 text-base font-semibold">
        Custom plot
      </p>
      <div className="absolute -left-24 bottom-0 flex justify-start items-center h-full">
        <div className="-rotate-90 min-w-fit">
          <DropdownMenu
            label=""
            options={axisOptions}
            currentValue={yAxisOption}
            onChange={(e) => setYAxisOption(e.target.value)}
          />
        </div>
      </div>
      <div className="absolute bottom-8 w-full left-0 flex items-center justify-center">
        <div className="w-fit">
          <DropdownMenu
            label=""
            options={axisOptions}
            currentValue={xAxisOption}
            onChange={(e) => setXAxisOption(e.target.value)}
          />
        </div>
      </div>
      <div className="absolute top-6 left-12 flex gap-4">
        <button
          type="button"
          className={`${isLine ? 'text-secondary-8' : ' text-NGrey-8'}`}
          onClick={() => {
            setIsLine(true);
          }}>
          {' '}
          <LineChartIcon className={`${isLine ? 'w-8 h-8' : 'w-6 h-6'}`} />
        </button>
        <button
          type="button"
          className={`${!isLine ? 'text-secondary-8' : ' text-NGrey-8'}`}
          onClick={() => {
            setIsLine(false);
          }}>
          {' '}
          <ScatterChartIcon className={`${!isLine ? 'w-8 h-8' : 'w-6 h-6'}`} />
        </button>
      </div>
      {isComponentLoading ? (
        <RingLoader className="w-10 h-10" />
      ) : (
        <div className="flex flex-col w-full">
          <div className="flex flex-row gap-1 w-full">
            <div className=" max-h-[68vh] w-[75%] mx-auto pb-4">
              <LineChart dataJobs={values} type={isLine} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
CustomCardChart.propTypes = {
  jobsData: PropTypes.instanceOf(Array).isRequired
};
