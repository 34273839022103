// @copyright (c) 2023-2024 Compular AB
import * as React from 'react';

function PhoneIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17 16"
      fill="none"
      {...props}>
      <path
        fill="currentColor"
        d="M4.913 7.193a10.1 10.1 0 0 0 4.394 4.394l1.466-1.467c.18-.18.447-.24.68-.16.747.247 1.554.38 2.38.38.367 0 .667.3.667.667v2.326c0 .367-.3.667-.667.667C7.573 14 2.5 8.927 2.5 2.667 2.5 2.3 2.8 2 3.167 2H5.5c.367 0 .667.3.667.667 0 .833.133 1.633.38 2.38a.669.669 0 0 1-.167.68L4.913 7.193Z"
      />
    </svg>
  );
}
export default PhoneIcon;
