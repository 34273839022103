// @copyright (c) 2023-2024 Compular AB
import * as React from 'react';

function NonVisibleIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      {...props}>
      <path
        fill="currentColor"
        d="M11.907 14.625a2.625 2.625 0 0 0 2.615-2.853l-2.844 2.843c.076.006.151.01.229.01ZM20.595 3.88l-1-1a.187.187 0 0 0-.266 0l-2.562 2.563C15.353 4.72 13.765 4.359 12 4.359c-4.504 0-7.865 2.346-10.082 7.039a1.414 1.414 0 0 0 0 1.207c.886 1.866 1.952 3.361 3.2 4.487l-2.48 2.478a.188.188 0 0 0 0 .265l1.001 1a.187.187 0 0 0 .265 0l16.691-16.69a.19.19 0 0 0 .041-.204.189.189 0 0 0-.04-.06ZM7.782 12a4.125 4.125 0 0 1 6.067-3.64L12.71 9.5a2.627 2.627 0 0 0-3.302 3.303l-1.14 1.14A4.104 4.104 0 0 1 7.782 12Z"
      />
      <path
        fill="currentColor"
        d="M22.083 11.395c-.825-1.737-1.807-3.154-2.946-4.25l-3.378 3.378a4.126 4.126 0 0 1-5.33 5.33l-2.865 2.865c1.33.615 2.808.923 4.436.923 4.505 0 7.866-2.346 10.083-7.039a1.413 1.413 0 0 0 0-1.207Z"
      />
    </svg>
  );
}
export default NonVisibleIcon;
