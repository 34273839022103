// @copyright (c) 2022-2024 Compular AB
import { compile } from 'path-to-regexp';
import { useMutation, useQueryClient } from 'react-query';
import API from './api/axios';

const useGenericMutation = (func, queryKey) => {
  const queryClient = useQueryClient();

  return useMutation(func, {
    onSettled: () => {
      queryClient.invalidateQueries(queryKey);
    }
  });
};
export const useGet = (url, options) => API.get(url, { params: options });

export const useDelete = (url) =>
  useGenericMutation(() => API.delete(url), url);

export const usePost = (url, data) => API.post(url, data);

export const usePut = (url, data) =>
  useGenericMutation(() => API.put(url, data), url);

export const pathToUrl = (path, params) => compile(path)(params);
