// @copyright (c) 2022-2024 Compular AB
import * as React from 'react';

function MessageIcon(props) {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M13.334 1.333H2.667c-.733 0-1.333.6-1.333 1.334v12L4 12h9.334c.733 0 1.333-.6 1.333-1.333v-8c0-.734-.6-1.334-1.333-1.334Zm0 9.334H4L2.667 12V2.667h10.666v8Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default MessageIcon;
