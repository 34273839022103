// @copyright (c) 2022-2024 Compular AB
import * as React from 'react';

function LogOutIcon(props) {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="m11.334 4.667-.94.94 1.72 1.726h-6.78v1.334h6.78l-1.72 1.72.94.946L14.667 8l-3.333-3.333ZM2.667 3.333H8V2H2.667c-.733 0-1.333.6-1.333 1.333v9.334c0 .733.6 1.333 1.333 1.333H8v-1.333H2.667V3.333Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default LogOutIcon;
