// @copyright (c) 2022-2024 Compular AB
import axios from 'axios';

export const request = axios;

// If/else check if REACT_APP_LOCAL_DB exists in .env file
request.defaults.baseURL = process.env.REACT_APP_LOCAL_DB
  ? process.env.REACT_APP_LOCAL_DB // *****************LocalInstance************
  : 'https://tech.demo.server.compular.app/';
// 'https://server.api.compular.app/'; // ************ProdInstance*************
// 'https://test.server.api.compular.app/'; // ********TestInstance*************
// 'https://tech.demo.server.compular.app/'; // *******DemoInstance*************

// Add header ['Content-Type'] = 'text/xml'; to all requests.
request.defaults.headers.post['Content-Type'] = 'text/xml';

// Different types of requests
const API = {
  getNoParam: (url) => request.get(url),
  get: (url, params) =>
    request.get(url, {
      params: { ...params }
    }),
  post: (url, data) => request.post(url, data),
  patch: (url, data) => request.patch(url, data),
  put: (url, data) => request.put(url, data),
  delete: (url) => request.delete(url)
};

export default API;
