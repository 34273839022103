// @copyright (c) 2023-2024 Compular AB
import * as React from 'react';

function PreviewIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="none"
      {...props}>
      <path
        fill="currentColor"
        d="M10 3.75C5.833 3.75 2.275 6.342.833 10c1.442 3.658 5 6.25 9.167 6.25 4.166 0 7.725-2.592 9.166-6.25-1.441-3.658-5-6.25-9.166-6.25Zm0 10.417A4.168 4.168 0 0 1 5.833 10C5.833 7.7 7.7 5.833 10 5.833S14.166 7.7 14.166 10 12.3 14.167 10 14.167ZM10 7.5A2.497 2.497 0 0 0 7.5 10c0 1.383 1.116 2.5 2.5 2.5 1.383 0 2.5-1.117 2.5-2.5S11.383 7.5 10 7.5Z"
      />
    </svg>
  );
}
export default PreviewIcon;
