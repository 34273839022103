// @copyright (c) 2023-2024 Compular AB
import * as React from 'react';

function CompanyIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17 16"
      fill="none"
      {...props}>
      <path
        fill="currentColor"
        d="M11.833 7.333V2H5.167v2.667H2.5V14h5.333v-2.667h1.334V14H14.5V7.333h-2.667Zm-6.666 5.334H3.833v-1.334h1.334v1.334Zm0-2.667H3.833V8.667h1.334V10Zm0-2.667H3.833V6h1.334v1.333ZM7.833 10H6.5V8.667h1.333V10Zm0-2.667H6.5V6h1.333v1.333Zm0-2.666H6.5V3.333h1.333v1.334ZM10.5 10H9.167V8.667H10.5V10Zm0-2.667H9.167V6H10.5v1.333Zm0-2.666H9.167V3.333H10.5v1.334Zm2.667 8h-1.334v-1.334h1.334v1.334Zm0-2.667h-1.334V8.667h1.334V10Z"
      />
    </svg>
  );
}
export default CompanyIcon;
