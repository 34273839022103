// @copyright (c) 2022-2024 Compular AB
import * as React from 'react';

function ArrowRightIcon(props) {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="m8.167 2.667-.94.94 3.72 3.726H2.833v1.334h8.114l-3.72 3.726.94.94L13.5 8 8.167 2.667Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default ArrowRightIcon;
