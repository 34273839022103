// @copyright (c) 2022-2024 Compular AB
/* eslint-disable array-callback-return */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ArrowDownIcon from '../../icons/ArrowDownIcon';
import ArrowUpIcon from '../../icons/ArrowUpIcon';
import RenderSvgFile from '../../icons/RenderSvgFile';
import SortIcon from '../../icons/SortIcon';
import { convertHeader, customSort } from '../../utils/utils';
/**
 * A modal that allows the user to contact us.
 *
 * @param data Object
 * @param type string
 * @param keys key
 * @param aUnit array of units
 * @returns
 */
const ENUMS = {
  name: 'MOLECULE NAME',
  value: 'AMOUNT',
  unit: 'UNIT',
  density: 'DENSITY',
  ensemble: 'ENSEMBLE',
  temperature: 'TEMPERATURE',
  pressure: 'PRESSURE'
};
function RenderStoichiometryHeader(key) {
  switch (key) {
    case 'MoleculeK':
      return 'Species';
    case 'FMoleculeCount':
      return 'Number of molecules';
    case 'Molar':
      return 'Molar';
    case 'Molal':
      return 'Molal';
    case 'MolarPercent':
      return 'Mol %';
    case 'WeightPercent':
      return 'Weight %';
    default:
      return 'Error';
  }
}

function renderChildrens(childrens, key) {
  return childrens.map((child, i) => (
    <tr
      className={` ${
        childrens.length - 1 === i ? '' : 'border-dashed'
      } border-b divide-x text-NGrey-8 ${key ? 'bg-white' : 'bg-NGrey-1'}`}
      key={child.id}>
      <td className="py-0.5  pr-3 pl-8 text-left " key={`name${child.id}`}>
        {child.molecule ? child.molecule.name : child.name}
      </td>
      <td className="py-0.5 px-3 text-center" key={`value${child.id}`}>
        {child.value}
      </td>
      <td className="py-0.5  px-3 text-center " key={`unit${child.id}`}>
        {child.unit}
      </td>
    </tr>
  ));
}
export default function Table({ data, type, keys, size, showModal }) {
  const [isSorted, setIsSorted] = useState({});
  const [sortAscending, setSortAscending] = useState(true);
  const [tableData, setTableData] = useState(data);

  function sortTable(sHeaderKey) {
    if (isSorted[sHeaderKey]) {
      setTableData(tableData.toReversed());
      setSortAscending(!sortAscending);
    } else {
      setSortAscending(true);
      if (type === 'compareTable') {
        const sortKey = keys.indexOf(sHeaderKey);
        if (typeof tableData[0][sortKey].dataStr === 'string')
          setTableData(
            tableData.toSorted((a, b) =>
              b[sortKey].dataStr.localeCompare(a[sortKey].dataStr)
            )
          );
        else {
          setTableData(
            tableData.toSorted((a, b) => {
              if (a[sortKey].dataStr === b[sortKey].dataStr) return 0;
              const returnval =
                a[sortKey].dataStr > b[sortKey].dataStr ? -1 : 1;

              return returnval;
            })
          );
        }
        let obj = {};
        keys.map((key) => {
          obj = { ...obj, [key]: false };
          return obj;
        });
        return setIsSorted({
          ...obj,
          [sHeaderKey]: true
        });
      }
      if (type === 'compareMolecular') {
        let obj = {};
        const aHeader = [];
        const setterHeader = new Set();

        for (let index = 0; index < keys.length; index++) {
          const element = keys[index];
          for (let i = 0; i < element.length; i++) {
            const value = element[i];
            setterHeader.add(value);
          }
        }
        setterHeader.forEach((header) => {
          aHeader.push(header);
        });

        aHeader.map((key) => {
          obj = { ...obj, [key]: false };
          return obj;
        });

        const indexForHeader = aHeader.indexOf(sHeaderKey);
        if (sHeaderKey === 'System name') {
          setTableData(
            tableData.toSorted((a, b) =>
              b[0].systemName.localeCompare(a[0].systemName)
            )
          );
        } else {
          setTableData(
            tableData.toSorted((a, b) => {
              if (
                a[indexForHeader][sHeaderKey] === b[indexForHeader][sHeaderKey]
              )
                return 0;
              const returnval =
                a[indexForHeader][sHeaderKey] > b[indexForHeader][sHeaderKey]
                  ? -1
                  : 1;

              return returnval;
            })
          );
        }
        return setIsSorted({
          ...obj,
          [sHeaderKey]: true
        });
      }

      const sortKey = keys.indexOf(sHeaderKey);
      if (typeof tableData[0][sortKey] === 'string') {
        setTableData(customSort(tableData, sortKey, false));
      } else {
        setTableData(customSort(tableData, sortKey, false));
      }
      let obj = {};
      keys.map((key) => {
        obj = { ...obj, [key]: false };
        return obj;
      });
      setIsSorted({
        ...obj,
        [sHeaderKey]: true
      });
    }
  }

  useEffect(() => {
    if (keys && keys.length > 0 && type === 'stoichiometry') {
      setTableData(customSort(tableData, 1, false));
      let obj = {};
      keys.map((key) => {
        if (key === 'FMoleculeCount') {
          obj = { ...obj, [key]: true };
          return obj;
        }
        obj = { ...obj, [key]: false };
        return obj;
      });
      setIsSorted(obj);
      return;
    }

    if (
      keys &&
      keys.length > 0 &&
      type !== 'compareTable' &&
      type !== 'compareMolecular'
    ) {
      let obj = {};
      keys.map((key, i) => {
        if (keys[1] === 'Structural formula' && i === 2) {
          obj = { ...obj, [key]: true };
          return obj;
        }
        if (i === 1 && key !== 'Structural formula') {
          obj = { ...obj, [key]: true };
          return obj;
        }
        obj = { ...obj, [key]: false };
        return obj;
      });
      setIsSorted(obj);
    } else if (keys && keys.length > 0 && type === 'compareMolecular') {
      setTableData(
        tableData.toSorted((a, b) =>
          b[0].systemName.localeCompare(a[0].systemName)
        )
      );
      let obj = {};
      const aHeader = [];
      const setterHeader = new Set();
      for (let index = 0; index < keys.length; index++) {
        const element = keys[index];
        for (let i = 0; i < element.length; i++) {
          const value = element[i];
          setterHeader.add(value);
        }
      }
      setterHeader.forEach((header) => {
        aHeader.push(header);
      });
      aHeader.map((key, i) => {
        if (aHeader[0] === 'System name' && i === 0) {
          obj = { ...obj, [key]: true };
          return obj;
        }
        obj = { ...obj, [key]: false };
        return obj;
      });
      setIsSorted(obj);
    } else if (keys && keys.length > 0 && type === 'compareTable') {
      setTableData(
        tableData.toSorted((a, b) => b[0].dataStr.localeCompare(a[0].dataStr))
      );
      let obj = {};
      keys.map((key) => {
        if (key === 'System name') {
          obj = { ...obj, [key]: true };
          return obj;
        }
        obj = { ...obj, [key]: false };
        return obj;
      });
      setIsSorted(obj);
    }
  }, [keys]);

  if (type === 'compareMolecular') {
    const aHeader = [];
    const setterHeader = new Set();
    for (let index = 0; index < keys.length; index++) {
      const element = keys[index];
      for (let i = 0; i < element.length; i++) {
        const value = element[i];
        setterHeader.add(value);
      }
    }
    setterHeader.forEach((header) => {
      aHeader.push(header);
    });

    const aDataKeys = aHeader.map((header) => {
      if (header === 'System name') {
        return 'systemName';
      }
      return header;
    });
    const aData = tableData.map((a) =>
      aDataKeys.map((b) => {
        const obj = a.find((a2) => Object.keys(a2)[0] === b);
        if (obj?.[b]) return obj[b];
        return null;
      })
    );
    return (
      <div className="overflow-y-hidden scrolling-touch max-h-[600px] w-full  relative shadow-md rounded-lg">
        <table className="w-full text-sm text-NGrey-9 relative text-center rounded-lg ">
          <thead className="text-xs text-white sticky top-0  bg-primary-10 rounded-t-lg">
            <tr
              className=" divide-x 
             rounded-t-lg">
              {aHeader.map((header, ind) => {
                const last = aHeader.length;
                return (
                  <th
                    key={header}
                    onClick={() => sortTable(header)}
                    scope="col"
                    style={{
                      minWidth: '110px',
                      minHeight: '86px',
                      maxWidth: '160px',
                      maxHeight: '100px'
                    }}
                    className={`${ind === 0 ? ' rounded-tl-lg ' : ''}${
                      ind === last - 1
                        ? 'rounded-tr-lg'
                        : ' border-r border-NGrey-6 '
                    }  p-3 text-NGrey-1 hover:bg-primary-7 cursor-pointer  font-medium text-sm text-center `}>
                    <div className="flex items-center justify-between gap-3 w-full ml-auto">
                      <p className="pl-8 diagonal-fractions w-full text-center">
                        {header && header}
                      </p>
                      {isSorted[header] &&
                        (sortAscending ? (
                          <ArrowDownIcon
                            className="min-h-5 w-5 h-5 text-orangeDark-10"
                            style={{
                              minWidth: '20px',
                              minHeight: '20px',
                              maxWidth: '20px',
                              maxHeight: '20px'
                            }}
                          />
                        ) : (
                          <ArrowUpIcon
                            className="min-h-5 w-5 h-5 text-orangeDark-10"
                            style={{
                              minWidth: '20px',
                              minHeight: '20px',
                              maxWidth: '20px',
                              maxHeight: '20px'
                            }}
                          />
                        ))}
                      {!isSorted[header] && (
                        <SortIcon
                          className=" min-h-5 w-5 h-5"
                          style={{
                            minWidth: '20px',
                            minHeight: '20px',
                            maxWidth: '20px',
                            maxHeight: '20px'
                          }}
                        />
                      )}
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="z-0 max-h-[700px]">
            {/* Wait with this. Testdata. */}
            {aData &&
              aData.length > 0 &&
              aData.map((value, i) => {
                return (
                  <tr
                    key={`${i}-val`}
                    className={`border-b divide-x max-h-32 h-14 ${
                      i % 2 === 1 ? 'bg-NGrey-1' : 'bg-white'
                    }`}>
                    {value.map((str, ind) => {
                      return (
                        <td
                          key={`str-${str}-${ind}`}
                          className={`break-keep ${
                            ind === 0 ? 'min-w-[160px]' : ''
                          }`}>
                          <p>{str || '-'}</p>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    );
  }
  if (type === 'stoichiometry') {
    const molecule = useSelector((state) => state.reducer.moleculeBank);
    return (
      <div className="overflow-x-scroll relative shadow-md sm:rounded-lg max-h-[200px]">
        <table className="w-full text-sm text-left text-NGrey-9 relative">
          <thead className="text-xs text-white  bg-NGrey-6 sticky top-0">
            <tr className="divide-x" key={`${type}header`}>
              {keys.map((output, i) => {
                return (
                  <th
                    key={`${output}-headers${i}`}
                    onClick={() => sortTable(output)}
                    scope="col"
                    className="p-3 font-medium text-[15px] text-center hover:bg-NGrey-5 cursor-pointer">
                    <div className="flex flex-1 items-center text-center gap-3">
                      <p className="w-full">
                        {RenderStoichiometryHeader(output)}
                      </p>
                      {isSorted[keys[i]] &&
                        (sortAscending ? (
                          <ArrowDownIcon
                            className="min-h-5 w-5 h-5 text-orangeDark-10"
                            style={{
                              minWidth: '20px',
                              minHeight: '20px',
                              maxWidth: '20px',
                              maxHeight: '20px'
                            }}
                          />
                        ) : (
                          <ArrowUpIcon
                            className="min-h-5 w-5 h-5 text-orangeDark-10"
                            style={{
                              minWidth: '20px',
                              minHeight: '20px',
                              maxWidth: '20px',
                              maxHeight: '20px'
                            }}
                          />
                        ))}
                      {!isSorted[keys[i]] && (
                        <SortIcon
                          className=" min-h-5 w-5 h-5"
                          style={{
                            minWidth: '20px',
                            minHeight: '20px',
                            maxWidth: '20px',
                            maxHeight: '20px'
                          }}
                        />
                      )}
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className=" overflow-auto">
            {tableData.map((row, ind) => {
              return (
                <tr
                  className={`border-b divide-x h-10 text-NGrey-9 ${
                    ind % 2 === 1 ? 'bg-white' : 'bg-NGrey-1'
                  }`}
                  key={`${type}body-${row[1]}-${ind}`}>
                  {row.map((output, i) => {
                    if (i === 0) {
                      const sName = molecule.find((x) => x[0] === output)[8];
                      return (
                        <td
                          className="py-2 px-3 text-center"
                          key={`${i}-condval`}>
                          {sName}
                        </td>
                      );
                    }
                    if (
                      typeof output === 'string' ||
                      typeof output === 'number'
                    ) {
                      return (
                        <td
                          className="py-2 px-3 text-center"
                          key={`${i}-condval`}>
                          {output}
                        </td>
                      );
                    }

                    return (
                      <td
                        className="py-2 px-3 text-center"
                        key={`${i}-condval`}>
                        {' '}
                        {output}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
  if (type === 'conditions') {
    return (
      <div className="overflow-x-scroll relative shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-NGrey-9">
          <thead className="text-xs text-white uppercase  bg-NGrey-6">
            <tr className="divide-x " key={`${type}header`}>
              {keys.map((output) => {
                return (
                  <th
                    key={`${output}-headers`}
                    scope="col"
                    className="p-3 font-medium text-sm text-center">
                    <div className="flex flex-1 items-center text-center gap-3">
                      <p className="w-full">{ENUMS[output]}</p>
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            <tr
              className="border-b divide-x bg-white text-NGrey-8"
              key={`${type}body`}>
              {Object.values(tableData).map((output, i) => {
                if (typeof output === 'string') {
                  return (
                    <td className="py-2 px-3 text-center" key={`${i}-condval`}>
                      {output}
                    </td>
                  );
                }
                const { unit } = output;
                if (Array.isArray(output.value)) {
                  return (
                    <td className="py-2 px-3 text-center" key={`${i}-condval`}>
                      {output.value.join(', ')} {convertHeader(unit)}
                    </td>
                  );
                }
                return (
                  <td className="py-2 px-3 text-center" key={`${i}-condval`}>
                    {output.value} {convertHeader(unit)}
                  </td>
                );
              })}
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
  if (type === 'compositions') {
    let aData = [];
    if (tableData.solute && tableData.solvent) {
      const solventUnit = tableData.solvent?.unit;
      for (let index = 0; index < tableData?.solute?.length; index++) {
        const solute = tableData.solute[index];
        aData.push(solute);
      }

      for (let index = 0; index < tableData?.solvent?.value?.length; index++) {
        const solvent = tableData.solvent.value[index];
        solvent.unit = solventUnit;

        if (!solvent.isParent) aData.push(solvent);
        if (solvent.isParent && solvent.children.value.length > 0) {
          const childrenUnit = solvent.children.unit;
          solvent.child = [];

          for (let i = 0; i < solvent.children.value.length; i++) {
            const child = solvent.children.value[i];
            child.unit = childrenUnit;
            solvent.child.push(child);
          }
          aData.push(solvent);
        }
      }
    } else {
      aData = tableData.map((comp) => {
        return {
          ...comp,
          child: comp.children
        };
      });
    }
    if (size === 'small') {
      return (
        <div className="overflow-x-scroll max-h-[230px]  relative shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-NGrey-9 relative">
            <thead className="text-xs text-white uppercase sticky top-0 bg-NGrey-6">
              <tr className="divide-x">
                <th scope="col" className="p-3 font-medium text-sm text-center">
                  <div className="flex flex-1 items-center text-center gap-3">
                    <p className="w-full">Molecule name</p>
                  </div>
                </th>
                <th scope="col" className="p-3 font-medium text-sm text-center">
                  <div className="flex flex-1 items-center text-center gap-3">
                    <p className="w-full">Amount</p>
                  </div>
                </th>
                <th scope="col" className="p-3 font-medium text-sm text-center">
                  <div className="flex flex-1 items-center text-center gap-3">
                    <p className="w-full">Unit</p>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="overflow-auto">
              {aData.map((output, i) => {
                return (
                  <React.Fragment key={`rows-${i}`}>
                    <tr
                      className={`${
                        !output.child ? '' : 'border-dashed'
                      } border-b divide-x text-NGrey-8 ${
                        i % 2 === 1 ? 'bg-white' : 'bg-NGrey-1'
                      }`}>
                      <td
                        className="py-0.5 px-3 text-left font-semibold"
                        key={`name${i}`}>
                        {/* 
                          If isParent == true render Name(string) or if empty render CommonName
                          else render CommonName or if empty Name(string)
                         */}
                        {output?.isParent
                          ? output.name || output.molecule.CommonName
                          : output?.molecule?.CommonName || output.name}
                        (
                        <span className="text-secondary-10">
                          {output.molecule.abb}
                        </span>
                        )
                      </td>
                      <td
                        className="py-0,5 px-3 text-center font-semibold"
                        key={`value${i}`}>
                        {output.value}
                      </td>
                      <td
                        className="py-0.5 px-3 text-center font-semibold"
                        key={`unit${i}`}>
                        {output.unit}
                      </td>
                    </tr>
                    {output &&
                      output.child.length > 0 &&
                      renderChildrens(output.child, i % 2 === 1)}
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    }
    return (
      <div className="overflow-x-scroll relative shadow-md w-full  sm:rounded-lg rounded-md">
        <table className="w-full text-sm text-left text-NGrey-9">
          <thead className="text-xs text-white uppercase  bg-NGrey-6">
            <tr className="divide-x">
              <th scope="col" className="p-3 font-medium text-sm text-center">
                <div className="flex flex-1 items-center text-center gap-3">
                  <p className="w-full">Molecule name</p>
                </div>
              </th>
              <th scope="col" className="p-3 font-medium text-sm text-center">
                <div className="flex flex-1 items-center text-center gap-3">
                  <p className="w-full">Amount</p>
                </div>
              </th>
              <th scope="col" className="p-3 font-medium text-sm text-center">
                <div className="flex flex-1 items-center text-center gap-3">
                  <p className="w-full">Unit</p>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {aData.map((output, i) => {
              return (
                <React.Fragment key={`rows-${i}`}>
                  <tr
                    className={`${
                      !output.isParent ? '' : 'border-dashed'
                    } border-b divide-x text-NGrey-8 ${
                      i % 2 === 1 ? 'bg-white' : 'bg-NGrey-1'
                    }`}>
                    <td
                      className="py-2 px-3 text-left font-semibold"
                      key={`name${i}`}>
                      <div className="flex flex-nowrap overflow-x-scroll whitespace-nowrap scrollbar-hide">
                        <p>
                          {output?.isParent
                            ? output.name || output.molecule.CommonName
                            : output?.molecule?.CommonName || output.name}
                          <span className="text-secondary-10 ml-1">
                            {output.molecule.abb}
                          </span>
                        </p>
                      </div>
                    </td>
                    <td
                      className="py-2 px-3 text-center font-semibold"
                      key={`value${i}`}>
                      {output.value}
                    </td>
                    <td
                      className="py-2 px-3 text-center font-semibold"
                      key={`unit${i}`}>
                      {output.unit}
                    </td>
                  </tr>
                  {output.child &&
                    output.child?.length > 0 &&
                    renderChildrens(output.child, i % 2 === 1)}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
  if (type === 'compareTable') {
    return (
      <div className="overflow-y-hidden scrolling-touch max-h-[600px]  relative shadow-md rounded-lg">
        <table className="w-full text-sm text-NGrey-9 relative text-center rounded-lg ">
          <thead className="text-xs text-white sticky top-0  bg-primary-10 rounded-t-lg">
            <tr
              className=" divide-x 
             rounded-t-lg">
              {keys
                .filter((i) => i !== 'xyz-file')
                .map((header, ind) => {
                  const last = keys.length;
                  return (
                    <th
                      key={header}
                      onClick={() => sortTable(header)}
                      scope="col"
                      style={{
                        minWidth: '110px',
                        minHeight: '86px',
                        maxWidth: '160px',
                        maxHeight: '100px'
                      }}
                      className={`${ind === 0 ? ' rounded-tl-lg ' : ''}${
                        ind === last - 1
                          ? 'rounded-tr-lg'
                          : ' border-r border-NGrey-6 '
                      }  p-3 text-NGrey-1 hover:bg-primary-7 cursor-pointer  font-medium text-sm text-center `}>
                      <div className="flex items-center justify-around w-full ml-auto">
                        <p className=" pl-5 diagonal-fractions w-full text-center">
                          {convertHeader(header)}
                        </p>
                        {isSorted[header] &&
                          (sortAscending ? (
                            <ArrowDownIcon
                              className="min-h-5 w-5 h-5 text-orangeDark-10"
                              style={{
                                minWidth: '20px',
                                minHeight: '20px',
                                maxWidth: '20px',
                                maxHeight: '20px'
                              }}
                            />
                          ) : (
                            <ArrowUpIcon
                              className="min-h-5 w-5 h-5 text-orangeDark-10"
                              style={{
                                minWidth: '20px',
                                minHeight: '20px',
                                maxWidth: '20px',
                                maxHeight: '20px'
                              }}
                            />
                          ))}
                        {!isSorted[header] && (
                          <SortIcon
                            className=" min-h-5 w-5 h-5"
                            style={{
                              minWidth: '20px',
                              minHeight: '20px',
                              maxWidth: '20px',
                              maxHeight: '20px'
                            }}
                          />
                        )}
                      </div>
                    </th>
                  );
                })}
            </tr>
          </thead>
          <tbody className="z-0 max-h-[700px]">
            {/* Wait with this. Testdata. */}
            {tableData &&
              tableData.length > 0 &&
              tableData.map((value, i) => {
                return (
                  <tr
                    key={`${i}-val`}
                    className={`border-b divide-x max-h-32 h-14 ${
                      i % 2 === 1 ? 'bg-NGrey-1' : 'bg-white'
                    }`}>
                    {value.map((str, ind) => {
                      const { dataStr } = str;
                      const { error } = str;
                      if (error && typeof error === 'number') {
                        return (
                          <td
                            key={`str-${str}-${ind}`}
                            className={`break-keep ${
                              ind === 0 ? 'min-w-[160px]' : ''
                            }`}>
                            <p>{convertHeader(`${dataStr}`)}</p>

                            <div className="text-Error-4 mt-1">
                              &#177; {error}
                            </div>
                          </td>
                        );
                      }
                      return (
                        <td
                          key={`str-${str}-${ind}`}
                          className={`break-keep ${
                            ind === 0 ? 'min-w-[160px]' : ''
                          }`}>
                          <p>{convertHeader(`${dataStr}`)}</p>
                          {/* <div className="text-Error-4 mt-1">
                            &#177; {error}
                          </div> */}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    );
  }
  if (type === 'small') {
    if (Object.keys(isSorted) && Object.keys(isSorted).length > 0) {
      const currentHeader = Object.keys(isSorted).filter((sortedHeader) => {
        return isSorted?.[sortedHeader];
      })[0];
      const sortKey = keys.indexOf(currentHeader);
      if (typeof tableData[0][sortKey] === 'string')
        tableData.toSorted((a, b) => a[sortKey].localeCompare(b[sortKey]));
      else {
        tableData.toSorted((a, b) => {
          if (a[sortKey] === b[sortKey]) return 0;
          const returnval = a[sortKey] > b[sortKey] ? -1 : 1;

          return returnval;
        });
      }
    }
    const aData = [...tableData].map((array) => {
      // let newData = [];
      return array
        .filter((res, i) => keys.length - 1 > i)
        .map((res, i) => {
          if (keys?.[i].includes('stderr')) {
            return undefined;
          }
          if (
            !keys?.[i].includes('stderr') &&
            !keys?.[i + 1].includes('stderr')
          ) {
            return res;
          }
          if (
            keys?.[i + 1].includes('stderr') &&
            !keys?.[i].includes('stderr')
          ) {
            return {
              value: res,
              error: array[i + 1]
            };
          }
        })
        .filter((res) => {
          if (res) return true;
          if (res === 0) return true;
          if (res === null) return true;
          if (res === undefined) return false;
        });
    });

    return (
      <div className="overflow-y-hidden scrolling-touch max-h-[600px]  relative shadow-md rounded-lg">
        <table className="w-full text-sm text-NGrey-9 relative text-center rounded-lg ">
          <thead className="text-xs text-white sticky top-0  bg-primary-10 rounded-t-lg">
            <tr
              className=" divide-x 
             rounded-t-lg">
              {keys
                .filter((header) => header !== 'xyz-file')
                .filter((header) => !header.includes('stderr'))

                .map((header, ind) => {
                  const last = keys.length;
                  return (
                    <th
                      key={header}
                      onClick={() => sortTable(header)}
                      scope="col"
                      style={{
                        minWidth: '110px',
                        minHeight: '86px',
                        maxWidth: '160px',
                        maxHeight: '100px'
                      }}
                      className={`${ind === 0 ? ' rounded-tl-lg ' : ''}${
                        ind === last - 1
                          ? 'rounded-tr-lg'
                          : ' border-r border-NGrey-6 '
                      }  p-3 text-NGrey-1 hover:bg-primary-7 cursor-pointer  font-medium text-sm text-center `}>
                      <div className="flex items-center justify-around gap-3 w-full ml-auto">
                        <p className="diagonal-fractions">
                          {convertHeader(header)}
                        </p>
                        {isSorted[header] &&
                          (sortAscending ? (
                            <ArrowDownIcon
                              className="min-h-5 w-5 h-5 text-orangeDark-10"
                              style={{
                                minWidth: '20px',
                                minHeight: '20px',
                                maxWidth: '20px',
                                maxHeight: '20px'
                              }}
                            />
                          ) : (
                            <ArrowUpIcon
                              className="min-h-5 w-5 h-5 text-orangeDark-10"
                              style={{
                                minWidth: '20px',
                                minHeight: '20px',
                                maxWidth: '20px',
                                maxHeight: '20px'
                              }}
                            />
                          ))}
                        {!isSorted[header] && (
                          <SortIcon
                            className=" min-h-5 w-5 h-5"
                            style={{
                              minWidth: '20px',
                              minHeight: '20px',
                              maxWidth: '20px',
                              maxHeight: '20px'
                            }}
                          />
                        )}
                      </div>
                    </th>
                  );
                })}
            </tr>
          </thead>
          <tbody className="z-0 max-h-[700px]">
            {aData.map((val, i) => {
              return (
                <tr
                  key={`${i}-val`}
                  className={`border-b divide-x max-h-32 h-14 ${
                    i % 2 === 1 ? 'bg-NGrey-1' : 'bg-white'
                  }`}>
                  {val.map((res, ind) => {
                    if (!res && res !== 0) {
                      return (
                        <td
                          key={`str-null-${ind}`}
                          className={`break-keep ${
                            ind === 0 ? 'min-w-[160px]' : ''
                          }`}>
                          <div>-</div>
                        </td>
                      );
                    }
                    if (res === 0) {
                      return (
                        <td className="h-full" key={`str-null-${ind}`}>
                          {keys
                            .filter((header) => header !== 'xyz-file')
                            .filter((header) => !header.includes('stderr'))[
                            ind
                          ] === 'Charge' &&
                            res === 0 && <p className=" text-3xl">{res}</p>}
                        </td>
                      );
                    }
                    const { value, error } = res;
                    if (typeof res === 'number' || typeof res === 'string') {
                      return (
                        <td
                          key={`str-${res}-${ind}`}
                          className={`break-keep ${
                            ind === 0 ? 'min-w-[160px]' : ''
                          }`}>
                          {typeof res === 'string' && ind === 1 && (
                            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions
                            <RenderSvgFile
                              size="table"
                              onPress={() => showModal(tableData[i])}
                              svgBody={res}
                            />
                          )}
                          {keys
                            .filter((header) => header !== 'xyz-file')
                            .filter((header) => !header.includes('stderr'))[
                            ind
                          ] === 'Charge' &&
                            res >= 1 && <p className=" text-3xl">+{res}</p>}
                          {keys
                            .filter((header) => header !== 'xyz-file')
                            .filter((header) => !header.includes('stderr'))[
                            ind
                          ] === 'Charge' &&
                            res <= -1 && <p className=" text-3xl">{res}</p>}

                          {!(
                            (typeof res === 'string' && ind === 1) ||
                            keys
                              .filter((header) => header !== 'xyz-file')
                              .filter((header) => !header.includes('stderr'))[
                              ind
                            ] === 'Charge'
                          ) && res}
                        </td>
                      );
                    }

                    if (typeof res !== 'number' || typeof res !== 'string') {
                      if (value === null && error === null) {
                        return (
                          <td
                            key={`str-null-${ind}`}
                            className={`break-keep ${
                              ind === 0 ? 'min-w-[160px]' : ''
                            }`}>
                            <div>-</div>
                          </td>
                        );
                      }
                      if (value === 0 && error === 0) {
                        return (
                          <td
                            key={`str-${value}-${ind}`}
                            className={`break-keep ${
                              ind === 0 ? 'min-w-[160px]' : ''
                            }`}>
                            <div>{value}</div>

                            <div className="text-Error-4 mt-1">
                              &#177; {error}
                            </div>
                          </td>
                        );
                      }
                      return (
                        <td
                          key={`str-${value}-${ind}`}
                          className={`break-keep ${
                            ind === 0 ? 'min-w-[160px]' : ''
                          }`}>
                          <div>{value}</div>
                          {error && (
                            <div className="text-Error-4 mt-1">
                              &#177; {error}
                            </div>
                          )}
                        </td>
                      );
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
  if (Object.keys(isSorted) && Object.keys(isSorted).length > 0) {
    const currentHeader = Object.keys(isSorted).filter((sortedHeader) => {
      return isSorted?.[sortedHeader];
    })[0];
    const sortKey = keys.indexOf(currentHeader);
    if (typeof tableData[0][sortKey] === 'string')
      tableData.toSorted((a, b) => a[sortKey].localeCompare(b[sortKey]));
    else {
      tableData.toSorted((a, b) => {
        if (a[sortKey] === b[sortKey]) return 0;
        const returnval = a[sortKey] > b[sortKey] ? -1 : 1;

        return returnval;
      });
    }
  }
  const aData = tableData.map((array) => {
    // let newData = [];
    return array
      .filter((res, i) => keys.length - 1 > i)
      .map((res, i) => {
        if (keys?.[i].includes('stderr')) {
          return undefined;
        }
        if (
          !keys?.[i].includes('stderr') &&
          !keys?.[i + 1].includes('stderr')
        ) {
          return res;
        }
        if (keys?.[i + 1].includes('stderr') && !keys?.[i].includes('stderr')) {
          return {
            value: res,
            error: array[i + 1]
          };
        }
      })
      .filter((res) => {
        if (res) return true;
        if (res === 0) return true;
        if (res === null) return true;
        if (res === undefined) return false;
      });
  });

  return (
    <div className="overflow-x-scroll scrolling-touch max-h-[643px] relative shadow-md rounded-lg">
      <table className="w-full text-sm text-NGrey-9 relative text-center rounded-lg ">
        <thead className="text-xs text-white sticky top-0  bg-primary-10 rounded-t-lg">
          <tr
            className="
       rounded-t-lg">
            {keys
              .filter((header) => header !== 'xyz-file')
              .filter((header) => !header.includes('stderr'))
              .map((header, ind) => {
                const last = keys.length;

                return (
                  <th
                    key={header}
                    onClick={() => sortTable(header)}
                    scope="col"
                    style={{
                      minWidth: '110px',
                      minHeight: '86px',
                      maxWidth: '160px',
                      maxHeight: '100px'
                    }}
                    className={`${ind === 0 ? ' rounded-tl-lg ' : ''}${
                      ind === last - 1
                        ? 'rounded-tr-lg'
                        : ' border-r border-NGrey-6 '
                    }  p-3 text-NGrey-1 hover:bg-primary-7 cursor-pointer  font-medium text-sm text-center `}>
                    <div className="flex items-center justify-around gap-3 w-full ml-auto">
                      <p className="diagonal-fractions">
                        {convertHeader(header)}
                      </p>
                      {isSorted[header] &&
                        (sortAscending ? (
                          <ArrowDownIcon
                            className="min-h-5 w-5 h-5 text-orangeDark-10"
                            style={{
                              minWidth: '20px',
                              minHeight: '20px',
                              maxWidth: '20px',
                              maxHeight: '20px'
                            }}
                          />
                        ) : (
                          <ArrowUpIcon
                            className="min-h-5 w-5 h-5 text-orangeDark-10"
                            style={{
                              minWidth: '20px',
                              minHeight: '20px',
                              maxWidth: '20px',
                              maxHeight: '20px'
                            }}
                          />
                        ))}
                      {!isSorted[header] && (
                        <SortIcon
                          className=" min-h-5 w-5 h-5"
                          style={{
                            minWidth: '20px',
                            minHeight: '20px',
                            maxWidth: '20px',
                            maxHeight: '20px'
                          }}
                        />
                      )}
                    </div>
                  </th>
                );
              })}
          </tr>
        </thead>
        <tbody className="z-0 max-h-[700px]">
          {/* Wait with this. Testdata. */}
          {aData
            //
            .map((val, i) => {
              return (
                <tr
                  key={`${i}-val`}
                  className={`border-b divide-x max-h-32 h-14 ${
                    i % 2 === 1 ? 'bg-NGrey-1' : 'bg-white'
                  }`}>
                  {val.map((res, ind) => {
                    if (!res && res !== 0) {
                      return (
                        <td
                          key={`str-null-${ind}`}
                          className={`break-keep ${
                            ind === 0 ? 'min-w-[160px]' : ''
                          }`}>
                          <div>-</div>
                        </td>
                      );
                    }
                    if (res === 0) {
                      return (
                        <td className="h-full" key={`str-null-${ind}`}>
                          {keys
                            .filter((header) => header !== 'xyz-file')
                            .filter((header) => !header.includes('stderr'))[
                            ind
                          ] === 'Charge' &&
                            res === 0 && <p className="text-3xl">{res}</p>}
                        </td>
                      );
                    }
                    const { value, error } = res;
                    if (typeof res === 'number' || typeof res === 'string') {
                      return (
                        <td
                          key={`str-${res}-${ind}`}
                          className={`break-keep ${
                            ind === 0 ? 'min-w-[160px]' : ''
                          }`}>
                          {typeof res === 'string' && ind === 1 && (
                            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions

                            <RenderSvgFile
                              size="table"
                              onPress={() => showModal(tableData[i])}
                              svgBody={res}
                            />
                          )}
                          {keys
                            .filter((header) => header !== 'xyz-file')
                            .filter((header) => !header.includes('stderr'))[
                            ind
                          ] === 'Charge' &&
                            res >= 1 && <p className=" text-3xl">+{res}</p>}
                          {keys
                            .filter((header) => header !== 'xyz-file')
                            .filter((header) => !header.includes('stderr'))[
                            ind
                          ] === 'Charge' &&
                            res <= -1 && <p className=" text-3xl">{res}</p>}
                          {keys
                            .filter((header) => header !== 'xyz-file')
                            .filter((header) => !header.includes('stderr'))[
                            ind
                          ] === 'Charge' &&
                            res === 0 && <p className=" text-3xl">{res}</p>}

                          {!(
                            (typeof res === 'string' && ind === 1) ||
                            keys
                              .filter((header) => header !== 'xyz-file')
                              .filter((header) => !header.includes('stderr'))[
                              ind
                            ] === 'Charge'
                          ) && res}
                        </td>
                      );
                    }

                    if (typeof res !== 'number' || typeof res !== 'string') {
                      if (value === null && error === null) {
                        return (
                          <td
                            key={`str-null-${ind}`}
                            className={`break-keep ${
                              ind === 0 ? 'min-w-[160px]' : ''
                            }`}>
                            <div>-</div>
                          </td>
                        );
                      }
                      if (value === 0 && error === 0) {
                        return (
                          <td
                            key={`str-${value}-${ind}`}
                            className={`break-keep ${
                              ind === 0 ? 'min-w-[160px]' : ''
                            }`}>
                            <div>{value}</div>

                            <div className="text-Error-4 mt-1">
                              &#177; {error}
                            </div>
                          </td>
                        );
                      }
                      return (
                        <td
                          key={`str-${value}-${ind}`}
                          className={`break-keep ${
                            ind === 0 ? 'min-w-[160px]' : ''
                          }`}>
                          <div>{value}</div>
                          {error && (
                            <div className="text-Error-4 mt-1">
                              &#177; {error}
                            </div>
                          )}
                        </td>
                      );
                    }
                  })}
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}
Table.propTypes = {
  data: PropTypes.any,
  keys: PropTypes.instanceOf(Array),
  showModal: PropTypes.func,
  type: PropTypes.string,
  size: PropTypes.string
};
Table.defaultProps = {
  data: [],
  keys: [],
  type: '',
  showModal: null,
  size: null
};
