// @copyright (c) 2023-2024 Compular AB
import * as React from 'react';

function CheckIcon(props) {
  return (
    <svg
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M8.687 15.41 4.778 11.5l-1.331 1.322 5.24 5.24 11.25-11.25-1.321-1.321-9.929 9.918Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default CheckIcon;
