// @copyright (c) 2022-2024 Compular AB
import { useGet, usePost } from '../../querys';

export const useGetGenerateJobFiles = (orgId, body) =>
  usePost(`/generate_job_files?&organization=${orgId}`, body);

export const useGetResult = (path, type, orgId, jobId) =>
  useGet(
    `/script?name=get_file&filename=${path}&type=${type}&org=${orgId}&job=${jobId}`
  );
