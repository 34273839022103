/* @copyright (c) 2021-2022 Compular AB */
import PropTypes from 'prop-types';

/**
 * Dropdown menu with optioinal label and disabled state
 *
 * @param {boolean} isDisabled Boolean to shift between disabled or not
 * @param {string} placeHolder Text shown inside dropdown menu
 * @param {string} label Label displayed above button
 * @param {options} options Array with options to show in dropdown menu
 * @param {Number} currentValue Value to handle current choosen option
 * @param {function} onChange Function to handle change of currentValue
 * @returns
 */

function DropdownMenu({
  isDisabled,
  placeHolder,
  label,
  options,
  currentValue,
  onChange
}) {
  return (
    <div className="flex flex-1 flex-col w-full p-0 font-sans font-normal ">
      {label && (
        <label className="font-sans font-normal text-base text-NGrey-9 pb-2">
          {label}
        </label>
      )}
      {isDisabled ? (
        <select
          value={currentValue}
          className="dropdown-disabled h-11 focus:bg-primary-10 focus:text-white rounded border border-NGrey-6 placeholder:text-NGrey-6 px-3  bg-NGrey-2 focus:ring-0 focus:border-primary-10"
          disabled>
          <option value="placeholder" disabled>
            {placeHolder}
          </option>
          {options.map((option, id) => (
            <option key={id} value={option}>
              {option}
            </option>
          ))}
        </select>
      ) : (
        <select
          value={currentValue}
          className="h-11 focus:bg-primary-10 focus:text-white rounded border border-NGrey-6 placeholder:text-NGrey-6 px-3 cursor-pointer focus:ring-0 focus:border-primary-10"
          onChange={onChange}>
          <option value="placeholder" disabled>
            {placeHolder}
          </option>
          {options.map((option, id) => (
            <option key={id} value={option}>
              {option}
            </option>
          ))}
        </select>
      )}
    </div>
  );
}

DropdownMenu.propTypes = {
  isDisabled: PropTypes.bool,
  placeHolder: PropTypes.string,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string),
  currentValue: PropTypes.string,
  onChange: PropTypes.func.isRequired
};
DropdownMenu.defaultProps = {
  isDisabled: false,
  placeHolder: 'Choose',
  options: [],
  currentValue: 'placeholder'
};

export default DropdownMenu;
