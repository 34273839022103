// @copyright (c) 2022-2024 Compular AB
import * as React from 'react';

function UserIcon(props) {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M8 1.333A6.67 6.67 0 0 0 1.333 8 6.67 6.67 0 0 0 8 14.666 6.669 6.669 0 0 0 14.667 8 6.669 6.669 0 0 0 8 1.333Zm0 2c1.107 0 2 .893 2 2s-.893 2-2 2-2-.893-2-2 .893-2 2-2ZM8 12.8a4.8 4.8 0 0 1-4-2.147C4.02 9.326 6.667 8.6 8 8.6c1.327 0 3.98.726 4 2.053A4.8 4.8 0 0 1 8 12.8Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default UserIcon;
