/* @copyright (c) 2021-2022 Compular AB */
import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Compositions from '../../../components/Compositions/Compositions';
import DefaultButton from '../../../components/DefaultButtons/DefaultButton';
import DropdownMenu from '../../../components/DropdownMenu/DropdownMenu';
import InputField from '../../../components/InputFields/InputField';
import ConfirmModal from '../../../components/Modals/ConfirmModal';
import Page from '../../../components/Page/Page';
import RadioButton from '../../../components/RadioButton/RadioButton';
import TextArea from '../../../components/TextArea/TextArea';
import WhiteBackgroundBox from '../../../components/WhiteBackgroundBox/WhiteBackgroundBox';
import {
  createJob,
  deleteJobById
  // GetStoichiometry
} from '../../../redux/actions/actions';
import {
  ENSEMBLE_OPTIONS,
  PRESSURE_UNITS,
  PRESSURE_UNIT_KEYS,
  TEMPERATURE_UNITS,
  TEMPERATURE_UNIT_KEYS,
  openInNewTab,
  SOLVENT_UNITS
} from '../../../utils/utils';
import LoadingPage from '../../LoadingPage/LoadingPage';
import DemoRequestModal from '../../../components/Modals/DemoRequestModal';
import MoleculeModal from '../../../components/Modals/MoleculeModal';
import PreviewModal from '../../../components/Modals/PreviewMoleculeModal';
import ScreeningModal from '../../../components/Modals/ScreeningModal';

function PrepareGenerationPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { id, screeningId } = useParams();
  const dispatch = useDispatch();
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
  const [editId, setEditId] = useState(null);
  const [isScreeningModalOpen, setIsScreeningModalOpen] = useState({
    open: false,
    type: ''
  });
  const [isMoleculeModalOpen, setIsMoleculeModalOpen] = useState(false);
  const [isPreviewMoleculeModalOpen, setIsPreviewMoleculeModalOpen] =
    useState(false);
  const [moleculePreviewId, setMoleculePreviewId] = useState(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [ConfirmModalId, setConfirmModalId] = useState(null);
  const [moleculePreviewInfo, setMoleculePreviewInfo] = useState(null);
  const { user, jobs, isLoading, moleculeBank } = useSelector(
    (state) => state.reducer
  );

  useEffect(() => {
    // if no user page has been refreshed.
    // Redirect to jobspage to check if user still is loggedin
    if (!user) {
      navigate('/jobs');
    }
  }, []);
  // get jobId from location state
  const aCopyCompJobId = useMemo(() => {
    if (location.state) {
      if (Array.isArray(location.state.compositionsJobId)) return null;
      return location.state.compositionsJobId;
    }
    return null;
  }, [location.state]);
  // get screeningId from location state
  const aCopyScreeningId = useMemo(() => {
    if (location.state) {
      if (Array.isArray(location.state.screeningId)) return null;
      return location.state.screeningId;
    }
    return null;
  }, [location.state]);

  // get id for job and screening
  const jobKey = aCopyCompJobId || id;
  const screeningKey = aCopyScreeningId || screeningId;

  // fetch screening from id or copy screening from id
  const screeningCopy =
    screeningKey && screeningKey !== 'new' ? jobs?.[screeningKey] : null;

  // fetch job from id or copy job from id. nested in screeningCopy ^
  const jobCopy = screeningCopy
    ? screeningCopy
        .findLast((ele) => ele)
        .filter((ajob) => ajob?.[0] === Number(jobKey))[0]
    : null;

  // fetch solutes for job or copy solutes from jobCopy
  const solutes = jobCopy
    ? useSelector((state) => {
        const oSolutes = state.reducer.compositions[jobKey];
        if (oSolutes) {
          // solvent[8] === typeof composition
          return Object.values(oSolutes).filter(
            (solute) => solute[8] === 'Solute'
          );
        }
        return null;
      })
    : null;

  // fetch solvents for job or copy solvents from jobCopy
  const solvents = jobCopy
    ? useSelector((state) => {
        // get the composition rows of type 'Solvent' which have no parent
        const oSolvents = state.reducer.compositions[jobKey];
        if (oSolvents) {
          // solvent[8] === typeof composition
          // solvent[7] === key to the parent if this is a component of a mixture.
          return Object.values(oSolvents)?.filter(
            (solvent) => solvent[8] === 'Solvent' && solvent[7] === null
          );
        }
        return null;
      })
    : null;
  // fetch solventChildren for job or copy solvents from jobCopy
  const solventChildren = jobCopy
    ? useSelector((state) => {
        // get the composition rows of type 'Solvent' which have a parent
        const oSolventChildren = state.reducer.compositions[jobKey];
        if (oSolventChildren) {
          // solvent[8] === typeof composition
          // solvent[7] === key to the parent if this is a component of a mixture
          return Object.values(oSolventChildren)?.filter(
            (solvent) => solvent[8] === 'Solvent' && !(solvent[7] === null)
          );
        }
        return null;
      })
    : null;

  const molecules = useMemo(() => {
    if (moleculeBank)
      return moleculeBank.map((molecule) => {
        return {
          CommonName: molecule[1], // molecule[1] moleculeCommonName
          IUPAC: molecule[2], // molecule[2] IUPAC name
          abbreviation: molecule[8], // molecule[8] Abbreviation
          id: molecule[0], // molecule[0] moleculeK
          alias: molecule[3], // molecule[3] alias
          defaultDensity: molecule[4] // molecule[4] density
        };
      });
  }, [moleculeBank]);

  // fetch solvent & solventChildren and return solvent tree
  const solventTree = useMemo(() => {
    if (jobKey === 'new') return null;
    if (jobCopy && solvents && solventChildren) {
      const parents = solvents.filter((comp) => comp[7] === null);

      const children = solventChildren
        .filter((comp) => comp[7])
        .map((comp) => {
          return {
            id: id === 'new' ? uuidv4() : comp[0],
            molecule: molecules.find((s) => s.id === comp[9]),
            value: parseInt(comp[5]),
            unit: comp[6],
            density: comp[10],
            parent: comp[7]
          };
        });
      return parents.map((composition) => {
        const filteredChildren = children.filter(
          (child) => composition[0] === child.parent
        );
        if (filteredChildren?.length === 0) {
          return {
            id: id === 'new' ? uuidv4() : composition[0],
            name: composition[3],
            molecule: molecules.find((s) => s.id === composition[9]),
            value: composition[5],
            unit: composition[6],
            density: composition[10],
            isParent: false
          };
        }
        return {
          id: id === 'new' ? uuidv4() : composition[0],
          name: composition[3],
          value: composition[5],
          unit: composition[6],
          isParent: true,
          children: {
            unit: filteredChildren[0].unit,
            value: filteredChildren
          }
        };
      });
    }
  }, [solvents, solventChildren, ConfirmModalId]);

  const [editedJob, setEditedJob] = useState(
    jobKey === 'new'
      ? {
          id: -1,
          organizationId: user?.[1] || null,
          name: location?.state?.name || '',
          screening: {
            id: uuidv4(),
            organizationId: user?.[1] || null,
            name: location?.state?.name || ''
          },
          composition: {
            solvent: { unit: SOLVENT_UNITS[0], value: [] },
            solute: []
          },
          ensemble: ENSEMBLE_OPTIONS[0],
          temperature: {
            value: '',
            unit: TEMPERATURE_UNITS.C
          },
          pressure: {
            value: '',
            unit: PRESSURE_UNITS.pa
          },
          freeText: ''
        }
      : {
          id: id === 'new' ? -1 : parseInt(jobKey),
          organizationId: jobCopy?.[1] ? jobCopy?.[1] : user?.[1],
          screening: {
            id: screeningId === 'new' ? uuidv4() : parseInt(screeningKey),
            organizationId: user?.[1] || null,
            name:
              screeningId === 'new'
                ? location?.state?.name
                : screeningCopy?.[5] || ''
          },
          name: id === 'new' ? location?.state?.name || '' : jobCopy?.[5] || '',
          ensemble: jobCopy?.[6] || ENSEMBLE_OPTIONS[0],
          composition: {
            solute:
              solutes?.map((solute) => {
                return {
                  id: id === 'new' ? uuidv4() : solute[0],
                  molecule: molecules.find((s) => s.id === solute[9]),
                  value: solute[5],
                  unit: solute[6],
                  isParent: false
                };
              }) || [],
            solvent: {
              unit: solventTree?.[0]?.unit || SOLVENT_UNITS[0],
              value: solventTree || []
            }
          },

          temperature: {
            value: jobCopy?.[7] || 0,
            unit: jobCopy?.[8] || TEMPERATURE_UNITS.C
          },
          pressure: {
            value: jobCopy?.[11] || '',
            unit: jobCopy?.[12] || PRESSURE_UNITS.pa
          },
          freeText: jobCopy?.[13] || ''
        }
  );
  useEffect(() => {
    if (editedJob.ensemble !== 'NPT') {
      setEditedJob({ ...editedJob, pressure: null });
    }
  }, [editedJob.ensemble]);

  const [isJobError, SetIsJobError] = useState({
    name: false,
    temperature: false,
    pressure: false
  });

  useEffect(() => {
    const arr = [];
    editedJob.composition.solute.map((val) => arr.push(val.id));
    if (
      editedJob.composition.solvent.value &&
      editedJob.composition.solvent.value?.length > 0
    )
      editedJob.composition.solvent.value.map((comp) => {
        if (comp.isParent)
          if (comp.children?.length > 0) {
            comp.children.map((child) => arr.push(child.id));
          }
        return arr.push(comp.id);
      });
    SetIsJobError({
      ...isJobError,
      ...arr.reduce((acc, next) => {
        return {
          ...acc,
          [next]: { name: false, value: false, density: false }
        };
      }, {})
    });
  }, [editedJob.composition, editedJob]);

  // check if all fields has values b4 save
  const checkJob = async () => {
    // DEMOVERSION
    return setIsHelpModalOpen(true);
    // let oCheck = {};
    // const aEditJobKeys = Object.keys(editedJob);
    // // Remove unwanted keys from object
    // let filteredACheck;
    // if (editedJob.ensemble === 'NPT') {
    //   filteredACheck = aEditJobKeys.filter(
    //     (key) =>
    //       [
    //         'organizationId',
    //         'id',
    //         'ensemble',
    //         'freeText',
    //         'screening',
    //         'composition'
    //       ].indexOf(key) === -1
    //   );
    // } else {
    //   filteredACheck = aEditJobKeys.filter(
    //     (key) =>
    //       [
    //         'organizationId',
    //         'id',
    //         'ensemble',
    //         'freeText',
    //         'screening',
    //         'composition',
    //         'pressure'
    //       ].indexOf(key) === -1
    //   );
    // }
    // // check solutes
    // editedJob.composition.solute.map((solute) => {
    //   const isValueFieldEmpty = !(solute.value.toString()?.length > 0);
    //   const isMoleculeFieldEmpty = !solute.molecule;
    //   oCheck = {
    //     ...oCheck,
    //     [solute.id]: { name: isMoleculeFieldEmpty, value: isValueFieldEmpty }
    //   };
    // });
    // // check if no solvent
    // if (editedJob.composition.solvent.value?.length === 0) {
    //   oCheck = {
    //     ...oCheck,
    //     noSolvent: true
    //   };
    // }
    // // check solvents
    // editedJob.composition.solvent.value.map((solvent) => {
    //   const isValueFieldEmpty = !(solvent.value.toString()?.length > 0);
    //   const isMoleculeFieldEmpty = solvent.isParent
    //     ? !(solvent.name?.length > 0)
    //     : !solvent.molecule;
    //   const isDensityFieldEmpty = solvent.isParent ? false : !solvent.density;

    //   oCheck = {
    //     ...oCheck,
    //     [solvent.id]: {
    //       name: isMoleculeFieldEmpty,
    //       value: isValueFieldEmpty,
    //       density: isDensityFieldEmpty
    //     }
    //   };
    //   // check if chidren array is 2 or higher for solvent parent
    //   if (solvent.isParent) {
    //     if (solvent.children.value?.length < 2) {
    //       oCheck = {
    //         ...oCheck,
    //         [solvent.id]: true
    //       };
    //     }
    //     // check solvent children
    //     solvent.children.value.map((child) => {
    //       const isChildValueFieldEmpty = !(child.value.toString()?.length > 0);
    //       const isChildMoleculeFieldEmpty = !child.molecule;
    //       const isChildDensityFieldEmpty = !child.density;
    //       oCheck = {
    //         ...oCheck,
    //         [child.id]: {
    //           name: isChildMoleculeFieldEmpty,
    //           value: isChildValueFieldEmpty,
    //           density: isChildDensityFieldEmpty
    //         }
    //       };
    //     });
    //   }
    // });
    // // check conditions && name for values
    // filteredACheck.map((key) => {
    //   if (typeof editedJob[key] === 'string') {
    //     // eslint-disable-next-line no-unneeded-ternary
    //     const isFieldEmpty = editedJob[key]?.length > 0 ? false : true;
    //     oCheck = { ...oCheck, [key]: isFieldEmpty };
    //     return oCheck;
    //   }
    //   // eslint-disable-next-line no-unneeded-ternary
    //   const isFieldEmpty = editedJob[key].value ? false : true;
    //   oCheck = { ...oCheck, [key]: isFieldEmpty };
    //   return oCheck;
    // });
    // // set isJobError state to oCheck to render red borders around inputfields
    // SetIsJobError(oCheck);
    // // check for errors. if something is true return
    // for (let i = 0, iMax = Object.values(oCheck)?.length; i < iMax; i++) {
    //   if (
    //     Object.values(oCheck)[i] === true ||
    //     Object.values(oCheck)[i].value === true ||
    //     Object.values(oCheck)[i].name === true ||
    //     Object.values(oCheck)[i].density === true
    //   ) {
    //     return;
    //   }
    // }
    // // create/save job
    // dispatch(
    //   createJob(
    //     editedJob,
    //     deletedCompositions,
    //     'save',
    //     function (keys, screeningK) {
    //       setEditedJob({
    //         ...editedJob,
    //         id: keys,
    //         screening: { ...editedJob.screening, id: screeningK }
    //       });
    //       setConfirmModalId(keys);
    //     },
    //     function (oldCompId, newCompId, type, parentId) {
    //       if (type === 'solute') {
    //         const allSolutes = editedJob.composition.solute.filter(
    //           (solute) => solute.id !== oldCompId
    //         );
    //         const comp = editedJob.composition.solute.find(
    //           (x) => x.id === oldCompId
    //         );
    //         comp.id = newCompId;
    //         allSolutes.push(comp);
    //         setEditedJob({
    //           ...editedJob,
    //           composition: {
    //             ...editedJob.composition,
    //             solute: allSolutes
    //           }
    //         });
    //       }
    //       if (type === 'solvent') {
    //         const allSolvents = editedJob.composition.solvent.value.filter(
    //           (solvent) => solvent.id !== oldCompId
    //         );
    //         const comp = editedJob.composition.solvent.value.find(
    //           (x) => x.id === oldCompId
    //         );
    //         comp.id = newCompId;

    //         allSolvents.push(comp);
    //         setEditedJob({
    //           ...editedJob,
    //           composition: {
    //             ...editedJob.composition,
    //             solvent: {
    //               ...editedJob.composition.solvent,
    //               value: allSolvents
    //             }
    //           }
    //         });
    //       }
    //       if (type === 'solventChild') {
    //         const allSolvents = editedJob.composition.solvent.value.filter(
    //           (solvent) => solvent.id !== parentId
    //         );
    //         const comp = editedJob.composition.solvent.value.find(
    //           (x) => x.id === parentId
    //         );
    //         const children = comp.children.value.filter(
    //           (x) => x.id !== oldCompId
    //         );
    //         const child = comp.children.value.find((x) => x.id === oldCompId);

    //         child.id = newCompId;
    //         children.push(child);

    //         allSolvents.push(comp);
    //         setEditedJob({
    //           ...editedJob,
    //           composition: {
    //             ...editedJob.composition,
    //             solvent: {
    //               ...editedJob.composition.solvent,
    //               value: allSolvents
    //             }
    //           }
    //         });
    //       }
    //     },
    //     (jobid) => dispatch(GetStoichiometry(jobid))
    //   )
    // );
    // // Open modal
    // setIsConfirmModalOpen(true);
  };

  // submit job
  const simulateJob = () => {
    dispatch(
      createJob(editedJob, 'submit', (key) => {
        setConfirmModalId(key[0]);
      })
    );
    // Redirect to InProgressPage => TODO
    navigate('/jobs');
  };
  // delete job. soft delete rn
  const deleteJob = () => {
    dispatch(deleteJobById(editedJob.screening.id, editedJob.id));
    // Redirect to InProgressPage => TODO
    navigate('/jobs');
  };
  const saveSimulation = () => {
    // DEMOVERSION
    return setIsHelpModalOpen(true);
    // let oCheck = {};
    // const aEditJobKeys = Object.keys(editedJob);
    // // check jobName
    // const filteredACheck = aEditJobKeys.filter(
    //   (key) => ['name'].indexOf(key) >= 0
    // );
    // filteredACheck.map((key) => {
    //   if (typeof editedJob[key] === 'string') {
    //     // eslint-disable-next-line no-unneeded-ternary
    //     const isFieldEmpty = editedJob[key]?.length > 0 ? false : true;
    //     oCheck = { ...oCheck, [key]: isFieldEmpty };
    //     return oCheck;
    //   }
    //   // eslint-disable-next-line no-unneeded-ternary
    //   const isFieldEmpty = editedJob[key].value ? false : true;
    //   oCheck = { ...oCheck, [key]: isFieldEmpty };
    //   return oCheck;
    // });
    // SetIsJobError(oCheck);
    // // Open modal if every field is filled in
    // for (let i = 0; i < Object.values(oCheck)?.length; i++) {
    //   if (Object.values(oCheck)[i] === true) {
    //     return;
    //   }
    // }
    // dispatch(
    //   createJob(
    //     editedJob,
    //     deletedCompositions,
    //     'save',
    //     function (keys, screeningK) {
    //       const key = keys.length === 1 ? keys : keys;
    //       setEditedJob({
    //         ...editedJob,
    //         id: key,
    //         screening: { ...editedJob.screening, id: screeningK }
    //       });
    //       setConfirmModalId(keys);
    //     },
    //     function (oldCompId, newCompId, type, parentId) {
    //       if (type === 'solute') {
    //         const allSolutes = editedJob.composition.solute.filter(
    //           (solute) => solute.id !== oldCompId
    //         );
    //         const comp = editedJob.composition.solute.find(
    //           (x) => x.id === oldCompId
    //         );
    //         comp.id = newCompId;
    //         allSolutes.push(comp);
    //         setEditedJob({
    //           ...editedJob,
    //           composition: {
    //             ...editedJob.composition,
    //             solute: allSolutes
    //           }
    //         });
    //       }
    //       if (type === 'solvent') {
    //         const allSolvents = editedJob.composition.solvent.value.filter(
    //           (solvent) => solvent.id !== oldCompId
    //         );
    //         const comp = editedJob.composition.solvent.value.find(
    //           (x) => x.id === oldCompId
    //         );
    //         comp.id = newCompId;

    //         allSolvents.push(comp);
    //         setEditedJob({
    //           ...editedJob,
    //           composition: {
    //             ...editedJob.composition,
    //             solvent: {
    //               ...editedJob.composition.solvent,
    //               value: allSolvents
    //             }
    //           }
    //         });
    //       }
    //       if (type === 'solventChild') {
    //         const allSolvents = editedJob.composition.solvent.value.filter(
    //           (solvent) => solvent.id !== parentId
    //         );
    //         const comp = editedJob.composition.solvent.value.find(
    //           (x) => x.id === parentId
    //         );
    //         const children = comp.children.value.filter(
    //           (x) => x.id !== oldCompId
    //         );
    //         const child = comp.children.value.find((x) => x.id === oldCompId);

    //         child.id = newCompId;
    //         children.push(child);

    //         allSolvents.push(comp);
    //         setEditedJob({
    //           ...editedJob,
    //           composition: {
    //             ...editedJob.composition,
    //             solvent: {
    //               ...editedJob.composition.solvent,
    //               value: allSolvents
    //             }
    //           }
    //         });
    //       }
    //     }
    //   )
    // );
    // setDeletedCompositions([]);
    // navigate('/jobs');
  };
  if (isLoading || !user) return <LoadingPage />;
  if (id !== 'new' && !editedJob.composition) return <LoadingPage />;
  return (
    <Page help onHelp={() => setIsHelpModalOpen(true)}>
      <div className=" w-full px-12 py-8">
        {ConfirmModalId && (
          <ConfirmModal
            isOpen={isConfirmModalOpen}
            header="Preview of system"
            close={() => setIsConfirmModalOpen(false)}
            job={editedJob}
            jobId={ConfirmModalId}
            process={() => {
              setIsConfirmModalOpen(false);
              simulateJob();
            }}
          />
        )}
        {/* PRODUCTIONINSTANCE */}
        {/* <HelpModal
          isOpen={isHelpModalOpen}
          close={() => setIsHelpModalOpen(false)}
        /> */}
        {/* DEMOVERSION */}
        <DemoRequestModal
          isOpen={isHelpModalOpen}
          close={() => setIsHelpModalOpen(false)}
        />
        <ScreeningModal
          isOpen={isScreeningModalOpen.open}
          unit={editedJob.temperature.unit}
          editedJob={editedJob}
          setEditedJob={setEditedJob}
          close={() => setIsScreeningModalOpen({ open: false, type: '' })}
        />
        <MoleculeModal
          isOpen={isMoleculeModalOpen}
          moleculeKey={editId}
          setJobMolecule={(molecule) => {
            // DEMOVERSION
            setIsMoleculeModalOpen(false);
            setIsHelpModalOpen(molecule);
            // if (moleculePreviewInfo.type)
            //   switch (moleculePreviewInfo.type) {
            //     case 'solvent': {
            //       let comp = editedJob.composition.solvent.value;
            //       comp = comp.map((com) => {
            //         if (com.id === moleculePreviewInfo.compositionId) {
            //           const solventComp = { ...com };
            //           solventComp.molecule = molecule;

            //           if (!molecule) {
            //             solventComp.density = molecule;
            //             return solventComp;
            //           }
            //           solventComp.density = molecule.density;

            //           return solventComp;
            //         }
            //         return com;
            //       });
            //       setEditedJob({
            //         ...editedJob,
            //         composition: {
            //           ...editedJob.composition,
            //           solvent: {
            //             ...editedJob.composition.solvent,
            //             value: comp
            //           }
            //         }
            //       });

            //       break;
            //     }
            //     case 'solute': {
            //       let comps = editedJob.composition.solute;
            //       comps = comps.map((com) => {
            //         if (com.id === moleculePreviewInfo.compositionId)
            //           // eslint-disable-next-line no-param-reassign
            //           com.molecule = molecule;

            //         if (!molecule) {
            //           // eslint-disable-next-line no-param-reassign
            //           com.density = null;
            //         }

            //         return com;
            //       });
            //       setEditedJob({
            //         ...editedJob,
            //         composition: {
            //           ...editedJob.composition,
            //           solute: comps
            //         }
            //       });
            //       break;
            //     }
            //     case 'mixture': {
            //       const comp = editedJob.composition.solvent.value;
            //       const objIndex =
            //         editedJob.composition.solvent.value.findIndex(
            //           (obj) => obj.id === moleculePreviewInfo.parentId
            //         );

            //       const childIndex = comp[objIndex].children.value.findIndex(
            //         (obj) => obj.id === moleculePreviewInfo.compositionId
            //       );
            //       comp[objIndex].children.value[childIndex].molecule = molecule;
            //       if (!molecule) {
            //         comp[objIndex].children.value[childIndex].density =
            //           molecule;
            //         return setEditedJob({
            //           ...editedJob,
            //           composition: {
            //             ...editedJob.composition,
            //             solvent: {
            //               ...editedJob.composition.solvent,
            //               value: comp
            //             }
            //           }
            //         });
            //       }

            //       comp[objIndex].children.value[childIndex].density =
            //         molecule.density;

            //       setEditedJob({
            //         ...editedJob,
            //         composition: {
            //           ...editedJob.composition,
            //           solvent: {
            //             ...editedJob.composition.solvent,
            //             value: comp
            //           }
            //         }
            //       });
            //       break;
            //     }
            //     default:
            //       break;
            //   }
          }}
          close={() => setIsMoleculeModalOpen(false)}
        />
        <PreviewModal
          isOpen={isPreviewMoleculeModalOpen}
          KMolecule={moleculePreviewId}
          setJobMolecule={(molecule) => {
            if (moleculePreviewInfo.type)
              switch (moleculePreviewInfo.type) {
                case 'solvent': {
                  let comp = editedJob.composition.solvent.value;
                  comp = comp.map((com) => {
                    if (com.id === moleculePreviewInfo.compositionId) {
                      const solventComp = { ...com };
                      solventComp.molecule = molecule;

                      if (!molecule) {
                        solventComp.density = molecule;
                        return solventComp;
                      }
                      solventComp.density = molecule.density;

                      return solventComp;
                    }
                    return com;
                  });
                  setEditedJob({
                    ...editedJob,
                    composition: {
                      ...editedJob.composition,
                      solvent: {
                        ...editedJob.composition.solvent,
                        value: comp
                      }
                    }
                  });

                  break;
                }
                case 'solute': {
                  let comps = editedJob.composition.solute;
                  comps = comps.map((com) => {
                    if (com.id === moleculePreviewInfo.compositionId)
                      // eslint-disable-next-line no-param-reassign
                      com.molecule = molecule;
                    if (!molecule) {
                      // eslint-disable-next-line no-param-reassign
                      com.density = null;
                    }

                    return com;
                  });
                  setEditedJob({
                    ...editedJob,
                    composition: {
                      ...editedJob.composition,
                      solute: comps
                    }
                  });
                  break;
                }
                case 'mixture': {
                  const comp = editedJob.composition.solvent.value;
                  const objIndex =
                    editedJob.composition.solvent.value.findIndex(
                      (obj) => obj.id === moleculePreviewInfo.parentId
                    );

                  const childIndex = comp[objIndex].children.value.findIndex(
                    (obj) => obj.id === moleculePreviewInfo.compositionId
                  );
                  comp[objIndex].children.value[childIndex].molecule = molecule;
                  if (!molecule) {
                    comp[objIndex].children.value[childIndex].density =
                      molecule;
                    return setEditedJob({
                      ...editedJob,
                      composition: {
                        ...editedJob.composition,
                        solvent: {
                          ...editedJob.composition.solvent,
                          value: comp
                        }
                      }
                    });
                  }

                  comp[objIndex].children.value[childIndex].density =
                    molecule.density;

                  setEditedJob({
                    ...editedJob,
                    composition: {
                      ...editedJob.composition,
                      solvent: {
                        ...editedJob.composition.solvent,
                        value: comp
                      }
                    }
                  });
                  break;
                }
                default:
                  break;
              }
            setIsPreviewMoleculeModalOpen(false);
          }}
          close={() => setIsPreviewMoleculeModalOpen(false)}
        />
        <WhiteBackgroundBox>
          <div className="h-full w-full relative min-w-[925px]">
            <div className="absolute w-full border-NGrey-3 border-b bg-white text-center">
              <div className="flex flex-start px-8 py-4 items-center">
                <div className="basis-1/3">
                  <DefaultButton
                    label="Go back to jobs"
                    type="text-secondary"
                    onPressed={() => {
                      // const message =
                      //   'It looks like you have been editing something. ' +
                      //   'If you leave before saving, your changes will be lost.';
                      // // eslint-disable-next-line no-restricted-globals
                      // if (confirm(message) === true) {
                      // }
                      navigate('/jobs');
                    }}
                    icon="arrowLeft"
                    iconPosition="left"
                  />
                </div>
                <p className="text-[23px] font-semibold text-primary-10 basis-1/3">
                  Specify System
                </p>
                <div className="flex gap-4 ml-auto basis-1/3 justify-end">
                  <DefaultButton
                    type="outlined-primary"
                    label="Save"
                    onPressed={() => {
                      saveSimulation();
                    }}
                    icon="save"
                    iconPosition="left"
                  />
                  {id !== 'new' && (
                    <DefaultButton
                      type="outlined-primary"
                      label="Delete"
                      onPressed={() => {
                        deleteJob();
                      }}
                      icon="delete"
                      iconPosition="left"
                    />
                  )}
                </div>
              </div>
            </div>
            <div className=" flex flex-col overflow-y-auto py-6 h-full w-full px-8 overflow-x-scroll">
              <div className="flex flex-col">
                <div className="flex justify-between items-center w-1/3 min-w-[400px] mt-16">
                  <InputField
                    label="System name"
                    inputValue={editedJob.name}
                    setInputValue={(e) => {
                      setEditedJob({
                        ...editedJob,
                        name: e.target.value,
                        screening: {
                          ...editedJob.screening,
                          name: e.target.value
                        }
                      });
                    }}
                    button={() => {
                      openInNewTab(
                        'https://compulartech.com/docs/create-job/#0-toc-title'
                      );
                    }}
                    name="mixtureName"
                    isRequired={false}
                    isError={isJobError.name}
                  />
                </div>
                <Compositions
                  setIsPreviewModalOpen={(
                    bool,
                    key,
                    type,
                    compId,
                    parentId = null
                  ) => {
                    setMoleculePreviewInfo({
                      type,
                      compositionId: compId,
                      parentId
                    });

                    if (key) {
                      setMoleculePreviewId(key);
                      setIsPreviewMoleculeModalOpen(bool);
                    }
                  }}
                  editedJob={editedJob}
                  setEditedJob={setEditedJob}
                  isJobError={isJobError}
                  setIsModalOpen={(
                    bool,
                    // eslint-disable-next-line default-param-last
                    idMol = null,
                    type,
                    compId,
                    parentId = null
                  ) => {
                    setMoleculePreviewInfo({
                      type,
                      compositionId: compId,
                      parentId
                    });
                    if (idMol) {
                      setEditId(idMol);
                    } else {
                      setEditId(null);
                    }

                    setIsMoleculeModalOpen(true);
                  }}
                />
              </div>
              <div>
                <div className="text-[28px] font-semibold mt-6 flex gap-2 items-center">
                  <p>Conditions</p>
                  <div>
                    <DefaultButton
                      label=""
                      type="text-primary"
                      onPressed={() => {
                        openInNewTab(
                          'https://compulartech.com/docs/create-job/#conditions'
                        );
                      }}
                      icon="help"
                      iconPosition="left"
                    />
                  </div>
                </div>
                <div className="mt-5 pb-2 w-[100px]">
                  <DropdownMenu
                    isDisabled
                    label="Ensemble"
                    placeHolder="Choose ensemble"
                    currentValue={editedJob.ensemble}
                    options={ENSEMBLE_OPTIONS}
                    onChange={(e) => {
                      if (e.target.value === 'NPT')
                        setEditedJob({
                          ...editedJob,
                          ensemble: e.target.value,
                          pressure: { value: '', unit: PRESSURE_UNITS.pa }
                        });
                      if (e.target.value !== 'NPT')
                        setEditedJob({
                          ...editedJob,
                          ensemble: e.target.value,
                          pressure: null
                        });
                    }}
                  />
                </div>
                <div className="flex gap-6 mt-5 w-full">
                  {Array.isArray(editedJob.temperature.value) ? (
                    <InputField
                      label="Temperature"
                      inputValue={
                        Array.isArray(editedJob.temperature.value)
                          ? editedJob.screening.values.join(', ')
                          : editedJob.temperature.value
                      }
                      setInputValue={(e) => {
                        setEditedJob({
                          ...editedJob,
                          temperature: {
                            ...editedJob.temperature,
                            value: e.target.value
                          }
                        });
                      }}
                      name="Temp"
                      isRequired={false}
                      isDisabled
                      isError={isJobError.temperature}
                    />
                  ) : (
                    <div className="max-w-[240px]">
                      <InputField
                        label="Temperature"
                        type={
                          Array.isArray(editedJob.temperature.value)
                            ? ''
                            : 'NUMBER'
                        }
                        inputValue={
                          Array.isArray(editedJob.temperature.value)
                            ? editedJob.screening.values.join(', ')
                            : editedJob.temperature.value
                        }
                        setInputValue={(e) => {
                          setEditedJob({
                            ...editedJob,
                            temperature: {
                              ...editedJob.temperature,
                              value: e.target.value
                            }
                          });
                        }}
                        name="Temp"
                        isRequired={false}
                        isError={isJobError.temperature}
                      />
                    </div>
                  )}
                  <RadioButton
                    label="Unit"
                    keys={Object.values(TEMPERATURE_UNIT_KEYS)}
                    options={TEMPERATURE_UNITS}
                    setEditedUnit={(value) => {
                      setEditedJob({
                        ...editedJob,
                        temperature: {
                          ...editedJob.temperature,
                          unit: value
                        }
                      });
                    }}
                    element="temp"
                    value={editedJob.temperature.unit}
                  />
                  <div className="self-end flex flex-1 justify-end items-center pb-2 right-6 h-11">
                    <DefaultButton
                      type={
                        editedJob.temperature.value === 'screening'
                          ? 'text-grey'
                          : 'text-primary'
                      }
                      icon="screening"
                      iconPosition="left"
                      label=""
                      onPressed={() => {
                        setIsScreeningModalOpen({
                          open: true,
                          type: 'temperature'
                        });
                      }}
                    />
                  </div>
                </div>
                {editedJob.pressure !== null && (
                  <div className="mt-5 flex gap-6">
                    <div className="max-w-[240px]">
                      <InputField
                        label="Pressure"
                        name="Pressure"
                        type="NUMBER"
                        inputValue={editedJob.pressure?.value}
                        setInputValue={(e) => {
                          setEditedJob({
                            ...editedJob,
                            pressure: {
                              ...editedJob.pressure,
                              value: e.target.value
                            }
                          });
                        }}
                        isRequired={false}
                        isError={isJobError.pressure}
                      />
                    </div>
                    <RadioButton
                      label="Unit"
                      keys={Object.values(PRESSURE_UNIT_KEYS)}
                      options={PRESSURE_UNITS}
                      setEditedUnit={(value) => {
                        setEditedJob({
                          ...editedJob,
                          pressure: {
                            ...editedJob.pressure,
                            unit: value
                          }
                        });
                      }}
                      element="pressure"
                      value={editedJob.pressure?.unit}
                    />
                  </div>
                )}
                <div className="mt-5 pb-2 w-1/2">
                  <TextArea
                    label="Other information"
                    warningLabel="Don't send sensitive information."
                    value={editedJob.freeText}
                    inputValue={editedJob.freeText}
                    setInputValue={(e) =>
                      setEditedJob({
                        ...editedJob,
                        freeText: e.target.value
                      })
                    }
                  />
                </div>
              </div>
              <div
                className={`bottom-0 w-full ${
                  isConfirmModalOpen || isHelpModalOpen || isScreeningModalOpen
                    ? ''
                    : 'z-20'
                }`}>
                <div className="w-full flex justify-between gap-4">
                  <div className="flex gap-4 ml-auto">
                    <DefaultButton
                      type="contained-secondary"
                      label="Save & continue"
                      onPressed={() => {
                        checkJob();
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </WhiteBackgroundBox>
      </div>
    </Page>
  );
}
export default PrepareGenerationPage;
