/* @copyright (c) 2021-2022 Compular AB */
import React from 'react';
import PropTypes from 'prop-types';
import './PagesBase.css';
import TopNavigator from '../TopNavigator/TopNavigator';

/**
 * Page template displaying a top and sub navigator menu as well as a viewport for hosting content.
 * Use this template by encasing your specific page in <Page></Page>.
 *
 * @param {*} props
 * @param {function} onHelp
 * @returns
 */
function Page(props) {
  return (
    <div id="window">
      {props.help ? (
        <TopNavigator openModal={props.onHelp} />
      ) : (
        <TopNavigator showProgressBar={false} />
      )}
      <div id="viewport" className="pb-12">
        {props.children}
      </div>
    </div>
  );
}
Page.propTypes = {
  children: PropTypes.element,
  help: PropTypes.bool,
  onHelp: PropTypes.func
};

Page.defaultProps = {
  children: null,
  help: false,
  onHelp: null
};
export default Page;
