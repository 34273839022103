// @copyright (c) 2022-2024 Compular AB
export const LOG_IN_START = 'LOG_IN_START';
export const LOG_IN_ERROR = 'LOG_IN_ERROR';
export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';

export const SIGN_OUT = 'SIGN_OUT';

export const CREATE_JOB_START = 'CREATE_JOB_START';
export const CREATE_JOB_ERROR = 'CREATE_JOB_ERROR';
export const CREATE_JOB_SUCCESS = 'CREATE_JOB_SUCCESS';

export const GET_JOB_START = 'GET_JOB_START';
export const GET_JOB_ERROR = 'GET_JOB_ERROR';
export const GET_JOB_SUCCESS = 'GET_JOB_SUCCESS';

export const GET_STOICHIOMETRY_START = 'GET_STOICHIOMETRY_START';
export const GET_STOICHIOMETRY_ERROR = 'GET_STOICHIOMETRY_ERROR';
export const GET_STOICHIOMETRY_SUCCESS = 'GET_STOICHIOMETRY_SUCCESS';

export const GET_JOB_RESULT_START = 'GET_JOB_RESULT_START';
export const GET_JOB_RESULT_ERROR = 'GET_JOB_RESULT_ERROR';
export const GET_JOB_RESULT_SUCCESS = 'GET_JOB_RESULT_SUCCESS';

export const SAVE_JOB_START = 'SAVE_JOB_START';
export const SAVE_JOB_ERROR = 'SAVE_JOB_ERROR';
export const SAVE_JOB_SUCCESS = 'SAVE_JOB_SUCCESS';

export const CANCEL_JOB_START = 'CANCEL_JOB_START';
export const CANCEL_JOB_ERROR = 'CANCEL_JOB_ERROR';
export const CANCEL_JOB_SUCCESS = 'CANCEL_JOB_SUCCESS';

export const DELETE_JOB_START = 'DELETE_JOB_START';
export const DELETE_JOB_ERROR = 'DELETE_JOB_ERROR';
export const DELETE_JOB_SUCCESS = 'DELETE_JOB_SUCCESS';

export const GET_MOLECULES_START = 'GET_MOLECULES_START';
export const GET_MOLECULES_ERROR = 'GET_MOLECULES_ERROR';
export const GET_MOLECULES_SUCCESS = 'GET_MOLECULES_SUCCESS';

export const ADD_MOLECULES_START = 'ADD_MOLECULES_START';
export const ADD_MOLECULES_ERROR = 'ADD_MOLECULES_ERROR';
export const ADD_MOLECULES_SUCCESS = 'ADD_MOLECULES_SUCCESS';

export const UPDATE_NAME_START = 'UPDATE_NAME_START';
export const UPDATE_NAME_ERROR = 'UPDATE_NAME_ERROR';
export const UPDATE_NAME_SUCCESS = 'UPDATE_NAME_SUCCESS';

export const DELETE_MOLECULES_START = 'DELETE_MOLECULES_START';
export const DELETE_MOLECULES_ERROR = 'DELETE_MOLECULES_ERROR';
export const DELETE_MOLECULES_SUCCESS = 'DELETE_MOLECULES_SUCCESS';
