// @copyright (c) 2022-2024 Compular AB
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CompularLogo from './components/CompularLogo/CompularLogo';
import WhiteBackgroundBox from './components/WhiteBackgroundBox/WhiteBackgroundBox';
import HeaderText from './components/TextComponent/HeaderText/HeaderText';
import LightText from './components/TextComponent/LightText/LightText';
// import HelpModal from './components/Modals/HelpModal';
// import MFA from './login/MFA';
import SignIn from './login/SignIn';
import { Account } from './Accounts';
import ForceChangePassword from './login/ForceChangePassword';
import MFA from './login/MFA';
import ForgotPassword from './login/ForgotPassword';
import DemoRequestModal from './components/Modals/DemoRequestModal';

export default function Login() {
  const navigate = useNavigate();
  const { user, isLoggedIn, cogUser } = useSelector((state) => state.reducer);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
  const [setUpMFA, setSetUpMFA] = useState(false); // usestate for setup totp.
  const [isMFA, setIsMFA] = useState(false); // usestate for totp. timebased one time password
  const [forceChangePassword, setForceChangePassword] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [forgotPassword, setForgotPassword] = useState(false);
  useEffect(() => {
    if ((user && isLoggedIn) || cogUser) {
      navigate('/jobs');
    }
  }, [user, isLoggedIn]);

  // returns html for loginpage.
  return (
    <div className="h-[90vh] items-center flex justify-center">
      {/* <HelpModal
        isOpen={isHelpModalOpen}
        close={() => setIsHelpModalOpen(false)}
      /> */}
      <DemoRequestModal
        isOpen={isHelpModalOpen}
        close={() => setIsHelpModalOpen(false)}
      />
      <div className="max-h-[600px] max-w-[1000px] ">
        <WhiteBackgroundBox>
          <div className="w-full flex flex-row items-center my-auto h-full">
            <div className="flex-1 h-full">
              <div className="p-8 px-10 py-10">
                <div className="px-12">
                  <HeaderText text="Welcome!" />
                </div>
                {/* DEMOVERSION */}
                <div className="flex flex-col gap-4 mt-4 pl-12">
                  <p className="light-text">
                    Check your email for your login credentials.
                    <a
                      href="https://compulartech.com/demo"
                      target="_blank"
                      rel="noreferrer"
                      className=" ml-1 text-blue-800 font-medium hover:underline underline-offset-1 active:text-blue-900">
                      Click here if you need them resent.
                    </a>
                  </p>
                  <LightText text="Please note that this is a demo version of our web-app. It showcases our software's capabilities, but you can't submit systems for simulation." />
                  <LightText text="The displayed results are for demonstration purposes only. Don't draw specific conclusions from them. The data serves as a placeholder and does not represent actual results." />
                  <LightText text="We appreciate your feedback and suggestions to improve our web-app. Reach out to us via the button in the top right." />
                  <LightText text="Thank you for using our demo version!" />
                </div>
                {/* <div className="flex flex-col gap-4 mt-6 px-12 ">
                  <LightText text="As one of our early customers you get to try out Compular’s tool capabilities in a slimmed down beta-version. This app will guide you through the steps of specifiying a system to be analysed and then view the results." />
                  <LightText text="As a start, we highly recommend to start with a quite simple or well-known system so that you can get used to the process and verify that the results correlate with what you already know." />
                  <LightText text="We love to hear your feedback. If you at any time have any questions, feature requests or just general input: press the support-button on the top right." />
                  <LightText text="With that said. Welcome to try out our app!" />
                  <div className="my-3"> </div>
                  <div className="text-NGrey-6 text-xs flex gap-3 flex-col">
                    <div className="flex gap-10">
                      <p>Version: 1.0.1</p>
                      <p>© 2023 Compular AB</p>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="flex flex-1 justify-center items-center overflow-hidden p-0">
              <div className="w-full flex my-auto items-center justify-center">
                <div className="w-80 flex flex-col gap-6 items-center justify-center rounded border border-NGrey-6 p-8 bg-NGrey-1">
                  <CompularLogo />
                  <Account>
                    {(setUpMFA || isMFA) && (
                      <MFA email={email} password={password} isMFA={isMFA} />
                    )}
                    {forceChangePassword && (
                      <ForceChangePassword
                        setForceChangePassword={setForceChangePassword}
                        setNewPassword={setNewPassword}
                        setConfirmNewPassword={setConfirmNewPassword}
                        newPassword={newPassword}
                        confirmNewPassword={confirmNewPassword}
                        setIsHelpModalOpen={setIsHelpModalOpen}
                        password={password}
                        email={email}
                        setSetUpMFA={setSetUpMFA}
                      />
                    )}
                    {forgotPassword && (
                      <ForgotPassword setForgotPassword={setForgotPassword} />
                    )}
                    {!forceChangePassword &&
                      !setUpMFA &&
                      !isMFA &&
                      !cogUser &&
                      !forgotPassword && (
                        <SignIn
                          setIsMFA={setIsMFA}
                          setForceChangePassword={setForceChangePassword}
                          setEmail={setEmail}
                          setIsHelpModalOpen={setIsHelpModalOpen}
                          setPassword={setPassword}
                          password={password}
                          email={email}
                          setSetUpMFA={setSetUpMFA}
                          setForgotPassword={setForgotPassword}
                        />
                      )}
                  </Account>
                </div>
              </div>
            </div>
          </div>
        </WhiteBackgroundBox>
      </div>
    </div>
  );
}
