// @copyright (c) 2023-2024 Compular AB
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Chart, registerables } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { convertHeader } from '../../utils/utils';

Chart.register(...registerables);
/**
 * BarChart for multiple systems.
 *
 * @param {Array} jobs Array of jobs+results to render chart
 * @param {string} sHeader header for chart
 * @returns
 */
export default function GroupedBarChart({ jobs, sHeader }) {
  const [aDatasets, setADatasets] = useState([]);
  const formattedHeader = useMemo(() => {
    return convertHeader(sHeader);
  }, [sHeader]);

  useEffect(() => {
    const resultData = jobs.map(({ result }) => result.molecularSystem);

    const indexForChartData = resultData[0][0].indexOf(sHeader);
    const indexForChartDataError = resultData[0][0].indexOf(sHeader) + 1;
    const keys = jobs.map(({ result }) =>
      result.molecularSystem
        .filter((header, i) => i !== 0)
        .map((molRes) => molRes[0])
    );
    const aHeader = [];
    const setterHeader = new Set();
    for (let index = 0; index < keys.length; index++) {
      const element = keys[index];
      for (let i = 0; i < element.length; i++) {
        const value = element[i];
        setterHeader.add(value);
      }
    }
    setterHeader.forEach((header) => {
      aHeader.push(header);
    });

    const dataO = jobs.map((job) => {
      return job.result.molecularSystem.map((molRow) => {
        return {
          [molRow[0]]: {
            value: molRow[indexForChartData],
            error: molRow[indexForChartDataError]
          }
        };
      });
    });
    const dataForJobs = dataO.map((a) =>
      aHeader.map((b) => {
        const obj = a.find((a2) => Object.keys(a2)[0] === b);
        if (obj?.[b].value)
          return { [b]: { value: obj[b].value, error: obj[b].error, name: b } };
        return { [b]: { value: null, error: null, name: b } };
      })
    );

    const chartData = aHeader.map((key) => {
      return dataForJobs.map((t) => t.find((a2) => Object.keys(a2)[0] === key));
    });

    setADatasets(chartData);
  }, [jobs]);

  function getBarColor(i) {
    // get color for bar.
    switch (i) {
      case 1:
        return '#D5E1A3';
      case 2:
        return '#B0413E';
      case 3:
        return '#548687';
      case 4:
        return '#D4CDF4';
      case 5:
        return 'lightpink';
      case 6:
        return 'lightblue';
      default:
        return '#D8A47F';
    }
  }

  const data = {
    labels: jobs.map(({ name }) => name),
    datasets: aDatasets.map((job, i) => {
      return {
        label: Object.values(job[0])[0].name,
        data: job.map((j) => Object.values(j)[0].value),
        errorData: job.map((j) => Object.values(j)[0].error),
        backgroundColor: getBarColor(i),
        borderRadius: 2,
        borderColor: getBarColor(i),
        borderWidth: 1
      };
    })
  };
  const options = {
    plugins: {
      tooltip: {
        padding: 12,
        callbacks: {
          label: (context) => {
            if (context.dataset.data[context.dataIndex])
              return `${context.dataset.label}: ${
                context.dataset.data[context.dataIndex]
              }`;
          },
          afterLabel: (context) => {
            if (context.dataset.errorData[context.dataIndex])
              return `± ${context.dataset.errorData[context.dataIndex]}`;
          }
        }
      },
      maintainAspectRatio: false,
      title: {
        display: true,
        position: 'left',
        font: {
          size: 15
        },
        text:
          formattedHeader === 'Diffusivity [m\u00b2/s]'
            ? formattedHeader
                .split('[')[0]
                .replaceAll(' ', ' [1e-10 m\u00b2/s]')
            : formattedHeader === 'Electrical mobility [m\u00b2/(V\u00D7s)] '
            ? formattedHeader
                .trim()
                .replaceAll(
                  '[m\u00b2/(V\u00D7s)]',
                  ' [1e-9 m\u00b2/(V\u00D7s)]'
                )
            : formattedHeader
      }
    }
  };

  const divStyle = {
    width: '100%',
    height: '67vh',
    display: 'flex',
    justifyContent: 'center'
  };

  return (
    <div style={divStyle}>
      <Bar data={data} options={options} />
    </div>
  );
}
GroupedBarChart.propTypes = {
  sHeader: PropTypes.string.isRequired,
  jobs: PropTypes.instanceOf(Array).isRequired
};
