// @copyright (c) 2023-2024 Compular AB
import * as React from 'react';

function EmailIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17 16"
      fill="none"
      {...props}>
      <path
        fill="currentColor"
        d="M13.834 2.667H3.167c-.733 0-1.326.6-1.326 1.333l-.007 8c0 .734.6 1.334 1.333 1.334h10.667c.733 0 1.333-.6 1.333-1.334V4c0-.733-.6-1.333-1.333-1.333Zm0 9.333H3.167V5.334l5.334 3.333 5.333-3.333V12ZM8.501 7.334 3.167 4h10.667L8.501 7.334Z"
      />
    </svg>
  );
}
export default EmailIcon;
