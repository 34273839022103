// @copyright (c) 2022-2024 Compular AB
import * as React from 'react';

function SortIcon(props) {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M10.834 11.34V6.667H9.5v4.673h-2L10.167 14l2.667-2.66h-2ZM6.167 2 3.5 4.66h2v4.673h1.333V4.66h2L6.167 2Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SortIcon;
