/* @copyright (c) 2021-2022 Compular AB */
import PropTypes from 'prop-types';

/**
 * White box used as background for pages content
 *
 * @param {*} props
 * @returns
 */
function WhiteBackgroundBox(props) {
  return (
    <div
      className="w-full h-full flex flex-col flex-1 min-h-[78.7vh] overflow-scroll bg-white border border-NGrey-6 rounded"
      style={{
        maxWidth: props.maxWidth && `${props.maxWidth}`
      }}>
      {props.children}
    </div>
  );
}

WhiteBackgroundBox.propTypes = {
  children: PropTypes.element,
  maxWidth: PropTypes.string
};

WhiteBackgroundBox.defaultProps = {
  children: null,
  maxWidth: null
};

export default WhiteBackgroundBox;
