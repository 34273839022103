// @copyright (c) 2023-2024 Compular AB
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CloseIcon from '../../icons/CloseIcon';
import RenderSvgFile from '../../icons/RenderSvgFile';
import { getXYZFile } from '../../redux/actions/actions';

/**
 * A confirm modal.
 *
 * @param isOpen bool. true if modal is open
 * @param header string. Header for modal
 * @param body string. body for modal
 * @param close func. Close modal.
 * @param process func. process
 * @param cancel func. cancel job.
 * @returns
 */
// Dispatch SimulateJob to change status to processing.
// Creates first log message and estimated time

export default function SmilesModal({ isOpen, close, data }) {
  const { jobid } = useParams();
  const [is3d, setIs3D] = useState(false);
  const { user } = useSelector((state) => state.reducer);
  const [file, setFile] = useState(null);

  const customModalStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      minWidth: '800px',
      height: '100%',
      maxHeight: '550px',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '16px',
      borderRadius: '5px',
      zIndex: 1000
    }
  };
  useEffect(() => {
    if (isOpen && user && jobid && data && is3d) {
      getXYZFile({
        organizationk: user[1], // OrganizationK from user redux state
        jobk: jobid, // Job Id from params
        filepath: data.findLast((ele) => ele) // filepath to xyz on server. -1 === last index in array
      }).then((val) => {
        if (val) setFile(val);
      });
    }
  }, [user, jobid, data, isOpen, is3d]);

  useEffect(() => {
    if (is3d && file) {
      $(() => {
        const element = $('#modal-body');

        const viewer = $3Dmol.createViewer(element);

        viewer.addModel(file, 'xyz');
        viewer.setStyle({
          sphere: { radius: 0.4, opacity: 0.8, colorscheme: 'Jmol' },
          stick: { colorscheme: 'Jmol' }
        });
        viewer.render();

        viewer.center();
        viewer.zoomTo(10);
        viewer.zoom(1.25, 1000);
      });
    }
  }, [is3d, file]);

  return (
    <Modal
      style={customModalStyle}
      isOpen={isOpen}
      ariaHideApp={false}
      onRequestClose={close}
      contentLabel="Create new project">
      <div className="w-full h-full relative">
        <div className="w-full flex flex-col gap-4">
          <div className="flex justify-between items-center">
            <div className="flex gap-4 text-primary-10 text-xl">
              <p
                className={`hover:underline-offset-8 hover:underline decoration-solid decoration-2 decoration-secondary-10 cursor-pointer ${
                  is3d
                    ? 'font-semibold text-NGrey-6'
                    : ' font-extrabold underline decoration-solid decoration-2 decoration-secondary-10 underline-offset-8'
                }`}
                onClick={() => {
                  setIs3D(false);
                  while (document.querySelectorAll('#undefined').length > 0)
                    document.querySelectorAll('#undefined')[0].remove();
                }}>
                2D
              </p>

              <p
                className={`hover:underline-offset-8 hover:underline decoration-solid decoration-2 decoration-secondary-10 cursor-pointer ${
                  is3d
                    ? 'font-extrabold underline decoration-solid decoration-2 decoration-secondary-10 underline-offset-8'
                    : ' font-semibold text-NGrey-6'
                }`}
                onClick={() => setIs3D(true)}>
                3D
              </p>
            </div>
            <CloseIcon
              className="h-5 w-5 cursor-pointer hover:text-NGrey-8"
              onClick={close}
            />
          </div>
          <div className="w-full flex flex-col gap-4">
            <div id="modal-header">
              {data[0] && (
                <p className="text-primary-10 font-sans  text-center text-[33px] font-semibold">
                  {data[0]}
                </p>
              )}
              <p className="text-NGrey-6 font-sans text-center text-xl font-semibold">
                Structural formula
              </p>
            </div>
            <div className="shadow-innerLG w-full justify-center flex rounded-lg p-2">
              <div
                id="modal-body"
                className="h-80 w-full mx-auto relative  flex justify-center items-baseline cursor-grab"
                onMouseDown={() => {
                  if (is3d)
                    document
                      .getElementById('modal-body')
                      .classList.add('cursor-grabbing');
                }}
                onMouseUp={() => {
                  if (is3d)
                    document
                      .getElementById('modal-body')
                      .classList.remove('cursor-grabbing');
                }}>
                {!is3d && data[1] && (
                  <RenderSvgFile svgBody={data[1]} size="modal" />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
SmilesModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.any
};
SmilesModal.defaultProps = {
  data: null
};
