// @copyright (c) 2023-2024 Compular AB
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import CloseIcon from '../../icons/CloseIcon';
import JobTable from '../JobTable/JobTable';
import DefaultButton from '../DefaultButtons/DefaultButton';
import { onselect, onselectall } from '../../redux/actions/actions';

/**
 * A modal with a list of jobs with status finished
 *
 * @param isOpen bool. true if modal is open
 * @param close function. Close modal
 * @param jobs array. list of finished jobs
 * @returns
 */
// Dispatch SimulateJob to change status to processing.
// Creates first log message and estimated time

export default function JobListModal({ isOpen, close, jobs }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedJobschecklist = useSelector(
    (state) => state.reducer.selectedJobs
  );

  const customModalStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      minWidth: '800px',
      height: '100%',
      maxHeight: '580px',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '8px',
      borderRadius: '5px'
    }
  };

  useEffect(() => {
    if (isOpen) dispatch(onselectall('clear', selectedJobschecklist));
  }, []);

  const onSelectall = () => {
    dispatch(
      onselectall(
        jobs.map((job) => job[0]),
        selectedJobschecklist
      )
    );
  };

  const onSelectjob = (id) => {
    dispatch(onselect(id, selectedJobschecklist));
  };

  return (
    <Modal
      style={customModalStyle}
      isOpen={isOpen}
      ariaHideApp={false}
      onRequestClose={close}
      contentLabel="Create new project">
      <div className="w-full h-full relative">
        <div className="w-full flex flex-col gap-3">
          <div className="flex justify-end items-center mt-2 mr-2">
            <CloseIcon
              className="h-5 w-5 cursor-pointer hover:text-NGrey-8"
              onClick={close}
            />
          </div>
          <div id="modal-header" className="flex flex-col gap-2 items-center">
            <p className="text-primary-10 text-xl font-bold">Compare results</p>
            <p className="text-NGrey-8 py-4">
              Only systems that are finished are comparable
            </p>
            <p className="w-full pl-7 flex justify-left text-NGrey-8 text-sm">
              <span className="font-bold text-primary-10 ">
                {selectedJobschecklist.length}
              </span>
              /{jobs.length} Select at least 2 jobs
            </p>
          </div>
          <div id="modal-body">
            {jobs && jobs.length > 0 && (
              <JobTable
                onselectall={onSelectall}
                screenings={jobs}
                action={(id) => {
                  onSelectjob(id);
                }}
                // jobNames={selectedJobsShortName}
                selectedJobs={selectedJobschecklist}
              />
            )}
          </div>
        </div>
        <div
          className={`px-6 w-full flex justify-end bottom-2 absolute 
          `}>
          {selectedJobschecklist.length > 1 ? (
            <div>
              <DefaultButton
                type="contained-secondary"
                label="Compare systems"
                onPressed={() => {
                  navigate('/jobs/results', { state: selectedJobschecklist });
                }}
              />
            </div>
          ) : (
            <div>
              <DefaultButton
                type="contained-gray"
                label="Compare systems"
                onPressed={() => {}}
              />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}
JobListModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  jobs: PropTypes.instanceOf(Array).isRequired
};
