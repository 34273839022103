// @copyright (c) 2023-2024 Compular AB
/* eslint-disable react/prop-types */
/* eslint-disable no-console */
import React, { useState } from 'react';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { useNavigate } from 'react-router-dom';
import Pool from '../UserPool';
import VisibleIcon from '../icons/VisibleIcon';
import NonVisibleIcon from '../icons/NonVisibleIcon';

export default function ForgotPassword({ userEmail, setForgotPassword }) {
  const navigate = useNavigate();
  const [stage, setStage] = useState(1); // 1 = email stage, 2 = code stage
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [isNewPasswordActive, setIsNewPasswordActive] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [isConfirmPasswordActive, setIsConfirmPasswordActive] = useState(false);

  const getUser = () => {
    return new CognitoUser({
      Username: email.toLowerCase(),
      Pool
    });
  };
  // When stage 1 send verification code run this func
  const sendCode = (event) => {
    // prevent url from changing (<form></form> element changes url by default)
    event.preventDefault();

    getUser().forgotPassword({
      onSuccess: () => {},
      onFailure: (err) => {
        console.error('onFailure:', err);
      },
      // if user is in cognito db send email with  verification code
      // this doesnt work right now because we need to upgrade AWS SES
      inputVerificationCode: () => {
        setStage(2);
      }
    });
  };
  // Second part of this page changes password
  // form element 2 is for this row 102-198
  const resetPassword = (event) => {
    event.preventDefault();

    // if password doesnt match return
    if (password !== confirmPassword) {
      console.error('Passwords are not the same');
      return;
    }
    // get user from userpool and call confirmpassword.
    // verification code as first parameter and new password as second
    getUser().confirmPassword(code, password, {
      onSuccess: () => {
        // set this page as inactive. this will redirect the user to loginpage
        // i think we can remove this because we authenticate user row 70

        setForgotPassword(false);
        navigate('/');
      },
      onFailure: (err) => {
        console.error('onFailure:', err);
      }
    });
  };

  return (
    <div>
      {stage === 1 && (
        <form onSubmit={sendCode} className="w-full flex flex-col gap-4">
          <div className="flex flex-1 flex-col justify-between  w-full p-0">
            <div className="flex gap-2 pb-2 items-center w-full">
              <p className="font-sans font-normal text-base text-NGrey-9">
                Email
              </p>
            </div>
            {userEmail ? (
              <input
                className="h-11 bg-currentColor w-full caret-secondary-10 border-box rounded border border-NGrey-6 font-sans font-normal text-base text-NGrey-9 pl-2 focus:ring-0 ring-0 focus:outline-none focus:border-primary-10"
                value={userEmail}
                disabled
              />
            ) : (
              <input
                className="h-11 bg-currentColor w-full caret-secondary-10 border-box rounded border border-NGrey-6 font-sans font-normal text-base text-NGrey-9 pl-2 focus:ring-0 ring-0 focus:outline-none focus:border-primary-10"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            )}
          </div>
          <button
            className="active:bg-secondary-8 active:border-secondary-8 hover:bg-secondary-7 hover:border-secondary-7 items-center bg-secondary-10 text-white border border-secondary-10 rounded flex gap-2 py-2 px-4 min-w-fit"
            type="submit">
            Send verification code
          </button>
        </form>
      )}

      {stage === 2 && (
        <form onSubmit={resetPassword}>
          <div className="flex flex-1 flex-col justify-between  w-full p-0">
            <div className="flex gap-2 pb-2 items-center">
              <p className="font-sans font-normal text-base text-NGrey-9">
                Verification code
              </p>
            </div>
            <input
              className="h-11 bg-currentColor caret-secondary-10 border-box rounded border border-NGrey-6 font-sans font-normal text-base text-NGrey-9 pl-2 focus:ring-0 ring-0 focus:outline-none focus:border-primary-10"
              value={code}
              autoComplete="off"
              onChange={(event) => setCode(event.target.value)}
            />
          </div>
          <div className="flex flex-1 flex-col justify-between  w-full p-0 mt-2">
            <div className="flex gap-2 pb-2 items-center">
              <p className="font-sans font-normal text-base text-NGrey-9">
                New password
              </p>
            </div>
            <div
              className={`border ${
                isNewPasswordActive ? 'border-primary-9' : 'border-NGrey-6'
              } rounded flex items-center h-11 bg-white pr-2 w-full`}>
              <div className="w-full flex items-center my-[1px]">
                <input
                  className="mr-2 caret-secondary-10  my-auto border-0 text-NGrey-9 text-normal border-transparent focus:border-transparent focus:ring-0 h-full w-full focus:outline-none"
                  onFocus={() => setIsNewPasswordActive(true)}
                  onBlur={() => {
                    setIsNewPasswordActive(false);
                  }}
                  autoComplete="off"
                  type={newPasswordVisible ? 'text' : 'password'}
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                />
              </div>

              <div className="w-full h-full flex items-center flex-1">
                {newPasswordVisible ? (
                  <VisibleIcon
                    className="w-5 h-5 cursor-pointer text-primary-9 hover:text-primary-7"
                    onMouseDown={() => setNewPasswordVisible(false)}
                  />
                ) : (
                  <NonVisibleIcon
                    className="w-5 h-5 cursor-pointer text-primary-9 hover:text-primary-7"
                    onMouseDown={() => setNewPasswordVisible(true)}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-1 flex-col justify-between  w-full p-0 mt-2">
            <div className="flex gap-2 pb-2 items-center">
              <p className="font-sans font-normal text-base text-NGrey-9">
                Confirm new password
              </p>
            </div>
            <div
              className={`border ${
                isConfirmPasswordActive ? 'border-primary-9' : 'border-NGrey-6'
              } rounded flex items-center h-11 bg-white pr-2 w-full`}>
              <div className="w-full flex items-center my-[1px]">
                <input
                  className="mr-2 caret-secondary-10  my-auto border-0 text-NGrey-9 text-normal border-transparent focus:border-transparent focus:ring-0 h-full w-full focus:outline-none"
                  onFocus={() => setIsConfirmPasswordActive(true)}
                  onBlur={() => {
                    setIsConfirmPasswordActive(false);
                  }}
                  type={confirmPasswordVisible ? 'text' : 'password'}
                  value={confirmPassword}
                  onChange={(event) => setConfirmPassword(event.target.value)}
                />
              </div>
              <div className="w-full h-full flex items-center flex-1">
                {confirmPasswordVisible ? (
                  <VisibleIcon
                    className="w-5 h-5 cursor-pointer text-primary-9 hover:text-primary-7"
                    onMouseDown={() => setConfirmPasswordVisible(false)}
                  />
                ) : (
                  <NonVisibleIcon
                    className="w-5 h-5 cursor-pointer text-primary-9 hover:text-primary-7"
                    onMouseDown={() => setConfirmPasswordVisible(true)}
                  />
                )}
              </div>
            </div>
          </div>

          <button
            type="submit"
            className="mt-4 ml-auto active:bg-secondary-8 active:border-secondary-8 hover:bg-secondary-7 hover:border-secondary-7 items-center bg-secondary-10 text-white border border-secondary-10 rounded flex gap-2 py-2 px-4 min-w-fit">
            Change password
          </button>
        </form>
      )}
    </div>
  );
}
