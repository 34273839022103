// @copyright (c) 2023-2024 Compular AB
import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Chart, registerables } from 'chart.js';
import { Radar } from 'react-chartjs-2';
import { convertHeader } from '../../utils/utils';

Chart.register(...registerables);
/**
 * Radarchart for multiple systems.
 *
 * @param {Array} jobs Array of jobs+results to render chart
 * @param {string} sHeader header for chart
 * @returns
 */
export default function GroupedRadarChart({ jobs, sHeader }) {
  const [aDatasets, setADatasets] = useState([]);
  const formattedHeader = useMemo(() => {
    return convertHeader(sHeader);
  }, [sHeader]);

  useEffect(() => {
    const resultData = jobs.map(({ result }) => result.molecularSystem);
    const indexForChartData = resultData[0][0].indexOf(sHeader);
    const indexForChartDataError = resultData[0][0].indexOf(sHeader) + 1;
    const keys = jobs.map(({ result }) => {
      return result.molecularSystem
        .filter((header, i) => i !== 0)
        .map((molRes) => molRes[0]);
    });
    const aHeader = [];
    const setterHeader = new Set();
    for (let index = 0; index < keys.length; index++) {
      const element = keys[index];
      for (let i = 0; i < element.length; i++) {
        const value = element[i];
        setterHeader.add(value);
      }
    }
    setterHeader.forEach((header) => {
      aHeader.push(header);
    });
    const dataO = jobs.map((job) => {
      return job.result.molecularSystem.map((molRow) => {
        return {
          [molRow[0]]: {
            value: molRow[indexForChartData],
            error: molRow[indexForChartDataError]
          }
        };
      });
    });
    const chartData = dataO.map((a) =>
      aHeader.map((b) => {
        const obj = a.find((a2) => Object.keys(a2)[0] === b);
        if (obj?.[b].value)
          return { [b]: { value: obj[b].value, error: obj[b].error, name: b } };
        return { [b]: { value: null, error: null, name: b } };
      })
    );
    setADatasets(chartData);
  }, [jobs]);

  function getRadarColor(i) {
    // get color for dataset.
    switch (i) {
      case 1:
        return 'rgba(213, 225, 163, 0.6)';
      case 2:
        return 'rgba(176, 65, 62, 0.6)';
      case 3:
        return 'rgba(84, 134, 135, 0.6)';
      case 4:
        return 'rgba(212, 205, 244, 0.6)';
      case 5:
        return 'rgba(255, 182, 193, 0.6)';
      case 6:
        return 'rgba(173, 216, 230, 0.6)';
      default:
        return 'rgba(216, 164, 127, 0.6)';
    }
  }
  const divStyle = {
    height: '67vh',
    display: 'flex',
    justifyContent: 'center'
  };

  if (aDatasets.length === 0) return <div style={divStyle} />;
  const data = {
    // labels ska vara något annat sen. typ system name
    labels: aDatasets[0].map((j) => Object.keys(j)[0]),
    datasets: aDatasets.map((job, i) => {
      return {
        label: jobs[i].name,
        data: job.map((j) => Object.values(j)[0].value),
        errorData: job.map((j) => Object.values(j)[0].error),
        backgroundColor: getRadarColor(i),
        borderRadius: 2,
        borderColor: getRadarColor(i),
        borderWidth: 1
      };
    })
  };
  const options = {
    plugins: {
      tooltip: {
        padding: 12,
        callbacks: {
          label: (context) => {
            if (context.dataset.data[context.dataIndex])
              return `${context.dataset.label}: ${
                context.dataset.data[context.dataIndex]
              }`;
          },
          afterLabel: (context) => {
            if (context.dataset.errorData[context.dataIndex])
              return `± ${context.dataset.errorData[context.dataIndex]}`;
          }
        }
      },
      maintainAspectRatio: false,
      title: {
        display: true,
        position: 'left',
        font: {
          size: 15
        },
        text:
          formattedHeader === 'Diffusivity [m\u00b2/s]'
            ? formattedHeader
                .split('[')[0]
                .replaceAll(' ', ' [1e-10 m\u00b2/s]')
            : formattedHeader === 'Electrical mobility [m\u00b2/(V\u00D7s)] '
            ? formattedHeader
                .trim()
                .replaceAll(
                  '[m\u00b2/(V\u00D7s)]',
                  ' [1e-9 m\u00b2/(V\u00D7s)]'
                )
            : formattedHeader
      }
    }
  };
  return (
    <div style={divStyle}>
      <Radar data={data} options={options} />
    </div>
  );
}
GroupedRadarChart.propTypes = {
  sHeader: PropTypes.string.isRequired,
  jobs: PropTypes.instanceOf(Array).isRequired
};
