// @copyright (c) 2022-2024 Compular AB
import React from 'react';
import PropTypes from 'prop-types';
import {
  // Must have
  Chart as ChartJS,
  ArcElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Pie } from 'react-chartjs-2';
/**
 * Outlined button with optioinal label and disabled state
 *
 * @param {any} aLabel Label displayed above button
 * @param {string} header Type of button
 * @param {any} aDataJob
 * @returns
 */

ChartJS.register(ArcElement, Title, Tooltip, Legend);
function PieChart({ dataJob, label, header }) {
  const data = {
    labels: label,
    datasets: [
      {
        // label: 'sample data',
        data: dataJob,
        borderColor: 'white',
        backgroundColor: [
          '#F2933D',
          '#133859',
          'grey',
          'green',
          'lightblue',
          'pink'
        ]
      }
    ],
    borderWidth: 4
  };
  if (label.length !== dataJob.length) return 'Failed to load image';
  return (
    <div className="h-full w-full flex justify-center items-center p-8">
      <Pie
        data={data}
        options={{
          plugins: {
            legend: {
              display: false
            },
            maintainAspectRatio: false,
            title: {
              display: true,
              font: {
                size: 10
              },
              text: header
            }
          }
        }}
      />
    </div>
  );
}

PieChart.propTypes = {
  label: PropTypes.instanceOf(Array).isRequired,
  header: PropTypes.string.isRequired,
  dataJob: PropTypes.instanceOf(Array).isRequired
};

export default PieChart;
