// @copyright (c) 2022-2024 Compular AB
import * as React from 'react';

function ArrowLeftIcon(props) {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M13.5 7.333H5.387l3.726-3.726-.946-.94L2.833 8l5.334 5.333.94-.94-3.72-3.726H13.5V7.333Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default ArrowLeftIcon;
