// @copyright (c) 2023-2024 Compular AB
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import CloseIcon from '../../icons/CloseIcon';
import DefaultButton from '../DefaultButtons/DefaultButton';
import DropdownMenu from '../DropdownMenu/DropdownMenu';
import InputField from '../InputFields/InputField';
import EditIcon from '../../icons/EditIcon';

/**
 * A modal that allows the user to contact us.
 *
 * @param isOpen boolean
 * @param close function
 * @returns
 */

const dropDownOptions = ['Single values', 'Number of values', 'Stride length'];
export default function ScreeningModal({
  isOpen,
  close,
  unit,
  setEditedJob,
  editedJob
}) {
  const [option, setOption] = useState(dropDownOptions[0]);
  const [inputValue, setInputValue] = useState('');
  const [addedValue, setAddedValue] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [minValue, setMinValue] = useState('');
  const [maxValue, setMaxValue] = useState('');
  const [numberOfValues, setNumberOfValues] = useState('');
  const [strideLength, setStrideLength] = useState('');

  const customModalStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      minWidth: '720px',
      maxWidth: '720px',
      height: '80%',
      maxHeight: '600px',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '16px',
      borderRadius: '5px',
      zIndex: 20
    }
  };
  const onPress = () => {
    if (addedValue.length <= 19) {
      if (inputValue === '') {
        return;
      }
      if (addedValue.includes(Number(inputValue))) {
        setErrorMessage('Value already exists');
        return;
      }
      setAddedValue([...addedValue, parseFloat(inputValue)]);
      setInputValue('');
    } else {
      setErrorMessage('Max amount of values is 20');
    }
  };
  useEffect(() => {
    if (inputValue !== '') setErrorMessage('');
  }, [inputValue]);

  const clearAll = () => {
    setAddedValue([]);
  };

  const valuesCalculation = () => {
    if (!maxValue || !minValue || !numberOfValues) {
      return null;
    }
    if (maxValue && minValue && numberOfValues) {
      let arr = [];
      const min = parseInt(minValue);
      const max = parseInt(maxValue);
      const nrofval = parseInt(numberOfValues);
      const interval = (max - min) / (nrofval - 1);

      if (nrofval > 20) {
        setErrorMessage('Max amount of values is 20');
        return;
      }
      if (nrofval >= 3) {
        let indexval;
        for (let i = 0; i < nrofval; i++) {
          if (i === 0) {
            indexval = min;
            arr.push(parseFloat(min.toFixed(3)));
          } else if (i + 1 === nrofval) arr.push(parseFloat(max.toFixed(3)));
          else {
            indexval += interval;
            arr.push(parseFloat(indexval.toFixed(3)));
          }
        }
      } else if (nrofval === 2) {
        arr = [min, max];
      }
      if (arr.length <= 20) {
        setAddedValue(arr);
      }
    }
  };

  useEffect(() => {
    setErrorMessage('');
    if (option === 'Number of values' || option === 'Stride length') {
      setAddedValue([]);
      setMinValue('');
      setMaxValue('');
      setNumberOfValues('');
      setStrideLength('');
    }
  }, [option]);

  const strideCalculation = () => {
    if (!maxValue || !minValue || !strideLength) {
      return null;
    }
    if (maxValue && minValue && strideLength) {
      const stridePoints = [];
      const min = parseInt(minValue);
      const max = parseInt(maxValue);
      const sl = parseInt(strideLength);
      for (let point = min; point <= max; point += sl) {
        stridePoints.push(point);
      }
      if (stridePoints.length <= 20) {
        setAddedValue(stridePoints);
      } else {
        setErrorMessage('Max amount of values is 20');
      }
    }
  };

  return (
    <Modal
      style={customModalStyle}
      isOpen={isOpen}
      ariaHideApp={false}
      onRequestClose={close}
      contentLabel="Create new project">
      <div className="w-full h-full relative">
        <div className="w-full flex flex-col gap-1 px-2 relative">
          <div className="absolute top-0 right-0 items-center">
            <CloseIcon
              className="h-5 w-5 cursor-pointer hover:text-NGrey-8"
              onClick={close}
            />
          </div>
          <div id="modal-header">
            <p className="text-primary-10 font-sans font-bold text-lg text-center">
              Screening
            </p>
            <p className="text-center text-NGrey-8 py-2">
              The maximum amount of values is 20
            </p>
          </div>
          <div className="mb-2 w-[172px]">
            <DropdownMenu
              label="Select type"
              placeHolder="Select type"
              currentValue={option}
              options={dropDownOptions}
              onChange={(e) => {
                setOption(e.target.value);
              }}
            />
          </div>
          <div>
            {option === 'Single values' && (
              <>
                <p className="text-base border-t pt-2 border-NGrey-6 font-semibold">
                  Enter value
                </p>
                <div className="flex gap-3 items-center pt-1">
                  <div className="w-1/4">
                    <InputField
                      type="NUMBER"
                      name="value"
                      label={`Value (${unit})`}
                      isRequired={false}
                      errorMessage="wrong"
                      inputValue={inputValue}
                      setInputValue={(e) => {
                        setInputValue(e.target.value);
                      }}
                      isError={addedValue.length > 20}
                    />
                  </div>
                  <div className="pt-8">
                    <DefaultButton
                      type="outlined-primary"
                      label="Add value"
                      onPressed={onPress}
                      disabled={addedValue.length >= 19}
                    />
                  </div>
                </div>
                <div className="h-4 py-1 mb-1">
                  {errorMessage.length > 0 && (
                    <p className="text-Error-6 text-sm">{errorMessage}</p>
                  )}
                </div>
              </>
            )}
            {option === 'Number of values' && (
              <>
                <p className="text-base border-t pt-2 border-NGrey-6 font-semibold">
                  Specify your screening values
                </p>
                <div>
                  <div>
                    <div className="flex gap-3">
                      <div className="flex items-center gap-1 pt-1 text-sm">
                        <div className="w-40">
                          <InputField
                            isRequired={false}
                            name="minNumber"
                            label="Min"
                            type="NUMBER"
                            inputValue={minValue}
                            setInputValue={(e) => {
                              setMinValue(e.target.value);
                            }}
                          />
                        </div>
                        <div className="mt-7">
                          <p>-</p>
                        </div>
                        <div className="w-40">
                          <InputField
                            isRequired={false}
                            name="maxNumber"
                            label="Max"
                            type="NUMBER"
                            inputValue={maxValue}
                            setInputValue={(e) => {
                              setMaxValue(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="flex gap-2">
                        <div className="pt-1 w-40">
                          <InputField
                            isRequired={false}
                            name="nrOfValues"
                            label="Number of values"
                            type="NUMBER"
                            inputValue={numberOfValues}
                            setInputValue={(e) => {
                              setNumberOfValues(e.target.value);
                            }}
                          />
                        </div>
                        <div className="mt-9">
                          <DefaultButton
                            type="outlined-primary"
                            label={
                              addedValue.length > 0
                                ? 'Replace values'
                                : 'Add values'
                            }
                            onPressed={() => valuesCalculation()}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="h-4 py-1 mb-1">
                      {errorMessage.length > 0 && (
                        <p className="text-Error-6 text-sm">{errorMessage}</p>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
            {option === 'Stride length' && (
              <div>
                <p className="text-base border-t pt-2 border-NGrey-6 font-semibold">
                  Specify your screening values
                </p>
                <div className="flex gap-3">
                  <div className="flex items-center gap-1 pt-1 text-sm">
                    <div className="w-40">
                      <InputField
                        isRequired={false}
                        label="Min"
                        name="minNumber"
                        type="NUMBER"
                        inputValue={minValue}
                        setInputValue={(e) => {
                          setMinValue(e.target.value);
                        }}
                      />
                    </div>
                    <div className="mt-7">
                      <p>-</p>
                    </div>
                    <div className="w-40">
                      <InputField
                        isRequired={false}
                        name="maxNumber"
                        label="Max"
                        type="NUMBER"
                        inputValue={maxValue}
                        setInputValue={(e) => {
                          setMaxValue(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex gap-2">
                    <div className="pt-1 w-40">
                      <InputField
                        isRequired={false}
                        name="strideLength"
                        label="Stride length"
                        type="NUMBER"
                        inputValue={strideLength}
                        setInputValue={(e) => {
                          setStrideLength(e.target.value);
                        }}
                      />
                    </div>
                    <div className="mt-9">
                      <DefaultButton
                        type="outlined-primary"
                        label="Add values"
                        onPressed={() => strideCalculation()}
                      />
                    </div>
                  </div>
                </div>
                <div className="h-4 py-1 mb-1">
                  {errorMessage.length > 0 && (
                    <p className="text-Error-6 text-sm">{errorMessage}</p>
                  )}
                </div>
              </div>
            )}
          </div>
          <div>
            <div className="flex justify-between items-center border-t border-NGrey-6 pt-2">
              <div className="flex gap-2 items-center">
                <p className="font-sans font-semibold text-NGrey-9">Values</p>
                {unit && (
                  <p className="text-NGrey-7 font-sans font-semibold">
                    ( {unit} )
                  </p>
                )}
                {option !== 'Single values' && (
                  <EditIcon
                    className="text-primary-9 w-4 h-4 ml-1"
                    onClick={() => setOption('Single values')}
                  />
                )}
              </div>

              {addedValue.length > 0 && (
                <DefaultButton
                  className="underline fontsize-xs"
                  onPressed={clearAll}
                  type="text-primary"
                  label="Clear all"
                />
              )}
            </div>
            <div className="h-4">
              {option !== 'Single values' && (
                <p className="text-xs text-NGrey-6">
                  To add specific values press edit, this changes the type into
                  single value
                </p>
              )}
            </div>
          </div>
          <div className="border border-NGrey-6 rounded-lg p-2 h-[170px] overflow-y-auto">
            <div className="items-center flex flex-wrap gap-2">
              {addedValue.map((value, i) => {
                return (
                  <div
                    key={`values-${value}-${i}`}
                    className="items-center flex px-3 py-1 text-NGrey-9 bg-primary-6 bg-opacity-30 rounded-md gap-2 border-2 border-primary-9 border-opacity-50">
                    <p>{Math.round((value + Number.EPSILON) * 1000) / 1000}</p>
                    <div className="right-1">
                      <CloseIcon
                        className="w-2 h-2 cursor-pointer hover:text-NGrey-7"
                        onClick={() => {
                          setAddedValue(
                            addedValue.filter((val, index) => i !== index)
                          );
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="w-full justify-end flex gap-2 mt-2">
            <div className="">
              <DefaultButton
                type="outlined-primary"
                label="Cancel"
                onPressed={close}
              />
            </div>
            <div className="">
              <DefaultButton
                type="contained-secondary"
                label="Apply screening"
                onPressed={() => {
                  setEditedJob({
                    ...editedJob,
                    screening: {
                      ...editedJob.screening,
                      values: addedValue
                    },
                    temperature: {
                      ...editedJob.temperature,
                      value: addedValue
                    }
                  });
                  close();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
ScreeningModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setEditedJob: PropTypes.func.isRequired,
  unit: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
  editedJob: PropTypes.any.isRequired
};
