/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* @copyright (c) 2021-2022 Compular AB */
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AccountContext } from '../../Accounts';
import UserIcon from '../../icons/UserIcon';
import { logOut } from '../../redux/actions/actions';
import CompularLogo from '../CompularLogo/CompularLogo';
import DefaultButton from '../DefaultButtons/DefaultButton';
import './TopNavigator.css';

/**
 *
 * Top Navigation bar. Used to navigate between the applications outer-most pages.
 *
 * @param {*} props
 * @param {Boolean} showProgressBar
 * @param {function} openModal
 * @returns
 */
function TopNavigator({ openModal }) {
  const { logout } = useContext(AccountContext);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const { user, isLoggedIn } = useSelector((state) => state.reducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const handleClick = (e) => {
  //   if (!document.getElementById('userDropdown')?.contains(e.target)) {
  //     setIsDropDownOpen(false);
  //     window.removeEventListener('click', handleClick);
  //     // Clicked outside the box
  //   }
  // };
  // useEffect(() => {
  //   if (isDropDownOpen) window.addEventListener('click', handleClick);
  //   else window.removeEventListener('click', handleClick);
  // }, [isDropDownOpen]);

  // const location = useLocation();

  return (
    <div
      id="top-nav-wrapper"
      className="min-h-[75px] max-h-[75px] border-b-2 border-[#dde2e5]">
      <div
        className="w-full flex items-center justify-between "
        aria-hidden="true">
        {user && (
          <div className="w-full flex items-center relative">
            <button
              type="button"
              // onClick={() => setIsDropDownOpen(!isDropDownOpen)}
            >
              <div className="py-2 px-4 flex items-center gap-1 border border-NGrey-5 rounded-lg text-NGrey-8 font-bold text-base">
                <UserIcon className="h-6 w-6" />
                <p>{user[3]}</p>
                <p>{user[4]}</p>
              </div>
            </button>
            {/* {isDropDownOpen && (
              <div
                id="userDropdown"
                className="top-[50px] min-w-[200px] px-2 absolute flex flex-col bg-NGrey-1 z-50 rounded-lg divide-y divide-primary-4 border border-NGrey-6">
                <button
                  type="button"
                  className="w-full py-2 text-left hover:text-secondary-6"
                  onClick={() => {
                    setIsDropDownOpen(!isDropDownOpen);
                    navigate('/account');
                  }}>
                  Account settings
                </button>
                <button
                  type="button"
                  className="w-full py-2 text-left hover:text-secondary-6"
                  onClick={() => {
                    dispatch(logOut());
                    logout();
                    navigate('/');
                  }}>
                  Log out
                </button>
              </div>
            )} */}
          </div>
        )}
        <div
          className="cursor-pointer w-full justify-center hidden lg:flex"
          onClick={() => {
            navigate('/jobs');
          }}>
          <CompularLogo />
        </div>
        {isLoggedIn && (
          <div className="flex gap-5 w-full justify-end min-w-[250px]">
            {/* <DefaultButton
              label="Support"
              type="outlined-primary"
              onPressed={() => {
                openModal();
              }}
              icon="message"
              iconPosition="left"
            /> */}
            <DefaultButton
              label="Contact us"
              type="outlined-primary"
              onPressed={() => {
                openModal();
              }}
            />
            {/* <DefaultButton
              label="Book demo"
              type="outlined-primary"
              onPressed={() => {
                openModal();
              }}
            /> */}
            <DefaultButton
              label="Log out"
              type="outlined-secondary"
              onPressed={() => {
                dispatch(logOut());
                logout();
                navigate('/');
              }}
              icon="logout"
              iconPosition="left"
            />
          </div>
        )}
      </div>
      {/* <div id="top-nav-text-wrapper">
        {location.pathname !== '/' ? (
          <>
            {/* Add more tabs here if necessary */}
      {/* <NavLink
              to="/projects"
              className={`nav-text${
                location.pathname.includes('/project') ? ' active' : ''
              }`}>
              Projects
            </NavLink>
            <NavLink
              to="/jobs"
              className={`nav-text${
                location.pathname.includes('/jobs') ? ' active' : ''
              }`}>
              Jobs
            </NavLink> */}
      {/*
            <NavLink
              to="/"
              className="navText"
              onClick={(e) => {
                alert('This page is not available yet!');
                e.preventDefault(); // remove all code in onclick to activate link add route to to prop
              }}>
              Compositions{' '}
            </NavLink>
            <div className="navText"> | </div>
            <NavLink
              to="/"
              className="navText"
              onClick={(e) => {
                alert('This page is not available yet!');
                e.preventDefault(); // remove all code in onclick to activate link add route to to prop
              }}>
              Settings
            </NavLink>
            */}
      {/* </> */}
      {/* //   ) : props.showProgressBar ? (
      //     <div id="progress-bar">
      //       <div className="nav-text active">
      //         System 5 Simulation step 539/1000
      //       </div>
      //       <SystemProgressBar current={539} total={1000} />
      //     </div>
      //   ) : (
      //     <div />
      //   )}
      // </div> */}
    </div>
  );
}
TopNavigator.propTypes = {
  openModal: PropTypes.func
  // showProgressBar: PropTypes.bool.isRequired
};
TopNavigator.defaultProps = {
  openModal: null

  // id: null
};
export default TopNavigator;
