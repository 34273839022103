// @copyright (c) 2022-2024 Compular AB
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import { toast } from 'react-toastify';
import { customSort, parseData, generateXml } from '../../utils/utils';
import { useSession } from '../api/auth/user';
import { useGetGenerateJobFiles, useGetResult } from '../api/job/job';
import { getScreeningByOrgId } from './api_wrappers';
import {
  postQueryDb,
  postQueryDbKey,
  postQueryDbHeader
} from '../api/execute/execute';
import {
  ADD_MOLECULES_ERROR,
  ADD_MOLECULES_START,
  ADD_MOLECULES_SUCCESS,
  CANCEL_JOB_ERROR,
  CANCEL_JOB_START,
  CANCEL_JOB_SUCCESS,
  CREATE_JOB_ERROR,
  CREATE_JOB_START,
  CREATE_JOB_SUCCESS,
  DELETE_JOB_ERROR,
  DELETE_JOB_START,
  DELETE_JOB_SUCCESS,
  GET_JOB_ERROR,
  GET_JOB_RESULT_START,
  GET_JOB_RESULT_SUCCESS,
  GET_JOB_RESULT_ERROR,
  GET_JOB_START,
  GET_JOB_SUCCESS,
  GET_MOLECULES_SUCCESS,
  SAVE_JOB_ERROR,
  SAVE_JOB_START,
  SAVE_JOB_SUCCESS,
  SIGN_OUT,
  UPDATE_NAME_START,
  UPDATE_NAME_ERROR,
  UPDATE_NAME_SUCCESS,
  DELETE_MOLECULES_START,
  DELETE_MOLECULES_ERROR,
  DELETE_MOLECULES_SUCCESS,
  GET_MOLECULES_ERROR,
  GET_MOLECULES_START,
  GET_STOICHIOMETRY_SUCCESS,
  GET_STOICHIOMETRY_START,
  GET_STOICHIOMETRY_ERROR,
  LOG_IN_START,
  LOG_IN_ERROR,
  LOG_IN_SUCCESS
} from '../types';
import {
  useDeleteMolecule,
  useUpsertMolecule
} from '../api/molecules/molecules';
import {
  useGetStoichiometry,
  useGetXYZ
} from '../api/stoichiometry/stoichiometry';
import { useSendSupportMail } from '../api/mail/mail';
// import {  useSendEmailJobStart } from '../api/mail/mail';
function updateToast(notification, types, message) {
  toast.update(notification, {
    render: message,
    type: types,
    isLoading: false,
    autoClose: 2500,
    hideProgressBar: true,
    pauseOnHover: true,
    draggable: true,
    theme: 'light'
  });
}
export function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}
// format date
export function formatD(date) {
  return `${[
    date.getFullYear(),
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate())
  ].join('-')} ${[
    padTo2Digits(date.getHours()),
    padTo2Digits(date.getMinutes()),
    padTo2Digits(date.getSeconds())
  ].join(':')}`;
}
// format date +7 days
export function formatDate(date) {
  return `${[
    date.getFullYear(),
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate() + 7)
  ].join('-')} ${[
    padTo2Digits(date.getHours()),
    padTo2Digits(date.getMinutes()),
    padTo2Digits(date.getSeconds())
  ].join(':')}`;
}

function fetchResult(data, sQueryName) {
  let xml;
  if (typeof data === 'string') {
    xml = new window.DOMParser().parseFromString(data, 'text/xml');
  }

  xml = xml.documentElement;
  const sBodyJobs = xml.firstElementChild.firstChild.nodeValue;
  const sQuery = xml.firstElementChild.getAttribute('query');
  if (sQueryName) {
    if (sQueryName !== sQuery) {
      // Return dispatch ERROR HERE!! || Return false!
      return;
    }
  }
  return JSON.parse(sBodyJobs);
}

export const sendMail = async (body) => {
  const notification = toast.loading('Sending mail.. ');
  try {
    await useSendSupportMail(body);
    updateToast(notification, 'success', 'Mail sent!');
  } catch (error) {
    updateToast(notification, 'error', 'Failed to send mail!');
  }
};

export const getXYZFile = async (data) => {
  // if we get the xyz of a molecule from the champion analysis output
  if (typeof data === 'object') {
    // encodeURIComponent here avoids loss of special characters like "+"
    const xyzData = await useGetResult(
      encodeURIComponent(data.filepath),
      'xyz',
      data.organizationk,
      data.jobk
    );
    let xml;
    if (typeof xyzData.data === 'string') {
      xml = new window.DOMParser().parseFromString(xyzData.data, 'text/xml');
    }

    xml = xml.documentElement;

    const sBodyMolecules = xml.firstElementChild.firstChild.nodeValue;
    const jsonData = JSON.parse(sBodyMolecules);
    return jsonData.data.toString();
  }
  // if we get the xyz of a molecule from the molecule bank
  const xyzData = await useGetXYZ(data);
  let xml;
  if (typeof xyzData.data === 'string') {
    xml = new window.DOMParser().parseFromString(xyzData.data, 'text/xml');
  }

  xml = xml.documentElement;
  const sBodyMolecules = xml.firstElementChild.firstChild.nodeValue;
  return sBodyMolecules.toString();
};

export const onselectall = (joblist, selectedJobs) => (dispatch) => {
  if (joblist === 'clear') {
    return dispatch({ type: 'SELECT_ALL', payload: { joblist: [] } });
  }
  const allSelected = !!(joblist.length === selectedJobs.length);
  if (allSelected) {
    dispatch({ type: 'SELECT_ALL', payload: { joblist: [] } });
  } else {
    dispatch({ type: 'SELECT_ALL', payload: { joblist } });
  }
};

export const onselect = (jobid, ajob) => (dispatch) => {
  const joblist = [...ajob];
  const selected = joblist.indexOf(jobid);

  if (selected >= 0) {
    if (selected === 0) joblist.shift();
    else joblist.splice(selected, selected);
    dispatch({ type: 'SELECT_JOB', payload: { joblist } });
  } else {
    const newjoblist = [...joblist, jobid];
    dispatch({ type: 'SELECT_JOB', payload: { joblist: newjoblist } });
  }
};
export const GetStoichiometry = (jobk) => async (dispatch) => {
  dispatch({ type: GET_STOICHIOMETRY_START });
  try {
    const { data } = await useGetStoichiometry(jobk);
    const stoichiometry = parseData(fetchResult(data));
    dispatch({
      type: GET_STOICHIOMETRY_SUCCESS,
      payload: { stoch: stoichiometry, id: parseInt(jobk) }
    });
  } catch (error) {
    dispatch({
      type: GET_STOICHIOMETRY_ERROR,
      errorMessage: `Compute stoichiometry failed! \n${error.message}`
    });
    if (error.message === 401) {
      window.location.assign('/');
    }
  }
};

export const deletePrivateMolecule = (moleculeKey) => async (dispatch) => {
  dispatch({ type: DELETE_MOLECULES_START });
  const notification = toast.loading('Deleting molecule.. ');
  try {
    await useDeleteMolecule(moleculeKey);
    dispatch({
      type: DELETE_MOLECULES_SUCCESS,
      payload: { moleculeK: moleculeKey }
    });
    updateToast(notification, 'success', 'Molecule deleted! 🗑️');
  } catch (error) {
    dispatch({
      type: DELETE_MOLECULES_ERROR,
      errorMessage: `Delete molecule failed! \n${error.message}`
    });
    if (error.message === 401) {
      return window.location.assign('/');
    }
    updateToast(notification, 'error', 'Failed to delete molecule! ');
  }
};

export const UpsertMolecules = (odata, fSetJobMolecule) => async (dispatch) => {
  dispatch({ type: ADD_MOLECULES_START });
  const notification = odata.id
    ? toast.loading('Updating molecule.. ')
    : toast.loading('Creating molecule.. ');
  const objToXML = {
    table: `TMolecule`,
    rows: [
      { type: 'V', name: 'OrganizationK', value: odata.orgId },
      { type: 'V', name: 'FName', value: odata.name },
      { type: 'V', name: 'ChemFormula', value: odata.chemForm },
      { type: 'V', name: 'MoleculeK', value: odata.id },
      { type: 'V', name: 'FDensity', value: odata.dens },
      { type: 'V', name: 'FDescription', value: odata.description }
    ]
  };

  const sMoleculeXml = generateXml({ values: [objToXML] });

  try {
    const { data } = await useUpsertMolecule(sMoleculeXml);
    const molecule = parseData(fetchResult(data));
    fSetJobMolecule({
      name: molecule[0][1],
      id: molecule[0][0],
      alias: molecule[0][2],
      density: molecule[0][3],
      orgK: molecule[0][4]
    });
    const newMolecule = molecule[0].map((m, i) => {
      if (i === 3) {
        if (m) return [m];
      }
      return m;
    });

    dispatch({
      type: ADD_MOLECULES_SUCCESS,
      payload: { loading: false, aMolecules: newMolecule }
    });
    if (odata.id !== undefined) {
      updateToast(notification, 'success', 'Molecule updated! ');
    } else {
      updateToast(notification, 'success', 'Molecule inserted! ');
    }
  } catch (error) {
    dispatch({
      type: ADD_MOLECULES_ERROR,
      errorMessage: `Upsert molecule failed! \n${error.message}`
    });
    if (error.message === 401) {
      window.location.assign('/');
    }
    if (odata.id !== undefined) {
      updateToast(notification, 'error', 'Failed to update molecule! ');
    } else {
      updateToast(notification, 'error', 'Failed to create molecule! ');
    }
  }
};

// const getMessageForLog = (logId) => async (dispatch) => {
//   const queryToXML = {
//     name: `job_log_message`,
//     command: 'select',
//     rows: [
//       {
//         type: 'W',
//         name: 'JobLogK',
//         table: 'JobLogMessage',
//         value: logId
//       }
//     ]
//   };
//   const xmlstr = generateXml({ queries: [{ query: [queryToXML] }] });

//   if (typeof logId === 'number') {
//     const { data } = await postQueryDb(xmlstr);
//     const message = fetchResult(data);
//     if (message.length > 0) {
//       message.forEach((m) => {
//         if (m.length > 0)
//           dispatch({
//             type: 'insertMessage',
//             payload: { logK: logId, message: m }
//           });
//       });
//     }
//   }
// };

const insertCompById = (id) => async (dispatch) => {
  const queryToXML = {
    name: `get_comp`,
    command: 'select',
    rows: [
      {
        type: 'W',
        name: 'JobCompositionK',
        table: 'TJobComposition',
        value: id
      }
    ]
  };
  const xmlstr = generateXml({ queries: [{ query: [queryToXML] }] });
  const { data } = await postQueryDb(xmlstr);
  const comp = parseData(fetchResult(data))[0];
  dispatch({ type: 'insertComp', payload: { newComp: comp, jobId: comp[1] } });
};

const removeCompById = (id, jobkey) => (dispatch) => {
  dispatch({ type: 'removeComp', payload: { compId: id, jobid: jobkey } });
};

export const cancelJob = (job, screeningId) => async (dispatch) => {
  dispatch({ type: CANCEL_JOB_START });
  const notification = toast.loading('Cancelling job...');
  const jobK = job.id;

  let objToXML = {
    table: `TJobLog`,
    command: 'insert',
    rows: [
      { type: 'V', name: 'JobK', value: jobK },
      { type: 'V', name: 'CodeC', value: 9 }
    ]
  };
  const xmlInsertLog = generateXml({ values: [objToXML] });

  try {
    const logData = await postQueryDbKey(xmlInsertLog);
    const __logId = fetchResult(logData.data);
    const logId = parseData(__logId)[0][1];

    let queryToXML = {
      name: `job_log`,
      command: 'select',
      rows: [{ type: 'W', name: 'JobLogK', table: 'TJobLog', value: logId }]
    };
    const xmlForLog = generateXml({ queries: [{ query: [queryToXML] }] });
    const newLogData = await postQueryDb(xmlForLog);
    const __log = fetchResult(newLogData.data);
    const parsedLog = parseData(__log)[0];
    dispatch({ type: 'insertLog', payload: { log: parsedLog } });

    objToXML = {
      table: `TJob`,
      command: 'update',
      rows: [
        { type: 'W', name: 'JobK', value: jobK },
        { type: 'V', name: 'FStatus', value: 'Not Started' },
        { type: 'V', name: 'EstimatedD', value: null }
      ]
    };
    const updateJobXml = generateXml({ values: [objToXML] });
    await postQueryDbKey(updateJobXml);

    const screeningData = await getScreeningByOrgId(screeningId);
    const screenings = parseData(fetchResult(screeningData.data));
    const aScreening = screenings[0];

    if (screenings?.length > 0) {
      aScreening.push([]);
      queryToXML = {
        name: `get_job`,
        command: 'select',
        rows: [
          { type: 'W', name: 'ScreeningK', table: 'TJob', value: screeningId }
        ]
      };
      const xmlForJob = generateXml({ queries: [{ query: [queryToXML] }] });
      const insertData = await postQueryDb(xmlForJob);
      const __job = fetchResult(insertData.data);
      const parsedJob = parseData(__job);

      for (let index = 0; index < parsedJob.length; index++) {
        aScreening.findLast((ele) => ele).push(parsedJob[index]);
      }
      dispatch({
        type: CANCEL_JOB_SUCCESS,
        payload: {
          loading: false,
          error: false,
          screening: aScreening,
          screeningKey: screeningId
        }
      });
      updateToast(notification, 'success', 'Job canceled! ');
      // send email here
      // await useSendEmailJobCancel(jobK);
    }
  } catch (error) {
    if (error.message === 401) {
      window.location.assign('/');
    }
    updateToast(notification, 'error', 'Failed to cancel job! ❌');
    dispatch({
      type: CANCEL_JOB_ERROR,
      errorMessage: `Cancel job failed!\n${error.message}`
    });
  }
};

/* function deleteJobById, PARAMS(screeningK == screeningK in db, jobK == jobK in db)
 ** jobK updates the TJob & TJobComposition table in the db. soft delete job & comps
 ** if jobs with screeningK === 0 delete screening
 */
export const deleteJobById = (screeningK, jobK) => async (dispatch) => {
  const notification = toast.loading('Deleting job...');
  dispatch({ type: DELETE_JOB_START });

  // remove all comps with jobK. SoftDelete
  let objToXML = {
    table: `TJobComposition`,
    command: 'update',
    rows: [
      { type: 'W', name: 'JobK', value: jobK },
      { type: 'V', name: 'DeletedD', value: formatD(new Date()) }
    ]
  };
  const compXml = generateXml({ values: [objToXML] });

  try {
    await postQueryDbKey(compXml);

    // delete job from db
    objToXML = {
      table: `TJob`,
      command: 'update',
      rows: [
        { type: 'W', name: 'JobK', value: jobK },
        { type: 'V', name: 'DeletedD', value: formatD(new Date()) }
      ]
    };
    const updateJobXml = generateXml({ values: [objToXML] });
    await postQueryDbKey(updateJobXml);

    // fetching all jobs from screeningK
    const queryToXML = {
      name: `get_job`,
      command: 'select',
      rows: [
        { type: 'W', name: 'ScreeningK', table: 'TJob', value: screeningK },
        { type: 'W', name: 'DeletedD', table: 'TJob', value: null }
      ]
    };
    const jobsXml = generateXml({ queries: [{ query: [queryToXML] }] });
    const screeningData = await postQueryDb(jobsXml);
    const jobs = parseData(fetchResult(screeningData.data));
    // if jobs.length > 0 update joblist in screening else delete screening
    if (jobs?.length > 0) {
      const { data } = await getScreeningByOrgId(screeningK);
      const screening = fetchResult(data)[0];
      screening.push([]);
      jobs.forEach((element) => {
        screening.findLast((ele) => ele).push(element);
      });
      // Update screening in reducer
      dispatch({
        type: DELETE_JOB_SUCCESS,
        payload: { jobId: jobK, screeningId: screeningK, aScreening: screening }
      });
      updateToast(notification, 'success', 'Job deleted! 🗑️');
    } else {
      // soft delete screening
      objToXML = {
        table: `TScreening`,
        command: 'update',
        rows: [
          { type: 'W', name: 'ScreeningK', value: screeningK },
          { type: 'V', name: 'DeletedD', value: formatD(new Date()) }
        ]
      };
      const updateScreeningXml = generateXml({ values: [objToXML] });
      await postQueryDbKey(updateScreeningXml);
      // Delete screening in reducer
      dispatch({
        type: DELETE_JOB_SUCCESS,
        payload: { screeningId: screeningK, jobId: jobK }
      });
      updateToast(notification, 'success', 'Job deleted! 🗑️');
    }
  } catch (error) {
    if (error.message === 401) {
      window.location.assign('/');
    }
    dispatch({
      type: DELETE_JOB_ERROR,
      errorMessage: `Delete job failed!\n${error.message}`
    });
    updateToast(notification, 'error', 'Failed to delete job! ❌');
  }
};

export const getAllMolecules = () => async (dispatch) => {
  dispatch({
    type: GET_MOLECULES_START
  });
  try {
    const filteredMolecules = [];

    const queryToXML = {
      name: `get_molecules`,
      command: 'select'
    };
    const sOrganizationMoleculeBankXML = generateXml({
      queries: [{ query: [queryToXML] }]
    });

    const { data } = await postQueryDbHeader(sOrganizationMoleculeBankXML);
    // Get array of molecules-aliases (foreach molecule alias = 1 row)
    const molecules = parseData(fetchResult(data, 'get_molecules'));
    // molecule = [key, commonname, IUPAC, alias, density, orgKey, description, charge]
    molecules.forEach((molecule) => {
      const newM = [...molecule];
      newM[3] = molecule[3] ? [molecule[3]] : molecule[3];
      // if filtermolcules contains a molecule with same moleculeK & moleculeName push newM-Alias to that molecule
      if (filteredMolecules.find((m) => m[0] === newM[0])) {
        const index = filteredMolecules.findIndex((m) => m[0] === newM[0]);
        return filteredMolecules[index][3].push(newM[3][0]);
      }
      return filteredMolecules.push(newM);
    });
    if (filteredMolecules?.length > 0) {
      dispatch({
        type: GET_MOLECULES_SUCCESS,
        payload: { l: true, aMolecules: filteredMolecules }
      });
    }
  } catch (error) {
    dispatch({
      type: GET_MOLECULES_ERROR,
      errorMessage: `Get all molecules failed!\n${error.message}`
    });
  }
};

export const getAllJobs = () => async (dispatch) => {
  try {
    await dispatch(getAllMolecules());
    dispatch({ type: GET_JOB_START });

    let queryToXML = {
      name: `get_screening`,
      command: 'select',
      rows: [{ type: 'W', name: 'DeletedD', table: 'TScreening', value: null }]
    };
    const screeningXml = generateXml({ queries: [{ query: [queryToXML] }] });

    // get all the screenings from the organization
    const screeningData = await postQueryDb(screeningXml);
    const screenings = parseData(fetchResult(screeningData.data));
    let aScreening = [];

    if (!screenings || screenings.length === 0) {
      dispatch({
        type: GET_JOB_SUCCESS,
        payload: { allJobs: aScreening, l: false }
      });
      return;
    }

    aScreening = [...screenings];

    // for each screening get its jobs
    screenings.map(async (screening, i) => {
      queryToXML = {
        name: `get_job`,
        command: 'select',
        rows: [
          {
            type: 'W',
            name: 'ScreeningK',
            table: 'TJob',
            value: screening[0]
          },
          { type: 'W', name: 'DeletedD', table: 'TJob', value: null }
        ]
      };
      const jobXml = generateXml({ queries: [{ query: [queryToXML] }] });

      const { data } = await postQueryDb(jobXml);
      const jobs = parseData(fetchResult(data, 'get_job'));

      aScreening[i].push(jobs);

      jobs.map(async (job) => {
        queryToXML = {
          name: `get_comp`,
          command: 'select',
          rows: [
            {
              type: 'W',
              name: 'JobK',
              table: 'TJobComposition',
              value: job[0]
            },
            {
              type: 'W',
              name: 'DeletedD',
              table: 'TJobComposition',
              value: null
            }
          ]
        };
        const compXml = generateXml({ queries: [{ query: [queryToXML] }] });

        const compData = await postQueryDb(compXml);
        const comp = parseData(fetchResult(compData.data));
        const filteredComps = [...comp.filter((com) => com[4] === null)];

        if (filteredComps?.length > 0)
          filteredComps.map((composition) =>
            dispatch({
              type: 'insertComp',
              payload: { newComp: composition, jobId: filteredComps[0][1] }
            })
          );

        // queryToXML = {
        //   name: `job_log`,
        //   command: 'select',
        //   rows: [
        //     { type: 'W', name: 'TJobLog.JobK', table: 'TJobLog', value: job[0] }
        //   ]
        // };
        // const logXml = generateXml({ queries: [{ query: [queryToXML] }] });

        // const logData = await postQueryDb(logXml);
        // const jsonparse = fetchResult(logData.data);
        // const logs = parseData(jsonparse);

        // if (logs?.length > 0) {
        //   logs.forEach(async (alog) => {
        //     if (alog?.length > 0) {
        //       dispatch(getMessageForLog(alog[0]));
        //     }

        //     dispatch({
        //       type: 'insertLog',
        //       payload: { log: alog }
        //     });
        //   });
        // }
      });
    });
    dispatch({
      type: GET_JOB_SUCCESS,
      payload: { allJobs: aScreening, l: false }
    });
  } catch (error) {
    if (error.message === 401) {
      window.location.assign('/');
    }
    dispatch({
      type: GET_JOB_ERROR,
      errorMessage: `Get all jobs/screenings failed!\n${error.message}`
    });
  }
};

export const logIn = () => async (dispatch) => {
  dispatch({ type: LOG_IN_START });
  try {
    const { data } = await useSession();

    let xml;
    if (typeof data === 'string') {
      xml = new window.DOMParser().parseFromString(data, 'text/xml');
    }
    xml = xml.documentElement;
    const sBody = xml?.firstElementChild?.firstChild?.nodeValue;
    if (!sBody)
      return dispatch({
        type: LOG_IN_ERROR,
        payload: {
          user: 'Error',
          loggedIn: false,
          errorMessage: 'Failed to log in! body is empty'
        }
      });
    const aParseData = parseData(JSON.parse(sBody));
    const aHeaders = aParseData[0]; // Headers from response
    const aUserData = aParseData[1]; // Data from response
    const sessionIndex = aHeaders.indexOf('SessionId');
    const userKIndex = aHeaders.indexOf('UserK');

    localStorage.setItem('CompularUserToken', aUserData[sessionIndex]);
    localStorage.setItem('USERID', aUserData[userKIndex]);

    axios.defaults.params = {};
    axios.defaults.params.session = aUserData[sessionIndex];

    // remove sessionId from user array
    const aUser = aUserData.filter((res, i) => i !== sessionIndex);
    if (aUser?.length > 0) {
      dispatch({
        type: LOG_IN_SUCCESS,
        payload: {
          user: aUser,
          loggedIn: true
        }
      });
      dispatch(getAllJobs());
    } else {
      dispatch({
        type: LOG_IN_ERROR,
        payload: {
          user: 'Error',
          loggedIn: false,
          errorMessage: 'Failed to log in! aUser is empty'
        }
      });
    }
  } catch (error) {
    if (error.message === 401) {
      window.location.assign('/');
    }
    dispatch({
      type: LOG_IN_ERROR,
      errorMessage: `Failed to log in!\n${error.message}`
    });
  }
};

// This function should clear token. Remove user from redux state.
export const logOut = () => (dispatch) => {
  localStorage.clear();
  axios.defaults.params = {};
  dispatch({ type: SIGN_OUT });
};

const getXyzFilenameDynamic = (basefilename, sys) =>
  `${basefilename}id_${sys[0]}`;

const getXyzFilenameMolecular = (basefilename, leadingZeros, sys, i) =>
  `${basefilename}${i.toString().padStart(leadingZeros, '0')}_${sys[1]}_id_${
    sys[0]
  }`;

function processResult(results, isDynamic) {
  const removeData = results[0].indexOf('Id');
  const SMILES = results[0].indexOf('svg');
  const basefilename = isDynamic
    ? 'geometries_dynamic_species/'
    : 'geometries_molecular_species/geometry_';
  const leadingZeros = Math.ceil(Math.log10(results.length));

  // sort on concentration first..
  const sortedres = customSort([...results], 3, true);

  const returnResults = [...sortedres].map((sys, i) => {
    if (i === 0) {
      const newArr = [...sys]
        .map((s, ind) => {
          if (ind === SMILES) return 'Structural formula';
          return s;
        })
        .filter((s, ind) => (removeData === -1 ? s : ind !== removeData));

      newArr.push('xyz-file');
      return newArr;
    }
    const xyzfile = isDynamic
      ? getXyzFilenameDynamic(basefilename, sys)
      : getXyzFilenameMolecular(basefilename, leadingZeros, sys, i);

    const newArr = [...sys].filter((s, ind) => {
      if (removeData > -1) return ind !== removeData;
      return ind !== -1;
    });

    newArr.push(xyzfile);
    return newArr;
  });

  return returnResults;
}

async function fetchAndParseResult(type, orgId, jobId) {
  const { data } = await useGetResult(type, 'csv', orgId, jobId);
  let xml;

  if (typeof data === 'string') {
    xml = new window.DOMParser().parseFromString(data, 'text/xml');
  }

  xml = xml.documentElement;
  const sBodyJobs = xml.firstElementChild.firstChild.nodeValue;

  const parsedBody = sBodyJobs
    .replaceAll(',,', ',null,')
    .replaceAll(',,', ',null,')
    .replaceAll(',]', ',null]');
  const results = parseData(JSON.parse(parsedBody));
  if (type === 'main_results') return results;

  const processedResult = processResult(results, !!(type === 'dynamic'));

  return processedResult;
}

/*
 * This func get all jobs in db.
 * TODO: Send in userKey/orgKey. So redux state only gets jobs/logs/comps from org.
 */
export const getJobResults = (orgId, jobId) => async (dispatch) => {
  dispatch({ type: GET_JOB_RESULT_START });
  try {
    const mainRes = await fetchAndParseResult('main_results', orgId, jobId);
    const jobDynamicSystem = await fetchAndParseResult('dynamic', orgId, jobId);
    const jobMolSystem = await fetchAndParseResult('molecular', orgId, jobId);

    const result = {
      mainResult: mainRes,
      molecularSystem: jobMolSystem,
      dynamicSystem: jobDynamicSystem
    };

    dispatch({
      type: GET_JOB_RESULT_SUCCESS,
      payload: { results: result, id: jobId, loading: false }
    });
  } catch (error) {
    dispatch({
      type: GET_JOB_RESULT_ERROR,
      errorMessage: `Fetch results failed!\n ${error.message}`
    });
    if (error.message === 401) {
      window.location.assign('/');
    }
  }
};

/*
 * This func creates/saves job to db.
 * If job exists already in db it updates. else create.
 * If creating job logs gets inserted in db.
 * Job === object. data from the job.
 * type === string. explains if job is saved or created.
 * if saved fields might be empty in job.
 * computeStoch === function to call compute stoichiometry
 */
export const createJob =
  (job, type, action, updateCompId, computeStoch) => async (dispatch) => {
    let notification;
    if (type === 'submit')
      notification = toast.loading(`
      ${type === 'submit' ? 'Submitting job..' : ''}`);
    if (type === 'save' && !computeStoch)
      notification = toast.loading('Saving job..');
    let jobId;
    let reducerStart;
    let reducerError;
    let reducerSuccess;
    if (type === 'submit') {
      reducerStart = CREATE_JOB_START;
      reducerError = CREATE_JOB_ERROR;
      reducerSuccess = CREATE_JOB_SUCCESS;
    } else {
      reducerStart = SAVE_JOB_START;
      reducerError = SAVE_JOB_ERROR;
      reducerSuccess = SAVE_JOB_SUCCESS;
    }
    dispatch({
      type: reducerStart // start
    });
    try {
      const { screening } = job;

      const objToXMLScreening = {
        table: `TScreening`,
        command: typeof screening.id === 'string' ? 'insert' : 'update',
        rows: [
          { type: 'V', name: 'OrganizationK', value: job.organizationId },
          { type: 'V', name: 'FName', value: screening.name },
          {
            type: 'V',
            name: 'TypeC',
            value: Array.isArray(job.temperature.value) ? 12 : 13
          },
          {
            type: 'V',
            name: 'FStatus',
            value: type === 'submit' ? 'In Progress' : 'Not Started'
          },
          typeof screening.id !== 'string' && {
            type: 'W',
            name: 'ScreeningK',
            value: screening.id
          }
        ]
      };
      const xmlScreening = generateXml({ values: [objToXMLScreening] });

      const dataScreening = await postQueryDbKey(xmlScreening);
      const valueScreening = fetchResult(dataScreening.data)[0];
      const screeningId =
        typeof screening.id === 'string' ? valueScreening[1] : screening.id;
      const str = job.freeText.replaceAll(/\r?\n|\r/g, '\r\n');
      if (Array.isArray(job.id)) {
        jobId = job.id;
      } else if (!Array.isArray(job.id) && job.id > 0) {
        jobId = [job.id];
      }

      let objToXML = { values: [] };

      // jobs within a screening
      if (Array.isArray(job.temperature.value)) {
        job.temperature.value.forEach((tempValue, i) => {
          const valuesToXML = {
            table: `TJob`,
            command: jobId?.[i] ? 'update' : 'insert',
            rows: [
              !!jobId?.[i] && { type: 'W', name: 'JobK', value: jobId?.[0] },
              { type: 'V', name: 'ScreeningK', value: screeningId },
              {
                type: 'V',
                name: 'FJobName',
                value: `${job.name}, T=${tempValue}`
              },
              { type: 'V', name: 'FFreeText', value: str },
              { type: 'V', name: 'OrganizationK', value: job.organizationId },
              { type: 'V', name: 'FEnsemble', value: job.ensemble },
              {
                type: 'V',
                name: 'FStatus',
                value: type === 'submit' ? 'In Progress' : 'Not Started'
              },
              { type: 'V', name: 'FTemperature', value: tempValue },
              {
                type: 'V',
                name: 'FTemperatureUnit',
                value: job.temperature.unit
              },
              job.ensemble === 'NPT' && {
                type: 'V',
                name: 'FPressure',
                value: job.pressure.value
              },
              job.ensemble === 'NPT' && {
                type: 'V',
                name: 'FPressureUnit',
                value: job.pressure.unit
              }
            ]
          };
          objToXML.values.push(valuesToXML);
        });
      }
      // single job
      else {
        const valuesToXML = {
          table: `TJob`,
          command: job.id > 0 ? 'update' : 'insert',
          rows: [
            job.id > 0 && { type: 'W', name: 'JobK', value: job.id },
            { type: 'V', name: 'ScreeningK', value: screeningId },
            { type: 'V', name: 'FJobName', value: job.name },
            { type: 'V', name: 'FFreeText', value: str },
            { type: 'V', name: 'OrganizationK', value: job.organizationId },
            { type: 'V', name: 'FEnsemble', value: job.ensemble },
            {
              type: 'V',
              name: 'FStatus',
              value: type === 'submit' ? 'In Progress' : 'Not Started'
            },
            { type: 'V', name: 'FTemperature', value: job.temperature.value },
            {
              type: 'V',
              name: 'FTemperatureUnit',
              value: job.temperature.unit
            },
            job.ensemble === 'NPT' && {
              type: 'V',
              name: 'FPressure',
              value: job.pressure.value
            },
            job.ensemble === 'NPT' && {
              type: 'V',
              name: 'FPressureUnit',
              value: job.pressure.unit
            }
          ]
        };
        objToXML.values.push(valuesToXML);
      }

      const xmlJob = generateXml(objToXML);

      const { data } = await postQueryDbKey(xmlJob);
      let xml;
      if (typeof data === 'string') {
        xml = new window.DOMParser().parseFromString(data, 'text/xml');
      }
      xml = xml.documentElement;
      const htmlCollection = xml.getElementsByTagName('response');
      // Using map-loop (convert HTMLCollection to Array first) and get id
      const myArray = Array.from(htmlCollection);
      // loop htmlcollection
      const aJobIds = myArray.map(({ firstChild }) => {
        const { nodeValue } = firstChild;
        // id for each job
        return JSON.parse(nodeValue)[0][1];
      });
      jobId = aJobIds;
      action(jobId, screeningId);
      // loop compositions here
      // loop and insert solutes
      jobId.forEach(async (JOBKEY) => {
        const queryToXML = {
          name: `get_comp`,
          command: 'select',
          rows: [
            {
              type: 'W',
              name: 'JobK',
              table: 'TJobComposition',
              value: JOBKEY
            },
            {
              type: 'W',
              name: 'DeletedD',
              table: 'TJobComposition',
              value: null
            }
          ]
        };
        const xmlstr = generateXml({ queries: [{ query: [queryToXML] }] });
        const jobComps = await postQueryDb(xmlstr);
        const comps = parseData(fetchResult(jobComps.data));
        if (comps?.length > 0) {
          const removeCompKeys = comps.map((comp) => comp[0]);
          await removeCompKeys.forEach(async (compKey) => {
            objToXML = {
              table: `TJobComposition`,
              command: 'update',
              rows: [
                { type: 'W', name: 'JobCompositionK', value: compKey },
                { type: 'V', name: 'DeletedD', value: formatD(new Date()) }
              ]
            };
            const xmlDeleteCompositionString = generateXml({
              values: [objToXML]
            });
            await postQueryDbKey(xmlDeleteCompositionString);
            dispatch(removeCompById(compKey, job.id));
          });
        }
        // loop solutes and insert to db
        await job.composition.solute.forEach(async (solute) => {
          objToXML = {
            table: `TJobComposition`,
            command: 'insert',
            rows: [
              { type: 'V', name: 'JobK', value: JOBKEY },
              { type: 'V', name: 'FName', value: solute.molecule.name },
              { type: 'V', name: 'MoleculeK', value: solute.molecule.id },
              { type: 'V', name: 'FAmount', value: solute.value },
              { type: 'V', name: 'FUnit', value: solute.unit },
              { type: 'V', name: 'FType', value: 'Solute' }
            ]
          };
          const xmlUpdateSoluteComposition = generateXml({
            values: [objToXML]
          });

          // post to db
          const dataComp = await postQueryDbKey(xmlUpdateSoluteComposition);
          const compValue = fetchResult(dataComp.data)[0];

          // get id of new composition
          const compId = compValue[1];
          if (type === 'create') updateCompId(solute.id, compId, 'solute');

          dispatch(insertCompById(compId));
        }); // END OF SOLUTE-LOOP
        const solventUnit = job.composition.solvent.unit;
        // loop solvents. if parent loop children
        await job.composition.solvent.value.forEach(async (solvent) => {
          objToXML = {
            table: `TJobComposition`,
            command: 'insert',
            rows: [
              { type: 'V', name: 'JobK', value: JOBKEY },
              {
                type: 'V',
                name: 'FName',
                value: solvent.isParent ? solvent.name : solvent.molecule.name
              },
              { type: 'V', name: 'MoleculeK', value: solvent.molecule?.id },
              { type: 'V', name: 'FSelectedDensity', value: solvent.density },
              { type: 'V', name: 'FAmount', value: solvent.value },
              { type: 'V', name: 'FUnit', value: solventUnit },
              { type: 'V', name: 'FType', value: 'Solvent' }
            ]
          };
          const xmlUpdateSolventComposition = generateXml({
            values: [objToXML]
          });

          // this will always happened. new job = new compositions
          const dataComp = await postQueryDbKey(xmlUpdateSolventComposition);
          const compValue = fetchResult(dataComp.data)[0];

          // Get compId
          const compId = compValue[1];
          if (type === 'create') updateCompId(solvent.id, compId, 'solvent');

          dispatch(insertCompById(compId));

          // if solvent is parent loop children
          if (solvent.isParent && solvent.children.value.length > 1) {
            const childrenSolventUnit = solvent.children.unit;
            await solvent.children.value.forEach(async (child) => {
              objToXML = {
                table: `TJobComposition`,
                command: 'insert',
                rows: [
                  { type: 'V', name: 'JobK', value: JOBKEY },
                  { type: 'V', name: 'FName', value: child.molecule.name },
                  { type: 'V', name: 'FAmount', value: child.value },
                  { type: 'V', name: 'FUnit', value: childrenSolventUnit },
                  { type: 'V', name: 'FType', value: 'Solvent' },
                  { type: 'V', name: 'ParentKey', value: compId },
                  { type: 'V', name: 'MoleculeK', value: child.molecule.id },
                  { type: 'V', name: 'FSelectedDensity', value: child.density }
                ]
              };
              const xmlUpdateChildComposition = generateXml({
                values: [objToXML]
              });
              const childData = await postQueryDbKey(xmlUpdateChildComposition);
              const parsedChild = fetchResult(childData.data);

              // get ChildCompId
              const childId = parseData(parsedChild)[0][1];
              if (type === 'create')
                updateCompId(child.id, childId, 'solventChild', compId);
              dispatch(insertCompById(childId));
            });
          }
        });
      });

      if (type === 'submit') {
        jobId.forEach(async (JOBKEY) => {
          objToXML = {
            table: `TJobLog`,
            command: 'insert',
            rows: [
              { type: 'V', name: 'JobK', value: JOBKEY },
              { type: 'V', name: 'CodeC', value: 1 }
            ]
          };
          const xmlInsertLog = generateXml({ values: [objToXML] });

          const logData = await postQueryDbKey(xmlInsertLog);
          const __logId = fetchResult(logData.data);
          const logId = parseData(__logId)[0][1];
          const queryToXML = {
            name: `job_log`,
            command: 'select',
            rows: [
              { type: 'W', name: 'JobLogK', table: 'TJobLog', value: logId }
            ]
          };
          const xmlForLog = generateXml({ queries: [{ query: [queryToXML] }] });

          const newLogData = await postQueryDb(xmlForLog);
          const __log = fetchResult(newLogData.data);
          const parsedLog = parseData(__log)[0];
          dispatch({ type: 'insertLog', payload: { log: parsedLog } });
        }); // END OF LOOP

        const screeningData = await getScreeningByOrgId(screeningId);
        const screenings = parseData(fetchResult(screeningData.data));
        const aScreening = screenings[0];

        if (screenings?.length > 0) {
          aScreening.push([]);
          const queryToXML = {
            name: `get_job`,
            command: 'select',
            rows: [
              {
                type: 'W',
                name: 'ScreeningK',
                table: 'TJob',
                value: screeningId
              }
            ]
          };
          const xmlForJob = generateXml({ queries: [{ query: [queryToXML] }] });
          const insertData = await postQueryDb(xmlForJob);

          const __job = fetchResult(insertData.data);
          const parsedJob = parseData(__job);

          for (let index = 0; index < parsedJob.length; index++) {
            aScreening.findLast((ele) => ele).push(parsedJob[index]);
          }

          const xmlGenerateConfig = `<?xml version="1.0"?><document>
            ${jobId
              .map((JOBKEY) => {
                return `<values table="TJob">
          <value name="JobK" type="int64">${JOBKEY}</value>
        </values>`;
              })
              .join(' ')}</document>`;

          await useGetGenerateJobFiles(job.organizationId, xmlGenerateConfig);
          dispatch({
            type: reducerSuccess,
            payload: {
              loading: false,
              error: false,
              job: aScreening
            }
          });
          updateToast(notification, 'success', 'Job submitted! ✅');
          // // SEND MAIL ******************************************** NOT FOR DEMO/TEST
          // await useSendEmailJobStart(jobId);
          // dispatch({
          //   type: reducerSuccess,
          //   payload: {
          //     loading: false,
          //     error: false,
          //     job: parsedJob
          //   }
          // });
        }
      } else {
        // If not submit
        const screeningData = await getScreeningByOrgId(screeningId);
        const screenings = parseData(fetchResult(screeningData.data));
        const aScreening = screenings[0];

        if (screenings?.length > 0) {
          aScreening.push([]);
          const queryToXML = {
            name: `get_job`,
            command: 'select',
            rows: [
              {
                type: 'W',
                name: 'ScreeningK',
                table: 'TJob',
                value: screeningId
              }
            ]
          };
          if (computeStoch) computeStoch(jobId[0]);
          else updateToast(notification, 'success', 'Job saved! ✅');
          const xmlForJob = generateXml({ queries: [{ query: [queryToXML] }] });
          const insertData = await postQueryDb(xmlForJob);
          const __job = fetchResult(insertData.data);
          const parsedJob = parseData(__job);

          for (let index = 0; index < parsedJob.length; index++) {
            aScreening
              .findLast((ele) => ele)
              .push(parsedJob.findLast((ele) => ele));
          }
          dispatch({
            type: reducerSuccess,
            payload: {
              loading: false,
              error: false,
              job: aScreening
            }
          });
        }
      }
    } catch (error) {
      updateToast(
        notification,
        'error',
        type === 'submit' ? 'Failed to submit job! ❌' : ''
      );
      if (error.message === 401) {
        window.location.assign('/');
      }
      dispatch({
        type: reducerError,
        errorMessage: `Failed to save/create/submit job!\n ${error.message}`
      });
    }
  };

/* function updateUserName, PARAMS(userK == userK in db, name == new name for the user, 
  isFirstName == true if we update the first name, false if we update the last name)
 */
export const updateUserName = (user, name, isFirstName) => async (dispatch) => {
  const notification = toast.loading('loading...');
  dispatch({ type: UPDATE_NAME_START });

  // remove all comps with jobK. SoftDelete
  try {
    const objToXML = {
      table: `TUser`,
      command: 'update',
      rows: [
        { type: 'W', name: 'UserK', value: user[0] },
        {
          type: 'V',
          name: isFirstName ? 'FFirstName' : 'FLastName',
          value: name
        }
      ]
    };
    const updateNameXml = generateXml({ values: [objToXML] });
    await postQueryDb(updateNameXml);

    // find index to update user at (3 if first name, 4 otherwise)
    const updateUserIndex = isFirstName ? 3 : 4;

    const newUser = [...user].toSpliced(updateUserIndex, 1, name);
    updateToast(notification, 'success', `Hi ${name}! ✅`);
    dispatch({
      type: UPDATE_NAME_SUCCESS,
      payload: {
        loading: false,
        error: false,
        user: newUser
      }
    });
  } catch (error) {
    updateToast(notification, 'error', 'Failed to update name! ❌');
    if (error.message === 401) {
      window.location.assign('/');
    }
    dispatch({
      type: UPDATE_NAME_ERROR,
      errorMessage: `Failed to update ${
        isFirstName ? 'first name' : 'last name'
      }!\n${error.message}`
    });
  }
};
