/* eslint-disable react/jsx-boolean-value */
// @copyright (c) 2023-2024 Compular AB
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Fuse from 'fuse.js';
import Tippy from '@tippyjs/react';
import CloseIcon from '../../icons/CloseIcon';
import SearchIcon from '../../icons/SearchIcon';
import DefaultButton from '../DefaultButtons/DefaultButton';

export default function SearchDropdownMenu({
  isError,
  moleculeBank,
  selectedMolecule,
  setJobSolute,
  label,
  openModal,
  openPreviewModal
}) {
  const [input, setInput] = useState('');
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const filteredMolecules = useMemo(() => {
    if (moleculeBank && moleculeBank.length > 0) {
      if (input.length > 0) {
        const fuse = new Fuse(moleculeBank, {
          keys: [
            {
              name: 'alias',
              weight: 2
            },
            {
              name: 'CommonName',
              weight: 2
            },
            {
              name: 'IUPAC',
              weight: 2
            },
            {
              name: 'abb',
              weight: 2
            }
          ],
          includeScore: true,
          threshold: 0.001
        });
        const results = fuse.search(input);
        return results.toSorted((a, b) => {
          return a.score - b.score;
        });
      }
      return moleculeBank.map((val) => {
        return { item: val };
      });
    }

    return moleculeBank.map((val) => {
      return { item: val };
    });

    // return null;
  }, [input, moleculeBank, isDropdownActive, openModal]);

  function renderMolecules(mc) {
    if (isDropdownActive)
      return (
        <div className=" absolute  divide-NGrey-6 border border-NGrey-6 rounded max-h-52 overflow-y-auto overflow-x-clip mt-1.5 z-10 bg-NGrey-1 w-full scrollbar-hide">
          {mc.map(({ item }, ind) => {
            return (
              <Tippy
                key={item.id}
                theme="primaryBlue"
                arrow={true}
                interactive={true}
                content={
                  <div style={{ maxWidth: '300px' }}>
                    <strong>Common name: </strong> {item.CommonName} <br />
                    <strong>Abbreviation: </strong> {item.abb} <br />
                    <strong>IUPAC name: </strong> {item.IUPAC} <br />
                    {item.alias && item.alias.length > 0 && (
                      <div
                        style={{
                          maxWidth: '300px',
                          display: 'flex',
                          gap: '4px',
                          flexWrap: 'wrap'
                        }}>
                        <strong>Aliases: </strong>
                        <p>
                          {item.alias.map((alias, i) => {
                            if (i + 1 === item.alias.length) return ` ${alias}`;
                            return ` ${alias},`;
                          })}
                        </p>
                      </div>
                    )}
                  </div>
                }>
                <div
                  className={`p-2 cursor-pointer flex hover:bg-NGrey-2 w-full gap-1 items-center justify-between  flex-nowrap ${
                    ind + 1 === mc.length ? '' : 'border-b border-NGrey-6'
                  }`}>
                  <div
                    className=" flex flex-nowrap overflow-x-scroll whitespace-nowrap scrollbar-hide"
                    onMouseDown={() => {
                      setInput('');
                      setJobSolute(null);
                      setJobSolute(item);
                      setIsDropdownActive(false);
                    }}>
                    <p>{item.CommonName}</p>
                    <p className=" text-secondary-10 ml-1">{item.abb}</p>
                    {item.IUPAC && (
                      <p className="text-NGrey-6 ml-1">{item.IUPAC}</p>
                    )}
                  </div>

                  {item.orgK && (
                    <div className="self-end px-2">
                      <div
                        onMouseDown={() => {
                          setIsDropdownActive(false);
                          openModal(item.id);
                          setJobSolute(null);
                        }}>
                        <DefaultButton
                          label=""
                          type="text-primary"
                          onPressed={() => {}}
                          icon="edit"
                          iconPosition="left"
                        />
                      </div>
                    </div>
                  )}
                  {!item.orgK && (
                    <div className=" px-2">
                      <div
                        onMouseDown={() => {
                          openPreviewModal(item.id);
                          setJobSolute(null);
                          setIsDropdownActive(false);
                        }}>
                        <DefaultButton
                          label=""
                          type="text-primary"
                          onPressed={() => {}}
                          icon="preview"
                          iconPosition="left"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </Tippy>
            );
          })}
          <div
            onMouseDown={() => openModal()}
            key="createnew"
            className="px-2 py-3 cursor-pointer sticky bottom-0 bg-NGrey-1 hover:bg-NGrey-2 border-t border-NGrey-3">
            {label === 'Solute name' ? (
              <p className="text-secondary-10 font-bold">Add new solute</p>
            ) : (
              <p className="text-secondary-10 font-bold">Add new solvent</p>
            )}
          </div>
        </div>
      );
  }
  return (
    <>
      <p>{label}</p>
      <div
        className={`mt-2 h-11 w-full rounded border ${
          isError ? 'border-Error-6' : 'border-NGrey-6'
        } flex px-2 gap-1 items-center bg-white`}>
        <SearchIcon
          className="w-4 h-4 text-NGrey-6 hover:text-NGrey-9 cursor-pointer mb-0.5"
          onClick={() => {
            document.getElementById(selectedMolecule.id).focus();
          }}
        />

        <input
          id={selectedMolecule.id}
          className=" focus:outline-none focus:ring-0 focus:border-primary-10 border-none flex-1"
          onFocus={() => setIsDropdownActive(true)}
          onBlur={() => setIsDropdownActive(false)}
          placeholder={
            selectedMolecule?.molecule?.CommonName || 'Search molecules'
          }
          type="search"
          value={input}
          onChange={(e) => {
            setInput(e.target.value);
          }}
        />
        {input.length > 0 && (
          <div className="pr-2">
            <CloseIcon
              className="w-3 h-3 text-NGrey-6 hover:text-NGrey-9 cursor-pointer"
              onClick={() => {
                setInput('');
              }}
            />
          </div>
        )}
        {selectedMolecule?.molecule && input.length === 0 && (
          <div className="pr-2">
            <CloseIcon
              className="w-3 h-3 text-NGrey-6 hover:text-NGrey-9 cursor-pointer"
              onClick={() => {
                setJobSolute(null);
              }}
            />
          </div>
        )}
      </div>
      <div className="relative mt-2">
        {filteredMolecules && renderMolecules(filteredMolecules)}
      </div>
    </>
  );
}
SearchDropdownMenu.propTypes = {
  moleculeBank: PropTypes.any.isRequired,
  isError: PropTypes.bool.isRequired,
  setJobSolute: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
  selectedMolecule: PropTypes.any,
  openModal: PropTypes.func,
  openPreviewModal: PropTypes.func
};
SearchDropdownMenu.defaultProps = {
  openModal: null,
  openPreviewModal: null,
  selectedMolecule: null
};
