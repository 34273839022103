// @copyright (c) 2022-2024 Compular AB
import * as React from 'react';

function BarChartIcon(props) {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M13.875 12.375H3.125v-9.75A.125.125 0 0 0 3 2.5h-.875A.125.125 0 0 0 2 2.625v10.75c0 .069.056.125.125.125h11.75a.125.125 0 0 0 .125-.125V12.5a.125.125 0 0 0-.125-.125ZM4.5 11.125h.875A.125.125 0 0 0 5.5 11V8.75a.125.125 0 0 0-.125-.125H4.5a.125.125 0 0 0-.125.125V11c0 .069.056.125.125.125Zm2.375 0h.875A.125.125 0 0 0 7.875 11V6a.125.125 0 0 0-.125-.125h-.875A.125.125 0 0 0 6.75 6v5c0 .069.056.125.125.125Zm2.375 0h.875A.125.125 0 0 0 10.25 11V7.219a.125.125 0 0 0-.125-.125H9.25a.125.125 0 0 0-.125.125V11c0 .069.056.125.125.125Zm2.375 0h.875a.125.125 0 0 0 .125-.125V4.75a.125.125 0 0 0-.125-.125h-.875a.125.125 0 0 0-.125.125V11c0 .069.056.125.125.125Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default BarChartIcon;
