// @copyright (c) 2023-2024 Compular AB
/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from 'react';
import { AccountContext } from '../Accounts';
import DefaultButton from '../components/DefaultButtons/DefaultButton';
import NonVisibleIcon from '../icons/NonVisibleIcon';
import VisibleIcon from '../icons/VisibleIcon';

export default function ForceChangePassword({
  newPassword,
  confirmNewPassword,
  setConfirmNewPassword,
  setNewPassword,
  password,
  setIsHelpModalOpen,
  setForceChangePassword,
  email,
  setSetUpMFA
}) {
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [isNewPasswordActive, setIsNewPasswordActive] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [isConfirmPasswordActive, setIsConfirmPasswordActive] = useState(false);
  const [error, setError] = useState({ bok: false, message: '' });
  const { forceChangePassword } = useContext(AccountContext);

  useEffect(() => {
    return () => {
      setError({}); // This worked for me
    };
  }, []);
  const changePassword = (e) => {
    e.preventDefault();

    if (confirmNewPassword === newPassword)
      return forceChangePassword(email, password, newPassword)
        .then((data) => {
          if (data.loggedIn) {
            setSetUpMFA(true);
            setForceChangePassword(false);
          }
        })
        .catch((err) => {
          setError({ bok: true, message: err });
        });
  };

  return (
    <div>
      <form onSubmit={changePassword} className="w-full flex flex-col">
        <div className="flex flex-1 flex-col justify-between  w-full p-0">
          <div className="flex gap-2 pb-2 items-center">
            <p className="font-sans font-normal text-base text-NGrey-9">
              New password
            </p>
          </div>
          <div
            className={`border ${
              isNewPasswordActive ? 'border-primary-9' : 'border-NGrey-6'
            } rounded flex items-center h-11 bg-white pr-2 w-full`}>
            <div className="w-full flex items-center my-[1px]">
              <input
                className="mr-2 caret-secondary-10  my-auto border-0 text-NGrey-9 text-normal border-transparent focus:border-transparent focus:ring-0 h-full w-full focus:outline-none"
                onFocus={() => setIsNewPasswordActive(true)}
                onBlur={() => {
                  setIsNewPasswordActive(false);
                }}
                autoComplete="off"
                type={newPasswordVisible ? 'text' : 'password'}
                value={newPassword}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                }}
              />
            </div>
            <div className="w-full h-full flex items-center flex-1">
              {newPasswordVisible ? (
                <VisibleIcon
                  className="w-5 h-5 cursor-pointer text-primary-9 hover:text-primary-7"
                  onMouseDown={() => setNewPasswordVisible(false)}
                />
              ) : (
                <NonVisibleIcon
                  className="w-5 h-5 cursor-pointer text-primary-9 hover:text-primary-7"
                  onMouseDown={() => setNewPasswordVisible(true)}
                />
              )}
            </div>
          </div>
        </div>

        <div className="flex flex-1 flex-col justify-between  w-full p-0 mt-6">
          <div className="flex gap-2 pb-2 items-center">
            <p className="font-sans font-normal text-base text-NGrey-9">
              Confirm new password
            </p>
          </div>
          <div
            className={`border ${
              isConfirmPasswordActive ? 'border-primary-9' : 'border-NGrey-6'
            } rounded flex items-center h-11 bg-white pr-2 w-full`}>
            <div className="w-full flex items-center my-[1px]">
              <input
                className="mr-2 caret-secondary-10  my-auto border-0 text-NGrey-9 text-normal border-transparent focus:border-transparent focus:ring-0 h-full w-full focus:outline-none"
                onFocus={() => setIsConfirmPasswordActive(true)}
                onBlur={() => {
                  setIsConfirmPasswordActive(false);
                }}
                type={confirmPasswordVisible ? 'text' : 'password'}
                value={confirmNewPassword}
                autoComplete="off"
                onChange={(e) => {
                  setConfirmNewPassword(e.target.value);
                }}
              />
            </div>

            <div className="w-full h-full flex items-center flex-1">
              {confirmPasswordVisible ? (
                <VisibleIcon
                  className="w-5 h-5 cursor-pointer text-primary-9 hover:text-primary-7"
                  onMouseDown={() => setConfirmPasswordVisible(false)}
                />
              ) : (
                <NonVisibleIcon
                  className="w-5 h-5 cursor-pointer text-primary-9 hover:text-primary-7"
                  onMouseDown={() => setConfirmPasswordVisible(true)}
                />
              )}
            </div>
          </div>
        </div>
        <div className="h-4 mt-3">
          {error.bok && <p className="text-Error-8">{error.message}</p>}
        </div>
        <div className="flex justify-between w-full mt-6">
          <DefaultButton
            type="outlined-primary"
            label="Contact us"
            onPressed={() => {
              setIsHelpModalOpen(true);
            }}
          />
          <button
            className="active:bg-secondary-8 active:border-secondary-8 hover:bg-secondary-7 hover:border-secondary-7 items-center bg-secondary-10 text-white border border-secondary-10 rounded flex gap-2 py-2 px-4 min-w-fit"
            type="submit">
            Change
          </button>
        </div>
      </form>
    </div>
  );
}
