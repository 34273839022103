/* @copyright (c) 2021-2022 Compular AB */
import { useState, useEffect, useRef, useMemo, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Page from '../../components/Page/Page';
import JobTable from '../../components/JobTable/JobTable';
import DefaultButton from '../../components/DefaultButtons/DefaultButton';
import LoadingPage from '../LoadingPage/LoadingPage';
import JobListModal from '../../components/Modals/JobListModal';
import CreateJobModal from '../../components/Modals/CreateJobModal';
import DemoRequestModal from '../../components/Modals/DemoRequestModal';
import { openInNewTab } from '../../utils/utils';
// import HelpModal from '../../components/Modals/HelpModal';
import { AccountContext } from '../../Accounts';
import { logIn } from '../../redux/actions/actions';
import { request } from '../../redux/api/axios';
import WhiteBackgroundBox from '../../components/WhiteBackgroundBox/WhiteBackgroundBox';

function JobsPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isPageLoading, setLoading] = useState(true);
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
  const [isCompareModalOpen, setIsCompareModalOpen] = useState(false);
  const [isCreateJobModalOpen, setIsCreateJobModalOpen] = useState(false);

  const { getSession } = useContext(AccountContext);
  const { jobs, user } = useSelector((state) => state.reducer);
  const isLoadingReduxState = useSelector((state) => state.reducer.isLoading);
  const [screenings, setScreenings] = useState(null);
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const tableHeight = useMemo(() => {
    if (windowSize.current.length > 0)
      return { height: windowSize.current[1] / 1.5 };
  }, [windowSize.innerHeight]);

  useEffect(() => {
    if (!user) {
      setLoading(true);
      getSession()
        .then((data) => {
          const AUTH_TOKEN = data.accessToken.jwtToken;
          request.defaults.headers.common.Authorization = AUTH_TOKEN;
          dispatch(logIn());
        })
        .catch(() => {
          navigate('/');
        });
    }
  }, []);

  useEffect(() => {
    if (!isLoadingReduxState && isPageLoading) {
      if (jobs && Object.values(jobs).length > 0) {
        const sortedJobs = Object.values(jobs)
          .toSorted((a, b) => a[3].localeCompare(b[3].toLowerCase()))
          .toReversed();

        setScreenings(sortedJobs);
      } else {
        setScreenings([]);
      }
    }
  }, [jobs, isLoadingReduxState]);

  useEffect(() => {
    // check if job is array. screening.at(-1) == joblist in screening
    if (isPageLoading && screenings) setLoading(false);
  }, [screenings]);

  if (isPageLoading) return <LoadingPage />;

  if (
    !isPageLoading &&
    screenings &&
    screenings.length === 0 &&
    user &&
    user.length > 0
  ) {
    return (
      <Page help onHelp={() => setIsHelpModalOpen(true)}>
        <div className="w-full min-h-[85vh] px-12 py-8 items-center flex justify-center flex-col gap-1">
          {/* <HelpModal
            isOpen={isHelpModalOpen}
            close={() => setIsHelpModalOpen(false)}
          /> */}
          {/* DEMOVERSION */}
          <DemoRequestModal
            isOpen={isHelpModalOpen}
            close={() => setIsHelpModalOpen(false)}
          />
          <WhiteBackgroundBox>
            <div className="m-auto pb-12 text-center">
              <p className="text-sm text-NGrey-6">Joblist is empty...</p>
              <p className="text-sm text-NGrey-6 mb-2">
                Create your first job!
              </p>
              <DefaultButton
                type="contained-secondary"
                onPressed={() => navigate('/jobs/new/edit/new')}
                label="Create job"
                icon="plus"
                iconPosition="left"
              />
            </div>
          </WhiteBackgroundBox>
        </div>
      </Page>
    );
  }
  return (
    <Page help onHelp={() => setIsHelpModalOpen(true)}>
      <div className="w-full min-w-[925px] px-12 py-8">
        {/* <HelpModal
          isOpen={isHelpModalOpen}
          close={() => setIsHelpModalOpen(false)}
        /> */}
        <DemoRequestModal
          isOpen={isHelpModalOpen}
          close={() => setIsHelpModalOpen(false)}
        />
        {isCompareModalOpen && (
          <JobListModal
            isOpen={isCompareModalOpen}
            close={() => {
              setIsCompareModalOpen(false);
            }}
            jobs={screenings
              .map((job) => {
                return job.findLast((ele) => ele);
              })
              .flat()
              .filter((job) => job[14] === 'Finished')}
          />
        )}
        {isCreateJobModalOpen && (
          <CreateJobModal
            isOpen={isCreateJobModalOpen}
            close={() => setIsCreateJobModalOpen(false)}
            jobs={screenings}
          />
        )}
        <WhiteBackgroundBox>
          {/* <div className="flex flex-1 flex-col my-8 mx-12 pb-4 bg-white border border-NGrey-6 rounded overflow-x-auto"> */}
          <div className="pt-6 w-full flex flex-col">
            <div className="flex justify-between px-6 pb-3">
              <div className="flex gap-2 items-center">
                <p className="text-NGrey-9 text-[33px] font-semibold select-none">
                  Jobs for <span className="text-NGrey-6">{user?.[5]}</span>
                </p>
                <div>
                  <DefaultButton
                    label=""
                    type="text-primary"
                    onPressed={() => {
                      openInNewTab(
                        'https://compulartech.com/docs/create-job/#job-page'
                      );
                    }}
                    icon="help"
                    iconPosition="left"
                  />
                </div>
              </div>
              <div className="flex gap-4 ml-auto basis-1/3 justify-end">
                {screenings &&
                  screenings
                    .map((job) => {
                      return job.findLast((ele) => ele);
                    })
                    .flat()
                    .filter((job) => job[14] === 'Finished').length > 1 && (
                    <DefaultButton
                      type="outlined-primary"
                      onPressed={() => {
                        setIsCompareModalOpen(true);
                      }}
                      label="Compare"
                      icon="compare"
                      iconPosition="left"
                    />
                  )}
                <DefaultButton
                  type="contained-secondary"
                  onPressed={() => {
                    setIsCreateJobModalOpen(true);
                  }}
                  label="Create job"
                  icon="plus"
                  iconPosition="left"
                />
              </div>
            </div>
            {screenings && tableHeight && (
              <div style={tableHeight}>
                <JobTable screenings={screenings} user={user} />
              </div>
            )}
          </div>
        </WhiteBackgroundBox>
      </div>
      {/* </div> */}
    </Page>
  );
}

export default JobsPage;
