// @copyright (c) 2023-2024 Compular AB
import { postQueryDb } from '../api/execute/execute';
import { generateXml } from '../../utils/utils';

// eslint-disable-next-line import/prefer-default-export
export const getScreeningByOrgId = async (screeningId) => {
  const queryToXML = {
    name: `get_screening`,
    command: 'select',
    rows: [
      {
        type: 'W',
        name: 'ScreeningK',
        table: 'TScreening',
        value: screeningId
      },
      {
        type: 'W',
        name: 'DeletedD',
        table: 'TScreening',
        value: null
      }
    ]
  };
  const screeningXml = generateXml({ queries: [{ query: [queryToXML] }] });
  const screeningData = await postQueryDb(screeningXml);
  return screeningData;
};
