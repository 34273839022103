// @copyright (c) 2023-2024 Compular AB
/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */
import React, { useState, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import RingLoader from 'react-spinners/RingLoader';
import { AccountContext } from '../Accounts';
import DefaultButton from '../components/DefaultButtons/DefaultButton';
import { logIn } from '../redux/actions/actions';
import { request } from '../redux/api/axios';

export default function MFA({ email, password, setIsHelpModalOpen, isMFA }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getSession, authenticateWithMFA } = useContext(AccountContext);
  const [userCode, setUserCode] = useState('');
  const [userCodeError, setUserCodeError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [image, setImage] = useState(null);

  const API = 'https://owimmfsv2c.execute-api.eu-north-1.amazonaws.com/dev/mfa';

  useEffect(() => {
    if (!isMFA) {
      getSession().then(({ accessToken, headers }) => {
        if (typeof accessToken !== 'string') {
          accessToken = accessToken.jwtToken;
        }

        const uri = `${API}?accessToken=${accessToken}`;

        fetch(uri, {
          headers
        })
          .then((data) => data.json())
          .then((data) => {
            setImage(data);
          })
          .catch();
      });
    }
  }, []);

  useEffect(() => {
    setUserCodeError(false);
    setErrorMessage('');
  }, [userCode.length]);

  const enableMFA = (event) => {
    event.preventDefault();
    if (userCode.length !== 6) return setUserCodeError(true);
    if (isMFA) {
      return authenticateWithMFA(email, password, userCode)
        .then((data) => {
          if (data.loggedIn) {
            const AUTH_TOKEN = data.data.accessToken.jwtToken;
            request.defaults.headers.common.Authorization = AUTH_TOKEN;
            dispatch(logIn(email, password));
          }
        })
        .catch((err) => {
          setUserCodeError(true);
          setErrorMessage(err.errorMessage);
        });
    }
    getSession().then(({ user, accessToken, headers }) => {
      if (typeof accessToken !== 'string') {
        accessToken = accessToken.jwtToken;
      }

      const uri = `${API}?accessToken=${accessToken}&userCode=${userCode}`;

      fetch(uri, {
        method: 'POST',
        headers
      })
        .then((data) => data.json())
        .then((result) => {
          if (result.Status && result.Status === 'SUCCESS') {
            navigate('/jobs');
            const settings = {
              PreferredMfa: true,
              Enabled: true
            };
            if (email && password) dispatch(logIn(email, password));
            if (!isMFA) user.setUserMfaPreference(null, settings, () => {});
          } else if (result.errorType === 'EnableSoftwareTokenMFAException') {
            // This exception is thrown when there is a code mismatch and the service fails to configure the software token TOTP multi-factor authentication (MFA).
            setUserCodeError(true);
            setErrorMessage('Incorrect 6-digit code!');
          } else if (result.errorType === 'InvalidParameterException') {
            // An input parameter violated a constraint. For example, in synchronous operations, an InvalidParameterException exception occurs when neither of the S3Object or Bytes values are supplied in the Document request parameter. Validate your parameter before calling the API operation again.
            setUserCodeError(true);
            setErrorMessage('Please provide a 6-digit number');
          }
        });
    });
  };

  return (
    <div>
      <div>
        <div
          style={{
            height: 'auto',
            margin: '0 auto',
            maxWidth: 128,
            width: '100%'
          }}>
          {!isMFA &&
            (image ? (
              <img src={image} alt="---" />
            ) : (
              <div className="w-full flex items-center justify-center text-center h-full">
                <RingLoader color="#F2933D" size={75} speedMultiplier={0.9} />
              </div>
            ))}
        </div>
        <form className="mt-4 flex flex-col gap-6 w-full" onSubmit={enableMFA}>
          <div className="flex flex-1 flex-col justify-between  w-full p-0">
            <div className="flex gap-2 pb-2 items-center">
              <p className="font-sans font-normal text-base text-NGrey-9">
                MFA Code:
              </p>
            </div>
            <input
              value={userCode}
              className={`${
                userCodeError ? 'border-Error-6' : 'border-NGrey-6'
              } h-11 bg-currentColor caret-secondary-10 border-box rounded border  font-sans font-normal text-base text-NGrey-9 pl-2 focus:ring-0 ring-0 focus:outline-none focus:border-primary-10`}
              onChange={(event) => setUserCode(event.target.value)}
              autoComplete="off"
              required
            />
            {userCodeError && errorMessage.length > 0 && (
              <p className="text-Error-8 mt-1 ">{errorMessage}</p>
            )}
          </div>

          <div
            className={`${
              userCodeError && errorMessage.length > 0 ? 'mt-3' : 'mt-6'
            } flex justify-between gap-4 w-full `}>
            {password && password && (
              <DefaultButton
                type="outlined-primary"
                label="Contact us"
                onPressed={() => {
                  setIsHelpModalOpen(true);
                }}
              />
            )}
            <button
              className="active:bg-secondary-8 active:border-secondary-8 hover:bg-secondary-7 hover:border-secondary-7 items-center bg-secondary-10 text-white border border-secondary-10 rounded flex gap-2 py-2 px-4 min-w-fit"
              type="submit">
              Enter code
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
