// @copyright (c) 2023-2024 Compular AB
/* eslint-disable no-useless-escape */
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { useState } from 'react';
import CloseIcon from '../../icons/CloseIcon';
import DefaultButton from '../DefaultButtons/DefaultButton';
import UserIcon from '../../icons/UserIcon';
import EmailIcon from '../../icons/EmailIcon';
import PhoneIcon from '../../icons/PhoneIcon';
import CompanyIcon from '../../icons/CompanyIcon';
import { sendMail } from '../../redux/actions/actions';

/**
 * A modal that allows the user to contact us.
 *
 * @param isOpen boolean
 * @param close func
 * @returns
 */
const customModalStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    maxWidth: '553px',
    height: '100%',
    maxHeight: '611px',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '16px',
    borderRadius: '5px',
    zIndex: 20
  }
};
export default function DemoRequestModal({ isOpen, close }) {
  const [isNameActive, setIsNameActive] = useState(false);
  const [isEmailActive, setIsEmailActive] = useState(false);
  const [isPhoneActive, setIsPhoneActive] = useState(false);
  const [isCompanyActive, setIsCompanyActive] = useState(false);
  const [message, setMessage] = useState(
    "Hi, I'm interested in learning more about your product and would like to book a meeting. Could you give me some suggestions of dates for a virtual meeting?  Thank you in advance!"
  );
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [company, setCompany] = useState('');

  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [messageError, setMessageError] = useState(false);

  function CheckMail() {
    let isNotError = true;
    if (name.length === 0) {
      isNotError = false;
      setNameError(true);
    }
    if (email.length === 0) {
      isNotError = false;
      setEmailError(true);
    }
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!email.match(mailformat)) {
      isNotError = false;
      setEmailError(true);
    }
    if (phone.length > 0) {
      if (!phone.includes('+') || phone.length > 14) {
        isNotError = false;
        setPhoneError(true);
      }
    }
    if (message.length === 0) {
      isNotError = false;
      setMessageError(true);
    }

    if (isNotError) {
      const mailBody = encodeURIComponent(
        `Company: ${company}\n Name: ${name}\n Email: ${email}\n Message:\n${message}`
      );
      sendMail(mailBody);
      close();
    }
  }
  return (
    <Modal
      style={customModalStyle}
      isOpen={isOpen}
      ariaHideApp={false}
      onRequestClose={close}
      contentLabel="Create new project">
      <div className="w-full h-full relative">
        <div className="w-full flex flex-col gap-2 px-2">
          <div className="flex justify-end items-center">
            <CloseIcon
              className="h-5 w-5 cursor-pointer hover:text-NGrey-8"
              onClick={close}
            />
          </div>
          <div id="modal-header">
            <p className="text-primary-10 font-sans font-bold text-lg text-center">
              This is only a demo version
            </p>
            <div className="font-sans font-normal text-sm text-NGrey-8 mt-4 text-left">
              <p>
                To unlock all the functionality of our product, please book a
                meeting with our experts. See how it can improve your business
                and take advantage of our free trial period to try it out for
                yourself.
              </p>
              <p className="mt-2">
                With the trial version, you can specify a system of interest,
                and gain a selected number of analysis properties. Reach out to
                us for a full disclosure of the scope of the current offering.
              </p>
            </div>
          </div>
          <div className="mt-2 w-full flex justify-between gap-4">
            <div className="w-full">
              <p className="text-sm mb-1 text-NGrey-9">Name*</p>
              <div
                className={`border ${
                  isNameActive
                    ? 'border-primary-9'
                    : nameError
                    ? 'border-Error-6'
                    : 'border-NGrey-6'
                } rounded flex items-center h-9 pl-2 w-full`}>
                <div className="w-full h-full flex items-center flex-1">
                  <UserIcon
                    className={`w-4 h-4 ${
                      isNameActive ? 'text-primary-9' : 'text-NGrey-6'
                    } `}
                  />
                </div>

                <div className="w-full flex items-center my-[1px] pb-1">
                  <input
                    className="ml-2  text-NGrey-8 text-sm  h-full w-full my-auto focus:outline-none"
                    onFocus={() => setIsNameActive(true)}
                    onBlur={() => {
                      setIsNameActive(false);
                    }}
                    value={name}
                    onChange={(e) => {
                      if (nameError) setNameError(false);
                      setName(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="w-full">
              <p className="text-sm mb-1 text-NGrey-9">Email*</p>
              <div
                className={`border ${
                  isEmailActive
                    ? 'border-primary-9'
                    : emailError
                    ? 'border-Error-6'
                    : 'border-NGrey-6'
                }
                } rounded flex items-center h-9 pl-2 w-full`}>
                <div className="w-full h-full flex items-center flex-1">
                  <EmailIcon
                    className={`w-4 h-4 ${
                      isEmailActive ? 'text-primary-9' : 'text-NGrey-6'
                    } `}
                  />
                </div>

                <div className="w-full flex items-center my-[1px] pb-1">
                  <input
                    className="ml-2 border-0 text-NGrey-8 text-sm border-transparent focus:border-transparent focus:ring-0 h-full w-full focus:outline-none"
                    onFocus={() => setIsEmailActive(true)}
                    onBlur={() => {
                      setIsEmailActive(false);
                    }}
                    value={email}
                    onChange={(e) => {
                      if (emailError) setEmailError(false);
                      setEmail(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-1 w-full flex gap-4 justify-between ">
            <div className="w-full">
              <p className="text-sm mb-1 text-NGrey-9">
                Phone number
                <span className="ml-1 text-[10px] text-NGrey-9 font-light">
                  (Start with country code)
                </span>
              </p>
              <div
                className={`border ${
                  isPhoneActive
                    ? 'border-primary-9'
                    : phoneError
                    ? 'border-Error-6'
                    : 'border-NGrey-6'
                } rounded flex items-center h-9 pl-2 w-full`}>
                <div className="w-full h-full flex items-center flex-1">
                  <PhoneIcon
                    className={`w-4 h-4 ${
                      isPhoneActive ? 'text-primary-9' : 'text-NGrey-6'
                    } `}
                  />
                </div>
                <div className="w-full flex items-center my-[1px] pb-1">
                  <input
                    className="ml-2 border-none text-NGrey-8 outline-none text-sm focus:border-transparent focus:ring-0 h-full w-full focus:outline-none"
                    onFocus={() => setIsPhoneActive(true)}
                    onBlur={() => {
                      setIsPhoneActive(false);
                    }}
                    value={phone}
                    onChange={(e) => {
                      if (phoneError) setPhoneError(false);
                      setPhone(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="w-full">
              <p className="text-sm mb-1 text-NGrey-9">Company</p>
              <div
                className={`border ${
                  isCompanyActive ? 'border-primary-9' : 'border-NGrey-6'
                } rounded flex items-center h-9 pl-2 w-full`}>
                <div className=" h-full flex items-center">
                  <CompanyIcon
                    className={`w-4 h-4 ${
                      isCompanyActive ? 'text-primary-9' : 'text-NGrey-6'
                    } `}
                  />
                </div>
                <input
                  className="ml-2 border-0 text-NGrey-9 text-sm border-transparent focus:border-transparent focus:ring-0 h-full w-full focus:outline-none"
                  onFocus={() => setIsCompanyActive(true)}
                  onBlur={() => {
                    setIsCompanyActive(false);
                  }}
                  value={company}
                  onChange={(e) => {
                    setCompany(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>

          <div id="modal-body" className="items-center w-full h-32 mt-2">
            <p className="text-sm mb-1 text-NGrey-9">Your message*</p>
            <textarea
              className={`resize-none ${
                messageError ? 'border-Error-6' : 'border-NGrey-6'
              }
               ${
                 message !==
                 "Hi, I'm interested in learning more about your product and would like to book a meeting. Could you give me some suggestions of dates for a virtual meeting?  Thank you in advance!"
                   ? 'text-NGrey-9'
                   : 'text-NGrey-6'
               }
              }
              w-full h-full border-box text-sm caret-secondary-10 whitespace-pre-wrap flex flex-row items-start gap-3 bg-white border  rounded  font-sans font-normal  focus:ring-0 focus:border-primary-10`}
              value={message}
              onChange={(e) => {
                if (messageError) setMessageError(false);
                setMessage(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="absolute bottom-0 right-0 justify-center flex gap-2 px-2">
          <div className="flex-1">
            <DefaultButton
              type="outlined-primary"
              label="Cancel"
              onPressed={close}
            />
          </div>
          <div className="">
            <DefaultButton
              type="contained-secondary"
              label="Book meeting"
              onPressed={() => {
                CheckMail();
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}
DemoRequestModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired
};
