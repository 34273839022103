// @copyright (c) 2022-2024 Compular AB
import PropTypes from 'prop-types';
import EnlargeIcon from '../../icons/EnlargeIcon';
import { convertHeader } from '../../utils/utils';
import BarChart from '../Charts/BarChart';
import LineChart from '../Charts/LineChart';
import PieChart from '../Charts/PieChart';

/**
 * Flipable card with data inside
 * @param {object} data data
 * @param {array} jobData data
 * @param {array} labels data
 * @param {array} headers data
 * @param {boolean} flipcard
 * @param {function} enlarge //open card in modal
 * @param {string} type // type of data
 * @param {string} size // type of data
 * @returns
 */
export default function Card({
  jobData,
  labels,
  enlarge,
  header,
  type,
  headers,
  size
}) {
  if (type === 'barchart') {
    if (size === 'big') {
      return (
        <div className="card min-w-[650px] w-[28%] min-h-[300px] flex-col">
          <div className="w-full px-2 flex h-4 relative">
            {header && jobData.length > 0 && (
              <p className="absolute top-8 w-full text-center left-1 text-primary-10 text-base font-semibold">
                {header}
              </p>
            )}
            {jobData.length > 0 && enlarge && (
              <EnlargeIcon
                className="h-4 w-4 cursor-pointer hover:text-NGrey-7 absolute right-2 top-2"
                onClick={enlarge}
              />
            )}
          </div>
          {jobData.length > 0 ? (
            <BarChart
              dataJob={jobData.map((job) => {
                return {
                  data: parseFloat(job.data),
                  error: parseFloat(job.error)
                };
              })}
              label={labels}
              header={headers}
            />
          ) : (
            <p className="w-full text-center text-lg font-semibold text-primary-10">
              Failed to load image
            </p>
          )}
        </div>
      );
    }
    return (
      <div
        className="card  flex-col justify-center items-center relative"
        style={{
          height: '320px',
          width: '425px'
        }}>
        <div className="w-full px-2 flex justify-end relative">
          {header && jobData.length > 0 && (
            <p className="absolute top-6 w-full text-center left-1 text-primary-10 text-base font-semibold">
              {header}
            </p>
          )}
          {jobData.length > 0 && enlarge && (
            <EnlargeIcon
              className="h-4 w-4 cursor-pointer hover:text-NGrey-7 absolute right-2 top-2"
              onClick={enlarge}
            />
          )}
        </div>
        {jobData.length > 0 ? (
          <BarChart
            dataJob={jobData.map((job) => {
              return {
                data: parseFloat(job.data),
                error: parseFloat(job.error)
              };
            })}
            label={labels}
            header={headers}
          />
        ) : (
          'Failed to load image'
        )}
      </div>
    );
  }
  if (type === 'piechart')
    return (
      <div className="card min-w-[425px] w-[28%] min-h-[300px] flex-col justify-center items-center relative">
        <div className="top-2 w-full absolute px-2 flex justify-end">
          {enlarge && (
            <EnlargeIcon
              className="h-4 w-4 cursor-pointer hover:text-NGrey-7"
              onClick={enlarge}
            />
          )}
        </div>
        <PieChart
          dataJob={jobData.filter((sys, i) => i !== 0).map((sys) => sys[1])}
          label={jobData.filter((sys, i) => i !== 0).map((sys) => sys[0])}
          header={jobData.filter((sys, i) => i === 0).map((sys) => sys[1])[0]}
        />
      </div>
    );
  if (type === 'linechart')
    return (
      <div className="card min-w-[425px] w-[28%] min-h-[300px] flex-col justify-center items-center relative">
        <div className="top-2 w-full absolute px-2 flex justify-end">
          {enlarge && (
            <EnlargeIcon
              className="h-4 w-4 cursor-pointer hover:text-NGrey-7"
              onClick={enlarge}
            />
          )}
        </div>
        <LineChart
          dataJob1={jobData.filter((sys, i) => i !== 0).map((sys) => sys[1])}
          dataJob2={jobData
            .filter((sys, i) => i !== 0)
            .map((sys) => sys[1] * 2)}
          label={jobData.filter((sys, i) => i !== 0).map((sys) => sys[0])}
          header={jobData.filter((sys, i) => i === 0).map((sys) => sys[1])[0]}
        />
      </div>
    );
  if (type === 'text') {
    const unit = jobData[1];

    return (
      <div
        className="card flex-col justify-center items-center relative"
        style={{
          height: '300px',
          width: '425px'
        }}>
        <div className="top-2 w-full absolute px-2 flex justify-end">
          {enlarge && (
            <EnlargeIcon
              className="h-4 w-4 cursor-pointer hover:text-NGrey-7"
              onClick={enlarge}
            />
          )}
        </div>
        <div className="w-full h-full flex items-center justify-center flex-col">
          <div className="flex w-full flex-col gap-4 items-center justify-center my-auto">
            <p className="text-xl text-NGrey-6 font-semibold w-full text-center">
              {jobData[0]}
            </p>
            <p className="text-3xl text-primary-9 font-semibold w-full text-center">
              {`${
                Math.round((jobData[2] + Number.EPSILON) * 1000) / 1000
              } ${convertHeader(unit)}`}
            </p>
            <p className="text-Error-4">
              &#177; {Math.round((jobData[3] + Number.EPSILON) * 1000) / 1000}
            </p>
          </div>
        </div>
      </div>
    );
  }
}
Card.propTypes = {
  jobData: PropTypes.instanceOf(Array),
  labels: PropTypes.instanceOf(Array),
  header: PropTypes.string,
  headers: PropTypes.string,
  enlarge: PropTypes.func,
  type: PropTypes.string.isRequired,
  size: PropTypes.string
};
Card.defaultProps = {
  jobData: [],
  enlarge: null,
  headers: null,
  header: null,
  labels: null,
  size: null
};
