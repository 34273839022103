/* @copyright (c) 2021-2022 Compular AB */
import PropTypes from 'prop-types';
import DefaultButton from '../DefaultButtons/DefaultButton';

/**
 * Text input component.
 *
 * @param {*} params
 * @param {boolean} isDisabled set True if control should be disabled.
 * @param {boolean} isError set True if control should display error status.
 * @param {string} placeholder set True if control should be disabled.
 * @param {string} label label for the input field.
 * @param {string} inputValue state for input value.
 * @param {function} setInputValue function that sets inputValue state.
 * @param {func} buttonAction function that adds help button
 * @param {string} type typeof input. num or password.
 * @param {function} onKeyDown
 * @returns
 */
function InputField({
  isDisabled,
  isError,
  isRequired,
  placeHolder,
  label,
  buttonAction,
  inputValue,
  setInputValue,
  name,
  type,
  isNoText,
  onKeyDown,
  errorMessage
}) {
  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur();
    // Prevent the page/container scrolling
    e.stopPropagation();

    // Refocus immediately, on the next tick (after the current function is done)
    setTimeout(() => e.target.focus(), 0);
  };
  if (type === 'MINNUMBER') {
    if (buttonAction) {
      return (
        <div className="flex flex-1 flex-col justify-between  w-full p-0">
          <div className="flex gap-2 pb-2 items-center">
            {label && (
              <p className="flex flex-row items-center gap-2 font-sans font-normal text-base text-NGrey-900">
                {label}
              </p>
            )}
            {buttonAction && (
              <DefaultButton
                label=""
                type="text-primary"
                onPressed={buttonAction}
                icon="help"
                iconPosition="left"
              />
            )}
          </div>
          {isDisabled && (
            <input
              autoComplete="off"
              type="number"
              name={name}
              className="input-disabled"
              placeholder={placeHolder}
              onWheel={numberInputOnWheelPreventChange}
              disabled
            />
          )}
          {isError ? (
            <input
              autoComplete="off"
              type="number"
              className="h-11 bg-currentColor  border-box rounded border border-Error-6 font-sans font-normal text-base text-NGrey-9 pl-2 focus:ring-0 focus:border-primary-10"
              name={name}
              placeholder={placeHolder}
              onWheel={numberInputOnWheelPreventChange}
              value={inputValue}
              onChange={setInputValue}
            />
          ) : (
            <input
              autoComplete="off"
              type="number"
              name={name}
              className="h-11 bg-currentColor caret-secondary-10 border-box rounded border border-NGrey-6 font-sans font-normal text-base text-NGrey-9 pl-2 focus:ring-0 focus:border-primary-10"
              placeholder={placeHolder}
              value={inputValue}
              onWheel={numberInputOnWheelPreventChange}
              onChange={setInputValue}
              onKeyDown={onKeyDown}
            />
          )}
          {isRequired && <p className="required-text">*Required</p>}
          {isError && isNoText && (
            <p className="error-text text-Error-6">Empty</p>
          )}
        </div>
      );
    }
    return (
      <div className="flex flex-1 flex-col justify-between  w-full p-0">
        {label && (
          <div className="flex gap-2 pb-2 items-center">
            <p className="font-sans font-normal text-base text-NGrey-900">
              {label}
            </p>
          </div>
        )}
        {buttonAction && (
          <div className="flex gap-2 pb-2 items-center">
            <DefaultButton
              label=""
              type="text-primary"
              onPressed={buttonAction}
              icon="help"
              iconPosition="left"
            />
          </div>
        )}

        {isDisabled && (
          <input
            autoComplete="off"
            type="number"
            name={name}
            className="input-disabled"
            placeholder={placeHolder}
            onWheel={numberInputOnWheelPreventChange}
            disabled
          />
        )}
        {isError ? (
          <input
            autoComplete="off"
            type="number"
            className="h-11 bg-currentColor  border-box rounded border border-Error-6 font-sans font-normal text-base text-NGrey-9 pl-2 focus:ring-0 focus:border-primary-10"
            name={name}
            placeholder={placeHolder}
            onWheel={numberInputOnWheelPreventChange}
            value={inputValue}
            onChange={setInputValue}
          />
        ) : (
          <input
            autoComplete="off"
            type="number"
            name={name}
            className="h-11 bg-currentColor caret-secondary-10 border-box rounded border border-NGrey-6 font-sans font-normal text-base text-NGrey-9 pl-2 focus:ring-0 focus:border-primary-10"
            placeholder={placeHolder}
            value={inputValue}
            onWheel={numberInputOnWheelPreventChange}
            onChange={setInputValue}
            onKeyDown={onKeyDown}
          />
        )}
        {isRequired && <p className="required-text">*Required</p>}
        {isError && isNoText && (
          <p className="error-text text-Error-6">Empty</p>
        )}
        {isError && isNoText && (
          <p className="error-text text-Error-6">{errorMessage || 'Empty'}</p>
        )}
      </div>
    );
  }
  if (type === 'NUMBER') {
    return (
      <div className="flex flex-1 flex-col justify-between  w-full p-0">
        {(label || buttonAction) && (
          <div className="flex gap-2 pb-2 items-center">
            {label && (
              <p className="font-sans font-normal text-base text-NGrey-900">
                {label}
              </p>
            )}
            {buttonAction && (
              <DefaultButton
                label=""
                type="text-primary"
                onPressed={buttonAction}
                icon="help"
                iconPosition="left"
              />
            )}
          </div>
        )}

        {isDisabled && (
          <input
            autoComplete="off"
            type="number"
            name={name}
            onWheel={numberInputOnWheelPreventChange}
            className="input-disabled"
            placeholder={placeHolder}
            disabled
          />
        )}
        {isError ? (
          <input
            type="number"
            className="h-11 bg-currentColor  border-box rounded border border-Error-6 font-sans font-normal text-base text-NGrey-9 pl-2 focus:ring-0 focus:border-primary-10"
            name={name}
            autoComplete="off"
            placeholder={placeHolder}
            onWheel={numberInputOnWheelPreventChange}
            value={inputValue}
            onChange={setInputValue}
          />
        ) : (
          <input
            type="number"
            name={name}
            className="h-11 bg-currentColor caret-secondary-10 border-box rounded border border-NGrey-6 font-sans font-normal text-base text-NGrey-9 pl-2 focus:ring-0 focus:border-primary-10"
            placeholder={placeHolder}
            autoComplete="off"
            value={inputValue}
            onWheel={numberInputOnWheelPreventChange}
            onChange={setInputValue}
            onKeyDown={onKeyDown}
          />
        )}
        {isRequired && <p className="required-text">*Required</p>}
        {isError && isNoText && (
          <p className="error-text text-Error-6">Empty</p>
        )}
      </div>
    );
  }
  if (type === 'password') {
    if (isError) {
      return (
        <div className="flex flex-1 flex-col justify-between  w-full p-0">
          <div className="flex gap-2 pb-2 items-center">
            {label && (
              <p className="font-sans font-normal text-base text-NGrey-9">
                {label}
              </p>
            )}
            {buttonAction && (
              <DefaultButton
                label=""
                type="text-primary"
                onPressed={buttonAction}
                icon="help"
                iconPosition="left"
              />
            )}
          </div>
          <input
            type="password"
            name={name}
            onKeyDown={onKeyDown}
            className="h-11 bg-currentColor caret-secondary-10  border-box rounded border border-Error-6 font-sans font-normal text-base text-NGrey-9 pl-2 focus:ring-0 focus:border-primary-10"
            placeholder={placeHolder}
            autoComplete="current-password"
            value={inputValue}
            onChange={setInputValue}
          />
        </div>
      );
    }
    return (
      <div className="flex flex-1 flex-col justify-between  w-full p-0">
        {label && (
          <div className="flex gap-2 pb-2 items-center">
            <p className="font-sans font-normal text-base text-NGrey-900">
              {label}
            </p>
          </div>
        )}
        {buttonAction && (
          <div className="flex gap-2 pb-2 items-center">
            <DefaultButton
              label=""
              type="text-primary"
              onPressed={buttonAction}
              icon="help"
              iconPosition="left"
            />
          </div>
        )}
        <input
          type="password"
          name={name}
          autoComplete="off"
          onKeyDown={onKeyDown}
          className="h-11 bg-currentColor caret-secondary-10 border-box rounded border border-NGrey-6 font-sans font-normal text-base text-NGrey-9 pl-2 focus:ring-0 focus:border-primary-10"
          placeholder={placeHolder}
          value={inputValue}
          onChange={setInputValue}
        />
      </div>
    );
  }
  return (
    <div className="flex flex-1 flex-col justify-between  w-full p-0">
      {label && !buttonAction && (
        <div className="flex gap-2 pb-2 items-center">
          <p className="font-sans font-normal text-base text-NGrey-900">
            {label}
          </p>
        </div>
      )}
      {buttonAction && !label && (
        <div className="flex gap-2 pb-2 items-center">
          <DefaultButton
            label=""
            type="text-primary"
            onPressed={buttonAction}
            icon="help"
            iconPosition="left"
          />
        </div>
      )}

      {buttonAction && label && (
        <div className="flex gap-2 pb-2 items-center">
          <p className="font-sans font-normal text-base text-NGrey-900">
            {label}
          </p>
          <DefaultButton
            label=""
            type="text-primary"
            onPressed={buttonAction}
            icon="help"
            iconPosition="left"
          />
        </div>
      )}
      {isError && !isDisabled ? (
        <input
          type="text"
          className="h-11 bg-currentColor  border-box rounded border border-Error-6 font-sans font-normal text-base text-NGrey-9 pl-2 focus:ring-0 focus:border-primary-10"
          name={name}
          autoComplete="off"
          placeholder={placeHolder}
          value={inputValue}
          onChange={setInputValue}
        />
      ) : isDisabled ? (
        <input
          type="text"
          autoComplete="off"
          name={name}
          className="input-disabled bg-NGrey-2 h-11 bg-currentColor caret-secondary-10 border-box rounded border border-NGrey-6 font-sans font-normal text-base text-NGrey-8 pl-2 focus:ring-0 focus:border-primary-10"
          placeholder={placeHolder}
          value={inputValue || ''}
          disabled
        />
      ) : (
        <input
          autoComplete="off"
          type="text"
          name={name || ''}
          className="h-11 bg-currentColor caret-secondary-10 border-box rounded border border-NGrey-6 font-sans font-normal text-base text-NGrey-9 pl-2 focus:ring-0 focus:border-primary-10"
          placeholder={placeHolder || ''}
          value={inputValue || ''}
          onChange={setInputValue}
          onKeyDown={onKeyDown}
        />
      )}
      {isRequired && <p className="required-text">*Required</p>}
      {isError && isNoText && <p className="error-text text-Error-6">Empty</p>}
    </div>
  );
}
InputField.propTypes = {
  isDisabled: PropTypes.bool,
  isError: PropTypes.bool,
  isRequired: PropTypes.bool,
  placeHolder: PropTypes.string,
  label: PropTypes.string,
  inputValue: PropTypes.any.isRequired,
  setInputValue: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  isNoText: PropTypes.bool,
  onKeyDown: PropTypes.func,
  buttonAction: PropTypes.func,
  errorMessage: PropTypes.string
};
InputField.defaultProps = {
  isDisabled: false,
  isError: false,
  isNoText: true,
  isRequired: true,
  type: null,
  placeHolder: '',
  label: '',
  onKeyDown: null,
  buttonAction: null,
  errorMessage: null
};

export default InputField;
