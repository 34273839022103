/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* @copyright (c) 2021-2022 Compular AB */
import PropTypes from 'prop-types';
import { convertHeader } from '../../utils/utils';
/**
 * Radio buttons with label
 * Access option-values with keys
 * @param {String} label Text shown over radiogroup
 * @param {String} value Text shown over radiogroup
 * @param {Object} options Options of radiogroup as object
 * @param {Function} setEditedUnit Set checked
 * @param {Array} keys OptionKeys
 *  @param {String} element name for radiogroup
 * @returns
 */

function RadioButton({ label, options, element, keys, setEditedUnit, value }) {
  return (
    <div>
      <p className="text-NGrey-9 text-base font-sans pb-2">{label}</p>
      <ul className="grid w-full grid-flow-col auto-cols-auto ">
        {keys.map((key, i) => {
          const head = options[key];
          return (
            <li
              key={key + i}
              onMouseDown={() => {
                setEditedUnit(options[key]);
              }}>
              <input
                type="radio"
                id={element + i}
                name={element}
                value={options[key]}
                onChange={() => {}}
                checked={value === options[key]}
                className="hidden peer"
                required
              />
              <label
                htmlFor={element + 1}
                className={`${
                  Object.values(options).length - 1 === i ? 'rounded-r-md' : ''
                }
                ${
                  i === 0 ? 'rounded-l-md' : ''
                }  inline-flex px-3 py-[3px] items-center justify-center w-20 rounded-none text-base font-normal font-sans text-NGrey-6 h-11 bg-white border border-NGrey-6 cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-white peer-checked:bg-primary-10 peer-checked:border-primary-10 peer-checked:text-white hover:text-primary-10 hover:border-primary-10`}>
                <p className="block text-lg">{convertHeader(head)}</p>
              </label>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
RadioButton.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  keys: PropTypes.array.isRequired,
  options: PropTypes.shape({ name: PropTypes.string }).isRequired,
  element: PropTypes.string.isRequired,
  setEditedUnit: PropTypes.func.isRequired
};
RadioButton.defaultProps = {
  label: ''
};

export default RadioButton;
