// @copyright (c) 2024 Compular AB
import * as React from 'react';

function ScatterChartIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M4 4a1 1 0 0 1 1 1v13h15a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1Zm7 6.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm2.5 3.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3ZM20 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0ZM13.5 10a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Zm5.5 2.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0ZM7.5 16a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default ScatterChartIcon;
