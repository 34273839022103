/* @copyright (c) 2021-2022 Compular AB */
import React, { useState, useMemo, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingPage from '../LoadingPage/LoadingPage';
import DefaultButton from '../../components/DefaultButtons/DefaultButton';
import DemoRequestModal from '../../components/Modals/DemoRequestModal';
import Page from '../../components/Page/Page';
import WhiteBackgroundBox from '../../components/WhiteBackgroundBox/WhiteBackgroundBox';
import ChartModal from '../../components/Modals/ChartModal';
import Card from '../../components/Card/Card';
import { getJobResults, logIn } from '../../redux/actions/actions';
import JobListModal from '../../components/Modals/JobListModal';
import SmilesModal from '../../components/Modals/SmilesModal';
import { openInNewTab } from '../../utils/utils';
import { AccountContext } from '../../Accounts';
import { request } from '../../redux/api/axios';
import Table from '../../components/Table/Table';

function ResultPage() {
  const { screeningid, jobid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDynamicalSpeciesExpanded, setIsDynamicalSpeciesExpanded] =
    useState(false);
  const [isMolecularSpeciesExpanded, setIsMolecularSpeciesExpanded] =
    useState(false);
  const [isCompareModalOpen, setIsCompareModalOpen] = useState(false);
  const [chartModalHeader, setChartModalHeader] = useState();
  const [chartModalLabels, setChartModalLabels] = useState();
  const [chartModalData, setChartModalData] = useState();
  const [smilesData, setSmilesData] = useState();
  const [comps, setComps] = useState(null);
  const [jobs, setJobs] = useState(null);
  const [isSmilesModalOpen, setIsSmilesModalOpen] = useState(false);

  const { getSession } = useContext(AccountContext);
  const help = true;
  const reduxLoading = useSelector((state) => state.reducer.isLoading);
  const user = useSelector((state) => state.reducer.user);
  const moleculeBank = useSelector((state) => state.reducer.moleculeBank);
  const oJobs = useSelector((state) => state.reducer.jobs);
  const allJobs = Object.values(oJobs);
  const screening = allJobs.filter(
    (_job) => _job[0] === parseInt(screeningid)
  )[0];
  const rawcomps = useSelector((state) => state.reducer.compositions[jobid]);
  const molecule = useMemo(() => {
    if (moleculeBank && moleculeBank.length > 0)
      return moleculeBank.map((m) => {
        return {
          IUPAC: m[2],
          id: m[0],
          CommonName: m[1],
          alias: m[3],
          density: m[4],
          orgK: m[5],
          abb: m[8]
        };
      });
  }, [moleculeBank]);
  const finishedJobs = useMemo(() => {
    if (
      allJobs &&
      allJobs.length > 0 &&
      Array.isArray(allJobs?.findLast((ele) => ele)?.findLast((ele) => ele))
    ) {
      const aFlatJobs = allJobs
        .map((job) => {
          return job.findLast((ele) => ele);
        })
        .flat()
        .filter((job) => job[14] === 'Finished');
      return aFlatJobs;
    }
    return null;
  }, [allJobs]);

  useEffect(() => {
    if (rawcomps) setComps(Object.values(rawcomps));
  }, [rawcomps]);
  const result = useSelector((state) => {
    const key = Object.keys(state.reducer.results).filter(
      (job) => job === jobid
    )[0];
    return state.reducer.results[key];
  });

  useEffect(() => {
    if (screening && Array.isArray(screening.findLast((ele) => ele)) && user) {
      const job = screening
        .findLast((ele) => ele)
        .find((j) => j[0] === parseInt(jobid));
      if (job && job.length > 0) {
        setJobs(job);
      }
    }
  }, [screening, user, result]);
  const job = useMemo(() => {
    if (jobs && comps && comps.length > 0 && user) {
      const parents = comps.filter((comp) => comp[7] === null);
      const children = comps
        .filter((comp) => comp[7])
        .map((comp) => {
          if (comp?.[9])
            return {
              id: comp[0],
              name: comp[3],
              value: comp[5],
              unit: comp[6],
              molecule: molecule.find((m) => m.id === comp[9]),
              parent: comp[7]
            };
          return {
            id: comp[0],
            name: comp[3],
            value: comp[5],
            unit: comp[6],
            parent: comp[7]
          };
        });
      const json = parents.map((composition) => {
        if (composition?.[9])
          return {
            id: composition[0],
            name: composition[3],
            value: composition[5],
            unit: composition[6],
            molecule: molecule.find((m) => m.id === composition[9]),
            children: children.filter(
              (child) => child.parent === composition[0]
            )
          };
        return {
          id: composition[0],
          name: composition[3],
          value: composition[5],
          unit: composition[6],
          children: children.filter((child) => child.parent === composition[0])
        };
      });

      return {
        composition: json,
        id: jobs[0],
        organizationId: jobs?.[1] ? jobs?.[1] : null,
        name: jobs[5] || '',
        ensemble: jobs[6],
        temperature: {
          value: jobs[7] || 0,
          unit: jobs[8]
        },
        pressure:
          jobs[6] !== 'NPT'
            ? null
            : {
                value: jobs[11] || '',
                unit: jobs[12]
              },
        density: {
          value: jobs[9] || '',
          unit: jobs[10]
        },
        formula: '',
        freeText: jobs[13] || ''
      };
    }
  }, [comps, jobs, screening, user]);
  useEffect(() => {
    if (result && user && job) return setLoading(false);
    if (!user) {
      setLoading(true);
      return getSession()
        .then((data) => {
          const AUTH_TOKEN = data.accessToken.jwtToken;
          request.defaults.headers.common.Authorization = AUTH_TOKEN;
          dispatch(logIn());
        })
        .catch(() => {
          navigate('/');
        });
    }
    if (!result && !reduxLoading)
      dispatch(getJobResults(user[1], parseInt(jobid)));
  }, [result, user, jobs, job, reduxLoading]);

  const openChartModal = (type, index) => {
    setChartModalData(
      type
        .filter((sys, i) => i !== 0)
        .toSorted((a, b) => {
          if (a[2] === b[2]) return 0;
          const returnval = a[2] > b[2] ? -1 : 1;

          return returnval;
        })
        .map((sys) => {
          return {
            data: sys[index],
            error: sys[index + 1]
          };
        })
    );
    setChartModalHeader(type.filter((sys, i) => i === 0)[0][index]);
    setChartModalLabels(
      type
        .filter((sys, i) => i !== 0)
        .toSorted((a, b) => {
          if (a[2] === b[2]) return 0;
          const returnval = a[2] > b[2] ? -1 : 1;

          return returnval;
        })
        .map((sys) => sys[0])
    );
    if (index === 4) {
      setChartModalData(
        type
          .filter((sys, i) => i !== 0)
          .toSorted((a, b) => {
            if (a[2] === b[2]) return 0;
            const returnval = a[2] > b[2] ? -1 : 1;

            return returnval;
          })
          .map((sys) => {
            return { data: sys[index] / 1e-10, error: sys[index + 1] / 1e-10 };
          })
      );
      setChartModalHeader(
        type
          .filter((sys, i) => i === 0)[0]
          [index].split('[')[0]
          .replaceAll(' ', ' [1e-10 m^2/s]')
      );
    }

    setIsModalOpen(true);
  };

  if (!job || loading || !result) return <LoadingPage />;
  return (
    <Page help={help} onHelp={() => setIsHelpModalOpen(true)}>
      <div className="w-full px-12 py-8">
        {/* <HelpModal
          isOpen={isHelpModalOpen}
          close={() => setIsHelpModalOpen(false)}
        /> */}
        <DemoRequestModal
          isOpen={isHelpModalOpen}
          close={() => setIsHelpModalOpen(false)}
        />
        {isModalOpen && (
          <ChartModal
            isOpen={isModalOpen}
            close={() => setIsModalOpen(false)}
            jobData={chartModalData}
            headers={chartModalHeader}
            labels={chartModalLabels}
          />
        )}
        {isSmilesModalOpen && (
          <SmilesModal
            isOpen={isSmilesModalOpen}
            close={() => setIsSmilesModalOpen(false)}
            data={smilesData}
          />
        )}
        {isCompareModalOpen && (
          <JobListModal
            isOpen={isCompareModalOpen}
            close={() => setIsCompareModalOpen(false)}
            jobs={finishedJobs}
          />
        )}

        <WhiteBackgroundBox>
          <div className="h-full w-full  relative">
            <div
              className={`sticky top-0 w-full border-NGrey-3 border-b pt-4 bg-white text-center ${
                isSmilesModalOpen ||
                isModalOpen ||
                isHelpModalOpen ||
                isCompareModalOpen
                  ? ''
                  : 'z-10'
              }`}>
              <div className="flex flex-start px-8 pb-4 items-center">
                <div className="basis-1/3">
                  <DefaultButton
                    label="Go back to jobs"
                    type="text-secondary"
                    onPressed={() => {
                      // const message =
                      //   'It looks like you have been editing something. ' +
                      //   'If you leave before saving, your changes will be lost.';
                      // // eslint-disable-next-line no-restricted-globals
                      // if (confirm(message) === true) {
                      // }
                      navigate('/jobs');
                    }}
                    icon="arrowLeft"
                    iconPosition="left"
                  />
                </div>
                <p className="text-[23px] font-semibold text-primary-10 basis-1/3">
                  Results
                </p>
                <div className="basis-1/3 ml-auto flex justify-end align-right">
                  <DefaultButton
                    label="Compare"
                    type="outlined-primary"
                    onPressed={() => {
                      setIsCompareModalOpen(true);
                    }}
                    icon="compare"
                    iconPosition="left"
                  />
                </div>
              </div>
            </div>
            <div className="px-8 divide-y divide-NGrey-3">
              <div className="pb-2">
                <div className="flex font-sans font-bold text-2xl text-NGrey-9 gap-2 items-center pt-4">
                  <p>System specifications</p>
                  <div>
                    <DefaultButton
                      label=""
                      type="text-primary"
                      onPressed={() => {
                        openInNewTab(
                          'https://compulartech.com/docs/results/#system-specifications'
                        );
                      }}
                      icon="help"
                      iconPosition="left"
                    />
                  </div>
                </div>
                <div className="w-full pt-4 flex justify-between">
                  {job && job.name && (
                    <p className="text-NGrey-6 text-[33px] font-semibold">
                      {job.name}
                    </p>
                  )}
                </div>
                <div className="w-full mt-6">
                  {job && (
                    <div className="flex w-full gap-3 mb-6">
                      <div className="w-1/2">
                        <Table data={job.composition} type="compositions" />
                      </div>
                      <div className="w-1/2 flex gap-5 flex-col">
                        <Table
                          data={
                            job.ensemble === 'NPT'
                              ? Object.keys(job)
                                  .filter(
                                    (key) =>
                                      [
                                        'name',
                                        'composition',
                                        'freeText',
                                        'organizationId',
                                        'id',
                                        'jobLogs',
                                        'density',
                                        'formula'
                                      ].indexOf(key) === -1
                                  )
                                  .map((acc) => {
                                    return job[acc];
                                  })
                              : Object.keys(job)
                                  .filter(
                                    (key) =>
                                      [
                                        'name',
                                        'composition',
                                        'freeText',
                                        'organizationId',
                                        'id',
                                        'jobLogs',
                                        'density',
                                        'formula',
                                        'pressure'
                                      ].indexOf(key) === -1
                                  )
                                  .map((acc) => {
                                    return job[acc];
                                  })
                          }
                          keys={
                            job.ensemble === 'NPT'
                              ? Object.keys(job).filter(
                                  (key) =>
                                    [
                                      'name',
                                      'composition',
                                      'freeText',
                                      'organizationId',
                                      'id',
                                      'jobLogs',
                                      'density',
                                      'formula'
                                    ].indexOf(key) === -1
                                )
                              : Object.keys(job).filter(
                                  (key) =>
                                    [
                                      'name',
                                      'composition',
                                      'freeText',
                                      'organizationId',
                                      'id',
                                      'jobLogs',
                                      'formula',
                                      'density',
                                      'pressure'
                                    ].indexOf(key) === -1
                                )
                          }
                          type="conditions"
                        />
                        {job && job.freeText && (
                          <div className="w-full min-h-fit disabled-card text-left flex flex-col p-5 justify-start m-0">
                            <p className="w-full text-NGrey-8">
                              Other information:
                            </p>
                            <p className="text-NGrey-6">{job.freeText}</p>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {result && (
                <div className="py-10">
                  <div className="w-full min-h-[320px] flex-row justify-evenly flex gap-6 flex-wrap">
                    {result &&
                      result.mainResult &&
                      result.mainResult
                        .filter((aMainR, i) => i !== 0)
                        .map((aFilteredMainR) => (
                          <React.Fragment key={aFilteredMainR[0]}>
                            <Card
                              key={aFilteredMainR[0]}
                              jobData={aFilteredMainR}
                              type="text"
                              flipcard={false}
                            />
                          </React.Fragment>
                        ))}
                  </div>
                </div>
              )}
              <div className="py-10">
                <div id="subheader" className="pb-10">
                  <div className="font-sans font-bold text-2xl text-NGrey-9 flex gap-2 items-center">
                    <p>Molecular species</p>
                    <div>
                      <DefaultButton
                        label=""
                        type="text-primary"
                        onPressed={() => {
                          openInNewTab(
                            'https://compulartech.com/docs/results/#molecular-species'
                          );
                        }}
                        icon="help"
                        iconPosition="left"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  {isMolecularSpeciesExpanded ? (
                    <Table
                      keys={
                        result.molecularSystem.filter((sys, i) => i === 0)[0]
                      }
                      data={result.molecularSystem.filter((sys, i) => i !== 0)}
                      showModal={(moleculeRow) => {
                        setSmilesData(moleculeRow);
                        setIsSmilesModalOpen(true);
                      }}
                    />
                  ) : (
                    <Table
                      type="small"
                      keys={
                        result.molecularSystem.filter((sys, i) => i === 0)[0]
                      }
                      data={result.molecularSystem.filter((sys, i) => i !== 0)}
                      showModal={(x) => {
                        setSmilesData(x);
                        setIsSmilesModalOpen(true);
                      }}
                    />
                  )}
                </div>

                <div className="flex w-full justify-end pb-12">
                  <div className="my-5">
                    {result.molecularSystem.filter((sys, i) => i !== 0).length >
                      5 && (
                      <DefaultButton
                        type="text-secondary"
                        label={
                          isMolecularSpeciesExpanded
                            ? 'Show less'
                            : 'Expand table'
                        }
                        icon={
                          isMolecularSpeciesExpanded ? 'arrowUp' : 'arrowDown'
                        }
                        iconPosition="right"
                        onPressed={() =>
                          setIsMolecularSpeciesExpanded(
                            !isMolecularSpeciesExpanded
                          )
                        }
                      />
                    )}
                  </div>
                </div>
                <div className="w-full min-h-[320px] flex-row justify-evenly flex gap-6 flex-wrap">
                  <Card
                    enlarge={() => {
                      openChartModal(result.molecularSystem, 2);
                    }}
                    flipcard={false}
                    type="barchart"
                    header={
                      result.molecularSystem
                        .filter((sys, i) => i === 0)[0][2]
                        .split('[')[0]
                    }
                    headers={
                      result.molecularSystem.filter((sys, i) => i === 0)[0][2]
                    }
                    labels={result.molecularSystem
                      .filter((sys, i) => i !== 0)
                      .map((sys) => sys[0])}
                    jobData={result.molecularSystem
                      .filter((sys, i) => i !== 0)
                      .map((sys) => {
                        return { data: sys[2], error: sys[3] };
                      })}
                  />
                  <Card
                    enlarge={() => {
                      openChartModal(result.molecularSystem, 4);
                    }}
                    flipcard={false}
                    header={
                      result.molecularSystem
                        .filter((sys, i) => i === 0)[0][4]
                        .split('[')[0]
                    }
                    type="barchart"
                    headers={result.molecularSystem
                      .filter((sys, i) => i === 0)[0][4]
                      .split('[')[0]
                      .replaceAll(' ', ' [1e-10 m^2/s]')}
                    labels={result.molecularSystem
                      .filter((sys, i) => i !== 0)
                      .map((sys) => sys[0])}
                    jobData={result.molecularSystem
                      .filter((sys, i) => i !== 0)
                      .map((sys) => {
                        return { data: sys[4] / 1e-10, error: sys[5] / 1e-10 };
                      })}
                  />

                  <Card
                    enlarge={() => {
                      openChartModal(result.molecularSystem, 7);
                    }}
                    flipcard={false}
                    header={
                      result.molecularSystem
                        .filter((sys, i) => i === 0)[0][7]
                        .split('[')[0]
                    }
                    type="barchart"
                    headers={
                      result.molecularSystem.filter((sys, i) => i === 0)[0][7]
                    }
                    labels={result.molecularSystem
                      .filter((sys, i) => i !== 0)
                      .map((sys) => sys[0])}
                    jobData={result.molecularSystem
                      .filter((sys, i) => i !== 0)
                      .map((sys) => {
                        return { data: sys[7], error: sys[8] };
                      })}
                  />
                </div>
              </div>
              <div className="py-10 w-full">
                <div
                  id="subheader"
                  className="pb-10 flex justify-between items-center">
                  <div className="font-sans font-bold text-2xl text-NGrey-9 flex gap-2 items-center">
                    <p>Dynamic species</p>
                    <div>
                      <DefaultButton
                        label=""
                        type="text-primary"
                        onPressed={() => {
                          openInNewTab(
                            'https://compulartech.com/docs/results/#dynamic-species'
                          );
                        }}
                        icon="help"
                        iconPosition="left"
                      />
                    </div>
                  </div>
                </div>
                {isDynamicalSpeciesExpanded ? (
                  <Table
                    keys={result.dynamicSystem.filter((sys, i) => i === 0)[0]}
                    data={result.dynamicSystem.filter((sys, i) => i !== 0)}
                    showModal={(x) => {
                      setSmilesData(x);
                      setIsSmilesModalOpen(true);
                    }}
                  />
                ) : (
                  <Table
                    type="small"
                    keys={result.dynamicSystem.filter((sys, i) => i === 0)[0]}
                    data={result.dynamicSystem.filter((sys, i) => i !== 0)}
                    showModal={(x) => {
                      setSmilesData(x);
                      setIsSmilesModalOpen(true);
                    }}
                  />
                )}

                <div className="flex w-full justify-end">
                  <div className="my-5 flex">
                    {result.dynamicSystem.filter((sys, i) => i !== 0).length >
                      5 && (
                      <DefaultButton
                        type="text-secondary"
                        label={
                          isDynamicalSpeciesExpanded
                            ? 'Show less'
                            : 'Expand table'
                        }
                        icon={
                          isDynamicalSpeciesExpanded ? 'arrowUp' : 'arrowDown'
                        }
                        iconPosition="right"
                        onPressed={() =>
                          setIsDynamicalSpeciesExpanded(
                            !isDynamicalSpeciesExpanded
                          )
                        }
                      />
                    )}
                  </div>
                </div>

                <div className="w-full h-[450px] mt-6 flex-row justify-center flex gap-6 flex-wrap">
                  <Card
                    enlarge={() => {
                      openChartModal(result.dynamicSystem, 2);
                    }}
                    size="big"
                    flipcard={false}
                    header={
                      result.dynamicSystem
                        .filter((sys, i) => i === 0)[0][2]
                        .split('[')[0]
                    }
                    type="barchart"
                    headers={
                      result.dynamicSystem.filter((sys, i) => i === 0)[0][2]
                    }
                    labels={result.dynamicSystem
                      .filter((sys, i) => i !== 0)
                      .filter((sys) => sys[2] >= 0.1)
                      .map((sys) => sys[0])}
                    jobData={result.dynamicSystem
                      .filter((sys, i) => i !== 0)
                      .filter((sys) => sys[2] >= 0.1)
                      .map((sys) => {
                        return { data: sys[2], error: sys[3] };
                      })}
                  />
                  <Card
                    enlarge={() => {
                      openChartModal(result.dynamicSystem, 4);
                    }}
                    size="big"
                    flipcard={false}
                    type="barchart"
                    header={
                      result.dynamicSystem
                        .filter((sys, i) => i === 0)[0][4]
                        .split('[')[0]
                    }
                    headers={result.dynamicSystem
                      .filter((sys, i) => i === 0)[0][4]
                      .split('[')[0]
                      .replaceAll(' ', ' [1e-10 m^2/s]')}
                    labels={result.dynamicSystem
                      .filter((sys, i) => i !== 0)
                      .toSorted((a, b) => {
                        if (a[2] === b[2]) return 0;
                        const returnval = a[2] > b[2] ? -1 : 1;

                        return returnval;
                      })
                      .filter((sys, i) => i <= 5)
                      .map((sys) => sys[0])}
                    jobData={result.dynamicSystem
                      .filter((sys, i) => i !== 0)
                      .toSorted((a, b) => {
                        if (a[2] === b[2]) return 0;
                        const returnval = a[2] > b[2] ? -1 : 1;

                        return returnval;
                      })
                      .filter((sys, i) => i <= 5)
                      .map((sys) => {
                        return { data: sys[4] / 1e-10, error: sys[5] / 1e-10 };
                      })}
                  />
                </div>
              </div>
            </div>
          </div>
        </WhiteBackgroundBox>
      </div>
    </Page>
  );
}

export default ResultPage;
