// @copyright (c) 2022-2024 Compular AB
import React from 'react';
import { PropTypes } from 'prop-types';

/**
 * Text input component.
 *
 * @param {*} param0
 * @param {boolean} isDisabled set True if control should be disabled.
 * @param {boolean} isError set True if control should display error status.
 * @param {boolean} isResizable set True if resizable.
 * @param {string} placeholder set True if control should be disabled.
 * @param {string} label label for the input field.
 * @param {string} warningLabel Warning-label for the input field.
 * @param {string} inputValue state for input value.
 * @param {function} setInputValue function that sets inputValue state.

 * @returns
 */

export default function TextArea({
  label,
  warningLabel,
  placeHolder,
  inputValue,
  setInputValue,
  isResizable = true
}) {
  return (
    <div className="flex flex-col h-full p-0 gap-2 w-full">
      {label && (
        <label className="font-sans font-normal text-base text-NGrey-9">
          {label}
        </label>
      )}
      {warningLabel && (
        <p className="text-NGrey-6 text-xs font-normal font-sans">
          {warningLabel}
        </p>
      )}
      <textarea
        className={`${
          isResizable ? '' : 'resize-none'
        } w-full h-full border-box caret-secondary-10 whitespace-pre-wrap flex flex-row items-start gap-3 bg-white border border-NGrey-6 rounded text-base font-sans font-normal text-NGrey-9 focus:ring-0 focus:border-primary-10`}
        placeholder={placeHolder}
        value={inputValue}
        onChange={setInputValue}
      />
    </div>
  );
}
TextArea.propTypes = {
  placeHolder: PropTypes.string,
  label: PropTypes.string,
  setInputValue: PropTypes.func.isRequired,
  inputValue: PropTypes.string.isRequired,
  warningLabel: PropTypes.string,
  isResizable: PropTypes.bool
};
TextArea.defaultProps = {
  placeHolder: '',
  warningLabel: '',
  label: '',
  isResizable: true
};
