// @copyright (c) 2022-2024 Compular AB
/* eslint-disable no-restricted-globals */
/* eslint-disable no-continue */
import moment from 'moment-timezone';
// Unit-keys to access values in unit-options
export const COMPOSITION_UNIT_KEYS = {
  molar: 'molar',
  mol: 'mol',
  hash: 'hash',
  perc: 'perc'
};
// unit-values
export const COMPOSITION_UNITS = {
  molar: 'Molar',
  mol: 'Mol',
  hash: '#',
  perc: '%'
};
// Unit-keys to access values in unit-options
export const PRESSURE_UNIT_KEYS = {
  pa: 'pa',
  atm: 'atm',
  bar: 'bar',
  psi: 'psi',
  mmhg: 'mmhg'
};
// unit-values
export const PRESSURE_UNITS = {
  pa: 'Pa',
  atm: 'atm',
  bar: 'bar',
  psi: 'psi',
  mmhg: 'mmHg'
};
// Unit-keys to access values in unit-options
export const TEMPERATURE_UNIT_KEYS = {
  C: 'C',
  F: 'F',
  K: 'K'
};
// unit-values
export const TEMPERATURE_UNITS = {
  C: '°C',
  F: '°F',
  K: 'K'
};

export const DENSITY_UNIT_KEYS = {
  G: 'G',
  KG: 'KG'
};

export const DENSITY_UNITS = {
  G: 'g/cm^3',
  KG: 'kg/m^3'
};
// Option-keys
export const ENSEMBLE_OPTION_KEYS = { nve: 'nve', nvt: 'nvt', npt: 'npt' };
// Option-values
// 'NVE', 'NVT',
export const ENSEMBLE_OPTIONS = ['NPT'];
export const COMPOSITION_UNITS_ARRAY = [
  'mol parts',
  'volume parts',
  'weight parts',
  'molar',
  'molal',
  'weight %',
  'volume %',
  'mol %'
];
export const SOLUTES_UNITS = ['M (molar)', 'm (molal)', 'wt. %'];
export const SOLVENT_UNITS = ['mol parts', 'wt. parts', 'vol. parts'];

// If Option npt is choosen show pressure
export const CONDITION_OPTIONS = {
  npt: 'PRESSURE'
};

/* This func takes data from server and decode it if string.
 * Example of aResData = [[data, data, data], [data, data, data]]
 * Arrays inside array.
 * Loop through all values and check if data is typeof string.
 * If typeof string === true decode. else return value.
 */
export function parseData(aResData) {
  return aResData.map((aData) =>
    aData.map((data) =>
      typeof data === 'string' ? decodeURIComponent(data) : data
    )
  );
}
export function getDate(str) {
  const d = new Date(str);
  const currentTime = moment(d).format('YYYY-MM-DD HH:mm');
  return currentTime;
}

export function openInNewTab(url) {
  window.open(url, '_blank', 'noreferrer');
}

export function convertHeader(headStart) {
  let formHeader;
  if (!headStart) return headStart;
  const indexhead = headStart.indexOf('^');
  if (indexhead > 0) {
    let str = '';
    formHeader = headStart.slice(0, indexhead);
    const headEnd = headStart.slice(indexhead - headStart.length);
    for (let index = 0; index < headEnd.length; index++) {
      const c = headEnd[index];
      if (c === '^') continue;
      if (!isNaN(parseInt(c))) {
        let utf;
        switch (c) {
          case '1': {
            utf = '\u00b1';
            break;
          }

          case '2': {
            utf = '\u00b2';
            break;
          }
          case '3': {
            utf = '\u00b3';
            break;
          }
          case '4': {
            utf = '\u00b4';
            break;
          }
          case '5': {
            utf = '\u00b5';
            break;
          }
          case '6': {
            utf = '\u00b6';
            break;
          }
          case '7': {
            utf = '\u00b7';
            break;
          }
          case '8': {
            utf = '\u00b8';
            break;
          }
          case '9': {
            utf = '\u00b9';
            break;
          }
          default: {
            utf = '\u00b0';
            break;
          }
        }
        str += utf;
      } else str += c;
    }
    formHeader += str;
  } else if (headStart.includes('degree')) {
    if (headStart.includes('degree Celsius'))
      formHeader = headStart.replaceAll('degree Celsius', '\u2103');
    else if (headStart.includes('degree Fahrenheit'))
      formHeader = headStart.replaceAll('degree Fahrenheit', '\u2109');
  } else {
    formHeader = headStart;
  }
  if (formHeader.includes('*')) {
    formHeader = formHeader.replaceAll('*', '\u00D7');
  }
  if (formHeader.includes('_')) {
    const indexSubScript = headStart.indexOf('_');
    if (indexSubScript > 0) {
      let str = '';
      formHeader = headStart.slice(0, indexSubScript);
      const headEnd = headStart.slice(indexSubScript - headStart.length);

      for (let index = 0; index < headEnd.length; index++) {
        const c = headEnd[index];
        if (c === '_') continue;
        if (!isNaN(parseInt(c))) {
          let utf;
          switch (c) {
            case '0': {
              utf = '\u2080';
              break;
            }
            case '1': {
              utf = '\u2081';
              break;
            }
            case '2': {
              utf = '\u2082';
              break;
            }
            case '3': {
              utf = '\u2083';
              break;
            }
            case '4': {
              utf = '\u2084';
              break;
            }
            case '5': {
              utf = '\u2085';
              break;
            }
            case '6': {
              utf = '\u2086';
              break;
            }
            case '7': {
              utf = '\u2087';
              break;
            }
            case '8': {
              utf = '\u2088';
              break;
            }
            case '9': {
              utf = '\u2089';
              break;
            }
            default: {
              utf = '';
              break;
            }
          }
          str += utf;
        } else str += c;
      }
      formHeader += str;
    }
  }
  return formHeader;
}

export function customSort(arr, sortIndex, skipFirst) {
  if (skipFirst) {
    const sortedArray = arr.toSorted((a, b, i) => {
      if (i === 0) return 0;
      // Handle numbers
      if (
        typeof a[sortIndex] === 'number' &&
        typeof b[sortIndex] === 'number'
      ) {
        return b[sortIndex] - a[sortIndex];
      }

      // Handle strings
      if (
        typeof a[sortIndex] === 'string' &&
        typeof b[sortIndex] === 'string'
      ) {
        return a[sortIndex].localeCompare(b[sortIndex]); // Use locale-aware string comparison
      }

      // Handle other cases (fallback)
      if (a[sortIndex] < b[sortIndex]) return 1;
      if (a[sortIndex] > b[sortIndex]) return -1;

      // Handle other cases (fallback)
      return 0; // No specific order, keep elements in the same order
    });
    return sortedArray;
  }
  const sortedArray = arr.toSorted((a, b) => {
    // Handle numbers
    if (typeof a[sortIndex] === 'number' && typeof b[sortIndex] === 'number') {
      return b[sortIndex] - a[sortIndex];
    }

    // Handle strings
    if (typeof a[sortIndex] === 'string' && typeof b[sortIndex] === 'string') {
      return a[sortIndex].localeCompare(b[sortIndex]); // Use locale-aware string comparison
    }
    // Handle other cases (fallback)
    if (a[sortIndex] < b[sortIndex]) return 1;
    if (a[sortIndex] > b[sortIndex]) return -1;

    // Handle other cases (fallback)
    return 0; // No specific order, keep elements in the same order
  });

  return sortedArray;
}

function generateXmlRow(rows) {
  let xmlRow = ``;
  // If the value is undefined, do not add anything to the xml
  if (rows.value === undefined) return;

  // handle type abreviations
  let rowType;
  if (rows.type.length > 1) {
    rowType = rows.type;
  } else if (rows.type === 'W') {
    rowType = 'where';
  } else if (rows.type === 'V') {
    rowType = 'value';
  }

  xmlRow += `<${rowType} `;

  // Check that column name is defined
  if (!rows.name) {
    // return error
    // console.error(`No name define in ${rows.type} section.`);
    return;
  }
  xmlRow += `name="${rows.name}" `;

  // Add table if present
  if (rows.table) {
    xmlRow += `table="${rows.table}" `;
  }

  // Add encoding if present
  if (rows.encode === 1) {
    xmlRow += `encode="1" `;
  }

  // Add value
  const { value } = rows;
  if (value === null || (typeof value === 'string' && value.length === 0)) {
    // if the value should be set to null
    xmlRow += `operator="null">`;
  } else if (typeof value === 'string') {
    xmlRow += `>${encodeURIComponent(value)}`;
  } else if (typeof value === 'number' && !Number.isNaN(value)) {
    if (Number.isInteger(value)) {
      xmlRow += `type="int64" >${value}`;
    } else {
      xmlRow += `type="double" >${value}`;
    }
  } else {
    // Other types
    xmlRow += `>${value}`;
  }

  // close the section
  xmlRow += `</${rowType}>`;

  return xmlRow;
}

/* the input of this function has the following format
values : [
  {
    table: <table_to_edit>,
    command: <select, update, insert>,
    rows : [
      {
        type: <where or value>,
        name = <name of the column>,
        value = <value of the column>
      }, ...
    ]
  }, ...
]

As an example:

`<?xml version="1.0"?><document>
    <values table="TJobComposition" command="update">
      <where name="JobCompositionK" type="int64">${compKey}</where>
      <value name="DeletedD">${encodeURIComponent(formatD(new Date()))}</value>
    </values>
  </document>`;

values : [
  {
    table: TJobComposition,
    command: update,
    value : [
      {
        type: where,
        name = JobCompositionK,
        value = compKey
      },
      {
        type: value,
        name = DeletedD,
        value = encodeURIComponent(formatD(new Date())
      },
      ...
    ]
  },
*/
function generateXmlValues(conditions) {
  let xmlBody = ``;
  conditions.values.forEach((values) => {
    // add values header
    // check that values.table is defined
    xmlBody += `<values table="${values.table}" ${
      values.header ? `header="${values.header}"` : ''
    } ${values.debug ? `debug="${values.debug}"` : ''} ${
      values.command ? `command="${values.command}"` : ''
    }>`;

    if (!Array.isArray(values.rows)) {
      // return error
      // console.error(`Error: values.rows is not an array.`);
      return;
    }
    // add the different "value" and "where" sections
    values.rows.forEach((rows) => {
      const xmlRow = generateXmlRow(rows);
      // add the xml rows to the body only if it is defined (can be undefined if the value was undefined)
      if (xmlRow) {
        xmlBody += xmlRow;
      }
    });

    xmlBody += `</values>`;
  });
  return xmlBody;
}

function generateXmlQueries(conditions) {
  let xmlBody = ``;
  conditions.queries.forEach((queries) => {
    // add values header

    xmlBody += '<queries>';
    if (!queries.query) {
      // return better errormessage
      return;
    }

    if (!Array.isArray(queries.query)) {
      // return error
      // console.error(`Error: queries.query is not an array.`);
      return;
    }
    // add the different "value" and "where" sections
    queries.query.forEach((query) => {
      let xmlQuery = '';
      // add values header
      // check that values.table is defined
      xmlBody += `<query query="${query.name}" ${
        query.header ? `header="${query.header}"` : ''
      } ${query.debug ? `debug="${query.debug}"` : ''} ${
        query.command ? `command="${query.command}"` : ''
      }>`;

      if (query.rows && query.rows.length > 0)
        query.rows.forEach((where) => {
          // generate where queries
          const xmlWhere = generateXmlRow(where);
          if (xmlWhere) {
            xmlQuery += xmlWhere;
          }
        });

      // close the query section
      xmlQuery += `</query>`;
      xmlBody += xmlQuery;
    });

    xmlBody += `</queries>`;
  });
  return xmlBody;
}

export function generateXml(conditions) {
  const xmlHeader = '<?xml version="1.0"?><document>';
  const xmlEnd = '</document>';

  let xmlBody = '';

  if (conditions.values) {
    xmlBody = generateXmlValues(conditions);
  } else if (conditions.queries) {
    xmlBody = generateXmlQueries(conditions);
  }

  return xmlHeader + xmlBody + xmlEnd;
}
