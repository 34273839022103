// @copyright (c) 2023-2024 Compular AB
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types';

/**
 * Function for rendering svgString
 *
 * @param onPress function for onclick
 * @param svgBody string. encodeduri for image
 * @param size String. size of image
 * @returns
 */

function RenderSvgFile({ svgBody, onPress, size }) {
  if (!svgBody)
    return (
      <div className="w-full flex items-center justify-center text-center h-full bg-primary-4" />
    );
  if (size === 'table')
    return (
      <img
        onClick={onPress}
        className="object-cover cursor-pointer h-20 hover:grayscale bg-center mx-auto w-full "
        src={`data:image/svg+xml;utf8,${encodeURIComponent(svgBody)}`}
        alt="..."
      />
    );
  if (size === 'modal')
    return (
      <img
        className="h-[320px] w-[320px] m-auto newSmiles bg-center"
        src={`data:image/svg+xml;utf8,${encodeURIComponent(svgBody)}`}
        alt="..."
      />
    );

  return '';
}
RenderSvgFile.propTypes = {
  svgBody: PropTypes.string.isRequired,
  onPress: PropTypes.func,
  size: PropTypes.string.isRequired
};
RenderSvgFile.defaultProps = {
  onPress: null
};
export default RenderSvgFile;
