// @copyright (c) 2022-2024 Compular AB
import {
  ADD_MOLECULES_ERROR,
  ADD_MOLECULES_START,
  ADD_MOLECULES_SUCCESS,
  CANCEL_JOB_ERROR,
  CANCEL_JOB_START,
  CANCEL_JOB_SUCCESS,
  CREATE_JOB_ERROR,
  CREATE_JOB_START,
  CREATE_JOB_SUCCESS,
  DELETE_JOB_ERROR,
  DELETE_JOB_START,
  DELETE_JOB_SUCCESS,
  DELETE_MOLECULES_ERROR,
  DELETE_MOLECULES_START,
  DELETE_MOLECULES_SUCCESS,
  GET_JOB_ERROR,
  GET_JOB_RESULT_ERROR,
  GET_JOB_RESULT_START,
  GET_JOB_RESULT_SUCCESS,
  GET_JOB_START,
  GET_JOB_SUCCESS,
  GET_MOLECULES_ERROR,
  GET_MOLECULES_START,
  GET_MOLECULES_SUCCESS,
  GET_STOICHIOMETRY_ERROR,
  GET_STOICHIOMETRY_START,
  GET_STOICHIOMETRY_SUCCESS,
  LOG_IN_ERROR,
  LOG_IN_START,
  LOG_IN_SUCCESS,
  SAVE_JOB_ERROR,
  SAVE_JOB_START,
  SAVE_JOB_SUCCESS,
  SIGN_OUT,
  UPDATE_NAME_ERROR,
  UPDATE_NAME_START,
  UPDATE_NAME_SUCCESS
} from '../types';

const initialState = {
  isLoading: true,
  error: false,
  errorLog: {},
  isLoggedIn: false,
  user: null,
  jobs: {},
  compositions: {},
  jobLogs: {},
  results: {},
  jobMessages: {},
  selectedJobs: [],
  stoichiometry: {}
};
// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SELECT_ALL':
      return {
        ...state,
        selectedJobs: action.payload.joblist
      };
    case 'SELECT_JOB':
      return {
        ...state,
        selectedJobs: action.payload.joblist
      };
    case LOG_IN_SUCCESS:
      return {
        ...state,
        isLoggedIn: action.payload.loggedIn,
        user: action.payload.user
      };
    case SIGN_OUT:
      return {
        ...state,
        isLoggedIn: false,
        isLoading: false,
        user: null
      };
    case ADD_MOLECULES_SUCCESS: {
      const arrayMol = [...state.moleculeBank];
      if (
        arrayMol.findIndex((s) => s[0] === action.payload.aMolecules[0]) > 0
      ) {
        const index = arrayMol.findIndex(
          (s) => s[0] === action.payload.aMolecules[0]
        );
        arrayMol[index] = action.payload.aMolecules;
      } else {
        arrayMol.push(action.payload.aMolecules);
      }
      return {
        ...state,
        isLoading: action.payload.loading,
        error: false,
        moleculeBank: arrayMol
      };
    }
    case GET_MOLECULES_SUCCESS:
      return {
        ...state,
        isLoading: action.payload.l,
        error: false,
        moleculeBank: action.payload.aMolecules
      };
    case DELETE_JOB_SUCCESS: {
      const newjobs = { ...state.jobs };
      const comps = { ...state.compositions };
      delete comps[action.payload.jobId];
      if (action.payload.aScreening) {
        return {
          ...state,
          isLoading: false,
          error: false,
          jobs: {
            ...state.jobs,
            [action.payload.screeningId]: action.payload.aScreening
          },
          compositions: comps
        };
      }

      if (action.payload.screeningId)
        delete newjobs[action.payload.screeningId];
      return {
        ...state,
        isLoading: false,
        error: false,
        jobs: newjobs,
        compositions: comps
      };
    }
    case CREATE_JOB_SUCCESS: {
      return {
        ...state,
        isLoading: action.payload.loading,
        error: false,
        jobs: {
          ...state.jobs,
          [action.payload.job[0]]: action.payload.job
        }
      };
    }
    case GET_JOB_SUCCESS:
      if (action.payload.allJobs.length === 0)
        return {
          ...state,
          isLoading: action.payload.l,
          error: false
        };
      return {
        ...state,
        isLoading: action.payload.l,
        error: false,
        jobs: action.payload.allJobs.reduce((acc, next) => {
          return { ...acc, [next[0]]: next };
        }, {})
      };
    case GET_JOB_RESULT_SUCCESS: {
      return {
        ...state,
        isLoading: action.payload.loading,
        error: false,
        results: {
          ...state.results,
          [action.payload.id]: action.payload.results
        }
      };
    }
    case GET_STOICHIOMETRY_SUCCESS:
      return {
        ...state,
        stoichiometry: {
          ...state.stoichiometry,
          [action.payload.id]: action.payload.stoch
        }
      };
    case SAVE_JOB_SUCCESS:
      return {
        ...state,
        isLoading: action.payload.loading,
        error: false,
        jobs: {
          ...state.jobs,
          [action.payload.job[0]]: action.payload.job
        }
      };
    case CANCEL_JOB_SUCCESS:
      return {
        ...state,
        isLoading: action.payload.loading,
        error: false,
        jobs: {
          ...state.jobs,
          [action.payload.screeningKey]: action.payload.screening
        }
      };
    case UPDATE_NAME_SUCCESS:
      return {
        ...state,
        isLoading: action.payload.loading,
        error: false,
        user: action.payload.user
      };
    case 'insertMessage':
      return {
        ...state,
        jobMessages: {
          ...state.jobMessages,
          [action.payload.logK]: {
            ...state.jobMessages[action.payload.logK],
            [action.payload.message[0]]: action.payload.message
          }
        }
      };
    case 'insertLog':
      return {
        ...state,
        jobLogs: {
          ...state.jobLogs,
          [action.payload.log[1]]: {
            ...state.jobLogs?.[action.payload.log[1]],
            [action.payload.log[0]]: action.payload.log
          }
        }
      };
    case 'insertComp': {
      return {
        ...state,
        compositions: {
          ...state.compositions,
          [action.payload.jobId]: {
            ...state.compositions?.[action.payload.newComp[1]],
            [action.payload.newComp[0]]: action.payload.newComp
          }
        }
      };
    }
    case 'removeComp': {
      const comps = { ...state.compositions[action.payload.jobid] };

      delete comps[action.payload.compId];

      return {
        ...state,
        compositions: {
          ...state.compositions,
          [action.payload.jobid]: comps
        }
      };
    }
    case 'updateComp': {
      return {
        ...state,
        compositions: {
          ...state.compositions,
          [action.payload.jobid]: {
            ...state.compositions[action.payload.jobid],
            [action.payload.compId]: action.payload.updatedComp
          }
        }
      };
    }
    case DELETE_MOLECULES_SUCCESS: {
      const arrayMol = [...state.moleculeBank];
      const molecules = [...arrayMol].filter(
        (m) => m[0] !== action.payload.moleculeK
      );

      return {
        ...state,
        isLoading: false,
        error: false,
        moleculeBank: molecules
      };
    }
    case ADD_MOLECULES_START:
    case GET_MOLECULES_START:
    case DELETE_JOB_START:
    case CREATE_JOB_START:
    case GET_JOB_START:
    case SAVE_JOB_START:
    case CANCEL_JOB_START:
    case UPDATE_NAME_START:
    case DELETE_MOLECULES_START:
    case LOG_IN_START:
    case GET_STOICHIOMETRY_START:
    case GET_JOB_RESULT_START:
      return {
        ...state,
        isLoading: true
      };
    case ADD_MOLECULES_ERROR:
    case GET_MOLECULES_ERROR:
    case DELETE_JOB_ERROR:
    case GET_STOICHIOMETRY_ERROR:
    case CREATE_JOB_ERROR:
    case GET_JOB_ERROR:
    case GET_JOB_RESULT_ERROR:
    case SAVE_JOB_ERROR:
    case CANCEL_JOB_ERROR:
    case UPDATE_NAME_ERROR:
    case LOG_IN_ERROR:
    case DELETE_MOLECULES_ERROR:
      return {
        ...state,
        isLoading: false,
        error: true,
        errorLog: action?.payload?.errorMessage
      };
    default:
      return state;
  }
};
export default reducer;
