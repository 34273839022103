// @copyright (c) 2023-2024 Compular AB
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-console */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable no-return-await */
/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext } from 'react';
import AWS from 'aws-sdk';
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import Pool from './UserPool';

const cognito = new AWS.CognitoIdentityServiceProvider({
  region: 'eu-north-1'
});
const AccountContext = createContext();

function Account(props) {
  const getSession = async () =>
    await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();
      if (user) {
        user.getSession(async (err, session) => {
          if (err) {
            reject(err);
          } else {
            const attributes = await new Promise((resolve, reject) => {
              user.getUserAttributes((err, attributes) => {
                if (err) {
                  reject(err);
                } else {
                  const results = {};

                  for (const attribute of attributes) {
                    const { Name, Value } = attribute;
                    results[Name] = Value;
                  }
                  resolve(results);
                }
              });
            });

            const accessToken = session.accessToken.jwtToken;

            const mfaEnabled = await new Promise((resolve) => {
              cognito.getUser(
                {
                  AccessToken: accessToken
                },
                (err, data) => {
                  if (err) resolve(false);
                  else
                    resolve(
                      data.UserMFASettingList &&
                        data.UserMFASettingList.includes('SOFTWARE_TOKEN_MFA')
                    );
                }
              );
            });

            const token = session.getIdToken().getJwtToken();

            resolve({
              user,
              accessToken,
              mfaEnabled,
              headers: {
                'x-api-key': attributes['custom:apikey'],
                Authorization: token
              },
              ...session,
              ...attributes
            });
          }
        });
      } else {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject({ error: 'no User' });
      }
    });

  const forceChangePassword = async (Username, Password, newPassword) =>
    await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });
      const authDetails = new AuthenticationDetails({ Username, Password });

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          resolve({ data, loggedIn: true });
        },

        onFailure: (err) => {
          console.error('onFailure:', err);
          reject(err);
        },

        newPasswordRequired: (userAttributes) => {
          const userK = userAttributes?.['custom:userKey'];
          // eslint-disable-next-line no-param-reassign
          delete userAttributes.email_verified;
          // eslint-disable-next-line no-param-reassign
          delete userAttributes.email;
          // eslint-disable-next-line no-param-reassign
          delete userAttributes?.['custom:userKey'];

          user.completeNewPasswordChallenge(
            newPassword,
            {},
            {
              onSuccess: (result) => {
                resolve({
                  result,
                  firstTimeLogin: true,
                  loggedIn: true,
                  userK
                });
                // login
              },
              onFailure: (err) => {
                console.error('onFailure:', err);
                reject(err);
              }
            }
          );
        }
      });
    });

  const authenticateWithMFA = async (Username, Password, UserCode) =>
    await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });
      const authDetails = new AuthenticationDetails({ Username, Password });
      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          resolve({ data, loggedIn: true, firstTimeLogin: true });
        },

        onFailure: (err) => {
          console.error('onFailure:', err);
          reject(err);
        },

        newPasswordRequired: (data) => {
          resolve({
            data,
            isLoggedIn: false,
            cogUser: user,
            authDetails,
            forceChangePassword: true
          });
        },
        mfaSetup: () => {
          user.associateSoftwareToken({
            onFailure: (error) => {
              reject(error);
            },
            associateSecretCode: (code) => {
              resolve({
                loggedIn: false,
                setMFA: true,
                endpoint: user.pool.client.endpoint,
                temporarySession: user.Session,
                qrcode: `otpauth://totp/${Username}?secret=${code}&issuer=Cognito-TOTP-MFA`
              });
            }
          });
        },
        totpRequired: async () => {
          user.sendMFACode(
            UserCode,
            {
              onSuccess: (data) => {
                resolve({ data, loggedIn: true });
              },
              onFailure: (err) => {
                if (err.code === 'CodeMismatchException') {
                  // eslint-disable-next-line prefer-promise-reject-errors
                  reject({ err, errorMessage: 'Invalid code' });
                }
              }
            },
            'SOFTWARE_TOKEN_MFA'
          );
        }
      });
    });

  const authenticate = async (Username, Password) =>
    await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });
      const authDetails = new AuthenticationDetails({ Username, Password });
      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          resolve({ data, loggedIn: true, firstTimeLogin: true });
        },

        onFailure: (err) => {
          reject(err);
        },

        newPasswordRequired: (data) => {
          resolve({
            data,
            isLoggedIn: false,
            cogUser: user,
            authDetails,
            forceChangePassword: true
          });
        },
        mfaSetup: () => {
          user.associateSoftwareToken({
            onFailure: (error) => {
              reject(error);
            },
            associateSecretCode: (code) => {
              resolve({
                loggedIn: false,
                setMFA: true,
                endpoint: user.pool.client.endpoint,
                temporarySession: user.Session,
                qrcode: `otpauth://totp/${Username}?secret=${code}&issuer=Cognito-TOTP-MFA`
              });
            }
          });
        },
        totpRequired: async () => {
          resolve({
            mfatoken: true
          });
        }
      });
    });

  const logout = () => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.signOut();
    }
  };

  return (
    <AccountContext.Provider
      value={{
        authenticate,
        getSession,
        logout,
        forceChangePassword,
        authenticateWithMFA
      }}>
      {props.children}
    </AccountContext.Provider>
  );
}

export { Account, AccountContext };
