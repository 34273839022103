// @copyright (c) 2022-2024 Compular AB
import React from 'react';
import PropTypes from 'prop-types';
import { Chart, registerables } from 'chart.js';
import { Scatter } from 'react-chartjs-2';

Chart.register(...registerables);
/**
 * Scatterchart with lines! LINECHART with values on x&y-axis
 * @param {string} type chart-type
 * @param {any} dataJobs Array with data + name. [{data: [data], name: ''}]
 * @returns
 */
// data = [job1, job2, ...]
// job = [(type + unit), value, error(not yet)]
function LineChart({ dataJobs, type }) {
  const sortedByXData = dataJobs
    .filter((x) => !(x.data[0][1] === null || x.data[1][1] === null))
    .sort((a, b) => a.data[0][1] - b.data[0][1]);
  const data = {
    datasets: [
      {
        data: sortedByXData.map((d) => {
          return {
            x: d.data[0][1],
            y: d.data[1][1],
            tooltip: d.name
          };
        }),
        label: sortedByXData.map(({ name }) => name),
        borderColor: '#F2933D',
        backgroundColor: '#F2933D',
        type: type ? 'line' : 'scatter',
        pointRadius: 10
      }
    ],
    borderWidth: 4
  };
  if (!dataJobs.length > 0) return 'Failed to load image';

  return (
    <div className="h-full w-full flex justify-center items-center">
      <Scatter
        data={data}
        options={{
          scales: {
            x: {
              type: 'linear', // Use linear scale for X-axis
              position: 'bottom',
              title: {
                display: false
                // text: convertHeader(labels[0]) // X-axis label
              }
            },
            y: {
              type: 'linear', // Use linear scale for Y-axis
              position: 'left',
              title: {
                display: false
              }
            }
          },
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              padding: 12,
              callbacks: {
                label: (context) => {
                  // Display only the Y value in the tooltip label
                  return `  x: ${
                    context.dataset.data[context.dataIndex].x
                  }\n y: ${context.dataset.data[context.dataIndex].y}`;
                },
                title: (context) => {
                  // Display only the Y value in the tooltip label
                  return context[0].dataset.label[context[0].dataIndex];
                }
              }
            },
            maintainAspectRatio: false,
            title: {
              display: true,
              font: {
                size: 10
              },
              text: ''
            }
          }
        }}
      />
    </div>
  );
}
LineChart.propTypes = {
  type: PropTypes.bool,
  dataJobs: PropTypes.instanceOf(Array).isRequired
};
LineChart.defaultProps = {
  type: null
};

export default LineChart;
