// @copyright (c) 2022-2024 Compular AB
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import RingLoader from 'react-spinners/RingLoader';
import CloseIcon from '../../icons/CloseIcon';
import DefaultButton from '../DefaultButtons/DefaultButton';
import Table from '../Table/Table';

/**
 * A confirm modal.
 *
 * @param isOpen bool. true if modal is open
 * @param header string. Header for modal
 * @param body string. body for modal
 * @param close func. Close modal.
 * @param process func. process
 * @param cancel func. cancel job.
 * @returns
 */
// Dispatch SimulateJob to change status to processing.
// Creates first log message and estimated time

export default function ConfirmModal({
  isOpen,
  close,
  cancel,
  header,
  body,
  process,
  job,
  jobId
}) {
  const { isLoading } = useSelector((state) => state.reducer);

  const [tab, setTab] = useState('stoch');
  const stoch = useSelector((state) => {
    if (state.reducer.stoichiometry[parseInt(jobId?.[0])]) {
      return state.reducer.stoichiometry[parseInt?.(jobId[0])];
    }
    return null;
  });

  const customModalStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      width: job ? '1000px' : '533px',
      height: '100%',
      maxHeight: job ? '600px' : '225px',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '16px',
      borderRadius: '5px'
    }
  };
  if (isLoading && !stoch)
    return (
      <Modal
        style={customModalStyle}
        isOpen={isOpen}
        ariaHideApp={false}
        onRequestClose={close}
        contentLabel="Create new project">
        <div className="w-full h-full relative">
          <div className="w-full flex items-center justify-center text-center h-full">
            <RingLoader color="#F2933D" size={150} speedMultiplier={0.9} />
          </div>
        </div>
      </Modal>
    );
  return (
    <Modal
      style={customModalStyle}
      isOpen={isOpen}
      ariaHideApp={false}
      onRequestClose={close}
      contentLabel="Create new project">
      <div className="w-full h-full relative">
        <div className="w-full flex flex-col">
          <div className="flex justify-end items-center">
            <CloseIcon
              className="h-5 w-5 cursor-pointer hover:text-NGrey-8"
              onClick={close}
            />
          </div>
          <div id="modal-header">
            {header && !body && (
              <>
                <p className="text-primary-10 font-sans  text-center font-bold text-lg">
                  {header}
                </p>
                {job && (
                  <p className="text-center text-NGrey-8 text-base mt-1.5">
                    To change species, go back to the form
                  </p>
                )}
              </>
            )}
          </div>
          <div id="modal-body">
            {job && (
              <div className="flex flex-col gap-8 px-8">
                <div className="w-full">
                  <div className="mb-2">
                    <p className="text-[19px] text-primary-10 font-bold">
                      Your input data
                    </p>
                  </div>
                  <div className="w-full flex gap-4 mb-2">
                    <div className="w-full">
                      <Table
                        data={
                          job.ensemble === 'NPT'
                            ? Object.keys(job)
                                .filter(
                                  (key) =>
                                    [
                                      'name',
                                      'composition',
                                      'freeText',
                                      'organizationId',
                                      'screening',
                                      'id',
                                      'jobLogs',
                                      'formula'
                                    ].indexOf(key) === -1
                                )
                                .map((acc) => {
                                  return job[acc];
                                })
                            : Object.keys(job)
                                .filter(
                                  (key) =>
                                    [
                                      'name',
                                      'composition',
                                      'freeText',
                                      'screening',
                                      'organizationId',
                                      'id',
                                      'jobLogs',
                                      'formula',
                                      'pressure'
                                    ].indexOf(key) === -1
                                )
                                .map((acc) => {
                                  return job[acc];
                                })
                        }
                        keys={
                          job.ensemble === 'NPT'
                            ? Object.keys(job).filter(
                                (key) =>
                                  [
                                    'name',
                                    'composition',
                                    'freeText',
                                    'screening',
                                    'organizationId',
                                    'id',
                                    'jobLogs',
                                    'formula'
                                  ].indexOf(key) === -1
                              )
                            : Object.keys(job).filter(
                                (key) =>
                                  [
                                    'name',
                                    'composition',
                                    'freeText',
                                    'organizationId',
                                    'screening',
                                    'id',
                                    'jobLogs',
                                    'formula',
                                    'pressure'
                                  ].indexOf(key) === -1
                              )
                        }
                        type="conditions"
                      />
                    </div>
                    {job && job.freeText && (
                      <div className="w-full max-h-[80px] overflow-x-scroll max-w-[420px] disabled-card text-left flex flex-col px-4 py-2 justify-start m-0">
                        <p className="w-full text-NGrey-8">
                          Other information:
                          <span className="text-NGrey-6 ml-1">
                            {job.freeText}
                          </span>
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="mb-4">
                    <p className="text-[19px] text-primary-10 font-bold">
                      Suggested simulation system
                    </p>
                  </div>
                  <div className="flex  mb-4">
                    <p
                      className={`border-b-4 rounded-t px-2 cursor-pointer hover:border-secondary-8 hover:bg-NGrey-1 text-lg font-bold  ${
                        tab === 'stoch'
                          ? 'text-primary-8 border-secondary-8'
                          : 'text-NGrey-6'
                      }`}
                      onMouseDown={() => {
                        setTab('stoch');
                      }}>
                      Stoichiometry
                    </p>
                    <p
                      className={`border-b-4 rounded-t px-2 cursor-pointer hover:border-secondary-8 hover:bg-NGrey-1 text-lg font-bold ${
                        tab !== 'stoch'
                          ? 'text-primary-8 border-secondary-8'
                          : 'text-NGrey-6'
                      }`}
                      onMouseDown={() => {
                        setTab('normal');
                      }}>
                      User input
                    </p>
                    <div className="flex-1 border-b-4" />
                  </div>
                  {stoch && tab === 'stoch' && (
                    <div className="w-full">
                      <Table
                        data={stoch.filter((sto, i) => i !== 0)}
                        keys={stoch.filter((sto, i) => i === 0)[0]}
                        type="stoichiometry"
                        // size="small"
                      />
                    </div>
                  )}
                  {tab === 'normal' && (
                    <div className="max-h-[200px] w-full">
                      <Table data={job.composition} type="compositions" />
                    </div>
                  )}
                </div>
              </div>
            )}
            {body && (
              <div className="h-full mt-8">
                <p className="text-primary-10 font-sans  text-center font-bold text-lg">
                  {header}
                </p>
                <p className="text-NGrey-8 font-sans font-normal text-[13px] text-center">
                  {body}
                </p>
              </div>
            )}
          </div>
        </div>

        <div className="absolute right-0 bottom-0 flex gap-2">
          {process ? (
            <>
              <DefaultButton
                type="outlined-primary"
                label="Close"
                onPressed={close}
              />
              <DefaultButton
                type="contained-secondary"
                label="Submit job"
                onPressed={() => {
                  process();
                }}
              />
            </>
          ) : (
            <>
              <DefaultButton
                type="outlined-primary"
                label="No"
                onPressed={close}
              />
              <DefaultButton
                type="contained-secondary"
                label="Yes, cancel"
                onPressed={cancel}
              />
            </>
          )}
        </div>
      </div>
    </Modal>
  );
}
ConfirmModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  cancel: PropTypes.func,
  header: PropTypes.string.isRequired,
  process: PropTypes.func,
  body: PropTypes.string,
  job: PropTypes.any,
  jobId: PropTypes.any
};
ConfirmModal.defaultProps = {
  body: null,
  process: null,
  cancel: null,
  job: null,
  jobId: null
};
