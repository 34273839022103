// @copyright (c) 2023-2024 Compular AB
import { useGet } from '../../querys';

export const useSendEmailJobStart = (jobId) =>
  useGet(
    `/send_mail?subject=${encodeURIComponent(
      'Job started'
    )}&body=jobId:${jobId.toString()}`
  );

export const useSendEmailJobCancel = (jobId) =>
  useGet(
    `/send_mail?subject=${encodeURIComponent(
      'Job canceled'
    )}&body=jobId:${jobId.toString()}`
  );

export const useSendSupportMail = (body) =>
  useGet(`/send_mail?subject=${encodeURIComponent('Support')}&body=${body}`);
