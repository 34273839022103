// @copyright (c) 2023-2024 Compular AB
import * as React from 'react';

function InfoIcon(props) {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M7.333 4.667h1.334V6H7.333V4.667Zm0 2.666h1.334v4H7.333v-4Zm.667-6A6.67 6.67 0 0 0 1.333 8 6.669 6.669 0 0 0 8 14.667 6.67 6.67 0 0 0 14.667 8 6.67 6.67 0 0 0 8 1.333Zm0 12A5.34 5.34 0 0 1 2.667 8 5.34 5.34 0 0 1 8 2.667 5.34 5.34 0 0 1 13.333 8 5.34 5.34 0 0 1 8 13.333Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default InfoIcon;
