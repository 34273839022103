// @copyright (c) 2023-2024 Compular AB
import * as React from 'react';

function RadarChartIcon(props) {
  return (
    <svg
      viewBox="0 0 24 25"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...props}>
      <path
        fill="currentColor"
        d="m21.722 9.806-9.281-6.75a.745.745 0 0 0-.882 0l-9.28 6.75a.75.75 0 0 0-.273.84l3.546 10.921a.75.75 0 0 0 .713.518h11.473a.748.748 0 0 0 .712-.518l3.546-10.922a.756.756 0 0 0-.274-.839Zm-2.067.467-2.309.75L12.66 7.63V5.184l6.996 5.089Zm-8.733 3.52-2.088 2.866-1.294-3.965 3.382 1.099Zm-2.726-2.269 3.148-2.276v3.3l-3.148-1.024ZM12 14.543l2.2 3.021H9.8l2.2-3.021Zm.659-5.295 3.145 2.276-3.145 1.022V9.248Zm.42 4.545 3.381-1.1-1.294 3.966-2.088-2.866Zm-1.735-8.609v2.447l-4.69 3.392-2.309-.75 6.999-5.089Zm-7.376 6.35 2.027.658 1.885 5.775L6.619 19.7l-2.651-8.165Zm3.698 8.957 1.18-1.617h6.311l1.179 1.617h-8.67Zm9.715-.792-1.26-1.732 1.884-5.775 2.027-.658-2.65 8.165Z"
      />
    </svg>
  );
}

export default RadarChartIcon;
