/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* @copyright (c) 2021-2024 Compular AB */
import React, { useState, useMemo, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingPage from '../LoadingPage/LoadingPage';
import DefaultButton from '../../components/DefaultButtons/DefaultButton';
// import HelpModal from '../../components/Modals/HelpModal';
import Page from '../../components/Page/Page';
import WhiteBackgroundBox from '../../components/WhiteBackgroundBox/WhiteBackgroundBox';
import ChevronDown from '../../icons/ChevronDown';
import ChevronUp from '../../icons/ChevronUp';
import { getJobResults, logIn } from '../../redux/actions/actions';
import GroupedBarChart from '../../components/Charts/GroupedBarChart';
import Card from '../../components/Card/Card';
import ChartModal from '../../components/Modals/ChartModal';
import { convertHeader, openInNewTab } from '../../utils/utils';
import { request } from '../../redux/api/axios';
import { AccountContext } from '../../Accounts';
import GroupedRadarChart from '../../components/Charts/GroupedRadarChart';
import CustomCardChart from '../../components/Card/CustomCardChart';
import DemoRequestModal from '../../components/Modals/DemoRequestModal';
import Table from '../../components/Table/Table';

function CompareResultPage() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
  const [openJob, setOpenJob] = useState(null);
  const [openData, setOpenData] = useState('Concentration [M]');
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [chartModalHeader, setChartModalHeader] = useState();
  const [chartModalLabels, setChartModalLabels] = useState();
  const [chartModalData, setChartModalData] = useState();
  const [tabs, setTabs] = useState({
    concentration: 'bar',
    diffusivity: 'bar',
    partialconductivity: 'bar',
    transferencenumber: 'bar',
    transportnumber: 'bar',
    electricalmobility: 'bar'
  });

  const { getSession } = useContext(AccountContext);
  const help = true;

  const aJobIds = useMemo(() => {
    if (location.state.length > 1) {
      localStorage.setItem('CompareIds', location.state);
      return location.state;
    }
    return null;
  }, [location.state]);
  const { user, isLoading, moleculeBank } = useSelector(
    (state) => state.reducer
  );
  const results = useSelector((state) => {
    if (isLoading) return null;
    let res = {};
    aJobIds.forEach((element) => {
      const result = state.reducer.results?.[element];
      if (result) {
        res = { ...res, [element]: state.reducer.results?.[element] };
      }
    });

    if (Object.values(res).length !== aJobIds.length) return null;
    return res;
  });
  const molecule = useMemo(() => {
    if (moleculeBank && moleculeBank.length > 0)
      return moleculeBank.map((m) => {
        return {
          IUPAC: m[2],
          id: m[0],
          CommonName: m[1],
          alias: m[3],
          density: m[4],
          orgK: m[5],
          abb: m[8]
        };
      });
  }, [moleculeBank]);
  useEffect(() => {
    if (
      results &&
      user &&
      Object.values(results).length === aJobIds.length &&
      !isLoading
    ) {
      setLoading(false);
    } else if (!user) {
      setLoading(true);
      getSession()
        .then((data) => {
          const AUTH_TOKEN = data.accessToken.jwtToken;
          request.defaults.headers.common.Authorization = AUTH_TOKEN;
          dispatch(logIn());
        })
        .catch(() => {
          navigate('/');
        });
    } else if (
      aJobIds &&
      aJobIds.length > 1 &&
      loading &&
      (!results || Object.values(results).length !== aJobIds.length)
    ) {
      for (let index = 0; index < aJobIds.length; index++) {
        const element = aJobIds[index];
        dispatch(getJobResults(user[1], parseInt(element)));
      }
    }
  }, [results, user, aJobIds]);
  const rawcomps = useSelector((state) => {
    if (aJobIds && aJobIds.length > 1) {
      let obj = {};
      for (let index = 0; index < aJobIds.length; index++) {
        const jobId = aJobIds[index];
        obj = { ...obj, [jobId]: state.reducer.compositions[jobId] };
      }
      return obj;
    }
    return null;
  });

  const screenings = useSelector((state) => {
    if (aJobIds && aJobIds.length > 1 && user) {
      return Object.values(state.reducer.jobs);
    }
    return null;
  });
  const jobs = useMemo(() => {
    if (
      screenings &&
      screenings.length > 0 &&
      Array.isArray(screenings.findLast((ele) => ele).findLast((ele) => ele))
    ) {
      const allJobs = screenings
        .map((job) => {
          return job.findLast((ele) => ele);
        })
        .flat()
        .filter((job) => aJobIds.indexOf(job[0]) >= 0);
      return allJobs;
    }
    return null;
  }, [screenings, rawcomps, results]);

  const selectedJobs = useMemo(() => {
    if (
      user &&
      jobs &&
      jobs.length > 1 &&
      !jobs.includes(undefined) &&
      rawcomps &&
      !Object.values(rawcomps).includes(undefined) &&
      Object.keys(rawcomps).length > 0
    ) {
      let job = [];
      for (let index = 0; index < jobs.length; index++) {
        if (!jobs?.[index]) {
          break;
        }
        const element = jobs[index];

        const parents = Object.values(rawcomps[element[0]]).filter((comp) => {
          return comp[7] === null;
        });
        const children = Object.values(rawcomps[element[0]])
          .filter((comp) => comp[7])
          .map((comp) => {
            if (comp?.[9])
              return {
                id: comp[0],
                name: comp[3],
                value: comp[5],
                unit: comp[6],
                molecule: molecule.find((m) => m.id === comp[9]),
                parent: comp[7]
              };
            return {
              id: comp[0],
              name: comp[3],
              value: comp[5],
              unit: comp[6],
              parent: comp[7]
            };
          });
        const json = parents.map((composition) => {
          if (composition?.[9])
            return {
              id: composition[0],
              name: composition[3],
              value: composition[5],
              unit: composition[6],
              molecule: molecule.find((m) => m.id === composition[9]),
              children: children.filter(
                (child) => child.parent === composition[0]
              )
            };
          return {
            id: composition[0],
            name: composition[3],
            value: composition[5],
            unit: composition[6],
            children: children.filter(
              (child) => child.parent === composition[0]
            )
          };
        });
        const obj = {
          composition: json,
          id: element[0],
          organizationId: element?.[1] ? element?.[1] : null,
          name: element[5] || '',
          ensemble: element[6],
          temperature: {
            value: element[7] || 0,
            unit: element[8]
          },
          result: results?.[element[0]],
          pressure:
            element[6] !== 'NPT'
              ? null
              : {
                  value: element[11] || '',
                  unit: element[12]
                },
          formula: '',
          freeText: element[13] || ''
        };
        job = [...job, obj];
      }
      if (job.length > 0) return job;
    }
  }, [rawcomps, jobs, user]);
  useEffect(() => {
    if (jobs && user && !jobs.includes(undefined))
      if (!openJob)
        if (jobs && jobs.length > 1)
          jobs.map((job, i) => {
            if (i === 0) return setOpenJob({ ...openJob, [job[0]]: true });
            return setOpenJob({ ...openJob, [job[0]]: false });
          });
  }, [jobs, location.state]);

  function openMainChartModal(chartindex) {
    setChartModalLabels(selectedJobs.map(({ name }) => name));
    setChartModalHeader(
      `${selectedJobs[0].result.mainResult[chartindex + 1][0]} ${
        selectedJobs[0].result.mainResult[chartindex + 1][1]
      }`
    );
    setChartModalData(
      selectedJobs.map(({ result }) => {
        return {
          data: result.mainResult[chartindex + 1][2],
          error: result.mainResult[chartindex + 1][3]
        };
      })
    );

    setIsModalOpen(true);
  }

  if (
    !jobs ||
    jobs.includes(undefined) ||
    Object.values(rawcomps).includes(undefined) ||
    loading ||
    isLoading ||
    !selectedJobs
  )
    return <LoadingPage />;

  return (
    <Page help={help} onHelp={() => setIsHelpModalOpen(true)}>
      <div className="w-full px-12 py-8">
        {/* <HelpModal
          isOpen={isHelpModalOpen}
          close={() => setIsHelpModalOpen(false)}
        /> */}
        <DemoRequestModal
          isOpen={isHelpModalOpen}
          close={() => setIsHelpModalOpen(false)}
        />
        {isModalOpen && (
          <ChartModal
            isOpen={isModalOpen}
            close={() => setIsModalOpen(false)}
            jobData={chartModalData}
            headers={chartModalHeader}
            labels={chartModalLabels}
          />
        )}

        <WhiteBackgroundBox>
          <div className="pb-10 w-full  relative">
            <div
              className={`sticky top-0 w-full border-NGrey-3 border-b pt-4 bg-white text-center ${
                isModalOpen || isHelpModalOpen ? '' : 'z-10'
              }`}>
              <div className="flex flex-start px-8 pb-4 items-center">
                <div className="basis-1/3">
                  <DefaultButton
                    label="Go back to jobs"
                    type="text-secondary"
                    onPressed={() => {
                      navigate('/jobs');
                    }}
                    icon="arrowLeft"
                    iconPosition="left"
                  />
                </div>
                <p className="text-[23px] font-semibold text-primary-10 basis-1/3">
                  Results
                </p>
                <div className="basis-1/3 ml-auto flex justify-end align-right" />
              </div>
            </div>
            <div className="px-6 pt-6">
              <div className="flex font-sans font-bold text-2xl text-NGrey-9 gap-2 items-center pb-4">
                <p>System specifications</p>
                <div>
                  <DefaultButton
                    label=""
                    type="text-primary"
                    onPressed={() => {
                      openInNewTab(
                        'https://compulartech.com/docs/results/#system-specifications'
                      );
                    }}
                    icon="help"
                    iconPosition="left"
                  />
                </div>
              </div>
              {selectedJobs &&
                selectedJobs.length > 1 &&
                selectedJobs.map((job) => (
                  <div className="border-b" key={job.name}>
                    <div
                      className="flex items-center hover:bg-primary-1 rounded-sm  py-4 px-2 justify-between cursor-pointer text-NGrey-6"
                      onClick={() => {
                        setOpenJob({
                          ...openJob,
                          [job.id]: !openJob[job.id]
                        });
                      }}>
                      <p className="text-xl font-bold">{job.name}</p>
                      {openJob[job.id] ? (
                        <div>
                          <ChevronUp className="h-5 w-5" />
                        </div>
                      ) : (
                        <div>
                          <ChevronDown className="h-5 w-5" />
                        </div>
                      )}
                    </div>
                    {openJob[job.id] && (
                      <div className="flex w-full gap-3 my-6">
                        <div className="w-1/2">
                          <Table data={job.composition} type="compositions" />
                        </div>
                        <div className="w-1/2 flex gap-5 flex-col">
                          <Table
                            data={
                              job.ensemble === 'NPT'
                                ? Object.keys(job)
                                    .filter(
                                      (key) =>
                                        [
                                          'name',
                                          'composition',
                                          'freeText',
                                          'organizationId',
                                          'id',
                                          'jobLogs',
                                          'formula',
                                          'result'
                                        ].indexOf(key) === -1
                                    )
                                    .map((acc) => {
                                      return job[acc];
                                    })
                                : Object.keys(job)
                                    .filter(
                                      (key) =>
                                        [
                                          'name',
                                          'composition',
                                          'freeText',
                                          'organizationId',
                                          'id',
                                          'jobLogs',
                                          'formula',
                                          'pressure',
                                          'result'
                                        ].indexOf(key) === -1
                                    )
                                    .map((acc) => {
                                      return job[acc];
                                    })
                            }
                            keys={
                              job.ensemble === 'NPT'
                                ? Object.keys(job).filter(
                                    (key) =>
                                      [
                                        'name',
                                        'composition',
                                        'freeText',
                                        'organizationId',
                                        'id',
                                        'jobLogs',
                                        'formula',
                                        'result'
                                      ].indexOf(key) === -1
                                  )
                                : Object.keys(job).filter(
                                    (key) =>
                                      [
                                        'name',
                                        'composition',
                                        'freeText',
                                        'organizationId',
                                        'id',
                                        'jobLogs',
                                        'formula',
                                        'pressure',
                                        'result'
                                      ].indexOf(key) === -1
                                  )
                            }
                            type="conditions"
                          />
                          {job && job.freeText && (
                            <div className="w-full min-h-fit disabled-card text-left flex flex-col p-5 justify-start m-0">
                              <p className="w-full text-NGrey-8">
                                Other information:
                              </p>
                              <p className="text-NGrey-6">{job.freeText}</p>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              <div className="pt-6">
                <p className="font-bold text-2xl">Main results</p>

                <div className="my-5">
                  <Table
                    type="compareTable"
                    keys={selectedJobs[0].result.mainResult.map((sys, i) => {
                      if (i === 0) return 'System name';
                      return `${sys[0]} (${sys[1]})`;
                    })}
                    data={selectedJobs.map((job) =>
                      job.result.mainResult.map((res, i) => {
                        if (i === 0)
                          return {
                            dataStr: job.name,
                            unit: '',
                            error: null
                          };
                        return {
                          dataStr:
                            Math.round((res[2] + Number.EPSILON) * 1000) / 1000,
                          error:
                            Math.round((res[3] + Number.EPSILON) * 1000) / 1000,
                          unit: ''
                        };
                      })
                    )}
                  />
                </div>
                <div className="w-full min-h-[320px] flex-row justify-evenly flex gap-6 flex-wrap my-12">
                  {selectedJobs &&
                    selectedJobs[0].result.mainResult &&
                    selectedJobs[0].result.mainResult
                      .filter((res, i) => i !== 0)
                      .map((mainres, index) => {
                        return (
                          <React.Fragment key={`${mainres[0]}-mainCard`}>
                            <Card
                              enlarge={() => {
                                openMainChartModal(index);
                              }}
                              flipcard={false}
                              type="barchart"
                              header={mainres[0]}
                              headers={`${mainres[0]} ${mainres[1]}`}
                              labels={selectedJobs.map(({ name }) => name)}
                              jobData={selectedJobs.map(({ result }) => {
                                return {
                                  data: result.mainResult.filter(
                                    (res, i) => i !== 0
                                  )[index]?.[2],
                                  error: result.mainResult.filter(
                                    (res, i) => i !== 0
                                  )[index]?.[3]
                                };
                              })}
                            />
                          </React.Fragment>
                        );
                      })}
                </div>
                <div className="px-8 mx-20">
                  <CustomCardChart jobsData={selectedJobs} />
                </div>
                <div className=" mt-12 pb-6">
                  <div className="flex gap-2 items-center">
                    <p className="font-bold text-2xl my-6">Molecular species</p>
                    <div>
                      <DefaultButton
                        label=""
                        type="text-primary"
                        onPressed={() => {
                          openInNewTab(
                            'https://compulartech.com/docs/results/#molecular-species'
                          );
                        }}
                        icon="help"
                        iconPosition="left"
                      />
                    </div>
                  </div>
                  <div className="border-b">
                    <div
                      className="flex items-center hover:bg-primary-1 rounded-sm   py-4 px-2 justify-between cursor-pointer text-NGrey-6"
                      onClick={() => {
                        if (
                          openData ===
                          selectedJobs[0].result.molecularSystem[0][2]
                        ) {
                          return setOpenData(null);
                        }
                        setOpenData(
                          selectedJobs[0].result.molecularSystem[0][2]
                        );
                      }}>
                      <p className="text-xl font-bold">
                        {
                          selectedJobs[0].result.molecularSystem[0][2].split(
                            '['
                          )[0]
                        }
                      </p>
                      {openData ===
                      selectedJobs[0].result.molecularSystem[0][2] ? (
                        <div>
                          <ChevronUp className="h-5 w-5" />
                        </div>
                      ) : (
                        <div>
                          <ChevronDown className="h-5 w-5" />
                        </div>
                      )}
                    </div>
                    {openData ===
                      selectedJobs[0].result.molecularSystem[0][2] && (
                      <div className="w-full my-6">
                        <div className="w-full px-2">
                          <p className="py-2 text-NGrey-6 font-semibold text-lg">{`Unit: ${convertHeader(
                            selectedJobs[0].result.molecularSystem[0][2]
                              .split('[')
                              .findLast((ele) => ele)
                              .split(']')[0]
                          )}`}</p>
                          <Table
                            type="compareMolecular"
                            keys={selectedJobs.map(({ result }) => {
                              return result.molecularSystem.map(
                                (molRes, ind) => {
                                  if (ind === 0) return 'System name';
                                  return molRes[0];
                                }
                              );
                            })}
                            data={selectedJobs.map((job) => {
                              return job.result.molecularSystem.map(
                                (molRow, i) => {
                                  if (i === 0) return { systemName: job.name };
                                  return { [molRow[0]]: molRow[2] };
                                }
                              );
                            })}
                          />
                        </div>
                        <div className="flex mt-4 gap-2 ml-2">
                          <DefaultButton
                            tippyContent="Bar Chart"
                            onPressed={() => {
                              setTabs({ ...tabs, concentration: 'bar' });
                            }}
                            type={
                              tabs.concentration === 'bar'
                                ? 'text-grey'
                                : 'text-primary'
                            }
                            label=""
                            iconPosition="left"
                            icon="BarChart"
                          />
                          <DefaultButton
                            label=""
                            tippyContent="Radar Chart"
                            onPressed={() => {
                              setTabs({ ...tabs, concentration: 'radar' });
                            }}
                            type={
                              tabs.concentration === 'radar'
                                ? 'text-grey'
                                : 'text-primary'
                            }
                            iconPosition="left"
                            icon="RadarChart"
                          />
                        </div>
                        <div className="flex justify-center my-12">
                          {tabs.concentration === 'radar' ? (
                            <GroupedRadarChart
                              jobs={selectedJobs}
                              aLabels={selectedJobs[0].result.molecularSystem}
                              sHeader={
                                selectedJobs[0].result.molecularSystem[0][2]
                              }
                            />
                          ) : (
                            <GroupedBarChart
                              jobs={selectedJobs}
                              aLabels={selectedJobs[0].result.molecularSystem}
                              sHeader={
                                selectedJobs[0].result.molecularSystem[0][2]
                              }
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="border-b">
                    <div
                      className="flex items-center hover:bg-primary-1 rounded-sm   py-4 px-2 justify-between cursor-pointer text-NGrey-6"
                      onClick={() => {
                        if (
                          openData ===
                          selectedJobs[0].result.molecularSystem[0][4]
                        ) {
                          return setOpenData(null);
                        }
                        setOpenData(
                          selectedJobs[0].result.molecularSystem[0][4]
                        );
                      }}>
                      <p className="text-xl font-bold">
                        {
                          selectedJobs[0].result.molecularSystem[0][4].split(
                            '['
                          )[0]
                        }
                      </p>
                      {openData ===
                      selectedJobs[0].result.molecularSystem[0][4] ? (
                        <div>
                          <ChevronUp className="h-5 w-5" />
                        </div>
                      ) : (
                        <div>
                          <ChevronDown className="h-5 w-5" />
                        </div>
                      )}
                    </div>
                    {openData ===
                      selectedJobs[0].result.molecularSystem[0][4] && (
                      <div className="w-full my-6">
                        <div className="w-full px-2">
                          <p className="py-2 text-NGrey-6 font-semibold text-lg">{`Unit: ${convertHeader(
                            selectedJobs[0].result.molecularSystem[0][4]
                              .split('[')
                              .findLast((ele) => ele)
                              .split(']')[0]
                          )}`}</p>
                          <Table
                            type="compareMolecular"
                            keys={selectedJobs.map(({ result }) => {
                              return result.molecularSystem.map(
                                (molRes, ind) => {
                                  if (ind === 0) return 'System name';
                                  return molRes[0];
                                }
                              );
                            })}
                            data={selectedJobs.map((job) => {
                              return job.result.molecularSystem.map(
                                (molRow, i) => {
                                  if (i === 0) return { systemName: job.name };
                                  return { [molRow[0]]: molRow[4] };
                                }
                              );
                            })}
                          />
                        </div>
                        <div className="flex mt-4 gap-2 ml-2">
                          <DefaultButton
                            tippyContent="Bar Chart"
                            onPressed={() => {
                              setTabs({ ...tabs, diffusivity: 'bar' });
                            }}
                            type={
                              tabs.diffusivity === 'bar'
                                ? 'text-grey'
                                : 'text-primary'
                            }
                            label=""
                            iconPosition="left"
                            icon="BarChart"
                          />
                          <DefaultButton
                            tippyContent="Radar Chart"
                            onPressed={() => {
                              setTabs({ ...tabs, diffusivity: 'radar' });
                            }}
                            type={
                              tabs.diffusivity === 'radar'
                                ? 'text-grey'
                                : 'text-primary'
                            }
                            label=""
                            iconPosition="left"
                            icon="RadarChart"
                          />
                        </div>
                        <div className="flex justify-center my-12">
                          {tabs.diffusivity === 'radar' ? (
                            <GroupedRadarChart
                              jobs={selectedJobs}
                              aLabels={selectedJobs[0].result.molecularSystem}
                              sHeader={
                                selectedJobs[0].result.molecularSystem[0][4]
                              }
                            />
                          ) : (
                            <GroupedBarChart
                              jobs={selectedJobs}
                              aLabels={selectedJobs[0].result.molecularSystem}
                              sHeader={
                                selectedJobs[0].result.molecularSystem[0][4]
                              }
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="border-b">
                    <div
                      className="flex items-center hover:bg-primary-1 rounded-sm  py-4 px-2 justify-between cursor-pointer text-NGrey-6"
                      onClick={() => {
                        if (
                          openData ===
                          selectedJobs[0].result.molecularSystem[0][7]
                        ) {
                          return setOpenData(null);
                        }
                        setOpenData(
                          selectedJobs[0].result.molecularSystem[0][7]
                        );
                      }}>
                      <p className="text-xl font-bold">
                        {
                          selectedJobs[0].result.molecularSystem[0][7].split(
                            '['
                          )[0]
                        }
                      </p>
                      {openData ===
                      selectedJobs[0].result.molecularSystem[0][7] ? (
                        <div>
                          <ChevronUp className="h-5 w-5" />
                        </div>
                      ) : (
                        <div>
                          <ChevronDown className="h-5 w-5" />
                        </div>
                      )}
                    </div>
                    {openData ===
                      selectedJobs[0].result.molecularSystem[0][7] && (
                      <div className="w-full my-6">
                        <div className="w-full px-2">
                          <p className="py-2 text-NGrey-6 font-semibold text-lg">{`Unit: ${convertHeader(
                            selectedJobs[0].result.molecularSystem[0][7]
                              .split('[')
                              .findLast((ele) => ele)
                              .split(']')[0]
                          )}`}</p>
                          <Table
                            type="compareMolecular"
                            keys={selectedJobs.map(({ result }) => {
                              return result.molecularSystem.map(
                                (molRes, ind) => {
                                  if (ind === 0) return 'System name';
                                  return molRes[0];
                                }
                              );
                            })}
                            data={selectedJobs.map((job) => {
                              return job.result.molecularSystem.map(
                                (molRow, i) => {
                                  if (i === 0) return { systemName: job.name };
                                  return { [molRow[0]]: molRow[7] };
                                }
                              );
                            })}
                          />
                        </div>
                        <div className="flex mt-4 gap-2 ml-2">
                          <DefaultButton
                            tippyContent="Bar Chart"
                            onPressed={() => {
                              setTabs({ ...tabs, partialconductivity: 'bar' });
                            }}
                            type={
                              tabs.partialconductivity === 'bar'
                                ? 'text-grey'
                                : 'text-primary'
                            }
                            label=""
                            iconPosition="left"
                            icon="BarChart"
                          />
                          <DefaultButton
                            tippyContent="Radar Chart"
                            onPressed={() => {
                              setTabs({
                                ...tabs,
                                partialconductivity: 'radar'
                              });
                            }}
                            type={
                              tabs.partialconductivity === 'radar'
                                ? 'text-grey'
                                : 'text-primary'
                            }
                            label=""
                            iconPosition="left"
                            icon="RadarChart"
                          />
                        </div>
                        <div className="flex justify-center my-12 w-full">
                          {tabs.partialconductivity === 'radar' ? (
                            <GroupedRadarChart
                              jobs={selectedJobs}
                              aLabels={selectedJobs[0].result.molecularSystem}
                              sHeader={
                                selectedJobs[0].result.molecularSystem[0][7]
                              }
                            />
                          ) : (
                            <GroupedBarChart
                              jobs={selectedJobs}
                              aLabels={selectedJobs[0].result.molecularSystem}
                              sHeader={
                                selectedJobs[0].result.molecularSystem[0][7]
                              }
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="border-b">
                    <div
                      className="flex items-center hover:bg-primary-1 rounded-sm  py-4 px-2 justify-between cursor-pointer text-NGrey-6"
                      onClick={() => {
                        if (
                          openData ===
                          selectedJobs[0].result.molecularSystem[0][12]
                        ) {
                          return setOpenData(null);
                        }
                        setOpenData(
                          selectedJobs[0].result.molecularSystem[0][12]
                        );
                      }}>
                      <p className="text-xl font-bold">
                        {
                          selectedJobs[0].result.molecularSystem[0][12].split(
                            '['
                          )[0]
                        }
                      </p>
                      {openData ===
                      selectedJobs[0].result.molecularSystem[0][12] ? (
                        <div>
                          <ChevronUp className="h-5 w-5" />
                        </div>
                      ) : (
                        <div>
                          <ChevronDown className="h-5 w-5" />
                        </div>
                      )}
                    </div>
                    {openData ===
                      selectedJobs[0].result.molecularSystem[0][12] && (
                      <div className="w-full my-6">
                        <div className="w-full px-2">
                          <p className="py-2 text-NGrey-6 font-semibold text-lg">{`Unit: ${convertHeader(
                            selectedJobs[0].result.molecularSystem[0][12]
                              .split('[')
                              .findLast((ele) => ele)
                              .split(']')[0]
                          )}`}</p>
                          <Table
                            type="compareMolecular"
                            keys={selectedJobs.map(({ result }) => {
                              return result.molecularSystem.map(
                                (molRes, ind) => {
                                  if (ind === 0) return 'System name';
                                  return molRes[0];
                                }
                              );
                            })}
                            data={selectedJobs.map((job) => {
                              return job.result.molecularSystem.map(
                                (molRow, i) => {
                                  if (i === 0) return { systemName: job.name };
                                  return { [molRow[0]]: molRow[12] };
                                }
                              );
                            })}
                          />
                        </div>
                        <div className="flex mt-4 gap-2 ml-2">
                          <DefaultButton
                            tippyContent="Bar Chart"
                            onPressed={() => {
                              setTabs({ ...tabs, transferencenumber: 'bar' });
                            }}
                            type={
                              tabs.transferencenumber === 'bar'
                                ? 'text-grey'
                                : 'text-primary'
                            }
                            label=""
                            iconPosition="left"
                            icon="BarChart"
                          />
                          <DefaultButton
                            tippyContent="Radar Chart"
                            onPressed={() => {
                              setTabs({ ...tabs, transferencenumber: 'radar' });
                            }}
                            type={
                              tabs.transferencenumber === 'radar'
                                ? 'text-grey'
                                : 'text-primary'
                            }
                            label=""
                            iconPosition="left"
                            icon="RadarChart"
                          />
                        </div>
                        <div className="flex justify-center my-12">
                          {tabs.transferencenumber === 'radar' ? (
                            <GroupedRadarChart
                              jobs={selectedJobs}
                              aLabels={selectedJobs[0].result.molecularSystem}
                              sHeader={
                                selectedJobs[0].result.molecularSystem[0][12]
                              }
                            />
                          ) : (
                            <GroupedBarChart
                              jobs={selectedJobs}
                              aLabels={selectedJobs[0].result.molecularSystem}
                              sHeader={
                                selectedJobs[0].result.molecularSystem[0][12]
                              }
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="border-b">
                    <div
                      className="flex items-center hover:bg-primary-1 rounded-sm  py-4 px-2 justify-between cursor-pointer text-NGrey-6"
                      onClick={() => {
                        if (
                          openData ===
                          selectedJobs[0].result.molecularSystem[0][10]
                        ) {
                          return setOpenData(null);
                        }
                        setOpenData(
                          selectedJobs[0].result.molecularSystem[0][10]
                        );
                      }}>
                      <p className="text-xl font-bold">
                        {
                          selectedJobs[0].result.molecularSystem[0][10].split(
                            '['
                          )[0]
                        }
                      </p>
                      {openData ===
                      selectedJobs[0].result.molecularSystem[0][10] ? (
                        <div>
                          <ChevronUp className="h-5 w-5" />
                        </div>
                      ) : (
                        <div>
                          <ChevronDown className="h-5 w-5" />
                        </div>
                      )}
                    </div>
                    {openData ===
                      selectedJobs[0].result.molecularSystem[0][10] && (
                      <div className="w-full my-6">
                        <div className="w-full px-2">
                          <p className="py-2 text-NGrey-6 font-semibold text-lg">{`Unit: ${convertHeader(
                            selectedJobs[0].result.molecularSystem[0][10]
                              .split('[')
                              .findLast((ele) => ele)
                              .split(']')[0]
                          )}`}</p>
                          <Table
                            type="compareMolecular"
                            keys={selectedJobs.map(({ result }) => {
                              return result.molecularSystem.map(
                                (molRes, ind) => {
                                  if (ind === 0) return 'System name';
                                  return molRes[0];
                                }
                              );
                            })}
                            data={selectedJobs.map((job) => {
                              return job.result.molecularSystem.map(
                                (molRow, i) => {
                                  if (i === 0) return { systemName: job.name };
                                  return { [molRow[0]]: molRow[10] };
                                }
                              );
                            })}
                          />
                        </div>
                        <div className="flex mt-4 gap-2 ml-2">
                          <DefaultButton
                            tippyContent="Bar Chart"
                            onPressed={() => {
                              setTabs({ ...tabs, transportnumber: 'bar' });
                            }}
                            type={
                              tabs.transportnumber === 'bar'
                                ? 'text-grey'
                                : 'text-primary'
                            }
                            label=""
                            iconPosition="left"
                            icon="BarChart"
                          />
                          <DefaultButton
                            tippyContent="Radar Chart"
                            onPressed={() => {
                              setTabs({ ...tabs, transportnumber: 'radar' });
                            }}
                            type={
                              tabs.transportnumber === 'radar'
                                ? 'text-grey'
                                : 'text-primary'
                            }
                            label=""
                            iconPosition="left"
                            icon="RadarChart"
                          />
                        </div>
                        <div className="flex justify-center my-12">
                          {tabs.transportnumber === 'radar' ? (
                            <GroupedRadarChart
                              jobs={selectedJobs}
                              aLabels={selectedJobs[0].result.molecularSystem}
                              sHeader={
                                selectedJobs[0].result.molecularSystem[0][10]
                              }
                            />
                          ) : (
                            <GroupedBarChart
                              jobs={selectedJobs}
                              aLabels={selectedJobs[0].result.molecularSystem}
                              sHeader={
                                selectedJobs[0].result.molecularSystem[0][10]
                              }
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="border-b">
                    <div
                      className="flex items-center hover:bg-primary-1 rounded-sm  py-4 px-2 justify-between cursor-pointer text-NGrey-6"
                      onClick={() => {
                        if (
                          openData ===
                          selectedJobs[0].result.molecularSystem[0][14]
                        ) {
                          return setOpenData(null);
                        }
                        setOpenData(
                          selectedJobs[0].result.molecularSystem[0][14]
                        );
                      }}>
                      <p className="text-xl font-bold">
                        {
                          selectedJobs[0].result.molecularSystem[0][14].split(
                            '['
                          )[0]
                        }
                      </p>
                      {openData ===
                      selectedJobs[0].result.molecularSystem[0][14] ? (
                        <div>
                          <ChevronUp className="h-5 w-5" />
                        </div>
                      ) : (
                        <div>
                          <ChevronDown className="h-5 w-5" />
                        </div>
                      )}
                    </div>
                    {openData ===
                      selectedJobs[0].result.molecularSystem[0][14] && (
                      <div className="w-full my-6">
                        <div className="w-full px-2">
                          <p className="py-2 text-NGrey-6 font-semibold text-lg">{`Unit: ${convertHeader(
                            selectedJobs[0].result.molecularSystem[0][14]
                              .split('[')
                              .findLast((ele) => ele)
                              .split(']')[0]
                          )}`}</p>
                          <Table
                            type="compareMolecular"
                            keys={selectedJobs.map(({ result }) => {
                              return result.molecularSystem.map(
                                (molRes, ind) => {
                                  if (ind === 0) return 'System name';
                                  return molRes[0];
                                }
                              );
                            })}
                            data={selectedJobs.map((job) => {
                              return job.result.molecularSystem.map(
                                (molRow, i) => {
                                  if (i === 0) return { systemName: job.name };
                                  return { [molRow[0]]: molRow[14] };
                                }
                              );
                            })}
                          />
                        </div>
                        <div className="flex mt-4 gap-2 ml-2">
                          <DefaultButton
                            tippyContent="Bar Chart"
                            onPressed={() => {
                              setTabs({ ...tabs, electricalmobility: 'bar' });
                            }}
                            type={
                              tabs.electricalmobility === 'bar'
                                ? 'text-grey'
                                : 'text-primary'
                            }
                            label=""
                            iconPosition="left"
                            icon="BarChart"
                          />
                          <DefaultButton
                            tippyContent="Radar Chart"
                            onPressed={() => {
                              setTabs({ ...tabs, electricalmobility: 'radar' });
                            }}
                            type={
                              tabs.electricalmobility === 'radar'
                                ? 'text-grey'
                                : 'text-primary'
                            }
                            label=""
                            iconPosition="left"
                            icon="RadarChart"
                          />
                        </div>
                        <div className="flex justify-center my-12">
                          {tabs.electricalmobility === 'radar' ? (
                            <GroupedRadarChart
                              jobs={selectedJobs}
                              aLabels={selectedJobs[0].result.molecularSystem}
                              sHeader={
                                selectedJobs[0].result.molecularSystem[0][14]
                              }
                            />
                          ) : (
                            <GroupedBarChart
                              jobs={selectedJobs}
                              aLabels={selectedJobs[0].result.molecularSystem}
                              sHeader={
                                selectedJobs[0].result.molecularSystem[0][14]
                              }
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </WhiteBackgroundBox>
      </div>
    </Page>
  );
}

export default CompareResultPage;
