// @copyright (c) 2022-2024 Compular AB
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import CloseIcon from '../../icons/CloseIcon';
import DefaultButton from '../DefaultButtons/DefaultButton';
import TextArea from '../TextArea/TextArea';
import InputField from '../InputFields/InputField';
import { sendMail } from '../../redux/actions/actions';

/**
 * A modal that allows the user to contact us.
 *
 * @param isOpen boolean
 * @param close func
 * @returns
 */
const customModalStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    minWidth: '330px',
    height: '100%',
    maxHeight: '500px',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '16px',
    borderRadius: '5px',
    zIndex: 20
  }
};
export default function HelpModal({ isOpen, close }) {
  const { user, isLoggedIn } = useSelector((state) => state.reducer);
  const [companyName, setCompanyName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (user && isLoggedIn) setCompanyName(user[5]);
  }, [user]);
  return (
    <Modal
      style={customModalStyle}
      isOpen={isOpen}
      ariaHideApp={false}
      onRequestClose={close}
      contentLabel="Create new project">
      <div className="w-full h-full relative">
        <div className="w-full flex flex-col gap-2 px-2">
          <div className="flex justify-end items-center">
            <CloseIcon
              className="h-5 w-5 cursor-pointer hover:text-NGrey-8"
              onClick={close}
            />
          </div>
          <div id="modal-header">
            <p className="text-primary-10 font-sans font-bold text-lg text-center">
              Contact us
            </p>
            <p className="font-sans text-sm text-NGrey-8 text-center">
              Estimated answer time: 1 day
            </p>
          </div>
          <div className="mt-0 w-full">
            <InputField
              label="Company name"
              inputValue={companyName}
              placeHolder=""
              setInputValue={(e) => {
                setCompanyName(e.target.value);
              }}
              name="companyName"
              isRequired={false}
            />
          </div>

          <div className="mt-2">
            <InputField
              label="Email"
              inputValue={email}
              setInputValue={(e) => {
                setEmail(e.target.value);
              }}
              name="mail"
              isRequired={false}
            />
          </div>

          <div id="modal-body" className="items-center w-full h-36 mt-2">
            <TextArea
              label="What can we help you with?"
              isResizable={false}
              inputValue={message}
              setInputValue={(e) => setMessage(e.target.value)}
            />
          </div>
        </div>
        <div className="absolute bottom-0 w-full justify-center flex gap-2 px-2">
          <div className="flex-1">
            <DefaultButton
              type="outlined-primary"
              label="Cancel form"
              onPressed={close}
            />
          </div>
          <div className="">
            <DefaultButton
              type="contained-secondary"
              label="Send form"
              onPressed={() => {
                const mailBody = encodeURIComponent(
                  `Company: ${companyName}\n Email: ${email}\n Message:\n${message}`
                );
                sendMail(mailBody);
                close();
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}
HelpModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired
};
