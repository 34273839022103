// @copyright (c) 2022-2024 Compular AB
import React from 'react';
import PropTypes from 'prop-types';
import './HeaderText.css';
/**
 * Lighttext. Parameter textvalue.
 *
 * @param {string} text Value displayed above button
 */
export default function HeaderText({ text }) {
  return <p className="header-text">{text}</p>;
}
HeaderText.propTypes = {
  text: PropTypes.string.isRequired
};
