/* @copyright (c) 2021-2022 Compular AB */
import React, { useState } from 'react';
import RingLoader from 'react-spinners/RingLoader';
import HelpModal from '../../components/Modals/HelpModal';
import Page from '../../components/Page/Page';
import WhiteBackgroundBox from '../../components/WhiteBackgroundBox/WhiteBackgroundBox';

export default function LoadingPage() {
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
  return (
    <Page help onHelp={() => setIsHelpModalOpen(true)}>
      <div className="w-full min-h-[85vh] h-full px-12 py-8">
        <HelpModal
          isOpen={isHelpModalOpen}
          close={() => setIsHelpModalOpen(false)}
        />
        <WhiteBackgroundBox>
          <div className="w-full flex flex-1 items-center justify-center text-center h-full">
            <RingLoader color="#F2933D" size={150} speedMultiplier={0.9} />
          </div>
        </WhiteBackgroundBox>
      </div>
    </Page>
  );
}
