// @copyright (c) 2023-2024 Compular AB
import { useGet } from '../../querys';

export const useGetStoichiometry = (id) =>
  useGet(`/compute_stoichiometry?job=${id}&header=1`);

// fetch the xyz data if it is a molecule from the molecule bank
export const useGetXYZ = (id) => useGet(`/get_xyz?MoleculeK=${id}`);

// fetch the xyz data if it is an output molecule from champion
export const useGetXYZFromPath = (organizationK, jobK, filepath) =>
  useGet(
    `/get_xyz?organizationk=${organizationK}&jobk=${jobK}&xyz_filepath=${filepath}`
  );
