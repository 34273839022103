// @copyright (c) 2022-2024 Compular AB
import React from 'react';
import PropTypes from 'prop-types';
import './LightText.css';
/**
 * Lighttext. Parameter textvalue.
 *
 * @param {string} text Value displayed above button
 */
export default function LightText({ text }) {
  return <p className="light-text">{text}</p>;
}
LightText.propTypes = {
  text: PropTypes.string.isRequired
};
