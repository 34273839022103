// @copyright (c) 2023-2024 Compular AB
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import RingLoader from 'react-spinners/RingLoader';
import CloseIcon from '../../icons/CloseIcon';
import DefaultButton from '../DefaultButtons/DefaultButton';
import { getXYZFile } from '../../redux/actions/actions';
import { convertHeader } from '../../utils/utils';

/**
 * A confirm modal.
 *
 * @param isOpen bool. true if modal is open
 * @param header string. Header for modal
 * @param body string. body for modal
 * @param close func. Close modal.
 * @param process func. process
 * @param cancel func. cancel job.
 * @returns
 */
// Dispatch SimulateJob to change status to processing.
// Creates first log message and estimated time

export default function PreviewModal({
  isOpen,
  close,
  KMolecule,
  setJobMolecule
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [file, setFile] = useState(null);
  const MOLECULES = useSelector((state) => state.reducer.moleculeBank);

  const molecule = useMemo(() => {
    if (isOpen && KMolecule)
      return MOLECULES.filter((molecules) => molecules[0] === KMolecule).map(
        (m) => {
          return {
            IUPAC: m[2],
            id: m[0],
            CommonName: m[1],
            alias: m[3],
            density: m[4],
            orgK: m[5],
            abb: m[8]
          };
        }
      )[0];
    return null;
  }, [MOLECULES, isOpen]);

  useEffect(async () => {
    if (isOpen && KMolecule) {
      const data = await getXYZFile(KMolecule);
      if (data) setFile(data);
    }
  }, [KMolecule, isOpen]);

  const customModalStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      minWidth: '800px',
      maxWidth: '800px',
      height: '100%',
      maxHeight: '580px',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '16px',
      borderRadius: '5px',
      zIndex: 1000
    }
  };

  useEffect(() => {
    if (isOpen) {
      const aCanvas = document.querySelectorAll('canvas');
      while (aCanvas.length > 1) {
        aCanvas[0].remove();
      }
    }
  }, [document.querySelectorAll('canvas').length]);
  useEffect(() => {
    if (!isOpen) setFile(null);
    if (file && isOpen) {
      $(() => {
        const element = $('#modal-body');
        const config = {
          id: '3dmolcanvas'
        };
        const viewer = $3Dmol.createViewer(element, config);
        viewer.addModel(file, 'xyz');
        viewer.setStyle({
          sphere: { radius: 0.4, opacity: 0.8, colorscheme: 'Jmol' },
          stick: { colorscheme: 'Jmol' }
        });
        viewer.render();

        viewer.center();
        viewer.zoomTo(10);
        viewer.zoom(1.25, 1000);

        if (viewer && file) setIsLoading(false);
      });
    }
  }, [file, isOpen, isLoading]);

  return (
    <Modal
      style={customModalStyle}
      isOpen={isOpen}
      ariaHideApp={false}
      onRequestClose={close}
      contentLabel="Create new project">
      <div className="w-full h-full relative">
        <div className="flex justify-end items-center ">
          <CloseIcon
            className="h-5 w-5 cursor-pointer hover:text-NGrey-8 "
            onClick={close}
          />
        </div>
        <div className="w-full flex flex-col gap-4">
          <div id="modal-header">
            {molecule?.IUPAC && (
              <p className="text-primary-10 font-sans max-w-5/6  text-center text-[24px] font-semibold truncates">
                {molecule.IUPAC}
              </p>
            )}
            {molecule?.CommonName && (
              <p className="text-NGrey-6 font-sans max-w-5/6  text-center text-[24px] font-semibold truncate">
                {molecule.CommonName}
              </p>
            )}
            {molecule?.abb && (
              <p className="text-secondary-10 font-sans max-w-5/6  text-center text-xl font-semibold truncate">
                {molecule.abb}
              </p>
            )}
            {molecule?.alias && (
              <p className="text-NGrey-6 font-sans text-center text-lg font-semibold truncate">
                {molecule.alias.map((alias, i) => {
                  if (i > 5) return;
                  if (i + 1 === molecule.alias.length) return alias;
                  if (i === 4) return `${alias}`;
                  return `${alias}, `;
                })}
              </p>
            )}
            {molecule?.density && (
              <p className="text-NGrey-6 font-sans text-center text-base font-semibold truncate">
                {`Density: ${molecule.density}`}
                <span className="ml-2">{convertHeader('g/cm^3')}</span>
              </p>
            )}
          </div>

          <div className=" rounded-lg p-2 shadow-innerLG">
            <div
              id="modal-body"
              className="h-72 w-full mx-auto relative  flex justify-center items-baseline cursor-grab "
              onMouseDown={() => {
                document
                  .getElementById('modal-body')
                  .classList.add('cursor-grabbing');
              }}
              onMouseUp={() => {
                document
                  .getElementById('modal-body')
                  .classList.remove('cursor-grabbing');
              }}>
              {isLoading && <RingLoader className="w-10 h-10" />}
            </div>
          </div>
        </div>
        <div className="absolute right-0 bottom-0 flex gap-2">
          <DefaultButton
            type="outlined-primary"
            label="Cancel"
            onPressed={close}
          />
          <DefaultButton
            type="contained-secondary"
            label="Add molecule"
            onPressed={() => {
              setJobMolecule(molecule);
            }}
          />
        </div>
      </div>
    </Modal>
  );
}
PreviewModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  KMolecule: PropTypes.any,
  setJobMolecule: PropTypes.func.isRequired
};
PreviewModal.defaultProps = {
  KMolecule: null
};
