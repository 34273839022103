// @copyright (c) 2022-2024 Compular AB
import * as React from 'react';

function EnlargeIcon(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M21 11V3h-8l3.29 3.29-10 10L3 13v8h8l-3.29-3.29 10-10L21 11Z"
        fill="currentcolor"
      />
    </svg>
  );
}

export default EnlargeIcon;
