// @copyright (c) 2023-2024 Compular AB
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '../../icons/CloseIcon';
import JobTable from '../JobTable/JobTable';
import DefaultButton from '../DefaultButtons/DefaultButton';
import InputField from '../InputFields/InputField';

/**
 * A modal with a list of jobs with status finished
 *
 * @param isOpen bool. true if modal is open
 * @param close function. Close modal
 * @param jobs array. list of finished jobs
 * @returns
 */
// Dispatch SimulateJob to change status to processing.
// Creates first log message and estimated time

export default function CreateJobModal({ isOpen, close, jobs }) {
  const navigate = useNavigate();

  const [tab, setTab] = useState('new');
  const [name, setName] = useState('');
  const [selectedJobs, setSelectedJobs] = useState([]);
  const [screeningId, setScreeningId] = useState([]);

  const customModalStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      minWidth: '862px',
      height: '100%',
      maxHeight: tab === 'new' ? '320px' : '700px',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '8px',
      borderRadius: '5px'
    }
  };
  const aJobs = useMemo(() => {
    if (jobs && jobs.length > 0) {
      const aFlatJobs = jobs
        .map((job) => {
          return job.findLast((ele) => ele);
        })
        .flat();
      // remove nested arrays
      return aFlatJobs;
    }
    return [];
  }, [jobs]);

  function submit() {
    navigate('/jobs/new/edit/new', {
      state: { name, compositionsJobId: selectedJobs, screeningId }
    });
  }
  const handleKeyEvent = (e) => {
    if (e.key === 'Enter') {
      submit();
    }
  };

  return (
    <Modal
      style={customModalStyle}
      isOpen={isOpen}
      ariaHideApp={false}
      onRequestClose={close}
      contentLabel="Create new project">
      <div className="w-full h-full relative">
        <div className="w-full flex flex-col gap-3">
          <div className="flex justify-end items-center mt-2 mr-2">
            <CloseIcon
              className="h-5 w-5 cursor-pointer hover:text-NGrey-8"
              onClick={close}
            />
          </div>
          <div id="modal-header" className="flex flex-col  items-center">
            <p className="text-primary-10 text-xl font-bold">
              Create a new job
            </p>
            {tab === 'old' && (
              <p className="text-NGrey-8 text-sm font-normal py-2">
                Use a previous system as template
              </p>
            )}
            <div className="w-full flex mt-[10px] px-6">
              <p
                onClick={() => {
                  setTab('new');
                  setSelectedJobs();
                }}
                className={`py-2 px-2 border-b-2 cursor-pointer hover:bg-secondary-1 ${
                  tab === 'new'
                    ? 'border-secondary-9 text-primary-9'
                    : 'border-NGrey-6 text-NGrey-6'
                }  text-lg font-bold`}>
                Create from scratch
              </p>
              <p
                onClick={() => setTab('old')}
                className={`py-2 px-2 border-b-2 cursor-pointer hover:bg-secondary-1 ${
                  tab === 'old'
                    ? 'border-secondary-9 text-primary-9'
                    : 'border-NGrey-6 text-NGrey-6'
                }  text-lg font-bold`}>
                Base on previous system
              </p>
              <p className="flex-1 pb-2 border-b-2 border-NGrey-6">{}</p>
            </div>
          </div>
          <div id="modal-body">
            <div className="w-[320px] px-6 mt-4 mb-4">
              <InputField
                label={tab === 'old' ? 'Name for new system' : 'System name'}
                inputValue={name}
                setInputValue={(e) => {
                  setName(e.target.value);
                }}
                name="mixtureName"
                onKeyDown={handleKeyEvent}
                isRequired={false}
              />
            </div>
            {tab === 'old' && jobs && jobs.length > 0 && (
              <JobTable
                screenings={aJobs}
                onSelect={(job) => {
                  setScreeningId(job.findLast((ele) => ele));
                  setSelectedJobs(job[0]);
                }}
                selectedJobs={selectedJobs}
              />
            )}
          </div>
        </div>
        <div className="px-6 w-full flex justify-end absolute bottom-1">
          <div>
            <DefaultButton
              type="contained-secondary"
              label="Create Job"
              onPressed={() => submit()}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}
CreateJobModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  jobs: PropTypes.instanceOf(Array).isRequired
};
