// @copyright (c) 2023-2024 Compular AB
import * as React from 'react';

function ScreeningIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 28"
      fill="none"
      {...props}>
      <path
        fill="currentColor"
        d="M20 23V5h2v18h-2ZM2 23V5h2v18H2Zm11.59-6.086L15 18.5l4-4.5-4-4.5-1.41 1.586 1.58 1.789H8.83l1.58-1.789L9 9.5 5 14l4 4.5 1.41-1.586-1.58-1.789h6.34l-1.58 1.789Z"
      />
    </svg>
  );
}
export default ScreeningIcon;
