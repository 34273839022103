/* @copyright (c) 2021-2022 Compular AB */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DefaultButton from '../../components/DefaultButtons/DefaultButton';
import HelpModal from '../../components/Modals/HelpModal';
import Page from '../../components/Page/Page';
import WhiteBackgroundBox from '../../components/WhiteBackgroundBox/WhiteBackgroundBox';

export default function ErrorPage() {
  const navigate = useNavigate();
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
  return (
    <Page help onHelp={() => setIsHelpModalOpen(true)}>
      <div className="w-full">
        <HelpModal
          isOpen={isHelpModalOpen}
          close={() => setIsHelpModalOpen(false)}
        />

        <WhiteBackgroundBox>
          <div className="w-full text-center">
            <p className="text-secondary-10 text-[250px] font-bold">404</p>
            <p className="font-semibold text-[40px] text-NGrey-8">
              Page not found
            </p>
            <div className="w-full flex justify-center">
              <p className="mt-6 font-normal text-NGrey-8 text-[23px] w-1/2">
                The page you are looking for might have been removed had its
                name changed or is temporarily unavailable.
              </p>
            </div>

            <div className="flex justify-center items-center gap-8 mt-8">
              <DefaultButton
                type="contained-secondary"
                onPressed={() => navigate(-1)}
                label="Go back to homepage"
              />
              <DefaultButton
                type="outlined-primary"
                onPressed={() => setIsHelpModalOpen(true)}
                label="Report a problem"
              />
            </div>
          </div>
        </WhiteBackgroundBox>
      </div>
    </Page>
  );
}
