// @copyright (c) 2022-2024 Compular AB
import * as React from 'react';

function LineChartIcon(props) {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M13.875 12.375H3.125v-9.75A.125.125 0 0 0 3 2.5h-.875A.125.125 0 0 0 2 2.625v10.75c0 .069.056.125.125.125h11.75a.125.125 0 0 0 .125-.125V12.5a.125.125 0 0 0-.125-.125Zm-9.097-2.41a.125.125 0 0 0 .177 0l2.16-2.15L9.11 9.82a.125.125 0 0 0 .177 0l4.303-4.301a.125.125 0 0 0 0-.177l-.619-.619a.125.125 0 0 0-.176 0L9.2 8.316 7.21 6.312a.125.125 0 0 0-.177 0L4.16 9.168a.125.125 0 0 0 0 .177l.617.62Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default LineChartIcon;
