/* eslint-disable jsx-a11y/click-events-have-key-events */
/* @copyright (c) 2021-2022 Compular AB */
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowDownIcon from '../../icons/ArrowDownIcon';
import SortIcon from '../../icons/SortIcon';
import ArrowUpIcon from '../../icons/ArrowUpIcon';
import { getDate } from '../../utils/utils';
import FolderIcon from '../../icons/FolderIcon';
import ChevronRight from '../../icons/ChevronRight';
import ChevronDown from '../../icons/ChevronDown';

// screenings === array of screenings
function JobTable({
  screenings,
  user,
  action,
  selectedJobs,
  onSelect,
  onselectall
}) {
  const navigate = useNavigate();
  const [openScreenings, setOpenScreenings] = useState({});
  const [isSorted, setIsSorted] = useState({
    name: false,
    updated: true,
    formula: false,
    status: false
  });
  const [sortAcending, setSortAcending] = useState(true);
  const [aScreenings, setScreenings] = useState(screenings);

  const sortTable = (field) => {
    const enums = {
      name: 5,
      status: 6,
      updated: 3
    };
    if (isSorted[field]) {
      setScreenings(aScreenings.toReversed());
      setSortAcending(!sortAcending);
    } else {
      setSortAcending(true);
      const sortIndex = enums[field];
      if (field !== 'updated')
        setScreenings(
          aScreenings.toSorted((a, b) =>
            a[sortIndex].localeCompare(b[sortIndex])
          )
        );
      if (field === 'updated') {
        setScreenings(
          aScreenings.toSorted((a, b) => {
            if (a[sortIndex] < b[sortIndex]) return 1;
            if (a[sortIndex] > b[sortIndex]) return -1;

            // Handle other cases (fallback)
            return 0; // No specific order, keep elements in the same order
          })
        );
      }
      const sortState = {
        name: false,
        updated: false,
        formula: false,
        status: false
      };
      setIsSorted({
        ...sortState,
        [field]: true
      });
    }
  };

  const getTableRows = () => {
    const tableRows = aScreenings.map((screening, i) => {
      if (
        screening?.findLast((ele) => ele) !== null &&
        Array.isArray(screening.findLast((ele) => ele)) &&
        screening.findLast((ele) => ele).length === 1
      ) {
        const aJobArray = screening.findLast((ele) => ele);
        const job = aJobArray[0];
        return (
          <div
            key={job[0]}
            className={`${screenings.length - 1 === i ? 'rounded-b-md' : ''} ${
              i % 2 === 0 ? 'bg-white' : 'bg-NGrey-1'
            } hover:bg-secondary-1 active:bg-secondary-5`}
            onClick={() => {
              if (job[14] === 'Finished') {
                navigate(`/jobs/${screening[0]}/${job[0]}/result`);
              }
              if (job[14] === 'Not Started')
                navigate(`/jobs/${job[0]}/edit/${screening[0]}`);
              if (job[14] === 'In Progress')
                navigate(`/jobs/${screening[0]}/${job[0]}`);
            }}
            aria-hidden="true">
            <div className="flex justify-between text-NGrey-8 divide-x divide-NGrey-2">
              <div
                id="name-col"
                className="flex-1 flex justify-start items-center cursor-pointer pl-4 py-3">
                <div className="job-name select-none">{job[5]}</div>
              </div>
              <div
                id="status-col"
                className="flex min-w-[200px] justify-start items-center cursor-pointer py-3 pl-4 select-none">
                {getDate(job[3])}
              </div>
              <div
                id="status-col"
                className="min-w-[200px] flex justify-start items-center cursor-pointer py-3 pl-4 select-none">
                <span
                  className={`pr-2 text-lg ${
                    job[14] === 'Not Started' && 'text-NGrey-7'
                  } ${job[14] === 'Finished' && 'text-Success-7'} ${
                    job[14] === 'In Progress' && 'text-Warning-5'
                  }`}>
                  &#x2022;
                </span>
                {job[14]}
              </div>
            </div>
          </div>
        );
      }
      return (
        <div
          key={screening[0]}
          className={`${aScreenings.length - 1 === i ? 'rounded-b-md' : ''} ${
            i % 2 === 0 ? 'bg-white' : 'bg-NGrey-1'
          }`}
          onClick={() => {
            if (openScreenings[screening[0]])
              return setOpenScreenings({
                ...openScreenings,
                [screening[0]]: false
              });
            setOpenScreenings({ ...openScreenings, [screening[0]]: true });
          }}
          aria-hidden="true">
          <div
            className={`flex justify-between text-NGrey-8 hover:bg-secondary-1 active:bg-secondary-5 ${
              openScreenings[screening[0]] && 'border-b border-dashed'
            }`}>
            <div
              id="name-col"
              className="flex-1 flex justify-left items-center cursor-pointer py-3 pl-4">
              <div className="job-name select-none flex gap-2 items-center">
                {!openScreenings[screening[0]] ? (
                  <ChevronRight className="w-4 h-4 text-primary-10" />
                ) : (
                  <ChevronDown className="w-4 h-4 text-primary-10" />
                )}
                <FolderIcon className="w-4 h-4 text-primary-10" />
                {screening[5]}
              </div>
            </div>
            <div
              id="status-col"
              className="flex min-w-[200px] justify-left items-center cursor-pointer py-3 pl-4 select-none">
              {getDate(screening[3])}
            </div>
            <div
              id="status-col"
              className="flex justify-left min-w-[200px] items-center cursor-pointer py-3 pl-4 select-none">
              <span
                className={`pr-2 text-lg ${
                  screening[6] === 'Not Started' && 'text-NGrey-7'
                } ${screening[6] === 'Finished' && 'text-Success-7'} ${
                  screening[6] === 'In Progress' && 'text-Warning-5'
                }`}>
                &#x2022;
              </span>
              {screening[6]}
            </div>
          </div>
          {openScreenings[screening[0]] && (
            <div className="divide-y divide-NGrey-2 divide-dashed">
              {screening
                .findLast((ele) => ele)
                .map((job) => (
                  <div
                    key={job[0]}
                    className={`${
                      i % 2 === 0 ? 'bg-white' : 'bg-NGrey-1'
                    } hover:bg-secondary-1 active:bg-secondary-5  divide-x divide-NGrey-2`}
                    onClick={() => {
                      if (job[14] === 'Finished') {
                        navigate(`/jobs/${screening[0]}/${job[0]}/result`);
                      }
                      if (job[14] === 'Not Started')
                        navigate(`/jobs/${job[0]}/edit/${screening[0]}`);
                      if (job[14] === 'In Progress')
                        navigate(`/jobs/${screening[0]}/${job[0]}`);
                    }}
                    aria-hidden="true">
                    <div className="flex justify-between text-NGrey-8">
                      <div
                        id="name-col"
                        className="flex-1 flex justify-left items-center cursor-pointer py-3 pl-10">
                        <div className="job-name select-none">{job[5]}</div>
                      </div>
                      <div
                        id="status-col"
                        className="pl-4 flex min-w-[200px] justify-left items-center cursor-pointer py-3 select-none">
                        {getDate(job[3])}
                      </div>
                      <div
                        id="status-col"
                        className="pl-4 flex min-w-[200px] justify-left items-center cursor-pointer py-3 select-none">
                        <span
                          className={`pr-2 text-lg ${
                            job[14] === 'Not Started' && 'text-NGrey-7'
                          } ${job[14] === 'Finished' && 'text-Success-7'} ${
                            job[14] === 'In Progress' && 'text-Warning-5'
                          }`}>
                          &#x2022;
                        </span>
                        {job[14]}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      );
    });
    return tableRows;
  };
  const getTableRowsForModal = (aJob) => {
    const tableRows = aJob.map((job, i) => {
      if (typeof job[0] !== 'number') {
        return getTableRowsForModal(job);
      }
      return (
        <div
          key={`${job[0]}-${i}`}
          onClick={() => {
            if (onSelect) onSelect(job);
          }}
          className={`${aScreenings.length - 1 === i ? 'rounded-b-md' : ''} ${
            onSelect && selectedJobs === job[0]
              ? 'bg-secondary-2'
              : i % 2 === 0
              ? 'bg-white'
              : 'bg-NGrey-1'
          } max-h-64 overflow-y-auto ${
            onSelect
              ? 'cursor-pointer hover:bg-secondary-2 active:bg-secondary-4'
              : ''
          }`}
          aria-hidden="true">
          <div className="flex justify-between text-NGrey-8">
            {action && (
              <div
                id="name-col"
                className="flex w-14 justify-center items-center px-5">
                <div className="job-name select-none">
                  <input
                    className="text-primary-9 focus:ring-0 focus:ring-offset-0 border-primary-9 cursor-pointer rounded border-2 w-[17px] h-[17px]"
                    onClick={() => {
                      if (action) action(job[0]);
                    }}
                    onChange={() => {}}
                    type="checkbox"
                    name=""
                    checked={selectedJobs.indexOf(job[0]) !== -1}
                  />
                </div>
              </div>
            )}
            <div
              id="name-col"
              className="w-full flex justify-start items-center pl-3 border-r border-NGray-2 text-sm">
              <div className="job-name select-none">{job[5]}</div>
            </div>
            <div
              id="status-col"
              className="w-80 flex justify-start items-center py-3 select-none pl-5 text-sm">
              {job[3].substring(0, job[3].length - 3)}
            </div>
          </div>
        </div>
      );
    });
    return (
      <div
        className={` ${
          onSelect
            ? ' max-h-[275px] overflow-auto border-x border-b rounded-b-md border-NGrey-2'
            : ''
        } ${
          action
            ? ' max-h-64 overflow-auto border-x border-b rounded-b-md border-NGrey-2'
            : ''
        }`}>
        {tableRows}
      </div>
    );
  };

  if (action || onSelect)
    return (
      <div id="job-table" className={` rounded-md mx-6  `}>
        <div className="flex justify-between border-b text-center border-NGrey-2 px-5 py-[10px] rounded-t-md bg-NGrey-2">
          {action && (
            <div
              id="name-col"
              className="flex w-14 justify-start items-center pr-8"
              aria-hidden="True">
              <input
                className="text-primary-9 focus:ring-0 focus:ring-offset-0 border-primary-9 cursor-pointer rounded border-2 w-[17px] h-[17px]"
                onClick={() => {
                  onselectall();
                }}
                onChange={() => {}}
                type="checkbox"
                name=""
                checked={selectedJobs.length === aScreenings.length}
              />
            </div>
          )}
          <div
            id="name-col"
            className="flex justify-center w-full cursor-pointer"
            onClick={() => sortTable('name')}
            aria-hidden="True">
            <div className="flex gap-2 items-center flex-1 justify-start font-semibold text-sm">
              <p className="select-none">Jobs</p>
              <div className="unsorted-field ">
                {isSorted.name &&
                  (sortAcending ? (
                    <ArrowDownIcon className="h-6 w-6" />
                  ) : (
                    <ArrowUpIcon className="w-6 h-6" />
                  ))}
                {!isSorted.name && (
                  <SortIcon className="text-NGrey-6 h-6 w-6" />
                )}
              </div>
            </div>
          </div>
          <div
            id="name-col"
            className="flex w-80 justify-start cursor-pointer pl-11 font-semibold text-sm"
            onClick={() => sortTable('updated')}
            aria-hidden="True">
            <div className="flex gap-2 items-center ">
              <p className="select-none">Date</p>
              <div className="unsorted-field">
                {isSorted.updated &&
                  (sortAcending ? (
                    <ArrowDownIcon className="h-6 w-6" />
                  ) : (
                    <ArrowUpIcon className="w-6 h-6" />
                  ))}
                {!isSorted.updated && (
                  <SortIcon className="text-NGrey-6 h-6 w-6" />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="job-area divide-y divide-NGrey-2">
          {getTableRowsForModal(aScreenings)}
        </div>
      </div>
    );
  return (
    <div
      id="job-table"
      className="rounded-md mx-6 h-full overflow-y-auto relative ">
      <div className="flex justify-between border-b  text-center border-NGrey-2 py-4 rounded-t-md bg-NGrey-2 sticky top-0">
        <div
          id="name-col"
          className="flex justify-center flex-1 pl-4 cursor-pointer"
          onClick={() => sortTable('name')}
          aria-hidden="True">
          <div className="flex gap-3 items-center flex-1 justify-start">
            <p className="select-none font-medium text-NGrey-9">System</p>
            <div className="unsorted-field ">
              {isSorted.name &&
                (sortAcending ? (
                  <ArrowDownIcon className="h-6 w-6" />
                ) : (
                  <ArrowUpIcon className="w-6 h-6" />
                ))}
              {!isSorted.name && <SortIcon className="text-NGrey-6 h-6 w-6" />}
            </div>
          </div>
        </div>
        <div
          id="name-col"
          className="flex justify-start min-w-[200px] pl-4 cursor-pointer "
          onClick={() => sortTable('updated')}
          aria-hidden="True">
          <div className="flex gap-3 items-center justify-start">
            <p className="select-none font-medium text-NGrey-9">Date</p>
            <div className="unsorted-field">
              {isSorted.updated &&
                (sortAcending ? (
                  <ArrowDownIcon className="h-6 w-6" />
                ) : (
                  <ArrowUpIcon className="w-6 h-6" />
                ))}
              {!isSorted.updated && (
                <SortIcon className="text-NGrey-6 h-6 w-6" />
              )}
            </div>
          </div>
        </div>
        <div
          id="name-col"
          className="flex justify-start min-w-[200px] pl-4 cursor-pointer"
          onClick={() => sortTable('status')}
          aria-hidden="True">
          <div className="flex gap-3 items-center justify-start">
            <p className="select-none font-medium text-NGrey-9">Status</p>
            <div className="unsorted-field">
              {isSorted.status &&
                (sortAcending ? (
                  <ArrowDownIcon className="h-6 w-6" />
                ) : (
                  <ArrowUpIcon className="w-6 h-6" />
                ))}
              {!isSorted.status && (
                <SortIcon className="text-NGrey-6 h-6 w-6" />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="job-area divide-y divide-NGrey-2 border-x border-b overflow-scroll rounded-b-md border-NGrey-2">
        {getTableRows(user)}
      </div>
    </div>
  );
}

JobTable.propTypes = {
  screenings: PropTypes.instanceOf(Array).isRequired,
  user: PropTypes.instanceOf(Array),
  selectedJobs: PropTypes.any,
  action: PropTypes.func,
  onSelect: PropTypes.func,
  onselectall: PropTypes.func
};
JobTable.defaultProps = {
  action: null,
  selectedJobs: null,
  user: null,
  onSelect: null,
  onselectall: null
};

export default JobTable;
