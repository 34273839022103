/* eslint-disable no-underscore-dangle */
/* @copyright (c) 2021-2022 Compular AB */
import React, { useState, useMemo, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { AccountContext } from '../../Accounts';
import DefaultButton from '../../components/DefaultButtons/DefaultButton';
import ConfirmModal from '../../components/Modals/ConfirmModal';
import HelpModal from '../../components/Modals/HelpModal';
import Page from '../../components/Page/Page';
import Table from '../../components/Table/Table';
import WhiteBackgroundBox from '../../components/WhiteBackgroundBox/WhiteBackgroundBox';
import CheckIcon from '../../icons/CheckIcon';
import CloseIcon from '../../icons/CloseIcon';
import HourglassIcon from '../../icons/HourglassIcon';
import { cancelJob, formatD, logIn } from '../../redux/actions/actions';
import { request } from '../../redux/api/axios';
import { getDate, openInNewTab } from '../../utils/utils';
import LoadingPage from '../LoadingPage/LoadingPage';

export default function ProgressPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setIsLoading] = useState(true);
  const [nDays, setDays] = useState(null);
  const { screeningId, jobId } = useParams();
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [comps, setComps] = useState(null);
  const [jobLogs, setLogs] = useState(null);
  const [message, setMessage] = useState({});
  const { user, isLoading, moleculeBank } = useSelector(
    (state) => state.reducer
  );
  const { getSession } = useContext(AccountContext);
  const jobs = useSelector(
    (state) =>
      Object.values(state.reducer.jobs).filter(
        (_job) => _job[0] === parseInt(screeningId)
      )[0]
  );
  const rawcomps = useSelector((state) => state.reducer.compositions[jobId]);

  useEffect(() => {
    if (rawcomps) setComps(Object.values(rawcomps));
  }, [rawcomps]);

  const _jobLogs = useSelector(
    (state) => state.reducer.jobLogs[parseInt(jobId)]
  );
  const { jobMessages } = useSelector((state) => state.reducer);

  useEffect(() => {
    if (_jobLogs) {
      setLogs(Object.values(_jobLogs));

      if (jobMessages) {
        for (let i = 0; i < Object.keys(_jobLogs).length; i++) {
          if (jobMessages?.[Object.keys(_jobLogs)[i]])
            setMessage({
              ...message,
              [Object.keys(_jobLogs)[i]]:
                jobMessages?.[Object.keys(_jobLogs)[i]]
            });
        }
      }
    }
  }, [_jobLogs, jobMessages]);

  useEffect(() => {
    if (!user) {
      getSession()
        .then((data) => {
          const AUTH_TOKEN = data.accessToken.jwtToken;
          request.defaults.headers.common.Authorization = AUTH_TOKEN;
          dispatch(logIn());
        })
        .catch(() => {
          navigate('/');
        });
    }
  }, []);

  const logs = useMemo(() => {
    if (jobLogs && jobLogs.length > 0)
      return jobLogs.filter((log) => log[1] === parseInt(jobId));
  }, [jobLogs]);

  const job = useMemo(() => {
    if (jobs && Array.isArray(jobs.findLast((ele) => ele))) {
      const aJob = jobs
        .findLast((ele) => ele)
        .filter((filterJob) => {
          return filterJob[0] === parseInt(jobId);
        })[0];
      if (aJob && comps && comps.length > 0 && logs) {
        if (aJob[14] !== 'In Progress') {
          if (aJob[14] === 'Done') navigate(`/jobs/${aJob[0]}/result`);
          else if (aJob[14] === 'Not Started')
            navigate(`/jobs/${aJob[0]}/edit/${screeningId}`);
        }
        const parents = comps.filter((comp) => comp[7] === null);

        const children = comps
          .filter((comp) => comp[7])
          .map((comp) => {
            return {
              id: comp[0],
              name: moleculeBank.find((x) => x[0] === comp[9])[8],
              value: comp[5],
              unit: comp[6],
              parent: comp[7]
            };
          });
        const json = parents.map((composition) => {
          return {
            id: composition[0],
            name: moleculeBank.find((x) => x[0] === composition[9])[8],
            value: composition[5],
            unit: composition[6],
            children: children.filter(
              (child) => child.parent === composition[0]
            )
          };
        });

        return {
          composition: json,
          logStatus: logs,
          id: aJob[0],
          organizationId: aJob?.[1] ? aJob?.[1] : null,
          name: aJob[5] || '',
          ensemble: aJob[6],
          temperature: {
            value: aJob[7] || 0,
            unit: aJob[8]
          },
          createdAt: aJob[3],
          estimatedAt: aJob[15] || null,
          pressure:
            aJob[6] !== 'NPT'
              ? null
              : {
                  value: aJob[11] || '',
                  unit: aJob[12]
                },
          density: {
            value: aJob[9] || '',
            unit: aJob[10]
          },
          formula: '',
          freeText: aJob[13] || ''
        };
      }
    }
  }, [comps, jobs, logs]);

  useEffect(() => {
    if (job) {
      if (job.estimatedAt) {
        const diffTime = Math.abs(
          new Date(job.estimatedAt) - new Date(formatD(new Date()))
        );
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        setDays(diffDays);
      }
      if (!isLoading) setIsLoading(false);
    }
  }, [job, isLoading, user]);

  if (loading) return <LoadingPage />;

  return (
    <Page help onHelp={() => setIsHelpModalOpen(true)}>
      <div className="w-full px-12 py-8">
        <HelpModal
          isOpen={isHelpModalOpen}
          close={() => setIsHelpModalOpen(false)}
        />
        {isConfirmModalOpen && (
          <ConfirmModal
            isOpen={isConfirmModalOpen}
            header="Are you sure you want to cancel the analysis?"
            body="The job will be canceled, returning you to the list of jobs."
            jobId={[job.id]}
            close={() => setIsConfirmModalOpen(false)}
            cancel={() => {
              dispatch(cancelJob(job, screeningId));
              setIsConfirmModalOpen(false);
              navigate('/jobs');
            }}
          />
        )}
        {job && (
          <WhiteBackgroundBox>
            <div className="flex w-full h-full flex-col gap-6 relative">
              <div
                className={`absolute top-0 w-full border-b border-NGrey-3 text-center bg-white  ${
                  isConfirmModalOpen || isHelpModalOpen ? '' : 'z-20'
                }`}>
                <div className="flex flex-start px-8 py-4 items-center">
                  <div className="basis-1/3">
                    <DefaultButton
                      label="Go back to jobs"
                      type="text-secondary"
                      onPressed={() => {
                        navigate('/jobs');
                      }}
                      iconPosition="left"
                      icon="arrowLeft"
                    />
                  </div>
                  <p className="text-[23px] font-semibold text-primary-10 basis-1/3">
                    In progress
                  </p>
                  <div className="flex gap-4 ml-auto basis-1/3 justify-end">
                    <DefaultButton
                      type="outlined-primary"
                      label="Cancel job"
                      onPressed={() => {
                        setIsConfirmModalOpen(true);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="flex w-full flex-col gap-6 divide-y-2 pt-6 px-8 overflow-y-auto ">
                <div className="w-full flex flex-col gap-8 pb-12">
                  <div>
                    <p className="text-NGrey-6 text-[33px] font-semibold select-none mt-16">
                      {job?.name}
                    </p>
                  </div>
                  <p className="text-xl font-bold text-NGrey-6 text-center w-full">
                    The results will be available once the process is completed.
                    You will also recieve a notification on your registered
                    email.
                  </p>
                  <p className="text-NGrey-6 text-xl font-bold text-center w-full">
                    {/* Subtract dates from now to estimated date. */}
                    {nDays
                      ? `Estimated remaining time: ${
                          nDays < 1
                            ? 'Less than a day'
                            : nDays === 1
                            ? `${nDays} day`
                            : `${nDays} days`
                        }`
                      : 'Estimating remaining time: ...'}
                  </p>
                  <div className="overflow-y-scroll w-full mx-auto border border-NGrey-7 max-h-[500px] py-8 px-12 rounded-lg shadow-sm flex flex-col-reverse">
                    {job.logStatus &&
                      job.logStatus.length > 0 &&
                      job.logStatus.map((log, i) => {
                        if (i === 0)
                          return (
                            <div
                              className=" w-full flex flex-row  pb-12"
                              key={`logs-${i}`}>
                              <div className=" border-l w-full flex gap-5  border-primary-10 relative">
                                <div className=" left-[-22.5px] top-0 flex  items-center absolute">
                                  <div className="bg-white border border-primary-10 self-start rounded-full min-h-[45px] min-w-[45px] w-[45px] h-[45px] flex items-center justify-center">
                                    {log[4].indexOf('Job canceled') !== -1 ? (
                                      <CloseIcon className="w-5 h-5 text-primary-10" />
                                    ) : log[4].indexOf('Done') === -1 ? (
                                      <HourglassIcon className="w-5 h-5 text-primary-10" />
                                    ) : (
                                      <CheckIcon className="w-5 h-5 text-primary-10" />
                                    )}
                                  </div>
                                  <div className="ml-3 flex flex-col">
                                    <div className="ml-2 flex flex-col ">
                                      <p className="text-primary-10 text-lg">
                                        {log[4]}
                                      </p>
                                      <p className="text-sm text-NGrey-6">
                                        {getDate(log[3])}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        return (
                          <div
                            className=" w-full flex flex-row"
                            key={`logs-${i}`}>
                            <div className=" border-l w-full flex gap-5  border-primary-10 pb-6 ">
                              <div className="h-full relative">
                                <div className=" left-[-22.5px] top-0 w-full flex items-center absolute">
                                  <div className="bg-white border border-primary-10 self-start rounded-full min-h-[45px] min-w-[45px] w-[45px] h-[45px] flex items-center justify-center">
                                    {log[4].indexOf('Job canceled') !== -1 ? (
                                      <CloseIcon className="w-5 h-5 text-primary-10" />
                                    ) : log[4].indexOf('Done') === -1 ? (
                                      <HourglassIcon className="w-5 h-5 text-primary-10" />
                                    ) : (
                                      <CheckIcon className="w-5 h-5 text-primary-10" />
                                    )}
                                  </div>
                                </div>
                                <div className="ml-8 flex flex-col justify-start gap-3">
                                  <div className="ml-2 flex flex-col ">
                                    <p className="text-primary-10 text-lg">
                                      {log[4]}
                                    </p>
                                    <p className="text-sm text-NGrey-6">
                                      {log[3].substring(0, log[3].length - 3)}
                                    </p>
                                  </div>
                                  <div className=" pl-3 grow flex flex-col gap-3">
                                    {message?.[log[0]] &&
                                      Object.values(message?.[log[0]])
                                        .filter((m) => m[1] === log[0])
                                        .toSorted((a, b) =>
                                          b[2].localeCompare(a[2].toLowerCase())
                                        )
                                        .map((m) => {
                                          return (
                                            <div
                                              className="px-3 py-2 w-full border border-NGrey-6 rounded-sm bg-NGrey-2"
                                              key={`message-${m[0]}`}>
                                              {m[3]}
                                            </div>
                                          );
                                        })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>

                <div className="py-14">
                  <div className="flex font-sans font-bold text-2xl text-NGrey-9 gap-2 items-center">
                    <p>System specifications</p>
                    <div>
                      <DefaultButton
                        label=""
                        type="text-primary"
                        onPressed={() => {
                          openInNewTab(
                            'https://compulartech.com/docs/in-progress/#0-toc-title'
                          );
                        }}
                        icon="help"
                        iconPosition="left"
                      />
                    </div>
                  </div>
                  <div className="w-full flex flex-col text-center h-full pt-8 pb-24">
                    {job && job.composition.length > 0 && (
                      <div className="flex w-full gap-6">
                        <div className="w-1/2">
                          <Table data={job.composition} type="compositions" />
                        </div>
                        <div className="w-1/2">
                          <Table
                            data={
                              job.ensemble === 'NPT'
                                ? Object.keys(job)
                                    .filter(
                                      (key) =>
                                        [
                                          'name',
                                          'composition',
                                          'freeText',
                                          'organizationId',
                                          'id',
                                          'logStatus',
                                          'formula',
                                          'estimatedAt',
                                          'createdAt',
                                          'density'
                                        ].indexOf(key) === -1
                                    )
                                    .map((acc) => {
                                      return job[acc];
                                    })
                                : Object.keys(job)
                                    .filter(
                                      (key) =>
                                        [
                                          'name',
                                          'composition',
                                          'freeText',
                                          'organizationId',
                                          'id',
                                          'logStatus',
                                          'formula',
                                          'pressure',
                                          'estimatedAt',
                                          'createdAt',
                                          'density'
                                        ].indexOf(key) === -1
                                    )
                                    .map((acc) => {
                                      return job[acc];
                                    })
                            }
                            keys={
                              job.ensemble === 'NPT'
                                ? Object.keys(job).filter(
                                    (key) =>
                                      [
                                        'name',
                                        'composition',
                                        'freeText',
                                        'organizationId',
                                        'id',
                                        'logStatus',
                                        'formula',
                                        'estimatedAt',
                                        'createdAt',
                                        'density'
                                      ].indexOf(key) === -1
                                  )
                                : Object.keys(job).filter(
                                    (key) =>
                                      [
                                        'name',
                                        'composition',
                                        'freeText',
                                        'organizationId',
                                        'id',
                                        'logStatus',
                                        'formula',
                                        'pressure',
                                        'estimatedAt',
                                        'createdAt',
                                        'density'
                                      ].indexOf(key) === -1
                                  )
                            }
                            type="conditions"
                          />
                          {job.freeText && (
                            <div className="w-full min-h-fit disabled-card text-left flex flex-col p-5 justify-start m-0 mt-5">
                              <p className="w-full text-NGrey-8">
                                Other information:
                              </p>
                              <p className="text-NGrey-6">{job.freeText}</p>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </WhiteBackgroundBox>
        )}
      </div>
    </Page>
  );
}
