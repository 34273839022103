// @copyright (c) 2021-2024 Compular AB
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PrepareGenerationPage from './pages/JobPages/PrepareGeneration/PrepareGenerationPage';
import ResultPage from './pages/ResultPage/ResultPage';
import CompareResultPage from './pages/ResultPage/CompareResultPage';
import Login from './Login';
import JobsPage from './pages/JobPages/JobsPage';
import ErrorPage from './pages/ErrorPage/ErrorPage';
import ProgressPage from './pages/ProgressPage/ProgressPage';
// import AccountPage from './pages/AccountPage/AccountPage';

function App() {
  return (
    <>
      <ToastContainer />
      <Routes>
        <Route
          exact
          path="/jobs/:screeningid/:jobid/result"
          element={<ResultPage />}
        />
        <Route exact path="/jobs/results" element={<CompareResultPage />} />
        <Route exact path="/jobs" element={<JobsPage />} />
        {/* <Route exact path="/account" element={<AccountPage />} /> */}
        <Route
          path="/jobs/:id/edit/:screeningId"
          element={<PrepareGenerationPage />}
        />
        <Route exact path="/" element={<Login />} />
        <Route
          exact
          path="/jobs/:screeningId/:jobId"
          element={<ProgressPage />}
        />
        <Route path="/*" element={<ErrorPage />} />
      </Routes>
    </>
  );
}

export default App;
