// @copyright (c) 2023-2024 Compular AB
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { openInNewTab, SOLUTES_UNITS, SOLVENT_UNITS } from '../../utils/utils';
import DefaultButton from '../DefaultButtons/DefaultButton';
import DropdownMenu from '../DropdownMenu/DropdownMenu';
import SearchDropdownMenu from '../DropdownMenu/SearchDropdownMenu';
import InputField from '../InputFields/InputField';

/**
 * Composition component
 *
 * @param editedJob Object
 * @param setEditedJob Function set job state
 * @param isJobError any
 * @param setIsModalOpen func, open modal.
 * @param setIsPreviewModalOpen func, open modal
 * @returns
 */

export default function Compositions({
  editedJob,
  setEditedJob,
  isJobError,
  setIsModalOpen,
  setIsPreviewModalOpen
}) {
  const MOLECULES = useSelector((state) => state.reducer.moleculeBank);

  const moleculesDropDown = useMemo(() => {
    // filter out the header line and the molecules that do not have a neutral charge
    return MOLECULES.filter((mol, i) => i !== 0 && mol[7] === 0).map((m) => {
      return {
        CommonName: m[1],
        id: m[0],
        alias: m[3],
        density: m[4],
        IUPAC: m[2],
        orgK: m[5],
        abb: m[8]
      };
    });
  }, [MOLECULES]);

  function renderChildrens(childrens, solvent) {
    const key = solvent.id;

    return (
      <div className="bg-NGrey-1 rounded-b-lg border-t border-NGrey-6 px-4 pt-8 pb-4">
        <div className="w-[288px]">
          <DropdownMenu
            label="Component unit"
            placeHolder="Choose unit"
            currentValue={childrens.unit}
            options={SOLVENT_UNITS}
            onChange={(e) => {
              const solvents = editedJob.composition.solvent.value;
              const compIndex = editedJob.composition.solvent.value.findIndex(
                (job) => job.id === key
              );
              solvents[compIndex].children.unit = e.target.value;
              setEditedJob({
                ...editedJob,
                composition: {
                  ...editedJob.composition,
                  solvent: {
                    ...editedJob.composition.solvent,
                    value: solvents
                  }
                }
              });
            }}
          />
        </div>
        {childrens.value.length < 2 && (
          <div className="mt-6 text-Error-7">
            Need at least two components to create a mixture
          </div>
        )}
        {childrens.value &&
          childrens.value.map((child, i) => (
            <div className="" key={`comp-${i}-child-${child.id}`}>
              <div className="flex gap-4  items-center ">
                <div className="flex w-full justify-between mt-6 items-center ">
                  <div className="flex gap-6">
                    <div className="w-[288px]">
                      <SearchDropdownMenu
                        moleculeBank={moleculesDropDown}
                        isError={isJobError[child.id]?.name || false}
                        openModal={(id) =>
                          setIsModalOpen(true, id, 'mixture', child.id, key)
                        }
                        closeModal={() => setIsModalOpen(false)}
                        openPreviewModal={(id) => {
                          setIsPreviewModalOpen(
                            true,
                            id,
                            'mixture',
                            child.id,
                            key
                          );
                        }}
                        closePreviewModal={() => setIsPreviewModalOpen(false)}
                        label="Mixture component name"
                        setJobSolute={(selectedMolecule) => {
                          const comp = editedJob.composition.solvent.value;
                          const objIndex =
                            editedJob.composition.solvent.value.findIndex(
                              (obj) => obj.id === key
                            );

                          const childIndex = comp[
                            objIndex
                          ].children.value.findIndex(
                            (obj) => obj.id === child.id
                          );
                          comp[objIndex].children.value[childIndex].molecule =
                            selectedMolecule;
                          if (!selectedMolecule) {
                            comp[objIndex].children.value[childIndex].density =
                              selectedMolecule;
                            return setEditedJob({
                              ...editedJob,
                              composition: {
                                ...editedJob.composition,
                                solvent: {
                                  ...editedJob.composition.solvent,
                                  value: comp
                                }
                              }
                            });
                          }

                          comp[objIndex].children.value[childIndex].density =
                            selectedMolecule.density;

                          setEditedJob({
                            ...editedJob,
                            composition: {
                              ...editedJob.composition,
                              solvent: {
                                ...editedJob.composition.solvent,
                                value: comp
                              }
                            }
                          });
                        }}
                        selectedMolecule={child}
                      />
                    </div>
                    <div className="max-w-[100px]">
                      <InputField
                        type="MINNUMBER"
                        label={childrens.unit}
                        isNoText={false}
                        inputValue={child?.value || ''}
                        setInputValue={(e) => {
                          const comp = editedJob.composition.solvent.value;
                          const objIndex =
                            editedJob.composition.solvent.value.findIndex(
                              (obj) => obj.id === key
                            );

                          const childIndex = comp[
                            objIndex
                          ].children.value.findIndex(
                            (obj) => obj.id === child.id
                          );

                          comp[objIndex].children.value[childIndex].value =
                            e.target.value;

                          setEditedJob({
                            ...editedJob,
                            composition: {
                              ...editedJob.composition,
                              solvent: {
                                ...editedJob.composition.solvent,
                                value: comp
                              }
                            }
                          });
                        }}
                        isError={isJobError[child.id]?.value}
                        name="mixtureName"
                        isRequired={false}
                      />
                    </div>
                    <div className="max-w-[100px]">
                      <InputField
                        label="Density"
                        name="Density"
                        type="NUMBER"
                        isNoText={false}
                        inputValue={child?.density || ''}
                        setInputValue={(e) => {
                          const comp = editedJob.composition.solvent.value;
                          const objIndex =
                            editedJob.composition.solvent.value.findIndex(
                              (obj) => obj.id === key
                            );

                          const childIndex = comp[
                            objIndex
                          ].children.value.findIndex(
                            (obj) => obj.id === child.id
                          );

                          comp[objIndex].children.value[childIndex].density =
                            e.target.value;

                          setEditedJob({
                            ...editedJob,
                            composition: {
                              ...editedJob.composition,
                              solvent: {
                                ...editedJob.composition.solvent,
                                value: comp
                              }
                            }
                          });
                        }}
                        isRequired={false}
                        isError={isJobError[child.id]?.density}
                      />
                    </div>
                  </div>
                  <div className="flex items-end pt-8">
                    <DefaultButton
                      type="text-primary"
                      icon="delete"
                      iconPosition="left"
                      label=""
                      onPressed={() => {
                        const comp = editedJob.composition.solvent.value;
                        const objIndex =
                          editedJob.composition.solvent.value.findIndex(
                            (obj) => obj.id === key
                          );

                        const childIndex = comp[
                          objIndex
                        ].children.value.findIndex(
                          (obj) => obj.id === child.id
                        );

                        // setting array of soft deleted composition/childcomps
                        const newChildren = childrens.value.filter(
                          (children) =>
                            children.id !== childrens.value[childIndex].id
                        );
                        comp[objIndex].children.value = newChildren;

                        setEditedJob({
                          ...editedJob,
                          composition: {
                            ...editedJob.composition,
                            solvent: {
                              ...editedJob.composition.solvent,
                              value: comp
                            }
                          }
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        {solvent.isParent && (
          <div className="mt-6 pb-2">
            <DefaultButton
              type="text-primary"
              icon="plus"
              iconPosition="left"
              label="Add mixture component"
              onPressed={() => {
                const comp = editedJob.composition.solvent.value;
                comp.map((com) => {
                  if (com.id === key) {
                    com.children.value.push({
                      id: uuidv4(),
                      molecule: null,
                      density: null,
                      value: '',
                      isParent: false
                    });
                  }
                  return com;
                });

                setEditedJob({
                  ...editedJob,
                  composition: {
                    ...editedJob.composition,
                    solvent: { ...editedJob.composition.solvent, value: comp }
                  }
                });
              }}
            />
          </div>
        )}
      </div>
    );
  }
  return (
    <div>
      <div className="text-[28px] font-semibold mt-6 flex gap-2 items-center">
        <p>Composition</p>
        <div>
          <DefaultButton
            label=""
            type="text-primary"
            onPressed={() => {
              openInNewTab(
                'https://compulartech.com/docs/create-job/#composition'
              );
            }}
            icon="help"
            iconPosition="left"
          />
        </div>
      </div>
      <div className="my-4">
        <p className=" font-bold text-xl text-NGrey-9 mb-4">Solutes</p>
        {editedJob.composition.solute.length === 0 && (
          <div className="w-full border border-NGrey-6 rounded-md bg-NGrey-1 flex flex-col mx-auto items-center py-6 gap-3">
            <p className="text-NGrey-7 font-bold text-lg">No solutes created</p>
            <p className="text-NGrey-7 font-normal text-lg">
              Add your first solute
            </p>
            <div className="flex gap-4">
              <DefaultButton
                type="contained-secondary"
                icon="plus"
                iconPosition="left"
                label="Add solute"
                onPressed={() => {
                  setEditedJob({
                    ...editedJob,
                    composition: {
                      ...editedJob.composition,
                      solute: [
                        ...editedJob.composition.solute,
                        {
                          id: uuidv4(),
                          molecule: null,
                          value: '',
                          unit: SOLUTES_UNITS[0],
                          isParent: false
                        }
                      ]
                    }
                  });
                }}
              />
            </div>
          </div>
        )}
        {editedJob.composition.solute.map((key, i) => (
          <div
            key={`comp-${i}-parent-${key.id}`}
            className="border border-NGrey-6 rounded-lg mb-4">
            <div
              className={`w-full flex items-center justify-between p-4 pb-12 ${
                i === 0 ? 'mt-4' : 'mt-8'
              }`}>
              <div className="flex gap-6">
                <div className="w-[352px] min-w-[352px]">
                  <SearchDropdownMenu
                    moleculeBank={moleculesDropDown}
                    openModal={(id) =>
                      setIsModalOpen(true, id, 'solute', key.id)
                    }
                    openPreviewModal={(id) => {
                      setIsPreviewModalOpen(true, id, 'solute', key.id);
                    }}
                    isError={isJobError?.[key.id]?.name || false}
                    label="Solute name"
                    setJobSolute={(selectedMolecule) => {
                      let comp = editedJob.composition.solute;
                      comp = comp.map((com) => {
                        if (com.id === key.id) com.molecule = selectedMolecule;
                        if (!selectedMolecule) {
                          com.density = null;
                        }
                        return com;
                      });
                      setEditedJob({
                        ...editedJob,
                        composition: {
                          ...editedJob.composition,
                          solute: comp
                        }
                      });
                    }}
                    selectedMolecule={key}
                  />
                </div>
                <div className="max-w-[100px]">
                  <InputField
                    type="MINNUMBER"
                    label="Amount"
                    inputValue={key?.value || ''}
                    isNoText={false}
                    setInputValue={(e) => {
                      let comp = editedJob.composition.solute;
                      comp = comp.map((com) => {
                        if (com.id === key.id) com.value = e.target.value;
                        return com;
                      });
                      setEditedJob({
                        ...editedJob,
                        composition: {
                          ...editedJob.composition,
                          solute: comp
                        }
                      });
                    }}
                    isError={isJobError[key.id]?.value}
                    name="mixtureName"
                    isRequired={false}
                  />
                </div>
                <div className="w-[288px]">
                  <DropdownMenu
                    label="Unit"
                    placeHolder="Choose unit"
                    currentValue={key.unit}
                    options={SOLUTES_UNITS}
                    onChange={(e) => {
                      const comp = editedJob.composition.solute;
                      const objIndex = comp.findIndex(
                        (obj) => obj.id === key.id
                      );
                      comp[objIndex].unit = e.target.value;
                      setEditedJob({
                        ...editedJob,
                        composition: {
                          ...editedJob.composition,
                          solute: comp
                        }
                      });
                    }}
                  />
                </div>
              </div>
              <div className="self-end flex items-center right-6 h-11 pb-2">
                <DefaultButton
                  type="text-primary"
                  icon="delete"
                  iconPosition="left"
                  label=""
                  onPressed={() => {
                    const allElements = editedJob.composition.solute.filter(
                      (com) => com.id !== key.id
                    );
                    setEditedJob({
                      ...editedJob,
                      composition: {
                        ...editedJob.composition,
                        solute: allElements
                      }
                    });
                  }}
                />
              </div>
            </div>
          </div>
        ))}
        {editedJob.composition.solute.length > 0 && (
          <div className="mt-6 flex gap-8">
            <DefaultButton
              type="text-secondary"
              icon="plus"
              iconPosition="left"
              label="Add solute"
              onPressed={() => {
                setEditedJob({
                  ...editedJob,
                  composition: {
                    ...editedJob.composition,
                    solute: [
                      ...editedJob.composition.solute,
                      {
                        id: uuidv4(),
                        molecule: null,
                        value: '',
                        unit: SOLUTES_UNITS[0],
                        isParent: false
                      }
                    ]
                  }
                });
              }}
            />
          </div>
        )}
      </div>
      <div className="my-4">
        <p className=" font-bold text-xl text-NGrey-9">Solvents</p>
      </div>
      <div className="w-[352px]  min-w-[200px] mb-4">
        <DropdownMenu
          label="Solvent unit"
          placeHolder="Choose unit"
          currentValue={editedJob.composition.solvent.unit}
          options={SOLVENT_UNITS}
          onChange={(e) => {
            setEditedJob({
              ...editedJob,
              composition: {
                ...editedJob.composition,
                solvent: {
                  ...editedJob.composition.solvent,
                  unit: e.target.value
                }
              }
            });
          }}
        />
      </div>
      {editedJob.composition.solvent.value.length === 0 && (
        <div
          className={`w-full border  rounded-md bg-NGrey-1 flex flex-col mx-auto items-center py-6 gap-3 ${
            isJobError.noSolvent ? 'border-Error-6' : 'border-NGrey-6'
          }`}>
          <p className="text-NGrey-7 font-bold text-lg">No solvents created</p>
          <p className="text-NGrey-7 font-normal text-lg">
            Add your first single solvent or mixture of solvents
          </p>
          <div className="flex gap-4">
            <DefaultButton
              type="contained-secondary"
              icon="plus"
              iconPosition="left"
              label="Add single solvent"
              onPressed={() => {
                setEditedJob({
                  ...editedJob,
                  composition: {
                    ...editedJob.composition,
                    solvent: {
                      ...editedJob.composition.solvent,
                      value: [
                        ...editedJob.composition.solvent.value,
                        {
                          id: uuidv4(),
                          name: '',
                          molecule: null,
                          density: null,
                          value: '',
                          isParent: false
                        }
                      ]
                    }
                  }
                });
              }}
            />
            <DefaultButton
              type="contained-secondary"
              icon="plus"
              iconPosition="left"
              label="Add mixture of solvents"
              onPressed={() => {
                setEditedJob({
                  ...editedJob,
                  composition: {
                    ...editedJob.composition,
                    solvent: {
                      ...editedJob.composition.solvent,
                      value: [
                        ...editedJob.composition.solvent.value,
                        {
                          id: uuidv4(),
                          name: '',
                          value: '',
                          isParent: true,
                          children: {
                            unit: SOLVENT_UNITS[0],
                            value: [
                              {
                                id: uuidv4(),
                                molecule: null,
                                density: null,
                                value: '',
                                isParent: false
                              },
                              {
                                id: uuidv4(),
                                molecule: null,
                                density: null,
                                value: '',
                                isParent: false
                              }
                            ]
                          }
                        }
                      ]
                    }
                  }
                });
              }}
            />
          </div>
        </div>
      )}
      {isJobError.noSolvent &&
        editedJob.composition.solvent.value.length === 0 && (
          <div className="mt-2 text-Error-7">Required*</div>
        )}
      {editedJob.composition.solvent.value.length > 0 &&
        editedJob.composition.solvent.value.map((key, i) => (
          <div
            key={`comp-${i}-parent-${key.id}`}
            className={`border  rounded-lg mb-4 ${
              key.isParent && key.children.value.length < 2
                ? 'border-Error-8'
                : 'border-NGrey-6'
            }`}>
            <div
              className={`w-full flex items-center justify-between p-4 pb-12 ${
                i === 0 ? 'mt-4' : 'mt-8'
              }`}>
              <div className="flex gap-6">
                <div className="w-[352px] min-w-[352px]">
                  {key.isParent ? (
                    <InputField
                      label={key.isParent ? 'Mixture name' : 'Solvent name'}
                      inputValue={key?.name || ''}
                      isNoText={false}
                      setInputValue={(e) => {
                        let comp = editedJob.composition.solvent.value;
                        comp = comp.map((com) => {
                          if (com.id === key.id) com.name = e.target.value;
                          return com;
                        });
                        setEditedJob({
                          ...editedJob,
                          composition: {
                            ...editedJob.composition,
                            solvent: {
                              ...editedJob.composition.solvent,
                              value: comp
                            }
                          }
                        });
                      }}
                      isError={isJobError[key.id]?.name}
                      name="mixtureName"
                      isRequired={false}
                    />
                  ) : (
                    <SearchDropdownMenu
                      isError={isJobError[key.id]?.name || false}
                      moleculeBank={moleculesDropDown}
                      openPreviewModal={(id) => {
                        setIsPreviewModalOpen(true, id, 'solvent', key.id);
                      }}
                      openModal={(id) =>
                        setIsModalOpen(true, id, 'solvent', key.id)
                      }
                      label="Solvent name"
                      setJobSolute={(selectedMolecule) => {
                        let comp = editedJob.composition.solvent.value;
                        comp = comp.map((com) => {
                          if (com.id === key.id) {
                            const solventComp = { ...com };
                            solventComp.molecule = selectedMolecule;
                            if (!selectedMolecule) {
                              solventComp.density = selectedMolecule;
                              return solventComp;
                            }
                            solventComp.density = selectedMolecule.density;
                            return solventComp;
                          }
                          return com;
                        });
                        setEditedJob({
                          ...editedJob,
                          composition: {
                            ...editedJob.composition,
                            solvent: {
                              ...editedJob.composition.solvent,
                              value: comp
                            }
                          }
                        });
                      }}
                      selectedMolecule={key}
                    />
                  )}
                </div>
                <div className="max-w-[100px]">
                  <InputField
                    type="MINNUMBER"
                    label={editedJob.composition.solvent.unit}
                    inputValue={key?.value || ''}
                    isNoText={false}
                    setInputValue={(e) => {
                      let comp = editedJob.composition.solvent.value;
                      comp = comp.map((com) => {
                        if (com.id === key.id) com.value = e.target.value;
                        return com;
                      });
                      setEditedJob({
                        ...editedJob,
                        composition: {
                          ...editedJob.composition,
                          solvent: {
                            ...editedJob.composition.solvent,
                            value: comp
                          }
                        }
                      });
                    }}
                    isError={isJobError[key.id]?.value}
                    name="mixtureName"
                    isRequired={false}
                  />
                </div>
                {key.isParent ? null : (
                  <div className="max-w-[100px]">
                    <InputField
                      label="Density"
                      name="Density"
                      type="NUMBER"
                      isNoText={false}
                      inputValue={key?.density || ''}
                      setInputValue={(e) => {
                        let comp = editedJob.composition.solvent.value;
                        comp = comp.map((com) => {
                          if (com.id === key.id) com.density = e.target.value;
                          return com;
                        });
                        setEditedJob({
                          ...editedJob,
                          composition: {
                            ...editedJob.composition,
                            solvent: {
                              ...editedJob.composition.solvent,
                              value: comp
                            }
                          }
                        });
                      }}
                      isRequired={false}
                      isError={isJobError[key.id]?.density}
                    />
                  </div>
                )}
              </div>
              <div className="self-end flex items-center pb-2 right-6 h-11">
                <DefaultButton
                  type="text-primary"
                  icon="delete"
                  iconPosition="left"
                  label=""
                  onPressed={() => {
                    const allElements =
                      editedJob.composition.solvent.value.filter(
                        (com) => com.id !== key.id
                      );
                    setEditedJob({
                      ...editedJob,
                      composition: {
                        ...editedJob.composition,
                        solvent: {
                          ...editedJob.composition.solvent,
                          value: allElements
                        }
                      }
                    });
                  }}
                />
              </div>
            </div>
            {key.children &&
              key.children.value &&
              renderChildrens(key.children, key)}
          </div>
        ))}
      {editedJob.composition.solvent.value.length > 0 && (
        <div className="mt-6 flex gap-8">
          <DefaultButton
            type="text-secondary"
            icon="plus"
            iconPosition="left"
            label="Add single solvent"
            onPressed={() => {
              setEditedJob({
                ...editedJob,
                composition: {
                  ...editedJob.composition,
                  solvent: {
                    ...editedJob.composition.solvent,
                    value: [
                      ...editedJob.composition.solvent.value,
                      {
                        id: uuidv4(),
                        name: '',
                        molecule: null,
                        value: '',
                        isParent: false
                      }
                    ]
                  }
                }
              });
            }}
          />
          <DefaultButton
            type="text-secondary"
            icon="plus"
            iconPosition="left"
            label="Add mixture of solvents"
            onPressed={() => {
              setEditedJob({
                ...editedJob,
                composition: {
                  ...editedJob.composition,
                  solvent: {
                    ...editedJob.composition.solvent,
                    value: [
                      ...editedJob.composition.solvent.value,
                      {
                        id: uuidv4(),
                        name: '',
                        value: '',
                        isParent: true,
                        children: {
                          unit: SOLVENT_UNITS[0],
                          value: [
                            {
                              id: uuidv4(),
                              molecule: null,
                              value: '',
                              isParent: false
                            },
                            {
                              id: uuidv4(),
                              molecule: null,
                              value: '',
                              isParent: false
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              });
            }}
          />
        </div>
      )}
    </div>
  );
}
Compositions.propTypes = {
  editedJob: PropTypes.any.isRequired,
  isJobError: PropTypes.any.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  setIsPreviewModalOpen: PropTypes.func.isRequired,
  setEditedJob: PropTypes.func.isRequired
};
