// @copyright (c) 2022-2024 Compular AB
import { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Chart, registerables } from 'chart.js';
import { Chart as ChartBar } from 'react-chartjs-2';
import {
  BarWithErrorBarsController,
  BarWithErrorBar
} from 'chartjs-chart-error-bars';
import { convertHeader } from '../../utils/utils';

Chart.register(...registerables, BarWithErrorBarsController, BarWithErrorBar);

/**
 * @param {any} label Label displayed above button
 * @param {string} header Type of button
 * @param {any} dataJob
 * @returns
 */

// todo set max length to datajob and render max 20. pagination for the rest
export function Barchart({ dataJob, label, header }) {
  const [data, setData] = useState(null);
  const formattedHeader = useMemo(() => {
    return convertHeader(header);
  }, [header]);

  const getErrorValue = () => {
    return {
      labels: label,
      datasets: [
        {
          data: dataJob.map((val) => {
            let oData = {};
            oData = {
              ...oData,
              y: Math.round((val.data + Number.EPSILON) * 1000) / 1000
            };

            if (val.error && val.error > 0.000000001)
              return {
                y: Math.round((val.data + Number.EPSILON) * 1000) / 1000,
                yMin:
                  Math.round((val.data - val.error + Number.EPSILON) * 1000) /
                  1000,
                yMax:
                  Math.round((val.data + val.error + Number.EPSILON) * 1000) /
                  1000
              };

            return oData;
          }),
          error: dataJob.map(({ error }) => {
            if (error)
              return Math.round((error + Number.EPSILON) * 1000) / 1000;
            return null;
          }),
          backgroundColor: 'rgba(242,147,61,0.85)',
          borderRadius: 2
        }
      ]
    };
  };

  useEffect(() => {
    setData(getErrorValue());
  }, []); // Run once during initial render
  const options = {
    type: 'barWithErrorBars',
    redraw: false,
    indexAxis: 'x',
    animation: {
      duration: 2
    },
    interaction: {
      mode: 'index',
      axis: 'x'
    },
    scales: {
      x: {
        ticks: {
          display: !(label.length > 30),
          callback: (value, index) => {
            if (label[index].length > 15)
              return `${label[index].substring(0, 10)}...`;

            return label[index];
          }
        },
        grid: {
          display: false
        }
      },
      y: {
        stacked: false
      }
    },

    responsive: true,
    plugins: {
      tooltip: {
        padding: 12,

        callbacks: {
          label: (context) => {
            return dataJob[context.dataIndex].value;
          },
          afterLabel: (context) => {
            if (context.dataset.error[context.dataIndex])
              return `± ${context.dataset.error[context.dataIndex]}`;
          }
        }
      },
      legend: {
        display: false
      },
      maintainAspectRatio: false,
      title: {
        display: true,
        position: 'left',
        font: {
          size: 10
        },
        text: formattedHeader
      }
    }
  };
  if (label.length !== dataJob.length) return 'Failed to load image';
  return (
    <div className="h-full w-full flex justify-center items-center p-8">
      {data && (
        <ChartBar
          type="barWithErrorBars"
          redraw={false}
          updateMode="resize"
          options={options}
          data={data}
        />
      )}
    </div>
  );
}
export default Barchart;
Barchart.propTypes = {
  label: PropTypes.instanceOf(Array).isRequired,
  header: PropTypes.string.isRequired,
  dataJob: PropTypes.instanceOf(Array).isRequired
};
