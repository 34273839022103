// @copyright (c) 2022-2024 Compular AB
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import CloseIcon from '../../icons/CloseIcon';
import BarChart from '../Charts/BarChart';

/**
 * A modal that allows the user to contact us.
 *
 * @param isOpen boolean
 * @param close func
 * @param {array} jobData data
 * @param {array} labels data
 * @param {string} headers data
 * @returns
 */
const customModalStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    width: '1200px',
    height: '650px',
    minHeight: '650px',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '16px',
    borderRadius: '5px'
  }
};
export default function ChartModal({
  isOpen,
  close,
  jobData,
  headers,
  labels
}) {
  return (
    <Modal
      style={customModalStyle}
      isOpen={isOpen}
      ariaHideApp={false}
      onRequestClose={close}>
      <div className="w-full h-full relative">
        <div className="w-full min-h-full flex flex-col items-center gap-2 px-2">
          <div className="flex w-full justify-end items-center">
            <CloseIcon
              className="h-5 w-5 cursor-pointer hover:text-NGrey-8"
              onClick={close}
            />
          </div>
          <div
            className=" h-full min-h-full flex items-center justify-center"
            style={{ width: '1000px', height: '600px' }}>
            {jobData.length > 0 ? (
              <BarChart dataJob={jobData} label={labels} header={headers} />
            ) : (
              'Failed to load image'
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}
ChartModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  jobData: PropTypes.instanceOf(Array).isRequired,
  labels: PropTypes.instanceOf(Array).isRequired,
  headers: PropTypes.string.isRequired
};
